import React, { useState, useEffect } from 'react'
import { Row, Spin } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_PODCASTS } from './graphql/Queries'
import CardLoader from './components/CardLoader'
import PodcastWrapper from './components/podcastWrapper'
import Meta from '../../components/Meta'
import './podcasts.css'
import AddPodcastCard from './components/AddPodcastCard'

export default function (props) {
  const [lastShowId, setLastShowId] = useState()
  const [podcasts, setPodcasts] = useState([])
  const {
    data,
    loading: isPodcastsLoading,
    error: podcastsError,
    fetchMore,
  } = useQuery(GET_PODCASTS, {
    variables: { first: 20 },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isPodcastsLoading && data && data.listPodcastDetails) {
      setPodcasts(data.listPodcastDetails)
      setLastShowId(
        data &&
        data.listPodcastDetails &&
        data.listPodcastDetails[data.listPodcastDetails.length - 1] &&
        data.listPodcastDetails[data.listPodcastDetails.length - 1].id,
      )
    }
  }, [isPodcastsLoading])

  const { history } = props
  return !podcastsError ? (
    <div className="shows-scroll">
      <Row gutter={24} type="flex" className="podcasts-wrapper podcasts-list">
        <Meta title="Podcast" description="" />
        <AddPodcastCard history={history} />
        {isPodcastsLoading ? (
          <CardLoader />
        ) : (
          <PodcastWrapper
            podcasts={podcasts}
            onLoadMore={() =>
              fetchMore({
                variables: {
                  first: 20,
                  after: lastShowId,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  const { listPodcastDetails: newPodcasts } = fetchMoreResult
                  if (newPodcasts.length) {
                    setLastShowId(newPodcasts[newPodcasts.length - 1].id)
                    setPodcasts([...podcasts, ...newPodcasts])
                  }
                },
              })
            }
          />
        )}
      </Row>
    </div>
  ) : (
    <>{podcastsError.message}</>
  )
}