import gql from 'graphql-tag'

export const GET_VIDEOS = gql`
  query videos($first: Int!, $after: String) {
    videos(first: $first, after: $after) {
      id
      name
      description
      videoNumber
      image
      logoImage
      allowedContinents
      rating
      status
      isLive
      scheduleAt
      discussionId
      videoState
      audioState
      muxAssetId
      streamKey
      uploadId
      thumbnail
      muxPlaybackId
      videoURL
      duration
      videoAccess
      liveChatAccess
      createdAt
      updatedAt
    }
  }
`
export const GET_VIDEO = gql`
  query video($id: ID!) {
    video(where: { id: $id }) {
      id
      name
      description
      videoNumber
      image
      logoImage
      allowedContinents
      rating
      status
      isLive
      scheduleAt
      discussionId
      videoState
      audioState
      muxAssetId
      streamKey
      uploadId
      thumbnail
      muxPlaybackId
      videoURL
      duration
      videoAccess
      liveChatAccess
      captions {
        id 
        name
        url
        language
        muxTrackId
        status
      }
      createdBy {
        id
      }
      createdAt
      updatedAt
      allowedCountries
    }
  }
`

export const GET_VIDEO_LIVE_STREAM_URL = gql`
  query getVideoLiveStreamUrl($id: ID!) {
    getVideoLiveStreamUrl(where: { id: $id }) {
      videoURL
      streamKey
    }
  }
`

export const GET_VIDEO_SIGNED_PUT_URL = gql`
  query getVideoSignedPutUrl(
    $fileName: String!
    $videoId: String!
    $contentType: String!
  ) {
    getVideoSignedPutUrl(
      data: {
        fileName: $fileName
        videoId: $videoId
        contentType: $contentType
      }
    ) {
      signedUrl
      getUrl
    }
  }
`

export const GET_VIDEO_SIGNED_VIDEO_PUT_URL = gql`
  query getVideoSignedVideoPutUrl($videoId: ID, $corsOrigin: String!) {
    getVideoSignedVideoPutUrl(
      corsOrigin: $corsOrigin
      where: { id: $videoId }
    ) {
      signedUrl
    }
  }
`

export const GET_MUX_DATA = gql`
  query getMuxData($assetId: String!) {
    getMuxData(where: { assetId: $assetId })
  }
`
export const CAPTION_SIGNED_PUT_URL = gql`
  query captionSingedPutUrl($fileName: String!) {
    captionSingedPutUrl(fileName: $fileName){
      fileName
      signedUrl
      getUrl
    }
  }
`