import React, { useState, useRef } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import {
  Row,
  Col,
  Button,
  PageHeader,
  Tag,
  notification,
  Modal,
  Divider,
} from 'antd'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { GET_EPISODE, GET_EPISODE_SIGNED_PUT_URL } from './graphql/Queries'
import {
  SUBSCRIBE_EPISODE,
  SUBSCRIBE_EPISODE_SEGMENTS,
} from './graphql/Subscriptions'
import {
  UPDATE_EPISODE,
  DELETE_EPISODE,
  UPDATE_EPISODE_STATUS,
} from './graphql/Mutations'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import AddEpisodeModal from './components/AddEpisodeModal'
import Segment from './components/Segment'
import SegmentLive from '../segment/components/SegmentLive'
import client from '../../apollo'
import { fileUpload } from '../../common/fileUpload'
import './episode.css'
import { LOCATIONS } from '../../common/constants'

const { confirm } = Modal
function checkStatus(status) {
  if (
    status === 'LIVE_FINISHED' ||
    status === 'DRAFT' ||
    status === 'UNPUBLISHED'
  ) {
    return false
  }
  return true
}

export default function (props) {
  const {
    history,
    match: {
      params: { episodeId, showId, featured },
    },
  } = props
  const {
    data: episodeData,
    loading: episodeLoading,
    error: episodeError,
  } = useQuery(GET_EPISODE, { variables: { id: episodeId } })
  const [showModal, setShowModal] = useState(false)
  const [openImageModal, setOpenImageModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const saveFormRef = useRef()
  useSubscription(SUBSCRIBE_EPISODE, { variables: { id: episodeId } })

  let SegmentNodes = []
  if (
    episodeData &&
    episodeData.episode &&
    episodeData.episode.segments[0]?.id
  ) {
    SegmentNodes = [{ node: { id: episodeData.episode.segments[0].id } }]
  }
  useSubscription(SUBSCRIBE_EPISODE_SEGMENTS, {
    variables: { nodeArray: SegmentNodes },
  })

  let selectedSeasonId
  let selectedSeasonEpisodeId
  if (
    episodeData?.episode?.seasonEpisode[0]?.id &&
    episodeData?.episode?.seasonEpisode[0]?.season &&
    episodeData?.episode?.seasonEpisode[0]?.season?.id
  ) {
    selectedSeasonEpisodeId = episodeData?.episode?.seasonEpisode[0]?.id
    selectedSeasonId = episodeData?.episode?.seasonEpisode[0]?.season?.id
  }

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleRequestFail(e) {
    setShowModal(false)
    setIsSubmit(false)
    openNotification('error', e || 'Something Went Wrong')
  }

  function handleCancel() {
    setShowModal(false)
    saveFormRef.current.props.form.resetFields()
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setIsSubmit(true)
      const {
        title,
        description,
        episodeNumber,
        image,
        isFeatured,
        featuredBannerImage,
        featureBannerWeight,
        schedule,
        videoAccess,
        liveChatAccess,
        createdAt,
        seasonId,
        allowedContinents,
        rating,
        locationRestriction,
        limitBy,
        allowedCountries
      } = values
      const {
        match: {
          params: { episodeId },
        },
      } = props
      try {
        let imageFileName = ''
        let featuredBannerImageFileName = ''
        let scheduleAt = null
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        if (schedule) {
          scheduleAt = schedule
        }
        if (
          typeof featuredBannerImage !== 'string' &&
          featuredBannerImage &&
          featuredBannerImage.length > 0 &&
          featuredBannerImage[0] &&
          featuredBannerImage[0].status !== 'done'
        ) {
          featuredBannerImageFileName = featuredBannerImage[0].name
        }

        if(locationRestriction){
          if(limitBy === LOCATIONS.CONTINENT){
            values.allowedCountries = []
          }
          else{
            values.allowedContinents = []
          }
        }
        else if(!locationRestriction){
          values.allowedContinents = []
          values.allowedCountries = []
        }

        const data = {
          title,
          description,
          episodeNumber,
          scheduleAt,
          isFeatured,
          createdAt,
          allowedContinents: { set: values.allowedContinents },
          rating: rating || null,
          allowedCountries : values.allowedCountries,
        }

        if (featureBannerWeight) {
          data.featureBannerWeight = parseInt(featureBannerWeight)
        }

        if (episodeData && episodeData.episode.isLive === true) {
          data.segments = {
            update: {
              data: {
                videoAccess: { set: videoAccess },
                audioAccess: { set: videoAccess },
                liveChatAccess: { set: liveChatAccess },
              },
              where: {
                id: episodeData.episode.segments[0].id,
              },
            },
          }
        }

        if (seasonId && selectedSeasonEpisodeId && seasonId !== selectedSeasonId) {
          data.seasonEpisode = {
            update: {
              data: {
                season: {
                  connect: {
                    id: seasonId
                  }
                }
              },
              where: {
                id: selectedSeasonEpisodeId
              }
            }
          }
        } else if (seasonId && !selectedSeasonEpisodeId && !selectedSeasonId) {
          data.seasonEpisode = {
            create: {
              season: { connect: { id: seasonId } },
              publishDate: moment(),
              weight: 0
            }
          }
        }

        await client.mutate({
          mutation: UPDATE_EPISODE,
          variables: {
            data: data,
            where: { id: episodeId },
          },
          refetchQueries: [
            {
              query: GET_EPISODE,
              variables: { id: episodeId },
            },
          ],
        })
        if (image && image.length > 0) {
          const tempFilename = image[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${episodeId}-${new Date().getTime()}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_EPISODE_SIGNED_PUT_URL,
            variables: { fileName, episodeId, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getEpisodeSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_EPISODE,
            variables: {
              data: {
                image: fileName,
              },
              where: { id: episodeId },
            },
            refetchQueries: [
              {
                query: GET_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
        }

        if (
          typeof featuredBannerImage !== 'string' &&
          featuredBannerImage &&
          featuredBannerImage.length > 0 &&
          featuredBannerImage[0] &&
          featuredBannerImage[0].status !== 'done'
        ) {
          const tempFilename = featuredBannerImage[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${episodeId}-feature-${new Date().getTime()}.${fileExt}`
          const contentType = featuredBannerImage[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_EPISODE_SIGNED_PUT_URL,
            variables: { fileName, episodeId, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getEpisodeSignedPutUrl.signedUrl,
            featuredBannerImage[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_EPISODE,
            variables: {
              data: {
                featuredBannerImage: fileName,
              },
              where: { id: episodeId },
            },
            refetchQueries: [
              {
                query: GET_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
        }

        openNotification('success', 'Episode Updated Successfully')
        form.resetFields()
        setShowModal(false)
        setIsSubmit(false)
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail()
      }
    })
  }

  function showConfirm(showId, episodeId) {
    const { history } = props
    confirm({
      title: 'Are you sure you want to DELETE this episode?',
      content: 'Upon clicking DELETE, the episode will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          await client.mutate({
            mutation: DELETE_EPISODE,
            variables: { episodeId },
          })
          openNotification('success', 'Episode Deleted Successfully')
          setTimeout(() => {
            if (featured) {
              history.push(`/featured-episodes`)
            } else {
              history.push(`/shows/${showId}`)
            }
          }, 500)
        } catch (e) {
          Sentry.captureException(e)
          let errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
          handleRequestFail(errorMessage)
        }
      },
    })
  }

  function publishEpisode(showId, episodeId, status) {
    if (status === 'SCHEDULED' && episodeData.episode.isLive &&
      episodeData.episode.segments && episodeData.episode.segments[0].simulateLive &&
      episodeData.episode.segments[0].liveVideoURL === null) {
      openNotification('error', 'Please Upload Live Simulation Video')
      return
    }

    const title =
      status === 'PUBLISHED'
        ? 'Are you sure you want to publish?'
        : status === 'SCHEDULED'
          ? 'Are you sure you want to schedule?'
          : 'Are you sure you want to unpublish?'
    const content =
      status === 'PUBLISHED'
        ? 'Upon clicking publish, this episode will become available to front end users'
        : status === 'SCHEDULED'
          ? 'Upon clicking schedule, this episode will become available to front end users'
          : 'Upon clicking unpublish, this episode will become unavailable to front end users'
    const okText =
      status === 'PUBLISHED'
        ? 'PUBLISH'
        : status === 'SCHEDULED'
          ? 'SCHEDULE'
          : 'UNPUBLISH'
    const okType = status === 'UNPUBLISHED' ? 'danger' : 'primary'
    const notification =
      status === 'PUBLISHED'
        ? 'Episode Published Successfully'
        : status === 'SCHEDULED'
          ? 'Episode Scheduled Successfully'
          : 'Episode Was Successfully Unpublished'

    confirm({
      title,
      content,
      okText,
      okType,
      async onOk() {
        try {
          await client.mutate({
            mutation: UPDATE_EPISODE_STATUS,
            variables: { showId, episodeId, status },
            refetchQueries: [
              {
                query: GET_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', notification)
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail()
        }
      },
    })
  }

  return (
    <>
      {episodeLoading ? (
        <div className="callback-wrapper">
          <Spinner />
        </div>
      ) : episodeError ? (
        <>{episodeError.message}</>
      ) : episodeData &&
        episodeData.episode &&
        episodeData.episode.show &&
        episodeData.episode.show.id === showId ? (
        <>
          <Meta title={episodeData.episode.title || ''} description="Episode" />
          <AddEpisodeModal
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            handleCancel={handleCancel}
            handleCreate={handleCreate}
            title={episodeData.episode.title}
            description={episodeData.episode.description}
            episodeNumber={episodeData.episode.episodeNumber}
            isLive={episodeData.episode.isLive}
            videoAccess={episodeData.episode.segments[0].videoAccess}
            liveChatAccess={episodeData.episode.segments[0].liveChatAccess}
            simulateLive={episodeData.episode.segments[0].simulateLive}
            showIsLIve={episodeData.episode.isLive}
            scheduleAt={episodeData.episode.scheduleAt}
            createdAt={episodeData.episode.createdAt}
            isFeatured={
              episodeData &&
              episodeData.episode &&
              episodeData.episode.isFeatured
            }
            featuredBannerImage={episodeData.episode.featuredBannerImage}
            featureBannerWeight={episodeData.episode.featureBannerWeight}
            isEdit={
              episodeData.episode.status === 'PUBLISHED' ||
                episodeData.episode.status === 'SCHEDULED' ||
                episodeData.episode.status === 'LIVE' ||
                episodeData.episode.status === 'LIVE_FINISHED'
                ? true
                : false
            }
            episodeId={episodeData.episode.id}
            seasonEpisode={episodeData.episode.seasonEpisode}
            showId={showId}
            seasonId={selectedSeasonId}
            allowedContinents={episodeData.episode.allowedContinents}
            rating={episodeData.episode.rating}
            allowedCountries={episodeData?.episode?.allowedCountries}
          />
          <Row gutter={24} type="flex" className="episode-title-wrapper">
            <Col span={24}>
              <PageHeader
                onBack={() => {
                  if (featured) {
                    history.push(`/featured-episodes`)
                  } else {
                    history.push(`/shows/${episodeData.episode.show.id}`)
                  }
                }}
                title={episodeData.episode.title}
                tags={
                  <Tag className={episodeData.episode.status}>
                    {episodeData.episode.status}
                  </Tag>
                }
                className="d-flex box align-center"
                extra={[
                  <Button
                    id='btn-episode-edit'
                    key="1"
                    shape="circle"
                    icon="edit"
                    size="small"
                    onClick={() => setShowModal(true)}
                  />,
                  <Button
                    id='btn-episode-delete'
                    key="2"
                    shape="circle"
                    size="small"
                    icon="delete"
                    type="danger"
                    ghost={true}
                    onClick={() =>
                      showConfirm(episodeData.episode.show.id, episodeId)
                    }
                    disabled={(episodeData.episode.isLive && episodeData.episode.status === 'LIVE') ? true : false }
                  />,
                  episodeData.episode.isLive &&
                    episodeData.episode.status !== 'SCHEDULED' ? (
                    <Button
                      id='btn-episode-schedule'
                      key="3"
                      type="primary"
                      size="small"
                      disabled={checkStatus(episodeData.episode.status)}
                      onClick={() =>
                        publishEpisode(
                          episodeData.episode.show.id,
                          episodeId,
                          'SCHEDULED',
                        )
                      }
                    >
                      SCHEDULE
                    </Button>
                  ) : episodeData.episode.status !== 'PUBLISHED' ? (
                    <Button
                      id='btn-episode-publish'
                      key="3"
                      type="primary"
                      size="small"
                      disabled={checkStatus(episodeData.episode.status)}
                      onClick={() =>
                        publishEpisode(
                          episodeData.episode.show.id,
                          episodeId,
                          'PUBLISHED',
                        )
                      }
                    >
                      PUBLISH
                    </Button>
                  ) : (
                    <Button
                      id='btn-episode-unpublish'
                      key="3"
                      type="danger"
                      size="small"
                      onClick={() =>
                        publishEpisode(
                          episodeData.episode.show.id,
                          episodeId,
                          'UNPUBLISHED',
                        )
                      }
                    >
                      UNPUBLISH
                    </Button>
                  ),
                ]}
              />
            </Col>
          </Row>
          <div className="box episode-description-div-wrapper">
            <Row
              gutter={24}
              type="flex"
              className="episode-description-wrapper"
            >
              <Col sm={24} md={6} lg={9} xl={6}>
                <Row>
                  <Col>
                    <div className="episode-card text-center">
                      <img
                        src={episodeData.episode.image}
                        alt={episodeData.episode.title}
                        title={episodeData.episode.title}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {episodeData.episode.isFeatured && (
                      <div className="mt-2">
                        <Button type="primary" onClick={() => setOpenImageModal(true)}>
                          View Featured Image
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={24} md={18} lg={15} xl={18}>
                <div className="episode-description">
                  {episodeData.episode.description}
                </div>
                <Modal
                  visible={openImageModal}
                  maskClosable={false}
                  title="Featured Image"
                  onCancel={() => setOpenImageModal(false)}
                  onOk={() => setOpenImageModal(false)}
                >
                  <img
                    style={{
                      width: '100%',
                      height: ' 200px',
                      objectFit: ' contain',
                    }}
                    alt={episodeData.episode.name}
                    src={episodeData.episode.featuredBannerImage}
                  />
                </Modal>
              </Col>
            </Row>
          </div>
          <Row gutter={24} type="flex" style={{ margin: '0px 20px' }}>
            <Divider />
          </Row>
          <Row
            gutter={24}
            type="flex"
            className={`episode-wrapper ${episodeData.episode.isLive ? 'live-segment-wrapper' : ''
              }`}
          >
            {!featured && episodeData.episode.isLive ? (
              <SegmentLive
                episodeId={episodeData.episode.id}
                episodeTitle={episodeData.episode.title}
                episodeStatus={episodeData.episode.status}
                episodeImage={episodeData.episode.image}
                segmentId={episodeData.episode.segments[0].id}
                segmentImage={episodeData.episode.segments[0].image}
                scheduleAt={episodeData.episode.scheduleAt}
                videoState={episodeData.episode.segments[0].videoState}
                discussionId={episodeData.episode.discussionId}
                segmentUpdatedAt={episodeData.episode.segments[0].updatedAt}
                simulateLive={episodeData.episode.segments[0].simulateLive}
                liveVideoURL={episodeData.episode.segments[0].liveVideoURL}
              />
            ) : (
              <Segment episodeId={episodeId} />
            )}
          </Row>
        </>
      ) : (
        <Page404 />
      )}
    </>
  )
}
