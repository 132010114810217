import React from 'react'
import { Col, Button } from 'antd'

function PodcastImage(props) {

  const { id, image, handleRemoveImage, indexId } = props

  return (
    <Col
      xs={24}
      sm={24}
      md={8}
      lg={4}
      xl={4}
    >
      <div className="tile-card-premiere-col-wrapper">
        <div className="tile-card-podcast-premiere-wrapper">
          <div className="tile-card-delete">
            <Button
              id={`btn-podcast-image-override-${indexId}-delete`}
              key="2"
              size="default"
              icon="delete"
              type="danger"
              onClick={handleRemoveImage}
            />
          </div>
          <div className="episode-card">
            <div className="text-center d-flex">
              <img src={`${image}?w=300&h=300`} className="podcast-upload-image" alt={"podcast"} />
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default PodcastImage
