import React, { useContext } from 'react'
import { Layout, Menu, Icon, Dropdown, Avatar, Modal, notification } from 'antd'
import { NavLink } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { AppContext } from '../../AppContext'
import client from '../../apollo'
import { CHANGE_PASSWORD } from '../graphql/Queries'

const { Header } = Layout
// const Search = Input.Search
const confirm = Modal.confirm
// const Option = Select.Option

export default function (props) {
  const { state } = useContext(AppContext)
  const { collapsed, toggle } = props //authorChange

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleRequestFail() {
    openNotification("error", "Something Went Wrong")
  }

  async function changePassword() {
    confirm({
      title: 'Are you sure you want to change your password?',
      // content: 'When clicked the DELETE button, this show will be deleted',
      okText: 'Change Password',
      async onOk() {
        try {
          await client.mutate({
            mutation: CHANGE_PASSWORD,
            variables: { email: state.currentUser.email }
          }).then((result) => (
            openNotification('success', result.data.changePassword.message)
          )).catch((e) => {
            handleRequestFail()
          })
        }
        catch (e) {
          Sentry.captureException(e)
          handleRequestFail()
        }
      },
    })
  }
  const menu = (
    <Menu>
      {
        state.currentUser.email &&
        <Menu.Item>
          {/* eslint-disable-next-line */}
          <a rel="noopener noreferrer" onClick={() => changePassword()}>Change Password</a>
        </Menu.Item>
      }
      <Menu.Item>
        <NavLink to="/logout" className="nav-text">
          Logout
        </NavLink>
      </Menu.Item>
    </Menu>
  )
  return (
    <Header className="header">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Icon
            className="trigger"
            type={collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={toggle}
          />
        </div>
        <div style={{ display: 'flex' }}>
          {/* <div
            className="menu-divider menu-divider-space"
          >
            <Search
              placeholder="Search"
              onSearch={value => console.log(value)}
            />
          </div> */}
          {/* <div
            span={3}
            className="menu-divider menu-divider-space"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select Author"
              optionFilterProp="children"
              onChange={authorChange}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="jack">Jack</Option>
              <Option value="lucy">Lucy</Option>
              <Option value="tom">Tom</Option>
            </Select>
          </div> */}
          {/* <div
                    className="menu-divider menu-divider-space notification"
                  >
                    <Badge count={5}>
                      <Icon type="bell" />
                    </Badge>
                  </div> */}
          {
            state.currentUser.firstName &&
            <div className="menu-divider-space user">
              <div>
                <Avatar>{state.currentUser.firstName.charAt(0)}</Avatar>
                <Dropdown overlay={menu}>
                  {/* eslint-disable-next-line */}
                  <a className="ant-dropdown-link" style={{ paddingLeft: 10, divor: 'inherit' }}>
                    {state.currentUser.firstName} {state.currentUser.lastName && state.currentUser.lastName} <Icon type="down" />
                  </a>
                </Dropdown>
              </div>
            </div>
          }
        </div>
      </div>
    </Header>
  )
}
