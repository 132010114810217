import gql from 'graphql-tag'

const GET_DISCUSSIONS = gql`
query discussions($skip: Int, $first: Int) {
  discussions(orderBy: scheduleAt_DESC, where: { type: DISCUSSION }, skip: $skip, first: $first) {
    id
    title
    messagesCount
    isLive
    status
    owner{
      id
      firstName
      lastName
      email
    }
    createdAt
    scheduleAt
  }
}
`

const GET_AUTHOR_LIST = gql`
query authorList($skip: Int, $first: Int,$where:AuthorListWhereInput){
  authorList(skip: $skip, first: $first,where:$where) {
    id
    email
    role
    firstName
    lastName
  }
}
`

export { GET_DISCUSSIONS, GET_AUTHOR_LIST }
