import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Radio, Icon } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce, cloneDeep, isEqual, uniqBy } from 'lodash'
import client from '../../../../apollo'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_PODCASTS } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE, DELETE_CAROUSEL_PODCAST } from '../../graphql/Mutations'
import PodcastListCard from './PodcastListCard'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import MoveArrow from '../MoveArrow'
import usePrevious from '../usePrevious'

const confirm = Modal.confirm
let debounceJob
const defaultPageSize = 5

function PodcastCarousel(props) {
  const { podcastModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [search, setSearch] = useState('')
  const [podcasts, setPodcasts] = useState(podcastModuleData.podcasts || [])
  const [isPublished, setIsPublished] = useState(podcastModuleData.isPublished || false)
  const [podcastNextOrder, setPodcastNextOrder] = useState(podcastModuleData.nextOrder || 1)
  const [orderChangeModal, setOrderChangeModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [current, setCurrent] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const oldPodcastsProp = usePrevious(podcastModuleData.podcasts || [])
  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {
    if (!isEqual(oldPodcastsProp, podcastModuleData.podcasts)) {
      setPodcasts(podcastModuleData.podcasts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastModuleData.podcasts])

  const id_not = podcasts.map(({ id }) => (id));
  const [getPodcasts, {
    data: podcastsData,
    loading: dataLoading
  }] = useLazyQuery(GET_PODCASTS, {
    variables: {
      first: 5,
      where: {
        OR: [{ name_starts_with: titleCase(search) },
        { name_starts_with: search.toUpperCase() },
        { name_starts_with: search.toLowerCase() },
        { name_starts_with: search },
        { name_contains: titleCase(search) },
        { name_contains: search.toUpperCase() },
        { name_contains: search.toLowerCase() },
        { name_contains: search },
        { name_ends_with: search },
        { name_ends_with: search.toUpperCase() },
          { name_ends_with: search.toLowerCase() },],
        id_not_in: id_not
      }
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getPodcasts()
  }, [])

  useEffect(() => {
    if (search?.length) {
      if (debounceJob) {
        debounceJob.cancel()
      }
      debounceJob = debounce(() => {
        getPodcasts()
      }, 500)
      debounceJob()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const columns = [
    {
      title: '',
      dataIndex: 'coverImage',
      sorter: false,
      width: '20%',
      render: (coverImage) => (
        <img
          className="table-image"
          src={coverImage}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'name',
      sorter: false,
      render: (name) => (<div className="table-name">
        {name}
      </div>)
    },

  ]

  const columnsOrderChange = [
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (record) => (
        <img
          className="table-image"
          src={record.coverImage}
          alt={''}
        />
      ),
    },
    {
      title: '',
      sorter: false,
      render: (_, record, index) => (<div className="table-name">{record.name}</div>),
    },
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (_, record, index) => {
        let indexId = index + (current - 1) * defaultPageSize
        return <div>
          {(indexId !== (podcasts.length - 1)) &&
            <Icon type="down" className="p-2" onClick={() => changeOrder(indexId, podcasts, 1)} />}

          {(indexId !== 0) &&
            <Icon type="up" className="p-2" onClick={() => changeOrder(indexId, podcasts, -1)} />}
        </div>
      },
    },
  ]

  function changeOrder(indexId, podcasts, direction) {
    const item = cloneDeep(podcasts[indexId])
    const nextItem = cloneDeep(podcasts[indexId + direction])
    const itemOrder = item.carouselPodcastOrder
    item.carouselPodcastOrder = nextItem.carouselPodcastOrder
    nextItem.carouselPodcastOrder = itemOrder
    const newPodcastList = podcasts.filter(podcast => {
      if (podcast.id === item.id || podcast.id === nextItem.id) {
        return false
      }
      return true
    })

    if (direction === -1) {
      newPodcastList.splice(indexId + direction, 0, item, nextItem)
    } else {
      newPodcastList.splice(indexId, 0, nextItem, item)
    }
    setPodcasts(newPodcastList)
    setFieldsValue({ [`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}.${podcastModuleData.id}.podcasts`]: newPodcastList })
  }

  function handleCancel() {
    setCurrent(1)
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    getPodcasts()
    setShowModal(true)
  }

  function handleOrderChangeModalCancel() {
    setOrderChangeModal(false)
  }

  function openOrderChangeModal() {
    setOrderChangeModal(true)
  }

  const onSelectChange = (selectedRowKeys, pageSelectedRowsData) => {
    let newSelectedRowData = uniqBy([...selectedRows, ...pageSelectedRowsData], 'id')
    setSelectedRows(newSelectedRowData)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  let filteredPodcastsData = podcastsData?.listPodcastDetails

  function addPodcasts() {
    setIsSubmit(true)
    let newSelectedPodcast = selectedRowKeys.filter((row) => {
      for (let index = 0; index < podcasts.length; index++) {
        if (podcasts[index].id === row) {
          return false
        }
      }
      return true
    })
    let currentOrder = podcastNextOrder
    let filterSelectedPodcasts = newSelectedPodcast.map((row) => {
      let podcast = selectedRows.find((podcast) => podcast.id === row)
      if (!podcast) {
        podcast = podcastsData?.listPodcastDetails?.find((podcast) => podcast.id === row)
      }
      podcast.carouselPodcastOrder = currentOrder
      currentOrder = currentOrder + 1
      return podcast
    })
    setCurrent(1)
    setPodcastNextOrder(currentOrder)
    setPodcasts([...podcasts, ...filterSelectedPodcasts])
    setFieldsValue({ [`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}.${podcastModuleData.id}.podcasts`]: [...podcasts, ...filterSelectedPodcasts] })
    setShowModal(false)
    setIsSubmit(false)
    setSelectedRows([])
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}.${podcastModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeletePodcastCarousel(podcastCarousel) {
    confirm({
      title: `Are you sure you want to remove podcast carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === podcastCarousel.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: podcastCarousel.id, type: MODULE_TYPES_CONST.PODCAST_CAROUSEL },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== podcastCarousel.id)
        setModuleList(newModules)
      },
    })
  }

  function deleteCarouselPodcast(id, podcast) {
    confirm({
      title: `Are you sure you want to remove podcast`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        if (podcast.carouselPodcastId) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_PODCAST,
              variables: { id: id },
            })
            const deleteCarouselPodcast = get(response, 'data.deleteCarouselPodcast')
            if (deleteCarouselPodcast && deleteCarouselPodcast.message) {
              openNotification('success', deleteCarouselPodcast.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        const newPodcasts = podcasts.filter(item => item.id !== podcast.id)
        setPodcasts(newPodcasts);
        setFieldsValue({ [`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}.${podcastModuleData.id}.podcasts`]: newPodcasts })
        setSelectedRowKeys(selectedRowKeys.filter(rowKeys => rowKeys !== podcast.id))
      },
    })
  }


  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrent(1)
      setLastPage(false)
    }
  }

  useEffect(() => {
    async function handlePageChange() {
      if (!dataLoading) {
        try {
          getPodcasts({
            variables: {
              skip: (current - 1) * 5,
              first: 5
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { listPodcastDetails } = fetchMoreResult
                if (listPodcastDetails?.length < 5) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                return listPodcastDetails?.length ? { listPodcastDetails: [...listPodcastDetails] } : prevResult
              }
            }
          })
        } catch (error) {
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  useEffect(() => {
    if (!dataLoading && podcastsData?.listPodcastDetails) {
      if (podcastsData?.listPodcastDetails?.length < 5) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [dataLoading])

  return (
    <Card key={podcastModuleData.id} title='Podcast Carousel' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} canMutate={true} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {getFieldDecorator(`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}.${podcastModuleData.id}.isPublished`, {
            initialValue: podcastModuleData.isPublished || false
          })(
            <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}-${indexId}-isPublished`}>
              Published
            </Checkbox>)}
          <Button id={`btn-delete-podcast-carousel-${indexId}`} icon='delete'
            onClick={() => handleDeletePodcastCarousel(podcastModuleData)}
            type='danger'>
            Delete
          </Button>
        </div>
      ]}
    >
      <Form.Item label='Title'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}.${podcastModuleData.id}.title`, {
          initialValue: podcastModuleData.title ? podcastModuleData.title : '',
          rules: [{ required: true, message: 'Please input your title!' }]
        })(
          <Input placeholder='Title' className={`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}-${indexId}-title`} />,
        )}
      </Form.Item>
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}.${podcastModuleData.id}.order`, {
          initialValue: podcastModuleData.order || 1
        })(<Input placeholder='Order' className={`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}-${indexId}-order`} />,)}
      </Form.Item>
      <Form.Item label='Podcasts' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.PODCAST_CAROUSEL}.${podcastModuleData.id}.podcasts`, {
          initialValue: podcastModuleData.podcasts || [],
          rules: [{ type: 'array', required: true, min: 1, message: 'Please add atleast one podcast!' }]
        })(
          <Row gutter={24} type="flex">
            {podcasts.length > 0 && podcasts.map((podcast, index) => (
              <PodcastListCard
                key={podcast.id}
                id={podcast.carouselPodcastId ? podcast.carouselPodcastId : podcast.id}
                name={podcast.name}
                image={podcast.coverImage}
                index={index}
                indexId={indexId}
                podcast={podcast}
                deleteCarouselPodcast={deleteCarouselPodcast}
              />
            ))}
          </Row>)}
      </Form.Item>
      <Row>
        <div className="mt-5">
          <Button icon='plus' id={`btn-add-podcast-${indexId}`} onClick={openModal}>
            Add Podcast
          </Button>
          {podcasts.length > 1 && (<Button className="ml-2" icon='edit' id={`btn-edit-podcast-${indexId}`}
            onClick={openOrderChangeModal}>
            Change Order
          </Button>)
          }
        </div>
      </Row>
      <Row>
        <Modal
          title="Add Podcasts"
          visible={showModal}
          maskClosable={false}
          onOk={addPodcasts}
          onCancel={handleCancel}
          footer={[
            <Button id={`btn-podcast-carousel-cancel-${indexId}`} key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              id={`btn-podcast-carousel-save-${indexId}`}
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={addPodcasts}
            >
              Add
            </Button>,
          ]}
        >
          <Row>
            <Col>
              <Input.Search placeholder="Search Podcast" onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-podcast-carousel-${indexId}`} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Table
              loading={dataLoading}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              dataSource={filteredPodcastsData}
              showHeader={false}
              pagination={false}
              onChange={handleChange}
            />
            <div className="page-change-btn-wrapper">
              <Button icon="left" onClick={() => setCurrent(current - 1)} disabled={current === 1} />
              <Button icon="right" onClick={() => setCurrent(current + 1)} disabled={lastPage} />
            </div>
          </Row>
        </Modal>
      </Row>
      <Modal
        title='Change Order'
        visible={orderChangeModal}
        maskClosable={false}
        onCancel={handleOrderChangeModalCancel}
        footer={null}
      >
        <Row>
          <Table
            columns={columnsOrderChange}
            rowKey={(record) => record.id}
            dataSource={podcasts}
            showHeader={false}
            pagination={{
              onChange(currentPage) { setCurrent(currentPage) },
              defaultPageSize
            }}
          />
        </Row>
      </Modal>
    </Card>
  )
}

export default PodcastCarousel
