import React, { Component } from 'react'
import { Col, Tag } from 'antd'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { truncateName } from '../../../common/utility'

export default class EpisodeCard extends Component {
  render() {
    const {
      podcastId,
      episode: { id, title, thumbnail, status, publishDate },
    } = this.props
    return (
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        xxl={6}
        className="podcastepisode-card-col-wrapper"
      >
        <div className="podacast-card-episode-wrapper">
          <Tag className={status}>{status}</Tag>
          <NavLink to={`/podcasts/${podcastId}/${id}`}>
              <img src={`${thumbnail}?w=300&h=300`} alt={title} title={title} />
            <div className="episode-details">
              <p className="episode-title">{truncateName(title)}</p>
              <p className="episode-date">
                {publishDate ? moment(publishDate).format('MMMM Do, YYYY'): ''}
              </p>
            </div>
          </NavLink>
        </div>
      </Col>
    )
  }
}
