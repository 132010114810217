import React, { Component } from 'react'
import EpisodeCard from './EpisodeCard'
import PodcastEpisodeCard from './PodcastEpisodeCard'

export default class EpisodeWrapper extends Component {
  constructor(props) {
    super(props)
    this.container = document.querySelector('.content-wrapper')
  }
  componentDidMount() {
    this.container.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.handleOnScroll)
  }

  handleOnScroll = () => {
    if (
      this.container.scrollTop + this.container.offsetHeight ===
      this.container.scrollHeight
    ) {
      if (!this.props.episodeLoading) {
        this.props.onLoadMore()
      }
    }
  }

  render() {
    const { episodes, name, updateEpisodeList, podcastEpisodes, type } = this.props
    if( type ==='PODCAST' && podcastEpisodes && podcastEpisodes.length > 0 ) {
      return (
        podcastEpisodes &&
        podcastEpisodes.map((episode) => (
          <PodcastEpisodeCard
            key={episode.id}
            {...episode}
            updateEpisodeList={updateEpisodeList}
            seasonName={name}
            type={type}
          />
        ))
      )
    }
    return (
      episodes &&
      episodes.map((episode) => (
        <EpisodeCard
          key={episode.id}
          {...episode}
          updateEpisodeList={updateEpisodeList}
          seasonName={name}
        />
      ))
    )
  }
}
