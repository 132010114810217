import React, { useEffect, useState } from 'react'
import { Row } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { remove } from 'lodash'
import { GET_VIDEOS } from './graphql/Queries'
import CardLoader from '../../components/loaders/CardLoader'
import VideoWrapper from './components/VideoWrapper'
import Meta from '../../components/Meta'
import AddVideoCard from './components/AddVideoCard'
import './video.css'

export default function (props) {
  const [videos, setVideos] = useState([])
  const [loading, setLoading] = useState([])
  const {
    data,
    loading: isVideoLoading,
    error: showsError,
    fetchMore,
  } = useQuery(GET_VIDEOS, {
    variables: { first: 50 },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isVideoLoading && data && data.videos) {
      setLoading(false)
      setVideos(data.videos)
    } else {
      setLoading(true)
    }
  }, [isVideoLoading])

  const updateEpisodeList = (e) => {
    setLoading(true)
    const videoFound = videos.find((video) => video.id === e)
    if (videoFound) {
      remove(videos, { id: e })
      setVideos(videos)
    }    
    setLoading(false)  
        setLoading(false)
    setLoading(false)  
  }

  const { history } = props
  return !showsError ? (
    <div className="shows-scroll">
      <Row gutter={24} type="flex" className="shows-wrapper shows-list">
        <Meta title="Videos" description="" />
        <AddVideoCard history={history} />
        {loading ? (
          <CardLoader />
        ) : (
          <VideoWrapper
            videos={videos}
            updateEpisodeList={updateEpisodeList}
            onLoadMore={() => {
              fetchMore({
                loading: isVideoLoading,
                variables: {
                  first: 50,
                  after: videos[videos.length - 1].id,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  const { videos } = fetchMoreResult
                  if (videos.length) {
                    setVideos([...prevResult.videos, ...videos])
                  }
                  return videos.length
                    ? {
                        videos: [...prevResult.videos, ...videos],
                      }
                    : prevResult
                },
              })
            }}
          />
        )}
      </Row>
    </div>
  ) : (
    <>{showsError.message}</>
  )
}
