import React, { useState, useContext, useRef } from 'react'
import { Col, Button, notification } from 'antd'
import * as Sentry from '@sentry/browser'
import client from '../../../apollo'
import { trim } from 'lodash'
import { fileUpload } from '../../../common/fileUpload'
import { GET_SEASON_SIGNED_PUT_URL, GET_SEASONS } from '../graphql/Queries'
import { CREATE_SEASON, UPDATE_SEASON_DATA } from '../graphql/Mutations'
import { AppContext } from '../../../AppContext'
import AddShowModal from './AddShowModal'

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleShowModal(b) {
    setShowModal(b)
  }

  function handleRequestFail(errorMessage) {
    setSubmit(false)
    openNotification('error', errorMessage || 'Something Went Wrong')
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const queryVariables = values
      const { image } = values
      try {
        let imageFileName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        if (queryVariables.image) {
          delete queryVariables.image
        }
        queryVariables.createdBy = state.currentUser.id
        queryVariables.name = trim(queryVariables.name)
        queryVariables.description = trim(queryVariables.description)
        queryVariables.image = imageFileName
        if (queryVariables.weight) {
          queryVariables.weight = Number(queryVariables.weight)
        } else {
          queryVariables.weight = 100
        }
        if (queryVariables.showId) {
          queryVariables.show = { connect: { id: queryVariables.showId } }
          delete queryVariables.showId
        }
        if (queryVariables.podcastId) {
          queryVariables.podcast = { connect: { id: queryVariables.podcastId } }
          delete queryVariables.podcastId
        }
        const createShowResult = await client.mutate({
          mutation: CREATE_SEASON,
          variables: queryVariables,
          refetchQueries: [
            {
              fetchPolicy: 'network-only',
              query: GET_SEASONS,
              variables: { first: 10 },
            },
          ],
        })
        const seasonId = createShowResult.data.createSeason.id
        if (image && image.length > 0) {
          const tempFilename = image[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${seasonId}-feature-${new Date().getTime()}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_SEASON_SIGNED_PUT_URL,
            fetchPolicy: 'network-only',
            variables: { fileName, contentType, seasonId: seasonId },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getSeasonSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_SEASON_DATA,
            variables: {
              image: fileName,
              id: seasonId,
            },
            refetchQueries: [
              {
                fetchPolicy: 'network-only',
                query: GET_SEASONS,
                variables: { first: 10 },
              },
            ],
          })
        }
        setShowModal(false)
        setSubmit(false)
        form.resetFields()
        openNotification('success', 'Season Created Successfully')
        props.history.push(`/seasons/${createShowResult.data.createSeason.id}`)
      } catch (e) {
        console.log(e)
        Sentry.captureException(e)
        let errorMessage =
          (e &&
            e.graphQLErrors &&
            e.graphQLErrors[0] &&
            e.graphQLErrors[0].message &&
            e.graphQLErrors[0].message) ||
          'Something Went Wrong'

        handleRequestFail(errorMessage)
      }
    })
  }
  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        className="episode-card-col-wrapper"
        onClick={() => handleShowModal(true)}
      >
        <div className="add-show-wrapper">
          <Button
            id='btn-season-create'
            type="dashed"
            size="large"
            className="add-show-button"
            onClick={() => handleShowModal(true)}
          >
            + Add Season
          </Button>
        </div>
      </Col>
      <AddShowModal
        saveFormRef={saveFormRef}
        showModal={showModal}
        isSubmit={isSubmit}
        handleCancel={() => {
          saveFormRef.current.props.form.resetFields()
          handleShowModal(false)
        }}
        handleCreate={handleCreate}
      />
    </>
  )
}
