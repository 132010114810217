import React, { Component } from 'react'
import {
  Form,
  Input,
  Upload,
  Icon,
  Button,
  Checkbox,
  DatePicker,
  message,
  Tooltip,
  Select,
  Row,
  Col,
  Typography,
  Radio,
  Switch
} from 'antd'
import moment from 'moment-timezone'
import { get, debounce, find } from 'lodash'
import client from '../../../apollo'
import { ACCESS_LEVELS, MEDIA_RATINGS, CONTINENTS, LOCATIONS } from '../../../common/constants'
import { GET_SEASONS } from '../grapql/Queries'
import { GET_COUNTRIES } from '../../episode/graphql/Queries'
import { UniqueArray } from '../../../common/utility'
import confirm from 'antd/lib/modal/confirm'

const { Option, OptGroup } = Select

const defaultAccessValuesForWithoutAds = ['READER', 'INSIDER', 'INSIDER_PLUS', 'ALL_ACCESS']

function beforeImageUpload(file) {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg'
  ) {
    return true
  }
  message.error('You can only upload image file!')
  return false
}

export default class AddEpisodeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      removeFile: false,
      tooltipIsVisible: false,
      scheduleTitle: '',
      isFeatured: false,
      seasonLoading: false,
      seasons: [],
      countries: [],
      locationRestriction: false,
      locationLimit: LOCATIONS.CONTINENT,
      countryLoading: false,
    }
  }

  normFile = (e) => {
    if (this.state.removeFile) {
      this.setState({ removeFile: false })
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  handleRemove = (e) => {
    this.setState({ removeFile: true })
  }

  disabledStartDate = (startValue) => {
    return startValue.valueOf() < moment().valueOf()
  }

  handleScheduleChange = (currentTime) => {
    if (currentTime) {
      const laTime = moment(currentTime.valueOf()).tz('America/Los_Angeles')
      this.setState({
        scheduleTitle: `Schedule at: ${laTime.format(
          'lll',
        )} ${laTime.zoneAbbr()}`,
        tooltipIsVisible: true,
      })
    } else {
      this.setState({
        tooltipIsVisible: false,
        scheduleTitle: '',
      })
    }
  }

  componentDidMount() {
    const { isFeatured, allowedContinents, allowedCountries } = this.props
    if (isFeatured) {
      this.setState({ isFeatured: true })
    }
    this.getSeasonList()
    this.getCountries()
    if (allowedContinents && (Array.isArray(allowedCountries) || allowedCountries === null)) {
      if (!(allowedContinents?.length === 0) || !(allowedCountries?.length === 0) || allowedCountries === null) {
        this.setState({ locationRestriction: true })
        if (Array.isArray(allowedCountries) && !(allowedCountries?.length === 0)) {
          this.setState({ locationLimit: LOCATIONS.COUNTRY })
        }
        else {
          this.setState({ locationLimit: LOCATIONS.CONTINENT })
        }
      }
      else {
        this.setState({ locationRestriction: false })
      }
    }
  }

  componentWillUnmount() {
    this.props.setFieldsValue({ locationRestriction: false })
    this.setState({ locationRestriction: false })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.locationLimit !== this.state.locationLimit) {
      this.props.setFieldsValue({ limitBy: this.state.locationLimit })
    }
  }

  getCountries = () => {
    this.setState({ countryLoading: true })
    client.query({
      query: GET_COUNTRIES,
      fetchPolicy: 'network-only'
    }).then(({ data, loading }) => {
      this.setState({ countryLoading: false })
      this.setState({ countries: data?.getCountrySelectionForRestriction })
    }).catch((e) => {
      this.setState({ countryLoading: false })
    })
  }


  getSeasonList = (searchFilter = undefined) => {
    const { podcastId, seasonEpisode, seasonId } = this.props
    if (podcastId) {
      this.setState({ seasonLoading: true })
      let queryFilter = { where: { podcast: { id: podcastId } } }
      if (searchFilter && searchFilter.length > 0) {
        queryFilter.where.OR = [
          { name_starts_with: searchFilter },
          { name_contains: searchFilter },
          { name_ends_with: searchFilter },
        ]
      }
      client.query({
        query: GET_SEASONS,
        variables: queryFilter,
        fetchPolicy: 'network-only',
      }).then((data) => {
        let seasonList = get(data, 'data.seasons')
        if (seasonEpisode && Array.isArray(seasonEpisode) && seasonEpisode.length > 0) {
          if (!find(seasonList, (seasonObj) => seasonObj.id === seasonId)) {
            seasonList.push(seasonEpisode[0].season)
          }
        }
        if (seasonList && Array.isArray(seasonList)) {
          this.setState({ seasons: seasonList, seasonLoading: false })
        } else {
          this.setState({ seasonLoading: false })
        }
      }).catch((e) => {
        this.setState({ seasonLoading: false })
      })
    }
  }

  onSeasonSearch = (e) => {
    if (this.seasonDebounceJob) {
      this.seasonDebounceJob.cancel()
    }
    this.seasonDebounceJob = debounce(() => {
      this.getSeasonList(e)
    }, 500)
    this.seasonDebounceJob()
  }

  onSeasonChange = (season) => {
    if (!season) {
      this.getSeasonList()
    }
  }

  handleLocationRestriction = (e) => {
    if (e === true) {
      this.setState({ locationRestriction: true })
      this.props.setFieldsValue({ locationRestriction: true })
      this.setState({ locationLimit: LOCATIONS.CONTINENT })
      this.props.setFieldsValue({ limitBy: LOCATIONS.CONTINENT })
    }
    else {
      if (this.props.allowedContinents && (Array.isArray(this.props.allowedCountries) || !Array.isArray(this.props.allowedCountries))) {
        confirm({
          title: 'Are you sure you want to disable location restriction as your selection will be lost ?',
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            this.props.setFieldsValue({ locationRestriction: false })
            this.setState({ locationRestriction: false })
            this.setState({ locationLimit: LOCATIONS.CONTINENT })
            this.props.setFieldsValue({ limitBy: LOCATIONS.CONTINENT })
          },
          onCancel: () => {
            this.props.setFieldsValue({ locationRestriction: true })
            this.setState({ locationRestriction: true })
          }
        })
      }
      else {
        this.setState({ locationRestriction: false })
        this.setState({ locationLimit: LOCATIONS.CONTINENT })
      }
    }
  }




  handleLocationLimit = (e) => {
    if (e === LOCATIONS.CONTINENT) {
      this.setState({ locationLimit: LOCATIONS.CONTINENT })
      this.props.setFieldsValue({ 'allowedContinents': CONTINENTS.map((continentObj) => continentObj.key) })
    }
    else {
      this.setState({ locationLimit: LOCATIONS.COUNTRY })
      this.props.setFieldsValue({ 'allowedContinents': [] })
    }
  }


  handleCountryInitialValue = (continentCode) => {
    this.state.countries.map((item) => {
      return item?.continentCode === continentCode
    })
  }



  render() {
    const {
      showModal,
      getFieldDecorator,
      title,
      description,
      episodeNumber,
      publishDate,
      scheduleAt,
      isEdit,
      audioWithAdsAccess,
      audioAccess,
      episodeId,
      seasonId,
      allowedContinents,
      rating,
      setFieldsValue,
      allowedCountries
    } = this.props
    const { scheduleTitle, tooltipIsVisible, seasonLoading, seasons, locationRestriction, locationLimit, countries } = this.state
    const uploadProps = {
      customRequest() {
        return false
      },
    }

    function imageDimension(file) {
      return new Promise(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e?.target?.result;
          const image = new Image();
          image.onload = function () {
            if (this.width || this.height) {
              resolve({ width: this.width, height: this.height });
            } else {
              message.error('Could not identify width and height')
              reject();
            }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    }

    const handleContinentChange = (e) => {
      if (allowedCountries && allowedCountries?.length > 0) {
        let countryData = [...allowedCountries]
        for (let index = 0; index < countries?.length; index++) {
          const element = countries[index];
          e.filter((value) => {
            if (value === element?.continentCode) {
              element.countries.map((item) => {
                return countryData?.push(item?.countryCode)
              })
            }
          })
        }
        setFieldsValue({ 'allowedCountries': UniqueArray(countryData) })
      }
      else {
        let countryCopy = []
        for (let index = 0; index < countries?.length; index++) {
          const element = countries[index];
          e.filter((value) => {
            if (value === element?.continentCode) {
              element.countries.map((item) => {
                return countryCopy?.push(item?.countryCode)
              })
            }
          })
        }
        setFieldsValue({ 'allowedCountries': UniqueArray(countryCopy) })
      }

    }

    return (
      <Form layout="vertical" className="add-episode-form add-podcastepisode-form">
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: 'Please write the title!',
              },
            ],
            initialValue: title,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [
              { required: true, message: 'Please write the description!' },
            ],
            initialValue: description,
          })(<Input.TextArea rows={5} />)}
        </Form.Item>
        <Form.Item label="Episode Number">
          {getFieldDecorator('episodeNumber', {
            initialValue: episodeNumber,
            normalize: this.normalize,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Season">
          {getFieldDecorator('seasonId', {
            rules: [{ required: true, message: 'Please select the season' }],
            initialValue: seasonId,
          })(
            <Select
              loading={seasonLoading}
              showSearch
              allowClear
              onChange={this.onSeasonChange}
              placeholder="Select Season"
              optionFilterProp="children"
              onSearch={this.onSeasonSearch}
            >
              {seasons && seasons.length > 0
                && seasons.map(({ id, name }) => (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Publish Date">
          {getFieldDecorator('publishDate', {
            initialValue: publishDate ? moment(publishDate) : null,
          })(
            <DatePicker
              className="podcastepisode-publishdate"
              placeholder="Select Date/Time"
              format="MM-DD-YYYY hh:mm A"
              showTime={{ use12Hours: true, format: 'hh:mm A' }}
              ranges={moment()}
            />,
          )}
        </Form.Item>
        <Form.Item label="Schedule Episode">
          <Tooltip
            title={scheduleTitle}
            visible={showModal && tooltipIsVisible}
            placement="right"
          >
            {getFieldDecorator('schedule', {
              initialValue: scheduleAt ? moment(scheduleAt) : null,
            })(
              <DatePicker
                style={{ minWidth: 220 }}
                placeholder="Select Date/Time"
                disabledDate={this.disabledStartDate}
                format="MM-DD-YYYY hh:mm A"
                showTime={{ use12Hours: true, format: 'hh:mm A' }}
                ranges={moment()}
                disabled={isEdit}
                onChange={this.handleScheduleChange}
                onOk={this.handleScheduleChange}
              />,
            )}
          </Tooltip>
        </Form.Item>
        <Form.Item label="The access level for Audio with ads" className="d-none">
          {getFieldDecorator('audioWithAdsAccess', {
            initialValue: audioWithAdsAccess,
          })(
            <Checkbox.Group>
              {ACCESS_LEVELS.map((level) => {
                return <Checkbox key={level.key} value={level.key}>{level.value}</Checkbox>
              })}
            </Checkbox.Group>,
          )}
        </Form.Item>
        <Form.Item label="The access level for Audio without ads" className="d-none">
          {getFieldDecorator('audioAccess', {
            initialValue: audioAccess || defaultAccessValuesForWithoutAds,
          })(
            <Checkbox.Group>
              {ACCESS_LEVELS.map((level) => {
                return <Checkbox key={level.key} value={level.key}>{level.value}</Checkbox>
              })}
            </Checkbox.Group>,
          )}
        </Form.Item>
        <Form.Item label='Location Based Restriction ? ' className='location-selection-button'>
          {getFieldDecorator('locationRestriction', {
            valuePropName: 'checked',
            initialValue: allowedContinents && (Array.isArray(allowedCountries) || allowedCountries === null) ? (!(allowedContinents?.length === 0) || !(allowedCountries?.length === 0) || allowedCountries === null) ? true : false : false,
          })(
            <Switch onChange={(e) => {
              this.handleLocationRestriction(e)
            }} />
          )}
        </Form.Item>
        {locationRestriction &&
          <Form.Item label='Limit By'>
            {getFieldDecorator('limitBy', {
              initialValue: allowedContinents && (Array.isArray(allowedCountries) || allowedCountries === null) ? (!(allowedCountries?.length === 0)) ? this.state.locationLimit : this.state.locationLimit : this.state.locationLimit,
            })(
              <Radio.Group onChange={(e) => {
                this.handleLocationLimit(e?.target?.value)
              }}>
                <Radio value="Continent">Continent</Radio>
                <Radio value="Country">Country</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        }
        {locationRestriction &&
          <Form.Item label={locationLimit === LOCATIONS.COUNTRY ? "Select Countries by Continent" : "Allowed Continents ( To limit continent access, please select at least one continent otherwise content will allow access on all continents )"}>
            {getFieldDecorator('allowedContinents', {
              initialValue: locationLimit === LOCATIONS.COUNTRY ? "" : allowedContinents || CONTINENTS.map((continentObj) => continentObj.key),
            })(
              <Checkbox.Group className="allowed-continents" onChange={(e) => {
                handleContinentChange(e)
              }}>
                <Row>
                  {CONTINENTS.map((continent) => {
                    return <Col span={8} key={continent?.key}>
                      <Checkbox value={continent?.key}>{continent?.value}</Checkbox>
                    </Col>
                  })}
                </Row>
              </Checkbox.Group>,
            )}
          </Form.Item>}
        {(locationRestriction && locationLimit === LOCATIONS.COUNTRY) &&
          <Form.Item label="Allowed Countries">
            {getFieldDecorator('allowedCountries', {
              initialValue: allowedCountries ? allowedCountries : [],
            })(
              <Select className='country-select' mode="multiple" placeholder='Select Country'
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.props?.children?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                }
              >
                {countries.map((item) => (
                  <OptGroup label={item?.continentName} key={item?.continentCode}>
                    {item?.countries.map((country) => (
                      <Option value={country?.countryCode} key={country?.countryCode}>{country?.countryName}</Option>
                    ))
                    }
                  </OptGroup>
                ))
                }
              </Select>
            )}
          </Form.Item>}
        <Form.Item label="Media Rating">
          {getFieldDecorator('rating', {
            initialValue: rating || undefined,
          })(
            <Select className='media-rating' allowClear placeholder="Select Media Rating">
              {MEDIA_RATINGS.map((ratingObj, index) => (
                <Option value={ratingObj.key} key={index}>
                  {ratingObj.value}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="Episode Cover Image">
          {getFieldDecorator('thumbnail', {
            valuePropName: 'fileList',
            getValueFromEvent: this.normFile,
            rules: [
              {
                validator: async (rules, value, cb) => {
                  if (value?.length > 0) {
                    const dim = await imageDimension(value[0]?.originFileObj)
                    if (dim?.width && dim?.height && dim?.width === dim?.height) {
                      cb()
                    } else {
                      cb(true)
                    }
                  }
                },
                message: 'Episode cover image should be square. Please check width and height of image'
              }
            ],
          })(
            <Upload
              name="logo"
              {...uploadProps}
              listType="picture"
              multiple={false}
              onRemove={(e) => this.handleRemove(e)}
            >
              <Button id='btn-episode-thumbnail-image'>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>,
          )}
          <Typography.Text className="ant-form-text" type="secondary">
            Dimension ( 2400 x 2400 )
          </Typography.Text>
        </Form.Item>
      </Form >
    )
  }
}
