import React from 'react'
import { Icon } from 'antd'
import { cloneDeep } from 'lodash'

function MoveArrow(props) {
  const { indexId, modulesList, setModuleList } = props

  function move(indexId, modulesList, direction) {
    const item = cloneDeep(modulesList[indexId])
    const nextItem = cloneDeep(modulesList[indexId + direction])
    const itemOrder = item.order
    item.order = nextItem.order
    nextItem.order = itemOrder
    const newModuleList = modulesList.filter(module => {
      if (module.id === item.id || module.id === nextItem.id) {
        return false
      }
      return true
    })

    if (direction === -1) {
      newModuleList.splice(indexId + direction, 0, item, nextItem)
    } else {
      newModuleList.splice(indexId, 0, nextItem, item)
    }
    setModuleList(newModuleList)
  }

  return <div>
    {(indexId !== (modulesList.length - 1)) &&
      <Icon type="down" className="p-2" onClick={() => move(indexId, modulesList, 1)} />}

    {(indexId !== 0) &&
      <Icon type="up" className="p-2" onClick={() => move(indexId, modulesList, -1)} />}
  </div>
}

export default MoveArrow