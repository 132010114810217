import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddEpisodeForm from './AddEpisodeForm'

const CollectionCreateForm = Form.create({ name: 'create_episode' })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const {
        podcastId,
        visible,
        onCancel,
        onCreate,
        form,
        isSubmit,
        title,
        description,
        episodeNumber,
        isEdit,
        episodeId,
        audioWithAdsAccess,
        audioAccess,
        seasonId,
        publishDate,
        scheduleAt,
        allowedContinents,
        rating,
        allowedCountries
      } = this.props
      const { getFieldDecorator, setFieldsValue } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${title ? 'Edit Episode Details' : 'Add New Episode'}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-episode-cancel' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id='btn-episode-save'
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              {title ? 'Save' : 'Add Episode'}
            </Button>,
          ]}
        >
          <AddEpisodeForm
            podcastId={podcastId}
            scheduleAt={scheduleAt}
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
            title={title}
            description={description}
            episodeNumber={episodeNumber}
            isEdit={isEdit}
            episodeId={episodeId}
            audioWithAdsAccess={audioWithAdsAccess}
            audioAccess={audioAccess}
            seasonId={seasonId}
            publishDate={publishDate}
            allowedContinents={allowedContinents}
            rating={rating}
            setFieldsValue={setFieldsValue}
            allowedCountries={allowedCountries}
          />
        </Modal>
      )
    }
  },
)

export default class AddEpisodeModal extends Component {
  render() {
    const {
      podcastId,
      showModal,
      isSubmit,
      handleCancel,
      handleCreate,
      saveFormRef,
      title,
      description,
      episodeNumber,
      isEdit,
      episodeId,
      audioWithAdsAccess,
      audioAccess,
      seasonId,
      publishDate,
      scheduleAt,
      allowedContinents,
      rating,
      allowedCountries
    } = this.props
    return (
      <CollectionCreateForm
        podcastId={podcastId}
        scheduleAt={scheduleAt}
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        title={title}
        description={description}
        episodeNumber={episodeNumber}
        isEdit={isEdit}
        episodeId={episodeId}
        audioWithAdsAccess={audioWithAdsAccess}
        audioAccess={audioAccess}
        seasonId={seasonId}
        publishDate={publishDate}
        allowedContinents={allowedContinents}
        rating={rating}
        allowedCountries={allowedCountries}
      />
    )
  }
}
