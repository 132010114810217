import React from 'react'
import { Col, Button } from 'antd'
import { truncateName } from '../../../../common/utility'

function PodcastListCard(props) {

  const { podcast, id, image, name, deleteCarouselPodcast, index, indexId } = props

  return (
    <Col
      xs={24}
      sm={24}
      md={6}
      lg={4}
      xl={4}
      className="tile-card-podcast-col-wrapper"
    >
      <div className="tile-card-podcast-wrapper">
        <div className="tile-card-delete">
          <Button
            id={`btn-podcast-${indexId}-${index}-delete`}
            key="2"
            size="default"
            icon="delete"
            type="danger"
            onClick={() => {
              deleteCarouselPodcast(id, podcast)
            }}
          />
        </div>
        <div className="tile-card-podcast">
          <div className="text-center d-flex">
            <img src={`${image}?w=300&h=300`} alt={name} title={name} />
          </div>
          <div className="tile-details-podcast line-height-1">
            <p className="tile-title-podcast">{truncateName(name)}</p>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default PodcastListCard
