import React, { useState, useEffect } from 'react'
import { Row, Spin } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_SHOWS } from './graphql/Queries'
import CardLoader from '../../components/loaders/CardLoader'
import ShowWrapper from './components/ShowWrapper'
import AddShowCard from './components/AddShowCard'
import Meta from '../../components/Meta'
import './shows.css'

export default function (props) {
  const [lastShowId, setLastShowId] = useState()
  const [shows, setShows] = useState([])
  const {
    data,
    loading: isShowsLoading,
    error: showsError,
    fetchMore,
  } = useQuery(GET_SHOWS, {
    variables: { first: 20 },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isShowsLoading && data && data.shows) {      
      setShows(data.shows)
      setLastShowId(
        data &&
          data.shows &&
          data.shows[data.shows.length - 1] &&
          data.shows[data.shows.length - 1].id,
      )
    }
  }, [isShowsLoading])

  const { history } = props
  return !showsError ? (
    <div className="shows-scroll">
      <Row gutter={24} type="flex" className="shows-wrapper shows-list">
        <Meta title="Shows" description="" />
        <AddShowCard history={history} />
        {isShowsLoading ? (
          <CardLoader />
        ) : (
          <ShowWrapper
            shows={shows}
            onLoadMore={() =>
              fetchMore({
                variables: {
                  first: 20,
                  after: lastShowId,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  const { shows: newShows } = fetchMoreResult
                  if (newShows.length) {
                    setLastShowId(newShows[newShows.length - 1].id)
                    setShows([...shows, ...newShows])
                  }
                },
              })
            }
          />
        )}
      </Row>
    </div>
  ) : (
    <>{showsError.message}</>
  )
}
