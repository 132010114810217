import React from 'react'
import { Col, Button } from 'antd'
import { truncateName } from '../../../../common/utility'

function EpisodeListCard(props) {

  const { episode, id, image, name, deleteCarouselEpisode, index, indexId } = props

  return (
    <Col
      xs={24}
      sm={24}
      md={8}
      lg={6}
      xl={6}
      className="tile-card-episode-col-wrapper"
    >
      <div className="tile-card-episode-wrapper">
        <div className="tile-card-delete">
          <Button
            id={`btn-episode-${indexId}-${index}-delete`}
            key="2"
            size="default"
            icon="delete"
            type="danger"
            onClick={() => {
              deleteCarouselEpisode(id, episode)
            }}
          />
        </div>
        <div className="tile-card-episode">
          <div className="text-center d-flex">
            <img src={`${image}?w=300&h=250`} alt={name} title={name} />
          </div>
          <div className="tile-details-episode line-height-1">
            <p className="tile-title-episode">{truncateName(name)}</p>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default EpisodeListCard
