import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddShowForm from './AddShowForm'

const CollectionCreateForm = Form.create({ name: 'create_show' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        isSubmit,
        onCancel,
        onCreate,
        form,
        season: { name } = {},
        season,
      } = this.props
      const { getFieldDecorator, getFieldValue } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${name ? 'Edit Season Details' : 'Add New Season'}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-season-cancel' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id='btn-season-save'
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              {name ? 'Save' : 'Create season'}
            </Button>,
          ]}
        >
          <AddShowForm
            name={name}
            {...season}
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
          />
        </Modal>
      )
    }
  },
)

export default class AddShowModal extends Component {
  render() {
    const {
      showModal,
      isSubmit,
      handleCancel,
      handleCreate,
      saveFormRef,
      season,
    } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        season={season}
      />
    )
  }
}
