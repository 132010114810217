import React, { Component } from "react"
import { Modal, Button, Form } from "antd"
import AddDiscussionForm from "./AddDiscussionForm"


const CollectionCreateForm = Form.create({ name: 'create_show' })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const { visible, isSubmit, onCancel, onCreate, form, subject, description, scheduleAt, host, isEdit } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${!subject ? 'Add New Discussion' : 'Update Discussion'}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-discussion-cancel' key="back" onClick={onCancel}>Cancel</Button>,
            <Button id='btn-discussion-save' key="submit" type="primary" loading={isSubmit} onClick={onCreate}>
              {!subject ? 'Add Discussion' : 'SAVE'}
            </Button>
          ]}
        >
          <AddDiscussionForm
            getFieldDecorator={getFieldDecorator}
            subject={subject}
            description={description}
            scheduleAt={scheduleAt}
            host={host}
            isEdit={isEdit}
            showModal={visible}
          />
        </Modal>
      )
    }
  }
)

export default class AddDiscussionModal extends Component {
  render() {
    const { showModal, isSubmit, handleCancel, handleOk, saveFormRef, subject, description, scheduleAt, host, isEdit } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleOk}
        subject={subject}
        description={description}
        scheduleAt={scheduleAt}
        host={host}
        isEdit={isEdit}
      />
    )
  }
}
