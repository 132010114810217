import gql from 'graphql-tag'

const UPDATE_EPISODE = gql`
mutation updateEpisode($status: EpisodeStatus, $isLive: Boolean, $episodeId: ID!){
  updateEpisode(data: { status: $status, isLive: $isLive }, where: { id: $episodeId })
  {
    id
  }
}
`
const UPDATE_EPISODE_LIVE_VIDEO_URL = gql`
mutation updateEpisode($episodeId: ID!,$segmentId: ID,$liveVideoURL: String){
  updateEpisode(data: { segments : { update : { where: { id: $segmentId }, data : { liveVideoURL: $liveVideoURL} } } }, where: { id: $episodeId })
  {
    id
  }
}
`

export { UPDATE_EPISODE, UPDATE_EPISODE_LIVE_VIDEO_URL }
