import React, { Component } from 'react'
import { Col, Card, Avatar } from 'antd'

const { Meta } = Card
const count = [true, true, true, true, true]
export default class CardLoader extends Component {
  render() {
    return (
      count.map((index, key) => (
        index &&
        <Col xs={24} sm={24} md={8} lg={4} xl={4} className="text-center podcast-loader-card" key={key}>
          <Card className="show-placeholder podcast-loader-card-wrapper" loading={true}>
            <Meta
              avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
              title="Card title"
              description="This is the description"
            />
          </Card>
        </Col>
      ))
    )
  }
}