import gql from 'graphql-tag'

const GET_SEGMENT_LIVE_VIDEO_URL = gql`
  query getSegmentLiveVideoUrl($segmentId: String!) {
    getSegmentLiveVideoUrl(where: { id: $segmentId }) {
      video
      streamKey
    }
  }
`

const GET_SEGMENT = gql`
  query episodeSegment($id: ID!) {
    episodeSegment(where: { id: $id }) {
      id
      title
      description
      image
      audio
      video
      videoAccess
      audioAccess
      duration
      order
      videoState
      muxAssetId
      captions {
        id
        name
        url
        language
        status
        muxTrackId
      }
    }
  }
`

export const GET_SEGMENT_SIMULATE_LIVE_VIDEO_PUT_URL = gql`
  query getSegmentSimulateLiveVideoPutUrl($fileName: String!){
    getSegmentSimulateLiveVideoPutUrl(data: { fileName: $fileName }){
      fileName
      signedUrl
      getUrl
      gets3Url
    }
  }
`
export const GET_SIGNED_URL = gql`
  query getSignedUrl($url: String!){
    getSignedUrl(url: $url){
      signedUrl
    }
}
`

export { GET_SEGMENT, GET_SEGMENT_LIVE_VIDEO_URL }
