import React, { Component } from 'react'
import { Col, Tag } from 'antd'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { truncateName } from '../../../common/utility'

export default class SeasonCard extends Component {
  render() {
    const { id, image, name, createdAt, status } = this.props
    return (
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={8}
        xl={8}
        xxl={6}
        className="episode-card-col-wrapper"
      >
        <div className="episode-card-wrapper">
          <Tag className={status}>{status}</Tag>
          <NavLink to={`/seasons/${id}`} className="episode-card">
            <div className="text-center d-flex">
              <img src={`${image}?w=300&h=250`} alt={name} title={name} />
            </div>
            <div className="episode-details">
              <p className="episode-title">{truncateName(name)}</p>
              <p className="episode-date">
                {moment(createdAt).format('MMMM Do, YYYY')}
              </p>
            </div>
          </NavLink>
        </div>
      </Col>
    )
  }
}
