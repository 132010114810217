import React, { useState, useRef } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import {
  Row,
  Col,
  Button,
  PageHeader,
  notification,
  Modal,
  Divider,
} from 'antd'
import * as Sentry from '@sentry/browser'
import { GET_CLIP, GET_CLIP_SIGNED_PUT_URL } from './graphql/Queries'
import { UPDATE_CLIP, DELETE_CLIP } from './graphql/Mutations'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import AddClipModal from './components/AddClipModal'
import client from '../../apollo'
import { fileUpload } from '../../common/fileUpload'
import './clip.css'
import SegmentDetails from './components/SegmentDetails'
import { useEffect } from 'react'
import { trim } from 'lodash'
import { SUBSCRIBE_CLIP } from './graphql/Subscriptions'

const { confirm } = Modal
function checkStatus(status) {
  if (
    status === 'LIVE_FINISHED' ||
    status === 'DRAFT' ||
    status === 'UNPUBLISHED'
  ) {
    return false
  }
  return true
}

export default function (props) {
  const [clipUploading, setClipUploading] = useState(false)
  const [clip, setClip] = useState({})
  const {
    history,
    match: {
      params: { clipId },
    },
  } = props
  const {
    data: clipData,
    loading: clipLoading,
    error: clipError,
  } = useQuery(GET_CLIP, { variables: { id: clipId } })

  useEffect(() => {
    setClip(clipData.clip)
  }, [clipData])

  const updateClip = () => {
    setTimeout(() => {
      client
        .query({
          query: GET_CLIP,
          fetchPolicy: 'network-only',
          variables: { id: clipId },
        })
        .then(({ data }) => {
          setClip(data.clip)
        })
        .catch(console.log)
    }, 1000)
  }

  const [showModal, setShowModal] = useState(false)
  const [openImageModal, setOpenImageModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const saveFormRef = useRef()

  useSubscription(SUBSCRIBE_CLIP, { variables: { id: clipId } })

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleRequestFail(e) {
    setShowModal(false)
    setIsSubmit(false)
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleCancel() {
    setShowModal(false)
    saveFormRef.current.props.form.resetFields()
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setIsSubmit(true)
      const {
        title,
        description,
        clipNumber,
        image,
        clipAccess,
        clipType,
        showId,
        videoId,
      } = values
      try {
        let imageFileName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        var data = {
          name: trim(title),
          description: trim(description),
          clipAccess: { set: clipAccess },
          clipNumber,
        }
        if (image && image.length > 0) {
          data.image = imageFileName
        }

        if (clipType === 'VIDEO') {
          data.video = { connect: { id: videoId } }
          if (clip && clip.show && clip.show.id) {
            data.show = { disconnect: true }
          }
        } else {
          data.show = { connect: { id: showId } }
          if (clip && clip.video && clip.video.id) {
            data.video = { disconnect: true }
          }
        }

        const updateClipResult = await client.mutate({
          mutation: UPDATE_CLIP,
          variables: {
            data,
            where: {
              id: clipId,
            },
          },
          refetchQueries: [
            {
              query: GET_CLIP,
              fetchPolicy: 'network-only',
              variables: { id: clipId },
            },
          ],
        })
        if (image && image.length > 0) {
          const tempFilenameArray = image[0].name.split('.')
          const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
          const temp = updateClipResult.data.updateClip.image.split('/')
          const tempFilename = temp[temp.length - 1].split('.')[0]
          const fileName = `${clipId}-${new Date().getTime()}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_CLIP_SIGNED_PUT_URL,
            variables: {
              fileName,
              clipId: clipId,
              contentType,
            },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getClipSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_CLIP,
            variables: {
              data: {
                image: fileName,
              },
              where: {
                id: clipId,
              },
            },
            refetchQueries: [
              {
                query: GET_CLIP,
                fetchPolicy: 'network-only',
                variables: { id: clipId },
              },
            ],
          })
        }
        openNotification('success', 'Clip Updated Successfully')
        form.resetFields()
        setShowModal(false)
        setIsSubmit(false)
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail(e)
      }
    })
  }

  function showConfirm(clipId) {
    const { history } = props
    confirm({
      title: 'Are you sure you want to DELETE this clip?',
      content: 'Upon clicking DELETE, the clip will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          await client.mutate({
            mutation: DELETE_CLIP,
            variables: { clipId },
          })
          openNotification('success', 'Clip Deleted Successfully')
          setTimeout(() => {
            history.push(`/clips`)
          }, 500)
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail(e)
        }
      },
    })
  }

  function publishClip(clipId, status) {
    const title =
      status === 'PUBLISHED'
        ? 'Are you sure you want to publish?'
        : status === 'SCHEDULED'
        ? 'Are you sure you want to schedule?'
        : 'Are you sure you want to unpublish?'
    const content =
      status === 'PUBLISHED'
        ? 'Upon clicking publish, this clip will become available to front end users'
        : status === 'SCHEDULED'
        ? 'Upon clicking schedule, this clip will become available to front end users'
        : 'Upon clicking unpublish, this clip will become unavailable to front end users'
    const okText =
      status === 'PUBLISHED'
        ? 'PUBLISH'
        : status === 'SCHEDULED'
        ? 'SCHEDULE'
        : 'UNPUBLISH'
    const okType = status === 'UNPUBLISHED' ? 'danger' : 'primary'
    const notification =
      status === 'PUBLISHED'
        ? 'Clip Published Successfully'
        : status === 'SCHEDULED'
        ? 'Clip Scheduled Successfully'
        : 'Clip Was Successfully Unpublished'

    confirm({
      title,
      content,
      okText,
      okType,
      async onOk() {
        try {
          await client.mutate({
            mutation: UPDATE_CLIP,
            variables: {
              data: { status },
              where: { id: clipId },
            },
            refetchQueries: [
              {
                query: GET_CLIP,
                fetchPolicy: 'newwork-only',
                variables: { id: clipId },
              },
            ],
          })
          openNotification('success', notification)
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail(e)
        }
      },
    })
  }
  return (
    <>
      {clipLoading ? (
        <div className="callback-wrapper">
          <Spinner />
        </div>
      ) : clipError ? (
        <>{clipError.message}</>
      ) : clipData && clip ? (
        <>
          <Meta title={clip.name || ''} description="Clip" />
          <AddClipModal
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            handleCancel={handleCancel}
            handleCreate={handleCreate}
            title={clip.name}
            description={clip.description}
            clipNumber={clip.clipNumber}
            showId={clip && clip.show && clip.show.id}
            videoId={clip && clip.video && clip.video.id}
            clipAccess={clip.clipAccess}
            isEdit={
              clip.status === 'PUBLISHED' ||
              clip.status === 'SCHEDULED' ||
              clip.status === 'LIVE' ||
              clip.status === 'LIVE_FINISHED'
                ? true
                : false
            }
            showCreatedAt={false}
          />
          <Row gutter={24} type="flex" className="episode-title-wrapper">
            <Col span={24}>
              <PageHeader
                onBack={() => {
                  history.goBack()
                }}
                title={clip.name}
                className="d-flex box align-center"
                extra={[
                  <Button
                    id='btn-clip-edit'
                    key="1"
                    shape="circle"
                    icon="edit"
                    size="small"
                    onClick={() => setShowModal(true)}
                  />,
                  <Button
                    id='btn-clip-delete'
                    key="2"
                    shape="circle"
                    size="small"
                    icon="delete"
                    type="danger"
                    ghost={true}
                    onClick={() => showConfirm(clipId)}
                  />,
                  clip.isLive && clip.status !== 'SCHEDULED' ? (
                    <Button
                      id='btn-clip-schedule'
                      key="3"
                      type="primary"
                      size="small"
                      disabled={checkStatus(clip.status)}
                      onClick={() => publishClip(clipId, 'SCHEDULED')}
                    >
                      SCHEDULE
                    </Button>
                  ) : clip.status !== 'PUBLISHED' ? (
                    <Button
                      id='btn-clip-publish'
                      key="3"
                      type="primary"
                      size="small"
                      disabled={checkStatus(clip.status)}
                      onClick={() => publishClip(clipId, 'PUBLISHED')}
                    >
                      PUBLISH
                    </Button>
                  ) : (
                    <Button
                      id='btn-clip-unpublish'
                      key="3"
                      type="danger"
                      size="small"
                      onClick={() => publishClip(clipId, 'UNPUBLISHED')}
                    >
                      UNPUBLISH
                    </Button>
                  ),
                ]}
              />
            </Col>
          </Row>
          <div className="box episode-description-div-wrapper">
            <Row
              gutter={24}
              type="flex"
              className="episode-description-wrapper"
            >
              <Col sm={24} md={6} lg={9} xl={6}>
                <div className="episode-card text-center">
                  <img src={clip.image} alt={clip.name} title={clip.name} />
                </div>
              </Col>
              <Col sm={24} md={18} lg={15} xl={18}>
                <div className="episode-description">{clip.description}</div>
                <Modal
                  visible={openImageModal}
                  maskClosable={false}
                  title="Featured Image"
                  onCancel={() => setOpenImageModal(false)}
                  onOk={() => setOpenImageModal(false)}
                >
                  <img
                    style={{
                      width: '100%',
                      height: ' 200px',
                      objectFit: ' contain',
                    }}
                    alt={clip.name}
                    src={clip.featuredBannerImage}
                  />
                </Modal>
                {clip.isFeatured && (
                  <div
                    style={{ marginTop: '20px' }}
                    className="featured-image-link"
                    onClick={() => setOpenImageModal(true)}
                  >
                    View Featured Image
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <Row gutter={24} type="flex" style={{ margin: '0px 20px' }}>
            <Divider />
          </Row>
          <Row gutter={24} type="flex" className={`episode-wrapper`}>
            <SegmentDetails
              videoStatus={clip.status}
              clipState={clip.clipState}
              title={clip.name}
              clipId={clipId}
              muxAssetId={clip.muxAssetId}
              videoUrl={clip.videoURL}                            
              videoState={clip.videoState}
              setClipLoading={setClipUploading}
              clipLoading={clipUploading}
              openNotification={openNotification}
              updateClip={updateClip}
              captions={clip.captions}
            />
          </Row>
        </>
      ) : (
        <Page404 />
      )}
    </>
  )
}
