import React, { Component } from 'react'
import VideoCard from './VideoCard'
export default class VideoWrapper extends Component {
  constructor(props) {
    super(props)
    this.container = document.querySelector('.content-wrapper')
  }
  componentDidMount() {
    this.container.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.handleOnScroll)
  }

  handleOnScroll = () => {
    if (
      this.container.scrollTop + this.container.offsetHeight ===
      this.container.scrollHeight
    ) {
      this.props.onLoadMore()
    }
  }

  render() {
    const { videos, updateEpisodeList } = this.props
    return (
      videos &&
      videos.map((video, id) => (
        <VideoCard updateEpisodeList={updateEpisodeList} {...video} key={id} />
      ))
    )
  }
}
