import gql from 'graphql-tag'

export const CREATE_SEASON = gql`
  mutation createSeason(
    $name: String!
    $description: String
    $image: String
    $weight: Int
    $orderBy: SeasonOrderBy!
    $createdBy: ID
    $type: SeasonType
    $show: ShowCreateOneWithoutSeasonsInput
    $podcast : PodcastCreateOneWithoutSeasonsInput 
  ) {
    createSeason(
      data: {
        name: $name
        description: $description
        image: $image
        weight: $weight
        orderBy: $orderBy
        createdBy: { connect: { id: $createdBy } }
        type: $type
        show: $show
        podcast: $podcast
      }
    ) {
      id
      name
      description
      image
      weight
      orderBy
      status
      show {
        name
      }
      type
      podcast {
        name
      }
      createdAt
      updatedAt
      message
    }
  }
`

export const UPDATE_SEASON_DATA = gql`
  mutation updateSeason(
    $name: String
    $description: String
    $image: String
    $weight: Int
    $orderBy: SeasonOrderBy
    $status: SeasonStatus
    $id: ID
  ) {
    updateSeason(
      data: {
        name: $name
        description: $description
        image: $image
        weight: $weight
        orderBy: $orderBy
        status: $status
      }
      where: { id: $id }
    ) {
      id
      name
      description
      image
      weight
      orderBy
      status
      message
    }
  }
`

export const UPDATE_SEASON_IMAGE = gql`
  mutation updateSeason($image: String, $id: ID) {
    updateSeason(data: { image: $image }, where: { id: $id }) {
      id
      image
    }
  }
`

export const UPDATE_SEASON = gql`
  mutation updateSeason(
    $name: String
    $description: String
    $image: String
    $slug: String
    $weight: Int
    $orderBy: SeasonOrderBy
    $status: SeasonStatus
    $createdBy: ID
    $type: SeasonType
    $show: ShowUpdateOneWithoutSeasonsInput
    $podcast: PodcastUpdateOneWithoutSeasonsInput
    $id: ID
  ) {
    updateSeason(
      data: {
        name: $name
        description: $description
        image: $image
        slug: $slug
        weight: $weight
        orderBy: $orderBy
        status: $status
        createdBy: { connect: { id: $createdBy } }
        type: $type
        show: $show
        podcast: $podcast
      }
      where: { id: $id }
    ) {
      id
      name
      description
      slug
      image
      weight
      orderBy
      status
      show {
        id
        name
      }
      podcast {
        id
        name
      }
      message
    }
  }
`

export const UPDATE_SEASONS_EPISODES = gql`
  mutation updateSeason(
    $seasonEpisodes: SeasonEpisodeUpdateManyWithoutSeasonInput
    $id: ID
  ) {
    updateSeason(
      data: { seasonEpisodes: $seasonEpisodes }
      where: { id: $id }
    ) {
      id
      seasonEpisodes {
        id
      }
    }
  }
`

export const DELETE_SEASON = gql`
  mutation deleteSeason($id: ID!) {
    deleteSeason(where: { id: $id }) {
      id
    }
  }
`

export const DELETE_SEASON_EPISODES = gql`
  mutation deleteSeasonEpisode($id: ID!) {
    deleteSeasonEpisode(where: { id: $id }) {
      id
    }
  }
`
export const DELETE_SEASON_PODCAST_EPISODES = gql`
  mutation removePodcastEpisodeFromSeason($id: ID!) {
    removePodcastEpisodeFromSeason(where: { id: $id }) {
      id
    }
  }
`

export const UPDATE_SEASONS_PODCAST_EPISODES = gql`
  mutation updateSeason(
    $podcastEpisodes: PodcastEpisodeUpdateManyWithoutSeasonInput
    $id: ID
  ) {
    updateSeason(
      data: { podcastEpisodes: $podcastEpisodes }
      where: { id: $id }
    ) {
      id
      podcastEpisodes {
        id
      }
    }
  }
`