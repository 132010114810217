import gql from 'graphql-tag'

const GET_SHOWS = gql`
  query shows {
    shows(orderBy: createdAt_DESC) {
      id
      name
      image
      createdAt
      updatedAt
    }
  }
`

const GET_SHOW = gql`
  query show($id: ID!) {
    show(where: { id: $id }) {
      id
      name
    }
  }
`

const GET_ALL_EPISODE = gql`
  query episodes($id: ID!, $after: String) {
    episodes(
      where: { show: { id: $id } }
      first: 15
      after: $after
      orderBy: createdAt_DESC
    ) {
      id
      title
      image
      status
      featuredBannerImage
      createdAt
    }
  }
`

const GET_EPISODE = gql`
  query episode($id: ID!) {
    episode(where: { id: $id }) {
      id
      title
      description
      episodeNumber
      image
      isFeatured
      featuredBannerImage
      featureBannerWeight
      status
      isLive
      discussionId
      segments(orderBy: order_ASC) {
        id
        title
        description
        image
        audio
        video
        videoAccess
        audioAccess
        liveChatAccess
        accessLevelScheduleAt
        scheduleVideoAccess
        scheduleAudioAccess
        duration
        order
        videoState
        muxAssetId
        simulateLive
        liveVideoURL
        updatedAt
      }
      seasonEpisode {
        id
        season {
          id
          name
        }
      }
      show {
        id
        name
      }
      scheduleAt
      createdAt
      allowedContinents 
      rating
      allowedCountries
    }
  }
`

const GET_EPISODE_SIGNED_PUT_URL = gql`
  query getEpisodeSignedPutUrl(
    $fileName: String!
    $episodeId: String!
    $contentType: String!
  ) {
    getEpisodeSignedPutUrl(
      data: {
        fileName: $fileName
        episodeId: $episodeId
        contentType: $contentType
      }
    ) {
      signedUrl
      getUrl
    }
  }
`

const GET_SEGMENT_SIGNED_VIDEO_PUT_URL = gql`
  query getSegmentSignedVideoPutUrl($segmentId: String!, $corsOrigin: String!) {
    getSegmentSignedVideoPutUrl(
      where: { id: $segmentId }
      corsOrigin: $corsOrigin
    ) {
      signedUrl
    }
  }
`

const GET_SEGMENT_SIGNED_PUT_URL = gql`
  query getSegmentSignedPutUrl(
    $fileName: String!
    $episodeId: String!
    $segmentId: String!
    $contentType: String!
  ) {
    getSegmentSignedPutUrl(
      data: {
        fileName: $fileName
        episodeId: $episodeId
        segmentId: $segmentId
        contentType: $contentType
      }
    ) {
      signedUrl
      getUrl
    }
  }
`

export const GET_MUX_DATA = gql`
  query getMuxData($assetId: String!) {
    getMuxData(where: { assetId: $assetId })
  }
`

const GET_EPISODE_SEASONS = gql`
  query seasons($first: Int!, $after: String, $where: SeasonWhereInput) {
    seasons(
      first: $first
      after: $after
      orderBy: weight_DESC    
      where: $where  
    ) {
      id
      name
      slug
      description
      show {
        id
        name
      }
      image
      weight
      orderBy
    }
  }
`
export const CAPTION_SIGNED_PUT_URL = gql`
  query captionSingedPutUrl($fileName: String!) {
    captionSingedPutUrl(fileName: $fileName){
      fileName
      signedUrl
      getUrl
    }
  }
`

export const GET_COUNTRIES = gql`
  query getCountrySelectionForRestriction{
    getCountrySelectionForRestriction {
      continentCode
      continentName 
      countries {
        countryName
        countryCode
      }
    }
  }
`;

export {
  GET_SHOWS,
  GET_SHOW,
  GET_ALL_EPISODE,
  GET_EPISODE,
  GET_EPISODE_SIGNED_PUT_URL,
  GET_SEGMENT_SIGNED_VIDEO_PUT_URL,
  GET_SEGMENT_SIGNED_PUT_URL,
  GET_EPISODE_SEASONS,
}
