import React, { useState, useContext, useRef } from 'react'
import {
  Row,
  Col,
  Modal,
  Button,
  PageHeader,
  notification,
  Tooltip,
  Typography,
} from 'antd'
import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import client from '../../apollo'
import { AppContext } from '../../AppContext'
import { fileUpload } from '../../common/fileUpload'
import { get } from 'lodash'
import {
  GET_SEASON_WITH_EPISODES,
  GET_SEASON_SIGNED_PUT_URL,
} from './graphql/Queries'
import {
  UPDATE_SEASON,
  UPDATE_SEASON_DATA,
  DELETE_SEASON,
} from './graphql/Mutations'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import EpisodeWrapper from './components/EpisodeWrapper'
import './shows.css'
import './episode.css'
import history from '../../history'
import AddShowModal from './components/AddShowModal'
import { useEffect } from 'react'
const { Title } = Typography
const confirm = Modal.confirm

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const { state } = useContext(AppContext)
  const [season, setSeason] = useState({})
  const [seasonEpisodes, setSeasonEpisodes] = useState([])
  const [episodeLoading, setEpisodeLoading] = useState(false)
  const [podcastEpisodes, setPodcastEpisodes] = useState([])
  const saveFormRef = useRef()
  const {
    match: {
      params: { seasonId },
    },
  } = props

  const {
    data: seasonData,
    loading: isSeasonLoading,
    error: seasonError,
    fetchMore,
  } = useQuery(GET_SEASON_WITH_EPISODES, {
    variables: { id: seasonId, first: 20 },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isSeasonLoading) {
      const { seasonEpisodes, podcastEpisodes, ...rest } = seasonData.listSeasonEpisodes
      setSeason(rest)
      setPodcastEpisodes(podcastEpisodes)
      setSeasonEpisodes(seasonEpisodes)
    }
  }, [isSeasonLoading])

  const updateEpisodeList = () => {
    client
      .query({
        query: GET_SEASON_WITH_EPISODES,
        fetchPolicy: 'network-only',
        variables: { id: seasonId, first: 20 },
      })
      .then(
        ({
          data: {
            listSeasonEpisodes: { seasonEpisodes, podcastEpisodes, ...rest },
          },
        }) => {
          setSeason(rest)
          setSeasonEpisodes(seasonEpisodes)
          setPodcastEpisodes(podcastEpisodes)
        },
      )
      .catch(console.log)
  }

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleRequestFail(e) {
    setSubmit(false)
    if (e && e.message) {
      const message =
        (e && e.graphQLErrors[0] && e.graphQLErrors[0].message) || e.message
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function publishEpisode(status, seasonId) {
    const title =
      status === 'PUBLISHED'
        ? 'Are you sure you want to publish this Season?'
        : 'Are you sure you want to unpublish this Season?'
    const okText = status === 'PUBLISHED' ? 'PUBLISH' : 'UNPUBLISH'
    const okType = status === 'UNPUBLISHED' ? 'danger' : 'primary'
    const notification =
      status === 'PUBLISHED'
        ? 'Season Published Successfully'
        : 'Season Unpublished Successfully'

    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          await client.mutate({
            mutation: UPDATE_SEASON_DATA,
            variables: { status, id: seasonId },
            refetchQueries: [
              {
                fetchPolicy: 'network-only',
                query: GET_SEASON_WITH_EPISODES,
                variables: { id: seasonId, first: 20 },
              },
            ],
          })
          setTimeout(() => {
            updateEpisodeList()
            openNotification('success', notification)
          }, 500)
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail(e)
        }
      },
    })
  }

  function showConfirm(id) {
    const { history } = props
    confirm({
      title: 'Are you sure you want to DELETE this season?',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          await client.mutate({
            mutation: DELETE_SEASON,
            variables: { id: id },
          })
          openNotification('success', 'Season Deleted Successfully')
          setTimeout(() => {
            history.push('/seasons')
          }, 500)
        } catch (error) {
          Sentry.captureException(error)
          handleRequestFail(error)
        }
      },
    })
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const queryVariables = values
      const { image } = values
      try {
        let imageFileName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        if (queryVariables.image) {
          delete queryVariables.image
        }
        queryVariables.createdBy = state.currentUser.id
        if (queryVariables.weight) {
          queryVariables.weight = Number(queryVariables.weight)
        } else {
          queryVariables.weight = 100
        }
        if (queryVariables.showId) {
          queryVariables.show = { connect: { id: queryVariables.showId } }
          if (get(season, 'podcast.id')) {
            queryVariables.podcast = { disconnect: true }
          }
          delete queryVariables.showId
        }
        if (queryVariables.podcastId) {
          queryVariables.podcast = { connect: { id: queryVariables.podcastId } }
          if (get(season, 'show.id')) {
            queryVariables.show = { disconnect: true }
          }
          delete queryVariables.podcastId
        }
        const variable = { ...queryVariables, slug: season.slug, id: seasonId }
        const updateSeasonResult = await client.mutate({
          mutation: UPDATE_SEASON,
          variables: variable,
          refetchQueries: [
            {
              fetchPolicy: 'network-only',
              query: GET_SEASON_WITH_EPISODES,
              variables: { id: seasonId, first: 20 },
            },
          ],
        })
        let extractSeasonResult = get(updateSeasonResult, 'data.updateSeason')
        if (extractSeasonResult && extractSeasonResult.id && extractSeasonResult.message) {
          delete extractSeasonResult.message
          setSeason(extractSeasonResult)
        }
        if (image && image.length > 0) {
          const tempFilename = image[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${seasonId}-feature-${new Date().getTime()}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_SEASON_SIGNED_PUT_URL,
            fetchPolicy: 'network-only',
            variables: { fileName, contentType, seasonId: seasonId },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getSeasonSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_SEASON_DATA,
            variables: {
              image: fileName,
              id: seasonId,
            },
            refetchQueries: [
              {
                fetchPolicy: 'network-only',
                query: GET_SEASON_WITH_EPISODES,
                variables: { id: seasonId, first: 20 },
              },
            ],
          })
        }
        setShowModal(false)
        setSubmit(false)
        form.resetFields()
        openNotification('success', 'Season Updated Successfully')
        setTimeout(() => {
          updateEpisodeList()
        }, 500)
      } catch (e) {
        console.log(e)
        Sentry.captureException(e)
        handleRequestFail(e)
      }
    })
  }

  return (
    <div className="show-detail-page">
      {isSeasonLoading ? (
        <Spinner />
      ) : season ? (
        <>
          <Meta title={season.name || ''} description="Seasons" />
          <AddShowModal
            season={season}
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            handleCancel={() => {
              setShowModal(false)
              saveFormRef.current.props.form.resetFields()
            }}
            handleCreate={handleCreate}
          />
          <Row gutter={24} type="flex" className="shows-title-wrapper">
            <Col span={24}>
              <PageHeader
                onBack={() => history.goBack()}
                title={season.name}
                className="box page-header"
                extra={[
                  <Button
                    id='btn-season-edit'
                    key="1"
                    shape="circle"
                    icon="edit"
                    size="small"
                    onClick={() => {
                      setShowModal(true)
                    }}
                  />,
                  <Button
                    id='btn-season-delete'
                    key="2"
                    shape="circle"
                    size="small"
                    icon="delete"
                    type="danger"
                    ghost={true}
                    onClick={() => {
                      showConfirm(season.id)
                    }}
                  />,
                  get(season, 'status') !== 'PUBLISHED' ? (
                    <Button
                      id='btn-season-publish'
                      key="3"
                      type="primary"
                      size="small"
                      onClick={() => {
                        publishEpisode('PUBLISHED', seasonId)
                      }}
                    >
                      PUBLISH
                    </Button>
                  ) : (
                    <Button
                      id='btn-season-unpublish'
                      key="3"
                      type="danger"
                      size="small"
                      onClick={() => {
                        publishEpisode('UNPUBLISHED', seasonId)
                      }}
                    >
                      UNPUBLISH
                    </Button>
                  ),
                ]}
              />
            </Col>
          </Row>
          <div className="box episode-description-div-wrapper season-episode-description-div-wrapper">
            <Row
              gutter={24}
              type="flex"
              className="episode-description-wrapper"
            >
              <Col sm={24} md={6} lg={9} xl={6}>
                <div className="episode-card text-center">
                  <img
                    src={season.image}
                    alt={season.name}
                    title={season.name}
                  />
                </div>
              </Col>
              <Col sm={24} md={18} lg={15} xl={18}>
                <div className="title d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <Title className="season-title-wrapper" level={4}>
                      {season && (season.show ? 'Show :' : season.podcast ? 'Podcast :' : '')}
                    </Title>
                    {season && (season.show ? season.show.name : season.podcast ? season.podcast.name : '')}
                  </div>
                  {season.orderBy === 'Manual' && (
                    <Button
                      type="primary"
                      onClick={() => {
                        history.push(`/seasons/${seasonId}/update`)
                      }}
                    >
                      Update Episode
                    </Button>
                  )}
                </div>
                <div className="episode-description season-episode-description">
                  {season.description}
                </div>
              </Col>
            </Row>
          </div>
          <Row type="flex" gutter={24} className={`episode-season-wrapper ${(season?.podcast) ? 'podcast-episode-season-wrapper' : ''}`}>
            <Col
              xs={24}
              sm={24}
              md={(season?.podcast) ? 6 : 8}
              lg={(season?.podcast) ? 6 : 8}
              xl={(season?.podcast) ? 6 : 8}
              xxl={6}
              className="add-episode-card-wrapper"
            >
              <div className={`add-episode-wrapper ${(season?.podcast) ? 'podcast-episode-add-btn' : ''}`}>
                <Button
                  id='btn-season-episode-create'
                  type="dashed"
                  size="large"
                  className="add-show-button"
                  onClick={() => {
                    history.push(`/seasons/${seasonId}/select`)
                  }}
                >
                  + Add Episode
                </Button>
              </div>
            </Col>
            <EpisodeWrapper
              episodes={seasonEpisodes}
              podcastEpisodes={podcastEpisodes}
              episodeLoading={episodeLoading}
              name={season.name}
              type={season.type}
              updateEpisodeList={updateEpisodeList}
              onLoadMore={() => {
                // setEpisodeLoading(true)
                const sEpisodes = seasonEpisodes
                const lastId =
                  sEpisodes &&
                  sEpisodes.length &&
                  sEpisodes[sEpisodes.length - 1].id

                fetchMore({
                  variables: {
                    id: seasonId,
                    first: 20,
                    after: sEpisodes.length ? lastId : '',
                  },
                  updateQuery: (prevResult, { fetchMoreResult }) => {
                    // setEpisodeLoading(false)
                    const {
                      listSeasonEpisodes: { seasonEpisodes },
                    } = fetchMoreResult
                    if (seasonEpisodes && seasonEpisodes.length) {
                      setSeasonEpisodes([
                        ...prevResult.listSeasonEpisodes.seasonEpisodes,
                        ...seasonEpisodes,
                      ])
                      const updatedEpisodes = [
                        ...prevResult.listSeasonEpisodes.seasonEpisodes,
                        ...seasonEpisodes,
                      ]
                      let data = {
                        listSeasonEpisodes: {
                          ...prevResult.listSeasonEpisodes,
                          ...{ seasonEpisodes: [...updatedEpisodes] }
                        }
                      }
                      return data
                    }
                    return prevResult
                  },
                })
              }}
            />
          </Row>
        </>
      ) : (
        <Page404 />
      )}
    </div>
  )
}
