import React, { Component } from 'react'
import { Col } from 'antd'
import { NavLink } from 'react-router-dom'

export default class ShowCard extends Component {
  render() {
    const { id, image, name } = this.props
    return (
      <Col xs={24} sm={24} md={8} lg={6} xl={6} className="text-center show-card">
        <div className="show-card-wrapper">
          <NavLink to={`/shows/${id}`} className="show-card">
            <img
              src={`${image}?w=300&h=250`}
              alt={name}
              title={name}
            />
            <p className="show-title">{name}</p>
          </NavLink>
        </div>
      </Col>
    )
  }
}
