import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Radio, Icon } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce, cloneDeep, isEqual } from 'lodash'
import client from '../../../../apollo'
import { GET_SHOWS } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE, DELETE_CAROUSEL_SHOW } from '../../graphql/Mutations'
import ShowListCard from './ShowListCard'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import MoveArrow from '../MoveArrow'
import './show-carousel.css'
import usePrevious from '../usePrevious'

const confirm = Modal.confirm
let debounceJob
const defaultPageSize = 7

function ShowCarousel(props) {
  const { showModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [search, setSearch] = useState('')
  const [shows, setShows] = useState(showModuleData.shows || [])
  const [showsData, setShowsData] = useState([])
  const [isPublished, setIsPublished] = useState(showModuleData.isPublished || false)
  const [orientation, setOrientation] = useState(showModuleData.orientation || 'PORTRAIT')
  const [showNextOrder, setShowNextOrder] = useState(showModuleData.nextOrder || 1)
  const [orderChangeModal, setOrderChangeModal] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedRows, setSelectedRows] = useState([])
  const [allShows, setAllShows] = useState([])
  const oldShowsProp = usePrevious(showModuleData.shows || [])

  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {
    if (!isEqual(oldShowsProp, showModuleData.shows)) {
      setShows(showModuleData.shows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModuleData.shows])

  function getShows() {
    setDataLoading(true)
    client
      .query({
        query: GET_SHOWS,
        ...(search !== '' && {
          variables: {
            where: {
              OR: [{ name_starts_with: titleCase(search) },
              { name_starts_with: search.toUpperCase() },
              { name_starts_with: search.toLowerCase() },
              { name_starts_with: search },
              { name_contains: titleCase(search) },
              { name_contains: search.toUpperCase() },
              { name_contains: search.toLowerCase() },
              { name_contains: search },
              { name_ends_with: search },
              { name_ends_with: search.toUpperCase() },
              { name_ends_with: search.toLowerCase() },]
            }
          }
        }),
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data && data.shows) {
          setShowsData(data.shows)
          if (search === '') {
            setAllShows(data.shows)
          }
        }
      })
      .catch((error) => {
        setDataLoading(false)
        Sentry.captureException(error)
        notification['error']({
          message: error.message,
        })
      }).finally(() => {
        setDataLoading(false)
      })
  }

  useEffect(() => {
    if (debounceJob) {
      debounceJob.cancel()
    }
    debounceJob = debounce(() => {
      getShows()
    }, 500)
    debounceJob()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      sorter: false,
      width: '20%',
      render: (image) => (
        <img
          className="table-image"
          src={image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'name',
      sorter: false,
      render: (name) => (<div className="table-name">
        {name}
      </div>)
    },

  ]

  const columnsOrderChange = [
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (record) => (
        <img
          className="table-image"
          src={record.image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      sorter: false,
      render: (_, record, index) => (<div className="table-name">{record.name}</div>),
    },
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (_, record, index) => {
        let indexId = index + (pageNumber - 1) * defaultPageSize
        return <div>
          {(indexId !== (shows.length - 1)) &&
            <Icon type="down" className="p-2" onClick={() => changeOrder(indexId, shows, 1)} />}

          {(indexId !== 0) &&
            <Icon type="up" className="p-2" onClick={() => changeOrder(indexId, shows, -1)} />}
        </div>
      },
    },

  ]

  function changeOrder(indexId, shows, direction) {
    const item = cloneDeep(shows[indexId])
    const nextItem = cloneDeep(shows[indexId + direction])
    const itemOrder = item.carouselShowOrder
    item.carouselShowOrder = nextItem.carouselShowOrder
    nextItem.carouselShowOrder = itemOrder
    const newShowList = shows.filter(show => {
      if (show.id === item.id || show.id === nextItem.id) {
        return false
      }
      return true
    })

    if (direction === -1) {
      newShowList.splice(indexId + direction, 0, item, nextItem)
    } else {
      newShowList.splice(indexId, 0, nextItem, item)
    }
    setShows(newShowList)
    setFieldsValue({ [`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.shows`]: newShowList })
  }

  function handleCancel() {
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    getShows()
    setShowModal(true)
  }

  function handleOrderChangeModalCancel() {
    setOrderChangeModal(false)
  }

  function openOrderChangeModal() {
    setOrderChangeModal(true)
  }

  const onSelectChange = (selectedRowKeys, selectedRowsData) => {
    setSelectedRows(selectedRowsData)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  let filteredShowsData = showsData.filter((show) => {
    for (let index = 0; index < shows.length; index++) {
      if (shows[index].id === show.id) {
        return false
      }
    }
    return true
  })

  function addShows() {
    setIsSubmit(true)
    let newSelectedShow = selectedRowKeys.filter((row) => {
      for (let index = 0; index < shows.length; index++) {
        if (shows[index].id === row) {
          return false
        }
      }
      return true
    })
    let currentOrder = showNextOrder
    let filterSelectedShows = newSelectedShow.map((row) => {
      let show = selectedRows.find((show) => show.id === row)
      if (!show) {
        show = allShows.find((show) => show.id === row)
      }
      show.carouselShowOrder = currentOrder
      currentOrder = currentOrder + 1
      return show
    })
    setShowNextOrder(currentOrder)
    setShows([...shows, ...filterSelectedShows])
    setFieldsValue({ [`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.shows`]: [...shows, ...filterSelectedShows] })
    setShowModal(false)
    setIsSubmit(false)
    setSelectedRows([])
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteShowCarousel(showCarousel) {
    confirm({
      title: `Are you sure you want to remove show carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === showCarousel.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: showCarousel.id, type: MODULE_TYPES_CONST.SHOW_CAROUSEL },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== showCarousel.id)
        setModuleList(newModules)
      },
    })
  }

  function deleteCarouselShow(id, show) {
    confirm({
      title: `Are you sure you want to remove show`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        if (show.carouselShowId) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_SHOW,
              variables: { id: id },
            })
            const deleteCarouselShow = get(response, 'data.deleteCarouselShow')
            if (deleteCarouselShow && deleteCarouselShow.message) {
              openNotification('success', deleteCarouselShow.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        const newShows = shows.filter(item => item.id !== show.id)
        setShows(newShows);
        setFieldsValue({ [`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.shows`]: newShows })
        setSelectedRowKeys(selectedRowKeys.filter(rowKeys => rowKeys !== show.id))
      },
    })
  }

  function onOrientationChange(e) {
    setOrientation(e.target.value)
  }

  return (
    <Card key={showModuleData.id} title='Show Carousel' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.isPublished`, {
            initialValue: showModuleData.isPublished || false
          })(
            <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.SHOW_CAROUSEL}-${indexId}-isPublished`}>
              Published
          </Checkbox>)}
          <Button id={`btn-delete-show-carousel-${indexId}`} icon='delete'
            onClick={() => handleDeleteShowCarousel(showModuleData)}
            type='danger'>
            Delete
        </Button>
        </div>
      ]}
    >
      <Form.Item label='Title'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.title`, {
          initialValue: showModuleData.title ? showModuleData.title : '',
          rules: [{ required: true, message: 'Please input your title!' }]
        })(
          <Input placeholder='Title' className={`${MODULE_TYPES_CONST.SHOW_CAROUSEL}-${indexId}-title`} />,
        )}
      </Form.Item>
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.order`, {
          initialValue: showModuleData.order || 1
        })(<Input placeholder='Order' className={`${MODULE_TYPES_CONST.SHOW_CAROUSEL}-${indexId}-order`} />,)}
      </Form.Item>
      <Form.Item label='Shows' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.shows`, {
          initialValue: showModuleData.shows || [],
          rules: [{ type: 'array', required: true, min: 1, message: 'Please add atleast one show!' }]
        })(
          <Row gutter={24} type="flex">
            {shows.length > 0 && shows.map((show, index) => (
              <ShowListCard
                key={show.id}
                id={show.carouselShowId ? show.carouselShowId : show.id}
                name={show.name}
                image={(orientation === 'PORTRAIT') ? show.portraitImage : show.image}
                index={index}
                indexId={indexId}
                show={show}
                deleteCarouselShow={deleteCarouselShow}
                orientation={orientation}
              />
            ))}
          </Row>)}
      </Form.Item>
      <Row>
        <div className="mt-5">
          <Button icon='plus' id={`btn-add-show-${indexId}`} onClick={openModal}>
            Add Show
          </Button>
          {shows.length > 1 && (<Button className="ml-2" icon='edit' id={`btn-edit-show-${indexId}`}
            onClick={openOrderChangeModal}>
            Change Order
          </Button>)
          }
        </div>
        <div className="image-override-margin-btn">
          <Form.Item label="Orientation">
            {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_CAROUSEL}.${showModuleData.id}.orientation`, {
              initialValue: showModuleData.orientation ? showModuleData.orientation : 'PORTRAIT',
              rules: [{ required: true, message: 'Please Select Orientation!' }]
            })(
              <Radio.Group onChange={onOrientationChange} className={`${MODULE_TYPES_CONST.SHOW_CAROUSEL}-${indexId}-orientation`}>
                <Radio value="PORTRAIT">Portrait</Radio>
                <Radio value="LANDSCAPE">Landscape</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
        </div>
      </Row>
      <Row>
        <Modal
          title="Add Shows"
          visible={showModal}
          maskClosable={false}
          onOk={addShows}
          onCancel={handleCancel}
          footer={[
            <Button id={`btn-show-carousel-cancel-${indexId}`} key="back" onClick={handleCancel}>
              Cancel
              </Button>,
            <Button
              id={`btn-show-carousel-save-${indexId}`}
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={addShows}
            >
              Add
              </Button>,
          ]}
        >
          <Row>
            <Col>
              <Input.Search placeholder="Search Show" onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-show-carousel-${indexId}`} />
            </Col>
          </Row>
          <Row className="mt-2">
            <Table
              loading={dataLoading}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              dataSource={filteredShowsData}
              showHeader={false}
              pagination={{ defaultPageSize }}
            />
          </Row>
        </Modal>
      </Row>
      <Modal
        title='Change Order'
        visible={orderChangeModal}
        maskClosable={false}
        onCancel={handleOrderChangeModalCancel}
        footer={null}
      >
        <Row>
          <Table
            columns={columnsOrderChange}
            rowKey={(record) => record.id}
            dataSource={shows}
            showHeader={false}
            pagination={{
              onChange(currentPage) { setPageNumber(currentPage) },
              defaultPageSize
            }}
          />
        </Row>
      </Modal>
    </Card >
  )
}

export default ShowCarousel
