import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Upload, Icon, message } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce } from 'lodash'
import { useLazyQuery } from '@apollo/react-hooks'
import client from '../../../../apollo'
import { GET_VIDEOS } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE } from '../../graphql/Mutations'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import VideoCard from './VideoCard'
import MoveArrow from '../MoveArrow'
import ShowImage from './ShowImage'

const confirm = Modal.confirm
let debounceJob

function VideoPremiereCarousel(props) {
  const { videoPremiereModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState('')
  const [video, setVideo] = useState(videoPremiereModuleData.video || '')
  const [isPublished, setIsPublished] = useState(videoPremiereModuleData.isPublished || false)
  const [removeFile, setRemoveFile] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const removeImageField = getFieldValue(`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.removeImage`)

  const [getVideos, {
    data: listVideos,
    loading: dataLoading
  }] = useLazyQuery(GET_VIDEOS, {
    variables: {
      first: 5,
      where: {
        ...(search !== '' && {
          OR:
            [{ name_starts_with: titleCase(search) },
            { name_starts_with: search.toUpperCase() },
            { name_starts_with: search.toLowerCase() },
            { name_starts_with: search },
            { name_contains: titleCase(search) },
            { name_contains: search.toUpperCase() },
            { name_contains: search.toLowerCase() },
            { name_contains: search },
            { name_ends_with: search },
            { name_ends_with: search.toUpperCase() },
            { name_ends_with: search.toLowerCase() },]
        }),
      }
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (debounceJob) {
      debounceJob.cancel()
    }
    debounceJob = debounce(() => {
      getVideos()
    }, 500)
    debounceJob()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      sorter: false,
      width: '20%',
      render: (image) => (
        <img
          className="table-image"
          src={image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'name',
      sorter: false,
      render: (name) => (<div className="table-name">
        {name}
      </div>)
    },

  ]

  function handleCancel() {
    setSearch('')
    setCurrentPageNumber(1)
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    getVideos()
    setShowModal(true)
  }

  function onRowSelect(record, rowIndex) {
    return {
      onClick: event => {
        setVideo(record)
        setFieldsValue({ [`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.video`]: record })
        setSearch('')
        setCurrentPageNumber(1)
        setShowModal(false)
      }, // click row
    };
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteVideoPremiereCarousel(videoPremiereCarousel) {
    confirm({
      title: `Are you sure you want to remove video premiere carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === videoPremiereCarousel.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: videoPremiereCarousel.id, type: MODULE_TYPES_CONST.VIDEO_PREMIERE },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== videoPremiereCarousel.id)
        setModuleList(newModules)
      },
    })
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  function handleRemoveImage() {
    confirm({
      title: `Are you sure you want to remove image override`,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        setFieldsValue({ [`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.removeImage`]: true })
      },
    })
  }

  const uploadProps = {
    customRequest() {
      return false
    },
  }

  function beforeImageUpload(file) {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    ) {
      return true
    }
    message.error('You can only upload image file!')
    return false
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      setLastPage(false)
    }
  }

  useEffect(() => {
    async function handlePageChange() {
      if (!dataLoading) {
        try {
          getVideos({
            variables: {
              skip: (currentPageNumber - 1) * 5,
              first: 5
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { videos } = fetchMoreResult
                if (videos?.length < 5) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                return videos?.length ? { videos: [...videos] } : prevResult
              }
            }
          })
        } catch (error) {
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber])

  useEffect(() => {
    if (!dataLoading && listVideos?.videos) {
      if (listVideos?.videos?.length < 5) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [dataLoading])

  return (
    <Card key={videoPremiereModuleData.id} title='Video Premiere' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.isPublished`, {
            initialValue: videoPremiereModuleData.isPublished || false
          })(
            <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.VIDEO_PREMIERE}-${indexId}-isPublished`}>
              Published
          </Checkbox>)}
          <Button id={`btn-delete-video-premiere-${indexId}`}
            className="ml-2"
            icon='delete'
            onClick={() => handleDeleteVideoPremiereCarousel(videoPremiereModuleData)}
            type='danger'>
            Delete
          </Button>
        </div>
      ]
      }
    >
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.order`, {
          initialValue: videoPremiereModuleData.order || 1
        })(<Input placeholder='Order' className={`${MODULE_TYPES_CONST.VIDEO_PREMIERE}-${indexId}-order`} />)}
      </Form.Item>
      <Form.Item label='Video' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.video`, {
          initialValue: videoPremiereModuleData.video || '',
          rules: [{
            type: 'object', required: true, message: 'Please Add Video!'
          }]
        })(
          <Row gutter={24} type="flex">
            {
              Object.keys(video).length > 0 && <VideoCard id={video.id}
                name={video.name}
                image={video.image}
                description={video.description}
                video={video}
              />
            }
          </Row>)}
      </Form.Item>
      <Row>
        <Col>
          <Button icon={videoPremiereModuleData.video ? 'edit' : 'plus'}
            id={`btn-add-video-premiere-${indexId}`}
            onClick={openModal}>
            {videoPremiereModuleData.video ? 'Edit Video' : 'Add Video'}
          </Button>
          <Modal
            title={videoPremiereModuleData.video ? 'Edit Video' : 'Add Video'}
            visible={showModal}
            maskClosable={false}
            // onOk={addVideo}
            onCancel={handleCancel}
            footer={null}
          >
            <Row>
              <Col>
                <Input.Search placeholder="Search Video" onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-video-premiere-${indexId}`} />
              </Col>
            </Row>
            <Row className="mt-2">
              <Table
                loading={dataLoading}
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={listVideos?.videos}
                showHeader={false}
                onRow={onRowSelect}
                pagination={false}
                onChange={handleChange}
              />
              <div className="page-change-btn-wrapper">
                <Button icon="left" onClick={() => setCurrentPageNumber(currentPageNumber - 1)} disabled={currentPageNumber === 1} />
                <Button icon="right" onClick={() => setCurrentPageNumber(currentPageNumber + 1)} disabled={lastPage} />
              </div>
            </Row>
          </Modal>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Form.Item label="Image (override) (3360 x 1728)" className="mb-0">
            {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.removeImage`, {
              initialValue: false
            })
              (
                <Row>
                  {videoPremiereModuleData.premiereImage && !removeImageField &&
                    <ShowImage id={videoPremiereModuleData.id} image={videoPremiereModuleData.premiereImage} handleRemoveImage={handleRemoveImage} indexId={indexId} />}
                </Row>
              )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_PREMIERE}.${videoPremiereModuleData.id}.image`, {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
            })(
              <Upload
                name="logo"
                {...uploadProps}
                listType="picture"
                multiple={false}
                onRemove={(e) => handleRemove(e)}
              >
                <Button className="image-override-margin-btn" id={`btn-video-premiere-image-${indexId}`}>
                  <Icon type="upload" /> {videoPremiereModuleData.premiereImage ? 'Edit Image' : 'Add Image'}
                </Button>
              </Upload>,
            )}
          </Form.Item>
        </Col>
      </Row>
    </Card >
  )
}

export default VideoPremiereCarousel
