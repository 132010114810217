import gql from 'graphql-tag'

export const SUBSCRIBE_CLIP = gql`
  subscription clip($id: ID!) {    
    clip(where: { node: { id: $id } }) {
      mutation
      node {
        id
        name
        description
        image
        status
        videoState       
        muxAssetId
        uploadId
        muxPlaybackId
        thumbnail
        videoURL
        duration
        clipAccess
        createdAt
        updatedAt
        message
      }
    }
  }
`