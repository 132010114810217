import React, { Component } from 'react'
import { Col } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
export default class SeasonCard extends Component {
  render() {
    const { id, image, name, createdAt } = this.props
    return (
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        className="episode-card-col-wrapper"
      >
        <div className="episode-card-wrapper" style={{ position: 'relative' }}>
          <NavLink to={`/seasons/${id}`} className="episode-card">
            <div className="text-center d-flex">
              <img src={`${image}?w=300&h=250`} alt={name} title={name} />
            </div>
            <div className="episode-details" style={{ textAlign: 'left' }}>
              <p className="episode-title">{name}</p>
              <p className="episode-date">
                {moment(createdAt).format('MMMM Do, YYYY')}
              </p>
            </div>
          </NavLink>
        </div>
      </Col>
    )
  }
}
