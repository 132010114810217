import gql from 'graphql-tag'

export const SUBSCRIBE_VIDEO = gql`
  subscription video($id: ID!) {
    video(where: { node: { id: $id } }) {
      mutation
      node {
        id
        name
        description
        image
        status
        isLive
        scheduleAt
        discussionId
        videoState
        audioState
        muxAssetId
        streamKey
        uploadId
        thumbnail
        muxPlaybackId
        videoURL
        duration
        videoAccess
      }
    }
  }
`
