import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Upload, Icon, message } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce } from 'lodash'
import client from '../../../../apollo'
import { GET_SHOWS } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE } from '../../graphql/Mutations'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import ShowCard from './ShowCard'
import MoveArrow from '../MoveArrow'
import ShowImage from './ShowImage'

const confirm = Modal.confirm
let debounceJob
const defaultPageSize = 7

function ShowPremiereCarousel(props) {
  const { showPremiereModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [show, setShow] = useState(showPremiereModuleData.show || '')
  const [showsData, setShowsData] = useState([])
  const [isPublished, setIsPublished] = useState(showPremiereModuleData.isPublished || false)
  const [removeFile, setRemoveFile] = useState(false)

  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const removeImageField = getFieldValue(`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.removeImage`)

  function getShows() {
    setDataLoading(true)
    client
      .query({
        query: GET_SHOWS,
        ...(search !== '' && {
          variables: {
            where: {
              OR: [{ name_starts_with: titleCase(search) },
              { name_starts_with: search.toUpperCase() },
              { name_starts_with: search.toLowerCase() },
              { name_starts_with: search },
              { name_contains: titleCase(search) },
              { name_contains: search.toUpperCase() },
              { name_contains: search.toLowerCase() },
              { name_contains: search },
              { name_ends_with: search },
              { name_ends_with: search.toUpperCase() },
              { name_ends_with: search.toLowerCase() },]
            }
          }
        }),
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data && data.shows) {
          setShowsData(data.shows)
        }
      })
      .catch((error) => {
        setDataLoading(false)
        Sentry.captureException(error)
        notification['error']({
          message: error.message,
        })
      }).finally(() => {
        setDataLoading(false)
      })
  }

  useEffect(() => {
    if (debounceJob) {
      debounceJob.cancel()
    }
    debounceJob = debounce(() => {
      getShows()
    }, 500)
    debounceJob()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      sorter: false,
      width: '20%',
      render: (image) => (
        <img
          className="table-image"
          src={image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'name',
      sorter: false,
      render: (name) => (<div className="table-name">
        {name}
      </div>)
    },

  ]

  function handleCancel() {
    setSearch('')
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    getShows()
    setShowModal(true)
  }

  function onRowSelect(record, rowIndex) {
    return {
      onClick: event => {
        setShow(record)
        setFieldsValue({ [`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.show`]: record })
        setSearch('')
        setShowModal(false)
      }, // click row
    };
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteShowPremiereCarousel(showPremiere) {
    confirm({
      title: `Are you sure you want to remove show premiere carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === showPremiere.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: showPremiere.id, type: MODULE_TYPES_CONST.SHOW_PREMIERE },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== showPremiere.id)
        setModuleList(newModules)
      },
    })
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  function handleRemoveImage() {
    confirm({
      title: `Are you sure you want to remove image override`,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        setFieldsValue({ [`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.removeImage`]: true })
      },
    })
  }

  const uploadProps = {
    customRequest() {
      return false
    },
  }

  function beforeImageUpload(file) {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    ) {
      return true
    }
    message.error('You can only upload image file!')
    return false
  }

  return (
    <Card key={showPremiereModuleData.id} title='Show Premiere' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.isPublished`, {
            initialValue: showPremiereModuleData.isPublished || false
          })(
            <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.SHOW_PREMIERE}-${indexId}-isPublished`}>
              Published
          </Checkbox>)}
          <Button id={`btn-delete-show-premiere-${indexId}`}
            className="ml-2"
            icon='delete'
            onClick={() => handleDeleteShowPremiereCarousel(showPremiereModuleData)}
            type='danger'>
            Delete
          </Button>
        </div>
      ]
      }
    >
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.order`, {
          initialValue: showPremiereModuleData.order || 1
        })(<Input placeholder='Order' className={`${MODULE_TYPES_CONST.SHOW_PREMIERE}-${indexId}-order`} />)}
      </Form.Item>
      <Form.Item label='Show' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.show`, {
          initialValue: showPremiereModuleData.show || '',
          rules: [{ type: 'object', required: true, min: 1, message: 'Please Add Show!' }]
        })(
          <Row gutter={24} type="flex">
            {
              Object.keys(show).length > 0 && <ShowCard id={show.id}
                name={show.name}
                image={show.image}
                description={show.description}
                show={show} />
            }
          </Row>)}
      </Form.Item>
      <Row >
        <Col>
          <Button icon={showPremiereModuleData.show ? 'edit' : 'add'}
            id={`btn-add-show-premiere-${indexId}`}
            onClick={openModal}>
            {showPremiereModuleData.show ? 'Edit Show' : 'Add Show'}
          </Button>
          <Modal
            title={showPremiereModuleData.show ? 'Edit Show' : 'Add Show'}
            visible={showModal}
            maskClosable={false}
            onCancel={handleCancel}
            footer={null}
          >
            <Row>
              <Col>
                <Input.Search placeholder="Search Show" onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-show-premiere-${indexId}`} />
              </Col>
            </Row>
            <Row className="mt-2">
              <Table
                loading={dataLoading}
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={showsData}
                showHeader={false}
                onRow={onRowSelect}
                pagination={{ defaultPageSize }}
              />
            </Row>
          </Modal>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Form.Item label="Image (override) (3360 x 1728)" className="mb-0">
            {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.removeImage`, {
              initialValue: false
            })(
              <Row>
                {showPremiereModuleData.premiereImage &&
                  !removeImageField &&
                  <ShowImage id={showPremiereModuleData.id} image={showPremiereModuleData.premiereImage} handleRemoveImage={handleRemoveImage} indexId={indexId} />}
              </Row>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(`${MODULE_TYPES_CONST.SHOW_PREMIERE}.${showPremiereModuleData.id}.image`, {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
            })(
              <Upload
                name="logo"
                {...uploadProps}
                listType="picture"
                multiple={false}
                onRemove={(e) => handleRemove(e)}
              >
                <Button className="image-override-margin-btn" id={`btn-show-premiere-image-${indexId}`}>
                  <Icon type="upload" /> {showPremiereModuleData.premiereImage ? 'Edit Image' : 'Add Image'}
                </Button>
              </Upload>,
            )}
          </Form.Item>
        </Col>
      </Row>
    </Card >
  )
}

export default ShowPremiereCarousel
