export const CONTINENTS = [
  { 'key': 'AF', 'value': 'Africa' },
  { 'key': 'NA', 'value': 'North America' },
  { 'key': 'OC', 'value': 'Oceania' },
  { 'key': 'AN', 'value': 'Antarctica' },
  { 'key': 'AS', 'value': 'Asia' },
  { 'key': 'EU', 'value': 'Europe' },
  { 'key': 'SA', 'value': 'South America' },
]

export const MEDIA_RATINGS = [
  { 'key': 'TV-Y', 'value': 'TV-Y (All Children)' },
  { 'key': 'TV-Y7', 'value': 'TV-Y7 (Directed To Older Children)' },
  { 'key': 'TV-G', 'value': 'TV-G (General Audience)' },
  { 'key': 'TV-PG', 'value': 'TV-PG (Parental Guidance Suggested)' },
  { 'key': 'TV-14', 'value': 'TV-14 (Parents Strongly Cautioned)' },
  { 'key': 'TV-MA', 'value': 'TV-MA (Mature Audiences Only)' }
]

export const ACCESS_LEVELS = [
  { 'key': 'READER', 'value': 'READER' },
  { 'key': 'INSIDER', 'value': 'INSIDER' },
  { 'key': 'INSIDER_PLUS', 'value': 'INSIDER ANNUAL' },
  { 'key': 'ALL_ACCESS', 'value': 'ALL ACCESS' },
]

export const MODULE_TYPES_CONST = {
  'EPISODE_CAROUSEL': 'EPISODE_CAROUSEL',
  'EPISODE_PREMIERE': 'EPISODE_PREMIERE',
  'VIDEO_CAROUSEL': 'VIDEO_CAROUSEL',
  'VIDEO_PREMIERE': 'VIDEO_PREMIERE',
  'SHOW_CAROUSEL': 'SHOW_CAROUSEL',
  'SHOW_PREMIERE': 'SHOW_PREMIERE',
  'CLIP_CAROUSEL': 'CLIP_CAROUSEL',
  'FREEFORM_HIGHLIGHT': 'FREEFORM_HIGHLIGHT',
  'FREEFORM_HEADLINER': 'FREEFORM_HEADLINER',
  'CONTINUE_WATCHING': 'CONTINUE_WATCHING',
  'PODCAST_CAROUSEL': 'PODCAST_CAROUSEL',
  'PODCAST_PREMIERE': 'PODCAST_PREMIERE',
  'PODCAST_EPISODE_CAROUSEL': 'PODCAST_EPISODE_CAROUSEL',
  'PODCAST_EPISODE_PREMIERE': 'PODCAST_EPISODE_PREMIERE',
  'CONTINUE_LISTENING': 'CONTINUE_LISTENING',
}

export const MODULE_TYPES = [
  { 'key': MODULE_TYPES_CONST.EPISODE_CAROUSEL, 'value': 'EPISODE CAROUSEL' },
  { 'key': MODULE_TYPES_CONST.EPISODE_PREMIERE, 'value': 'EPISODE PREMIERE' },
  { 'key': MODULE_TYPES_CONST.VIDEO_CAROUSEL, 'value': 'VIDEO CAROUSEL' },
  { 'key': MODULE_TYPES_CONST.VIDEO_PREMIERE, 'value': 'VIDEO PREMIERE' },
  { 'key': MODULE_TYPES_CONST.SHOW_CAROUSEL, 'value': 'SHOW CAROUSEL' },
  { 'key': MODULE_TYPES_CONST.SHOW_PREMIERE, 'value': 'SHOW PREMIERE' },
  { 'key': MODULE_TYPES_CONST.CLIP_CAROUSEL, 'value': 'CLIP CAROUSEL' },
  { 'key': MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT, 'value': 'FREEFORM HIGHLIGHT' },
  { 'key': MODULE_TYPES_CONST.FREEFORM_HEADLINER, 'value': 'FREEFORM HEADLINER' },
  { 'key': MODULE_TYPES_CONST.CONTINUE_WATCHING, 'value': 'CONTINUE WATCHING' },
  { 'key': MODULE_TYPES_CONST.PODCAST_CAROUSEL, 'value': 'PODCAST CAROUSEL' },
  { 'key': MODULE_TYPES_CONST.PODCAST_PREMIERE, 'value': 'PODCAST PREMIERE' },
  { 'key': MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL, 'value': 'PODCAST EPISODE CAROUSEL' },
  { 'key': MODULE_TYPES_CONST.PODCAST_EPISODE_PREMIERE, 'value': 'PODCAST EPISODE PREMIERE' },
  { 'key': MODULE_TYPES_CONST.CONTINUE_LISTENING, 'value': 'CONTINUE LISTENING' },
]

export const LANGUAGE = [
  { 'label': 'English', 'value': 'en-US' },   // add option to add more subtitle support
]

export const LOCATIONS = {
  CONTINENT : 'Continent',
  COUNTRY: 'Country'
}

export default {
  CONTINENTS,
  MEDIA_RATINGS,
}
