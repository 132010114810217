import gql from 'graphql-tag'

const CREATE_SHOW = gql`
  mutation createShow(
    $name: String!
    $description: String!
    $weight: Int
    $image: String
    $backgroundImage: String
    $hostImage: String
    $logoImage: String
    $portraitImage: String
    $showDetailImage: String
    $episodeDetailImage: String
    $author: ID!
    $createdBy: ID!
  ) {
    createShow(
      data: {
        name: $name
        description: $description
        weight: $weight
        image: $image
        backgroundImage: $backgroundImage
        hostImage: $hostImage
        logoImage: $logoImage
        portraitImage: $portraitImage
        showDetailImage: $showDetailImage
        episodeDetailImage: $episodeDetailImage
        author: { connect: { id: $author } }
        createdBy: { connect: { id: $createdBy } }
      }
    ) {
      id
      name
      weight
      image
      backgroundImage
      portraitImage
      hostImage
      logoImage
      showDetailImage
      episodeDetailImage
      author {
        email
      }
    }
  }
`

const UPDATE_SHOW = gql`
  mutation updateShow(
    $name: String
    $description: String
    $weight: Int
    $image: String
    $backgroundImage: String
    $hostImage: String
    $logoImage: String
    $portraitImage: String
    $showDetailImage: String
    $episodeDetailImage: String
    $author: ID
    $showId: ID!
  ) {
    updateShow(
      data: {
        name: $name
        description: $description
        weight: $weight
        image: $image
        backgroundImage: $backgroundImage
        hostImage: $hostImage
        logoImage: $logoImage
        portraitImage: $portraitImage
        showDetailImage: $showDetailImage
        episodeDetailImage: $episodeDetailImage
        author: { connect: { id: $author } }
      }
      where: { id: $showId }
    ) {
      id
      image
      backgroundImage
      portraitImage
      hostImage
      logoImage
      showDetailImage
      episodeDetailImage
    }
  }
`

const UPDATE_SHOW_IMAGE = gql`
  mutation updateShow(
    $backgroundImage: String
    $hostImage: String
    $logoImage: String
    $portraitImage: String
    $showDetailImage: String
    $episodeDetailImage: String
    $showId: ID!
  ) {
    updateShow(
      data: {
        backgroundImage: $backgroundImage
        hostImage: $hostImage
        logoImage: $logoImage
        portraitImage: $portraitImage
        showDetailImage: $showDetailImage
        episodeDetailImage: $episodeDetailImage
      }
      where: { id: $showId }
    ) {
      id
      image
      backgroundImage
      portraitImage
      hostImage
      logoImage
      showDetailImage
      episodeDetailImage
    }
  }
`

const DELETE_SHOW = gql`
  mutation deleteShow($showId: ID!) {
    deleteShow(where: { id: $showId }) {
      id
    }
  }
`

export { CREATE_SHOW, UPDATE_SHOW, UPDATE_SHOW_IMAGE, DELETE_SHOW }
