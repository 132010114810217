import gql from "graphql-tag"

export const GET_PERSONS = gql`
query persons($skip: Int, $first: Int, $filters: PersonWhereInput, $orderByFilter: PersonOrderByInput) {
  persons(where: $filters, skip: $skip, first: $first, orderBy: $orderByFilter) {
    id
    email
    firstName
    profileImage
    lastName
    userName
    authZeroID
    recurlyId
    recurlyPlan
    promoPlan
    promoPlanExpiration
    role
    isActive
    createdAt
    updatedAt
  }
}
`

export const SYNC_RECURLY_PLAN = gql`
query syncRecurlyPlan($recurlyId: String!) {
  syncRecurlyPlan(where:{ recurlyId: $recurlyId })
}
`
