import gql from 'graphql-tag'

const SUBSCRIBE_MESSAGE = gql`
subscription message($id: ID!){
  message(where: { node: { discussion:{ id : $id } } }) {
    mutation
    previousValues{
      id
    }
    node {
      id
      message
      isBlocked
      isDeleted
      userType
      createdAt
      parent{
        id
        message
        isBlocked
        isDeleted
        userType
        createdAt
        flags{
          id
        }
        createdBy{
          id
          firstName
          lastName
          userName
          role
          profileImage
        }
        likes {
          id
        }
      }
      flags{
        id
      }
      createdBy{
        id
        firstName
        lastName
        role
        userName
        profileImage
      }
      likes {
        id
      }
    }
  }
}
`

const SUBSCRIBE_DISCUSSION = gql`
subscription discussion($id: ID!){
  discussion(where: { node: { id: $id } }) {
    mutation
    node {
      id
      status
    }
  }
}
`

export { SUBSCRIBE_MESSAGE, SUBSCRIBE_DISCUSSION }
