import gql from 'graphql-tag'

export const CREATE_PODCAST = gql`
  mutation createPodcastCustom(
    $name: String!
    $description: String!
    $weight: Int
    $coverImage: String
    $backgroundImage: String
    $logoImage: String    
    $author: ID!
  ) {
    createPodcastCustom(
      data: {
        name: $name
        description: $description
        weight: $weight
        coverImage: $coverImage
        backgroundImage: $backgroundImage
        logoImage: $logoImage
        authorId:  $author
      }
    ) {
      id
      name
      weight
      coverImage
      backgroundImage
      logoImage
    }
  }
`

export const UPDATE_PODCAST = gql`
  mutation updatePodcastCustom(
    $name: String
    $description: String
    $weight: Int
    $coverImage: String
    $backgroundImage: String
    $logoImage: String
    $author: ID
    $podcastId: ID!
  ) {
    updatePodcastCustom(
      data: {
        name: $name
        description: $description
        weight: $weight
        coverImage: $coverImage
        backgroundImage: $backgroundImage
        logoImage: $logoImage
        authorId: $author
      }
      where: { id: $podcastId }
    ) {
      id
      name
      description
      status      
      coverImage
      backgroundImage
      logoImage
    }
  }
`

export const UPDATE_PODCAST_STATUS = gql`
  mutation updatePodcastCustom(
    $status: PodcastStatus
    $podcastId: ID!
  ) {
    updatePodcastCustom(
      data: {
        status: $status
      }
      where: { id: $podcastId }
    ) {
      id
      name
      description
      status      
      coverImage
      backgroundImage
      logoImage
    }
  }
`

export const UPDATE_PODCAST_IMAGE = gql`
  mutation updatePodcastCustom(
    $backgroundImage: String
    $coverImage: String
    $logoImage: String
    $podcastId: ID!
  ) {
    updatePodcastCustom(
      data: {
        backgroundImage: $backgroundImage
        logoImage: $logoImage
        coverImage: $coverImage
      }
      where: { id: $podcastId }
    ) {
      id
      backgroundImage
      logoImage
      coverImage
    }
  }
`

export const DELETE_PODCAST = gql`
  mutation deletePodcastCustom($podcastId: ID!) {
    deletePodcastCustom(where: { id: $podcastId })
  }
`