import React, { Component } from 'react'
import { Modal, Form, Select } from 'antd'
import { MODULE_TYPES } from '../../../common/constants'

const { Option } = Select

const CollectionCreateForm = Form.create({ name: 'create_module' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title='Add Module'
          okText='Add'
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout='vertical'>
            <Form.Item label='Module Type'>
              {getFieldDecorator('moduleType', {
                rules: [{ required: true, message: 'Please select the module' }],
              })(
                <Select
                  showSearch
                  allowClear
                  placeholder='Select Module'
                >
                  {MODULE_TYPES.map(({ key, value }) => (
                    <Option value={key} key={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);

class AddModuleTypeModal extends Component {
  onCreateModule = () => {
    const { form } = this.moduleTypeFormRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // console.log('Received values of form: ', values);
      // form.resetFields();
      this.props.handleCreate(values, form)      
    });
  };

  saveFormRef = formRef => {
    this.moduleTypeFormRef = formRef;
  };

  render() {
    const {
      showMouduleTypeModal
    } = this.props
    return (
      <div>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={showMouduleTypeModal}
          onCancel={this.props.handleCancel}
          onCreate={this.onCreateModule}
        />
      </div>
    );
  }
}

export default AddModuleTypeModal
