import React, { Component } from 'react'
import ShowCard from './ShowCard'

export default class ShowWrapper extends Component {
  constructor(props) {
    super(props)
    this.container = document.querySelector('.content-wrapper')
  }
  componentDidMount() {
    this.container.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.handleOnScroll)
  }

  handleOnScroll = () => {
    if (
      this.container.scrollTop + this.container.offsetHeight >=
      this.container.scrollHeight - 5
    ) {
      this.props.onLoadMore()
    }
  }

  render() {
    const { shows } = this.props
    return shows && shows.map((show, id) => <ShowCard {...show} key={id} />)
  }
}
