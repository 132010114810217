import React from 'react'
import { Col, Button } from 'antd'

function ShowImage(props) {

  const { id, image, handleRemoveImage } = props

  return (
    <Col
      xs={24}
      sm={24}
      md={8}
      lg={6}
      xl={6}
    >
      <div className="tile-card-premiere-wrapper">
        <div className="tile-card-delete">
          <Button
            id={`btn-show-image-override-${id}-delete`}
            key="2"
            size="default"
            icon="delete"
            type="danger"
            onClick={handleRemoveImage}
          />
        </div>
        <div className="episode-card">
          <div className="text-center d-flex">
            <img src={`${image}?w=300&h=250`} alt={"show"} />
          </div>
        </div>
      </div>
    </Col>
  )
}

export default ShowImage
