import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Select, Icon } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce, cloneDeep, isEqual, uniqBy } from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import client from '../../../../apollo'
import { GET_PODCASTS, GET_PODCAST_EPISODES } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE, DELETE_CAROUSEL_PODCAST_EPISODE } from '../../graphql/Mutations'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import PodcastEpisodeListCard from './PodcastEpisodeListCard'
import MoveArrow from '../MoveArrow'
import usePrevious from '../usePrevious'

const confirm = Modal.confirm
const Option = Select.Option
let debounceJob
const defaultPageSize = 7

function PodcastEpisodeCarousel(props) {
  const { podcastEpisodeModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [search, setSearch] = useState('')
  const [podcastEpisodes, setPodcastEpisodes] = useState(podcastEpisodeModuleData.podcastEpisodes || [])
  const [isPublished, setIsPublished] = useState(podcastEpisodeModuleData.isPublished || false)
  const [podcast, setPodcast] = useState('ALL')
  const [podcastEpisodeNextOrder, setPodcastEpisodeNextOrder] = useState(podcastEpisodeModuleData.nextOrder || 1)
  const [orderChangeModal, setOrderChangeModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [loadingMorePodcasts, setLoadingMorePodcasts] = useState(false)
  const [podcastDataEndReached, setPodcastDataEndReached] = useState(false)
  const [current, setCurrent] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const oldPodcastEpisodesProp = usePrevious(podcastEpisodeModuleData.podcastEpisodes || []);

  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {
    if (!isEqual(oldPodcastEpisodesProp, podcastEpisodeModuleData.podcastEpisodes)) {
      setPodcastEpisodes(podcastEpisodeModuleData.podcastEpisodes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastEpisodeModuleData.podcastEpisodes])

  const {
    data: podcastData,
    loading: podcastsLoading,
    fetchMore: fetchMorePodcasts
  } = useQuery(GET_PODCASTS, {
    fetchPolicy: 'network-only',
  })

  const id_not = podcastEpisodes.map(({ id }) => (id));
  const [getPodcastEpisodes, {
    data: listPodcastEpisode,
    loading: dataLoading
  }] = useLazyQuery(GET_PODCAST_EPISODES, {
    variables: {
      first: 5,
      where: {
        ...(podcast !== 'ALL' && { podcast: { id: podcast } }),
          OR: [{ title_starts_with: titleCase(search) },
          { title_starts_with: search.toUpperCase() },
          { title_starts_with: search.toLowerCase() },
          { title_starts_with: search },
          { title_contains: titleCase(search) },
          { title_contains: search.toUpperCase() },
          { title_contains: search.toLowerCase() },
          { title_contains: search },
          { title_ends_with: search },
          { title_ends_with: search.toUpperCase() },
          { title_ends_with: search.toLowerCase() },
        ],
        id_not_in: id_not
      },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getPodcastEpisodes()
  }, [])

  useEffect(() => {
    if (search.length) {
      if (debounceJob) {
        debounceJob.cancel()
      }
      debounceJob = debounce(() => {
        getPodcastEpisodes()
      }, 500)
      debounceJob()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, podcast])

  const onPodcastSelect = (e) => {
    setSearch('')
    setPodcast(e)
  }

  const columns = [
    {
      title: '',
      dataIndex: 'thumbnail',
      sorter: false,
      width: '20%',
      render: (thumbnail) => (
        <img
          className="table-image"
          src={thumbnail}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'title',
      sorter: false,
      render: (title) => (<div className="table-name">
        {title}
      </div>)
    },

  ]

  const columnsOrderChange = [
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (record) => (
        <img
          className="table-image"
          src={record.thumbnail}
          alt={''}
        />
      ),
    },
    {
      title: '',
      sorter: false,
      render: (_, record, index) => (<div className="table-name">{record.title}</div>),
    },
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (_, record, index) => {
        let indexId = index + (current - 1) * defaultPageSize
        return <div>
          {(indexId !== (podcastEpisodes.length - 1)) &&
            <Icon type="down" className="p-2" onClick={() => changeOrder(indexId, podcastEpisodes, 1)} />}

          {(indexId !== 0) &&
            <Icon type="up" className="p-2" onClick={() => changeOrder(indexId, podcastEpisodes, -1)} />}
        </div>
      },
    },

  ]

  function changeOrder(indexId, podcastEpisodes, direction) {
    const item = cloneDeep(podcastEpisodes[indexId])
    const nextItem = cloneDeep(podcastEpisodes[indexId + direction])
    const itemOrder = item.carouselPodcastEpisodeOrder
    item.carouselPodcastEpisodeOrder = nextItem.carouselPodcastEpisodeOrder
    nextItem.carouselPodcastEpisodeOrder = itemOrder
    const newEpisodeList = podcastEpisodes.filter(episode => {
      if (episode.id === item.id || episode.id === nextItem.id) {
        return false
      }
      return true
    })

    if (direction === -1) {
      newEpisodeList.splice(indexId + direction, 0, item, nextItem)
    } else {
      newEpisodeList.splice(indexId, 0, nextItem, item)
    }
    setPodcastEpisodes(newEpisodeList)
    setFieldsValue({ [`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}.${podcastEpisodeModuleData.id}.podcastEpisodes`]: newEpisodeList })
  }

  function handleCancel() {
    setCurrent(1)
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    setPodcast('ALL')
    getPodcastEpisodes()
    setShowModal(true)
  }

  function handleOrderChangeModalCancel() {
    setOrderChangeModal(false)
  }

  function openOrderChangeModal() {
    setOrderChangeModal(true)
  }

  const onSelectChange = (selectedRowKeys, pageSelectedRowsData) => {
    let newSelectedRowData = uniqBy([...selectedRows, ...pageSelectedRowsData], 'id')
    setSelectedRows(newSelectedRowData)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  let filteredEpisodesData = listPodcastEpisode?.listPodcastEpisodeDetails

  function addEpisodes() {
    setIsSubmit(true)
    let newSelectedEpisode = selectedRowKeys.filter((row) => {
      for (let index = 0; index < podcastEpisodes.length; index++) {
        if (podcastEpisodes[index].id === row) {
          return false
        }
      }
      return true
    })
    let currentOrder = podcastEpisodeNextOrder
    let filterSelectedEpisodes = newSelectedEpisode.map((row) => {
      let episode = selectedRows.find((episode) => episode.id === row)
      if (!episode) {
        episode = listPodcastEpisode?.listPodcastEpisodeDetails?.find((episode) => episode.id === row)
      }
      episode.carouselPodcastEpisodeOrder = currentOrder
      currentOrder = currentOrder + 1
      return episode
    })
    setCurrent(1)
    setPodcastEpisodeNextOrder(currentOrder)
    setPodcastEpisodes([...podcastEpisodes, ...filterSelectedEpisodes])
    setFieldsValue({ [`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}.${podcastEpisodeModuleData.id}.podcastEpisodes`]: [...podcastEpisodes, ...filterSelectedEpisodes] })
    setShowModal(false)
    setIsSubmit(false)
    setSelectedRows([])
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}.${podcastEpisodeModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeletePodcastEpisodeCarousel(podcastEpisodeCarousel) {
    confirm({
      title: `Are you sure you want to remove podcast episode carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === podcastEpisodeCarousel.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: podcastEpisodeCarousel.id, type: MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== podcastEpisodeCarousel.id)
        setModuleList(newModules)
      },
    })
  }

  function deleteCarouselPodcastEpisode(id, episode) {
    confirm({
      title: `Are you sure you want to remove podcast episode`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        if (episode.carouselPodcastEpisodeId) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_PODCAST_EPISODE,
              variables: { id: id },
            })
            const deleteCarouselPodcastEpisode = get(response, 'data.deleteCarouselPodcastEpisode')
            if (deleteCarouselPodcastEpisode && deleteCarouselPodcastEpisode.message) {
              openNotification('success', deleteCarouselPodcastEpisode.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        const newEpisodes = podcastEpisodes.filter(item => item.id !== episode.id)
        setPodcastEpisodes(newEpisodes)
        setFieldsValue({ [`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}.${podcastEpisodeModuleData.id}.podcastEpisodes`]: newEpisodes })
        setSelectedRowKeys(selectedRowKeys.filter(rowKeys => rowKeys !== episode.id))
      },
    })
  }

  const loadMorePodcasts = async () => {
    try {
      setLoadingMorePodcasts(true)
      let podcastLength = get(podcastData, 'listPodcastDetails').length
      let lastId = get(podcastData, `listPodcastDetails.${podcastLength - 1}.id`)
      let variables = {
        first: 20,
        after: lastId,
      }
      await fetchMorePodcasts({
        variables: variables,
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { listPodcastDetails: newPodcasts } = fetchMoreResult
          if (newPodcasts.length < 20) {
            setPodcastDataEndReached(true)
          }
          if (!newPodcasts) return prevResult;
          return { ...prevResult, listPodcastDetails: [...prevResult.listPodcastDetails, ...newPodcasts] }
        },
      })

    } catch (e) {
      Sentry.captureException(e)
      let errorMessage = get(e, 'graphQLErrors.0.message', 'Something Went Wrong')
      handleRequestFail(errorMessage)
    } finally {
      setLoadingMorePodcasts(false)
    }
  }

  const podcastDropDownScroll = async (event) => {

    let target = event.target
    let scrollHeight = target.scrollHeight
    if (!podcastDataEndReached && target.scrollTop + target.offsetHeight >= target.scrollHeight - 5) {
      await loadMorePodcasts()
      target.scrollTo(0, scrollHeight - 40)
    }
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrent(1)
      setLastPage(false)
    }
  }

  useEffect(() => {
    async function handlePageChange() {
      if (!dataLoading) {
        try {
          getPodcastEpisodes({
            variables: {
              skip: (current - 1) * 5,
              first: 5
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { listPodcastEpisodeDetails } = fetchMoreResult
                if (listPodcastEpisodeDetails?.length < 5) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                return listPodcastEpisodeDetails?.length ? { listPodcastEpisodeDetails: [...listPodcastEpisodeDetails] } : prevResult
              }
            }
          })
        } catch (error) {
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  useEffect(() => {
    if (!dataLoading && listPodcastEpisode?.listPodcastEpisodeDetails) {
      if (listPodcastEpisode?.listPodcastEpisodeDetails?.length < 5) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [dataLoading])

  return (
    <Card key={podcastEpisodeModuleData.id} title='Podcast Episode Carousel' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} canMutate={true} />}
      actions={
        [
          <div className="carousel-card-footer-wrapper">
            {
              getFieldDecorator(`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}.${podcastEpisodeModuleData.id}.isPublished`, {
                initialValue: podcastEpisodeModuleData.isPublished || false
              })(
                <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}-${indexId}-isPublished`}>
                  Published
                </Checkbox>)
            }
            <Button id={`btn-delete-podcastepisode-carousel-${podcastEpisodeModuleData.id}`}
              className="ml-2"
              icon='delete'
              onClick={() => handleDeletePodcastEpisodeCarousel(podcastEpisodeModuleData)}
              type='danger'>
              Delete
            </Button>
          </div>
        ]}
    >
      <Form.Item label='Title'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}.${podcastEpisodeModuleData.id}.title`, {
          initialValue: podcastEpisodeModuleData.title ? podcastEpisodeModuleData.title : '',
          rules: [{ required: true, message: 'Please input your title!' }]
        })(
          <Input placeholder='Title' className={`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}-${indexId}-title`} />,
        )}
      </Form.Item>
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}.${podcastEpisodeModuleData.id}.order`, {
          initialValue: podcastEpisodeModuleData.order || 1
        })(
          <Input placeholder='Order' className={`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}-${podcastEpisodeModuleData.id}-order`} />,
        )}
      </Form.Item>
      <Form.Item label='Episodes' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL}.${podcastEpisodeModuleData.id}.podcastEpisodes`, {
          initialValue: podcastEpisodeModuleData.podcastEpisodes || [],
          rules: [{ type: 'array', required: true, min: 1, message: 'Please add atleast one episode!' }]
        })(
          <Row gutter={24} type='flex'>
            {podcastEpisodes.length > 0 && podcastEpisodes.map((episode, index) => (
              <PodcastEpisodeListCard
                key={episode.id}
                id={episode.carouselPodcastEpisodeId ? episode.carouselPodcastEpisodeId : episode.id}
                name={episode.title}
                image={episode.thumbnail}
                index={index}
                indexId={indexId}
                episode={episode}
                deleteCarouselPodcastEpisode={deleteCarouselPodcastEpisode}
              />
            ))}
          </Row>)}
      </Form.Item>
      <Row>
        <Button icon='plus' id={`btn-add-podcastepisode-${indexId}`} onClick={openModal}>
          Add Episode
        </Button>
        {podcastEpisodes.length > 1 && (<Button className="ml-2" icon='edit' id={`btn-edit-podcastepisode-${indexId}`}
          onClick={openOrderChangeModal}>
          Change Order
        </Button>)
        }
        <Modal
          title='Add Episodes'
          visible={showModal}
          maskClosable={false}
          onOk={addEpisodes}
          onCancel={handleCancel}
          footer={[
            <Button id={`btn-podcastepisode-carousel-cancel-${indexId}`} key='back' onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              id={`btn-podcastepisode-carousel-save-${indexId}`}
              key='submit'
              type='primary'
              loading={isSubmit}
              onClick={addEpisodes}
            >
              Add
            </Button>,
          ]}
        >
          <Row>
            <Col>
              <div className='d-flex justify-content-between'>
                <div>
                  <p>Search</p>
                  <Input.Search placeholder='Search Episode' onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-podcastepisode-carousel-${indexId}`} />
                </div>
                <div>
                  <p>podcast</p>
                  <Select
                    style={{ width: '250px' }}
                    loading={podcastsLoading || loadingMorePodcasts}
                    defaultValue={'ALL'}
                    placeholder='Select Podcasts'
                    onChange={(e) => onPodcastSelect(e)}
                    value={podcast}
                    onPopupScroll={podcastDropDownScroll}
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    <Option key={'ALL'} value={'ALL'}>
                      All Podcasts
                    </Option>
                    {podcastData &&
                      podcastData.listPodcastDetails?.map(({ name, id }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Table
              loading={dataLoading}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              dataSource={filteredEpisodesData}
              showHeader={false}
              pagination={false}
              onChange={handleChange}
            />
            <div className="page-change-btn-wrapper">
              <Button icon="left" onClick={() => setCurrent(current - 1)} disabled={current === 1} />
              <Button icon="right" onClick={() => setCurrent(current + 1)} disabled={lastPage} />
            </div>
          </Row>
        </Modal>
      </Row>
      <Modal
        title='Change Order'
        visible={orderChangeModal}
        maskClosable={false}
        onCancel={handleOrderChangeModalCancel}
        footer={null}
      >
        <Row>
          <Table
            columns={columnsOrderChange}
            rowKey={(record) => record.id}
            dataSource={podcastEpisodes}
            showHeader={false}
            pagination={{
              onChange(currentPage) { setCurrent(currentPage) },
              defaultPageSize
            }}
          />
        </Row>
      </Modal>
    </Card >
  )
}

export default PodcastEpisodeCarousel
