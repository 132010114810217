import React, { Component } from 'react'
import SeasonCard from './SeasonCard'
export default class SeasonWrapper extends Component {
  constructor(props) {
    super(props)
    this.container = document.querySelector('.content-wrapper')
  }
  componentDidMount() {
    this.container.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.handleOnScroll)
  }

  handleOnScroll = () => {
    if (
      this.container.scrollTop + this.container.offsetHeight ===
      this.container.scrollHeight
    ) {
      this.props.onLoadMore()
    }
  }

  render() {
    const { seasons, updateEpisodeList } = this.props
    return (
      seasons &&
      seasons.map((season, id) => (
        <SeasonCard
          updateEpisodeList={updateEpisodeList}
          {...season}
          key={id}
        />
      ))
    )
  }
}
