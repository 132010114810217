import axios from 'axios'
import { has } from 'lodash'
import { cookies } from '../../apollo'
const CHAT_REST_BASE_URL = process.env.REACT_APP_CHAT_REST_BASE_URL

const apiInstance = axios.create({
  baseURL: CHAT_REST_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept, Authorization',
  },
})

apiInstance.interceptors.request.use(
  config => config,
  error => {
    Promise.reject(error)
  },
)

apiInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error)
  },
)

export async function getDownloadDiscussionData(discussionId) {
  const authorizationToken = cookies.get("accessToken")  
  try {
    if (authorizationToken) {
      const response = await apiInstance({
        method: 'GET',
        url: `/discussions/download?discussion_id=${discussionId}`,
        headers: {
          'Authorization': `Bearer ${authorizationToken}`,
          'Content-Type': 'text/csv'
        },
      })
      if (response && response.status === 200) {
        if (response.headers && has(response.headers, 'x-message')) {
          return Promise.resolve({ hasData: false, data: null, message: response.headers['x-message'] })
        } else if (response.data) {
          return Promise.resolve({ hasData: true, data: response.data })
        } else {
          return Promise.resolve(null)
        }
      } else {
        return Promise.resolve(null)
      }
    } else {
      return Promise.reject({ 'key': 'INVALID_TOKEN', 'message': 'Invalid token. Please try again after some times' })
    }
  } catch (err) {
    throw new Error(err)
  }
}

export default { getDownloadDiscussionData }
