import React from 'react'
import { Col, Button, Typography, Icon, List, Menu, Dropdown } from 'antd'

const { Title } = Typography


export default function SegmentList(props) {
  const { segmentsParts, selectedSegment, setSegmentModal, setIsNewSegment, setSegment, showSegmentModalWithEdit, showSegmentConfirm, videoLoading, audioLoading } = props

  return (
    <Col sm={24} md={24} lg={8} xl={8} className="segment-lists-wrapper">
      <div className="box">
        <div className="title-wrapper border-bottom">
          <Title level={4}>Episode Segments</Title>
          {
            // (episodeStatus === 'DRAFT' || episodeStatus === 'LIVE_FINISHED' || episodeStatus === 'UNPUBLISHED') &&
            <Button id='btn-new-segment-modal' className={`add-segment-button ${(videoLoading || audioLoading) ? 'uploading' : ''}`} shape="circle" icon="plus" size="default" onClick={() => {
              setSegmentModal(true)
              setIsNewSegment(true)
            }} />
          }
        </div>
        <div>
          <List
            className="parts-lists"
            itemLayout="horizontal"
            locale={{ emptyText: 'No Segments' }}
            dataSource={segmentsParts}
            renderItem={(segmentsPart, index) => (
              <List.Item
                className={`${selectedSegment && segmentsPart.id === selectedSegment.id && 'active'} ${(videoLoading || audioLoading) && 'uploading'}`}
                onClick={(e) => {
                  e.preventDefault()
                  setSegment(segmentsPart)
                }}
                extra={<>
                  {segmentsPart.video && <Icon theme="filled" type={'play-circle'} />}
                  {segmentsPart.audio && <Icon theme="filled" style={{ paddingLeft: 8 }} type={'sound'} />}
                  {
                    // (episodeStatus === 'DRAFT' || episodeStatus === 'LIVE_FINISHED' || episodeStatus === 'UNPUBLISHED') &&
                    <Dropdown placement="bottomRight" overlay={(
                      <Menu className="text-center">
                        <Menu.Item key="0" onClick={() => showSegmentModalWithEdit(segmentsPart, index)}>
                          EDIT
                        </Menu.Item>
                        {
                          segmentsParts.length !== 1 &&
                          <Menu.Item key="1" onClick={() => showSegmentConfirm(segmentsPart.id)}>
                            DELETE
                        </Menu.Item>
                        }
                      </Menu>
                    )}>
                      <Icon style={{ paddingLeft: 8 }} className="segment-more-button" type={'more'} />
                    </Dropdown>
                  }
                </>}
              >
                <List.Item.Meta
                  title={<span>{segmentsPart.title}</span>}
                  description={segmentsPart.duration}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Col>
  )
}
