import gql from 'graphql-tag'

const CREATE_EPISODE = gql`
  mutation createEpisode(
    $title: String!
    $description: String!
    $episodeNumber: String
    $image: String
    $scheduleAt: DateTime
    $createdAt: DateTime
    $isLive: Boolean
    $isFeatured: Boolean
    $featuredBannerImage: String
    $featureBannerWeight: Int
    $showId: ID!
    $createdBy: ID!
    $videoAccess: [String!]!
    $audioAccess: [String!]!
    $liveChatAccess: [String!]!
    $accessLevelScheduleAt: DateTime
    $scheduleAudioAccess: [String!]
    $scheduleVideoAccess: [String!]
    $seasonId: ID!
    $publishDate: DateTime
    $allowedContinents: [String!]
    $allowedCountries: Json
    $rating: String
    $simulateLive: Boolean,
  ) {
    createEpisode(
      data: {
        title: $title
        description: $description
        episodeNumber: $episodeNumber
        image: $image
        isLive: $isLive
        isFeatured: $isFeatured
        featuredBannerImage: $featuredBannerImage
        featureBannerWeight: $featureBannerWeight
        scheduleAt: $scheduleAt
        createdAt: $createdAt
        show: { connect: { id: $showId } }
        createdBy: { connect: { id: $createdBy } }
        segments: {
          create: {
            title: "Hour 1"
            order: 0
            videoAccess: { set: $videoAccess }
            audioAccess: { set: $audioAccess }
            liveChatAccess: { set: $liveChatAccess }
            accessLevelScheduleAt: $accessLevelScheduleAt
            scheduleAudioAccess: { set: $scheduleAudioAccess }
            scheduleVideoAccess: { set: $scheduleVideoAccess }
            simulateLive: $simulateLive
          }
        }
        seasonEpisode: {
          create: {
            season: { connect: { id: $seasonId } }     
            publishDate: $publishDate       
            weight: 0
          }
        }
        allowedContinents: { set: $allowedContinents }
        allowedCountries: $allowedCountries     
        rating: $rating
      }
    ) {
      id
      title
      description
      image
      featuredBannerImage
    }
  }
`

const UPDATE_EPISODE = gql`
  mutation updateEpisode(
    $data: EpisodeUpdateInput!
    $where: EpisodeWhereUniqueInput!
  ) {
    updateEpisode(data: $data, where: $where) {
      id
      image
      featuredBannerImage
    }
  }
`

const UPDATE_EPISODE_STATUS = gql`
  mutation updateEpisodeStatus(
    $status: EpisodeStatus
    $episodeId: ID!
    $showId: ID!
  ) {
    updateEpisode(
      data: { status: $status, show: { connect: { id: $showId } } }
      where: { id: $episodeId }
    ) {
      id
    }
  }
`

const DELETE_EPISODE = gql`
  mutation deleteEpisode($episodeId: ID!) {
    deleteEpisode(where: { id: $episodeId }) {
      id
    }
  }
`

const CREATE_EPISODE_SEGMENT = gql`
  mutation createEpisodeSegment(
    $title: String!
    $description: String
    $order: Int!
    $audioAccess: [String!]
    $videoAccess: [String!]
    $accessLevelScheduleAt: DateTime
    $scheduleAudioAccess: [String!]
    $scheduleVideoAccess: [String!]
    $imageFileName: String
    $episodeId: ID!
  ) {
    createEpisodeSegment(
      data: {
        title: $title
        description: $description
        image: $imageFileName
        order: $order
        audioAccess: { set: $audioAccess }
        videoAccess: { set: $videoAccess }
        accessLevelScheduleAt: $accessLevelScheduleAt
        scheduleAudioAccess: { set: $scheduleAudioAccess }
        scheduleVideoAccess: { set: $scheduleVideoAccess }
        episode: { connect: { id: $episodeId } }
      }
    ) {
      id
      title
      description
      image
      order
    }
  }
`

const UPDATE_SEGMENT = gql`
  mutation updateEpisodeSegment(
    $title: String
    $description: String
    $audioAccess: [String!]
    $videoAccess: [String!]
    $liveChatAccess: [String!]
    $accessLevelScheduleAt: DateTime
    $scheduleAudioAccess: [String!]
    $scheduleVideoAccess: [String!]
    $image: String
    $audio: String
    $segmentId: ID!
    $duration: Float
  ) {
    updateEpisodeSegment(
      data: {
        title: $title
        description: $description
        audioAccess: { set: $audioAccess }
        videoAccess: { set: $videoAccess }
        liveChatAccess: { set: $liveChatAccess }
        accessLevelScheduleAt: $accessLevelScheduleAt
        scheduleAudioAccess: { set: $scheduleAudioAccess }
        scheduleVideoAccess: { set: $scheduleVideoAccess }
        image: $image
        audio: $audio
        duration: $duration
      }
      where: { id: $segmentId }
    ) {
      id
    }
  }
`

const UPDATE_SEGMENT_VIDEO_STATE = gql`
  mutation updateEpisodeSegment($videoState: String!, $segmentId: ID!) {
    updateEpisodeSegment(
      data: { videoState: $videoState }
      where: { id: $segmentId }
    ) {
      id
      videoState
    }
  }
`

const DELETE_SEGMENT = gql`
  mutation deleteEpisodeSegment($segmentId: ID!) {
    deleteEpisodeSegment(where: { id: $segmentId }) {
      id
    }
  }
`

const DELETE_SEGMENT_VIDEO = gql`
  mutation updateEpisodeSegment($segmentId: ID!) {
    updateEpisodeSegment(
      data: { video: "", duration: null, videoState: "IDLE" }
      where: { id: $segmentId }
    ) {
      id
    }
  }
`
const DELETE_SEGMENT_AUDIO = gql`
  mutation updateEpisodeSegment($segmentId: ID!, $data: EpisodeSegmentUpdateInput!) {
    updateEpisodeSegment(data: $data, where: { id: $segmentId }) {
      id
    }
  }
`

export const REQUEST_MASTER_ACCESS = gql`
  mutation requestMasterAccess($assetId: String!) {
    requestMasterAccess(where: { assetId: $assetId })
  }
`

export const CREATE_CAPTION_CUSTOM = gql`
  mutation createCaptionCustom($data: [CreateCaptionInput!]!) {
    createCaptionCustom(data: $data){
      message
    }
  }
`
export const DELETE_CAPTION_CUSTOM = gql`
  mutation deleteCaptionCustom($where: DeleteCaptionCustomInput) {
    deleteCaptionCustom(where: $where){
      message
    }
  }
`

export {
  CREATE_EPISODE,
  UPDATE_EPISODE,
  UPDATE_EPISODE_STATUS,
  DELETE_EPISODE,
  CREATE_EPISODE_SEGMENT,
  UPDATE_SEGMENT,
  UPDATE_SEGMENT_VIDEO_STATE,
  DELETE_SEGMENT,
  DELETE_SEGMENT_VIDEO,
  DELETE_SEGMENT_AUDIO,
}
