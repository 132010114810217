import React, { Component } from 'react'
import { Col, Card, Avatar } from 'antd'

const { Meta } = Card
const count = [true, true, true, true, true]
export default class CardLoader extends Component {
  render() {
    return (
      count.map((index, key) => (
        index &&
        <Col xs={24} sm={24} md={8} lg={6} xl={6} className="text-center show-card" key={key}>
          <Card className="show-placeholder show-card-wrapper" loading={true} style={{ width: 300, margin: '0 auto' }}>
            <Meta
              avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
              title="Card title"
              description="This is the description"
            />
          </Card>
        </Col>
      ))
    )
  }
}
