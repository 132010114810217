import gql from 'graphql-tag'

const GET_SHOWS = gql`
  query shows($first: Int!, $after: String) {
    shows(orderBy: weight_DESC, first: $first, after: $after) {
      id
      name
      weight
      image
      author {
        id
      }
      createdAt
      updatedAt
    }
  }
`

const GET_SHOW_WITH_EPISODES = gql`
  query show($id: ID!) {
    show(where: { id: $id }) {
      id
      name
      weight
      image
      backgroundImage
      portraitImage
      hostImage
      logoImage
      showDetailImage
      episodeDetailImage
      description
      author {
        id
        firstName
        lastName
      }
    }
  }
`

const GET_SHOW_SIGNED_PUT_URL = gql`
  query getShowSignedPutUrl($fileName: String!, $contentType: String!) {
    getShowSignedPutUrl(
      data: { fileName: $fileName, contentType: $contentType }
    ) {
      signedUrl
      getUrl
    }
  }
`

const GET_AUTHORS = gql`
  query persons($first: Int, $after: String, $where: PersonWhereInput) {
    persons(
      first: $first
      after: $after    
      where: $where  
    ) { 
      id
      email
      firstName
      lastName
    }
  }
`

const GET_APP_AUTHORS = gql`
  query persons($first: Int, $after: String, $where: UserWhereInput) {
    users(
      first: $first
      after: $after    
      where: $where  
    ) { 
      id
      email
      firstName
      lastName
    }
  }
`

export const GET_CLIPS = gql`
  query clips($first: Int!, $after: String, $where: ClipWhereInput) {
    clips(first: $first, after: $after, where: $where) {
      id
      name
      description
      image
      status
      show {
        id
        name
      }
      video {
        id
        name
      }
      muxAssetId
      uploadId
      muxPlaybackId
      thumbnail
      videoURL
      duration
      clipAccess
      createdAt
      updatedAt
      message
    }
  }
`

export const GET_SEASONS = gql`
  query seasons($first: Int!, $after: String, $where: SeasonWhereInput) {
    seasons(first: $first, after: $after, orderBy: weight_DESC, where: $where) {
      id
      name
      slug
      description
      status
      show {
        id
        name
      }
      image
      weight
      orderBy
      createdAt
    }
  }
`

export {
  GET_SHOWS,
  GET_SHOW_WITH_EPISODES,
  GET_SHOW_SIGNED_PUT_URL,
  GET_AUTHORS,
  GET_APP_AUTHORS
}
