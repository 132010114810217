import gql from "graphql-tag"

export const UPDATE_PERSON = gql`
mutation updatePerson($firstName: String, $lastName: String, $recurlyId: String, $recurlyPlan: Plans, $promoPlan: Plans, $promoPlanExpiration: DateTime, $isActive: Boolean, $personId: ID!){
  updatePerson(data: { firstName: $firstName, lastName: $lastName, recurlyId: $recurlyId, recurlyPlan: $recurlyPlan, promoPlan: $promoPlan, promoPlanExpiration: $promoPlanExpiration, isActive: $isActive }, where: { id: $personId }) {
    id
  }
}
`

export const DELETE_PERSON = gql`
mutation deletePerson($personId: ID!){
  deletePerson(where: { id: $personId }) {
    id
  }
}
`


export const RESET_PASSWORD = gql`
mutation resetPassword($authId: ID!) {
  resetPassword(where:{auth0_id:$authId}){
    message
  }
}
`