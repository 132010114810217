import React, { useState, useContext, useRef } from 'react'
import { Col, Button, notification } from 'antd'
import * as Sentry from '@sentry/browser'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import { GET_SHOW_SIGNED_PUT_URL } from '../graphql/Queries'
import { CREATE_SHOW, UPDATE_SHOW_IMAGE } from '../graphql/Mutations'
import AddShowModal from './AddShowModal'
import { AppContext } from '../../../AppContext'

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleShowModal(b) {
    setShowModal(b)
  }

  function handleRequestFail(errorMessage) {
    setSubmit(false)
    openNotification('error', errorMessage || 'Something Went Wrong')
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const queryVariables = values      
      const {
        image,
        backgroundImage,
        hostImage,
        logoImage,
        portraitImage,
        showDetailImage,
        episodeDetailImage,
      } = values
      try {
        let imageFileName = ''
        let backgroundImageName = ''
        let hostImageName = ''
        let logoImageName = ''
        let portraitImageName = ''
        let showDetailImageName = ''
        let episodeDetailImageName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        if (portraitImage && portraitImage.length > 0) {
          portraitImageName = portraitImage[0].name
        }
        if (backgroundImage && backgroundImage.length > 0) {
          backgroundImageName = backgroundImage[0].name
        }
        if (hostImage && hostImage.length > 0) {
          hostImageName = hostImage[0].name
        }
        if (logoImage && logoImage.length > 0) {
          logoImageName = logoImage[0].name
        }
        if (showDetailImage && showDetailImage.length > 0) {
          showDetailImageName = showDetailImage[0].name
        }
        if (episodeDetailImage && episodeDetailImage.length > 0) {
          episodeDetailImageName = episodeDetailImage[0].name
        }
        if (queryVariables.image) {
          delete queryVariables.image
        }
        if (queryVariables.portraitImage) {
          delete queryVariables.portraitImage
        }
        if (queryVariables.backgroundImage) {
          delete queryVariables.backgroundImage
        }
        if (queryVariables.hostImage) {
          delete queryVariables.hostImage
        }
        if (queryVariables.logoImage) {
          delete queryVariables.logoImage
        }
        if (queryVariables.showDetailImage) {
          delete queryVariables.showDetailImage
        }
        if (queryVariables.episodeDetailImage) {
          delete queryVariables.episodeDetailImage
        }
        queryVariables.image = imageFileName
        // queryVariables.portraitImage = portraitImageName;
        // queryVariables.backgroundImage = backgroundImageName
        // queryVariables.logoImage = logoImageName
        queryVariables.createdBy = state.currentUser.id
        if (!queryVariables.author) {
          queryVariables.author = state.currentUser.id
        }
        if (queryVariables.weight) {
          queryVariables.weight = Number(queryVariables.weight)
        } else {
          queryVariables.weight = 100
        }
        const createShowResult = await client.mutate({
          mutation: CREATE_SHOW,
          variables: queryVariables,
        })
        if (image && image.length > 0) {
          const tempFilenameArray = image[0].name.split('.')
          const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
          const temp = createShowResult.data.createShow.image.split('/')
          const tempFilename = temp[temp.length - 1].split('.')[0]
          const fileName = `${tempFilename}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
        }
        if (portraitImage && portraitImage.length > 0) {
          const tempBgImgFilenameArray = portraitImage[0].name.split('.')
          const bgFileExt =
            tempBgImgFilenameArray[tempBgImgFilenameArray.length - 1]
          const bgFileName = `${
            createShowResult.data.createShow.id
          }-portraitImage-${new Date().getTime()}.${bgFileExt}`
          const bgContentType = portraitImage[0].type
          const getBISignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: bgFileName, contentType: bgContentType },
          })
          portraitImageName = bgFileName
          await fileUpload(
            getBISignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            portraitImage[0].originFileObj,
          )
        }
        if (backgroundImage && backgroundImage.length > 0) {
          const tempBgImgFilenameArray = backgroundImage[0].name.split('.')
          const bgFileExt =
            tempBgImgFilenameArray[tempBgImgFilenameArray.length - 1]
          const bgFileName = `${
            createShowResult.data.createShow.id
          }-backgroundImage-${new Date().getTime()}.${bgFileExt}`
          const bgContentType = backgroundImage[0].type
          const getBISignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: bgFileName, contentType: bgContentType },
          })
          backgroundImageName = bgFileName
          await fileUpload(
            getBISignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            backgroundImage[0].originFileObj,
          )
        }
        if (hostImage && hostImage.length > 0) {
          const tempHostImgFilenameArray = hostImage[0].name.split('.')
          const hostFileExt =
            tempHostImgFilenameArray[tempHostImgFilenameArray.length - 1]
          const hostFileName = `${
            createShowResult.data.createShow.id
          }-hostImage-${new Date().getTime()}.${hostFileExt}`
          const hostContentType = hostImage[0].type
          const getHostSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: hostFileName, contentType: hostContentType },
          })
          hostImageName = hostFileName
          await fileUpload(
            getHostSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            hostImage[0].originFileObj,
          )
        }
        if (logoImage && logoImage.length > 0) {
          const tempLogoFilenameArray = logoImage[0].name.split('.')
          const logoFileExt =
            tempLogoFilenameArray[tempLogoFilenameArray.length - 1]
          const logoFileName = `${
            createShowResult.data.createShow.id
          }-logoImage-${new Date().getTime()}.${logoFileExt}`
          const logoContentType = logoImage[0].type
          const getLogoSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: logoFileName, contentType: logoContentType },
          })
          await fileUpload(
            getLogoSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            logoImage[0].originFileObj,
          )
          logoImageName = logoFileName
        }
        if (showDetailImage && showDetailImage.length > 0) {
          const tempShowDetailFilenameArray = showDetailImage[0].name.split('.')
          const showDetailFileExt =
            tempShowDetailFilenameArray[tempShowDetailFilenameArray.length - 1]
          const showDetailFileName = `${
            createShowResult.data.createShow.id
          }-showDetailImage-${new Date().getTime()}.${showDetailFileExt}`
          const showDetailContentType = showDetailImage[0].type
          const getShowDetailSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: showDetailFileName, contentType: showDetailContentType },
          })
          await fileUpload(
            getShowDetailSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            showDetailImage[0].originFileObj,
          )
          showDetailImageName = showDetailFileName
        }
        if (episodeDetailImage && episodeDetailImage.length > 0) {
          const tempEpisodeDetailFilenameArray = episodeDetailImage[0].name.split('.')
          const episodeDetailFileExt =
            tempEpisodeDetailFilenameArray[tempEpisodeDetailFilenameArray.length - 1]
          const episodeDetailFileName = `${
            createShowResult.data.createShow.id
          }-episodeDetailImage-${new Date().getTime()}.${episodeDetailFileExt}`
          const episodeDetailContentType = episodeDetailImage[0].type
          const getEpisodeDetailSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: episodeDetailFileName, contentType: episodeDetailContentType },
          })
          await fileUpload(
            getEpisodeDetailSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            episodeDetailImage[0].originFileObj,
          )
          episodeDetailImageName = episodeDetailFileName
        }
        if (
          (logoImage && logoImage.length > 0) ||
          (backgroundImage && backgroundImage.length > 0) ||
          (hostImage && hostImage.length > 0) ||
          (portraitImage && portraitImage.length > 0) ||
          (showDetailImage && showDetailImage.length > 0) ||
          (episodeDetailImage && episodeDetailImage.length > 0)
        ) {
          await client.mutate({
            mutation: UPDATE_SHOW_IMAGE,
            variables: {
              logoImage: logoImageName,
              backgroundImage: backgroundImageName,
              hostImage: hostImageName,
              portraitImage: portraitImageName,
              showDetailImage: showDetailImageName,
              episodeDetailImage: episodeDetailImageName,
              showId: createShowResult.data.createShow.id,
            },
          })
        }
        setShowModal(false)
        setSubmit(false)
        form.resetFields()
        props.history.push(`/shows/${createShowResult.data.createShow.id}`)
      } catch (e) {
        Sentry.captureException(e)
        let errorMessage =
          e &&
          e.graphQLErrors &&
          e.graphQLErrors[0] &&
          e.graphQLErrors[0].message &&
          e.graphQLErrors[0].message === 'Same name already exist'
            ? e.graphQLErrors[0].message
            : 'Something Went Wrong'
        handleRequestFail(errorMessage)
      }
    })
  }
  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        className="add-show-card-wrapper"
        onClick={() => handleShowModal(true)}
      >
        <div className="add-show-wrapper">
          <Button
            id='btn-show-create'
            type="dashed"
            size="large"
            className="add-show-button"
            onClick={() => handleShowModal(true)}
          >
            + Add Show
          </Button>
        </div>
      </Col>
      <AddShowModal
        saveFormRef={saveFormRef}
        showModal={showModal}
        isSubmit={isSubmit}
        handleCancel={() => {
          saveFormRef.current.props.form.resetFields()
          handleShowModal(false)
        }}
        handleCreate={handleCreate}
      />
    </>
  )
}
