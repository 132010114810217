import React from 'react'
import { Button } from 'antd'
import { useAuth0 } from './Auth0'
import './auth.css'
import logo from '../../assets/logo.png'

export default function () {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="login-wrapper">
      <div className="login-form-wrapper">
        <div className="header">
          <img src={logo} alt="DAILYWIRE" title="DAILYWIRE" />
        </div>
        <hr />
        <div className="body">
          <Button id="btn-signin" type="primary" size="large" 
            onClick={() => loginWithRedirect({})}>SIGN IN</Button>
        </div>
      </div>
    </div>
  )
}

