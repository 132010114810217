import React, { Component } from 'react'
import { Form, Input, Checkbox, message, DatePicker } from 'antd'
import moment from 'moment-timezone'
import { ACCESS_LEVELS } from '../../../common/constants'

const defaultPlainOptions = ['INSIDER', 'INSIDER_PLUS', 'ALL_ACCESS']

function beforeImageUpload(file) {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg'
  ) {
    return true
  }
  message.error('You can only upload image file!')
  return false
}

export default class AddSegmentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      removeFile: false,
      indeterminate: true,
    }
  }

  normFile = (e) => {
    if (this.state.removeFile) {
      this.setState({ removeFile: false })
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }
  handleRemove = (e) => {
    this.setState({ removeFile: true })
  }
  render() {
    const { getFieldDecorator, segment, order, isNewSegment, currentEpisode, showLiveChatAccssField,
      handleAccessLevelChange, isAllowScheduleAccessLevel } = this.props
    const { discussionId } = currentEpisode
    const disabledPreviousDates = (startValue) => {
      return startValue.valueOf() < moment().valueOf()
    }
    return (
      <Form layout='vertical' className='add-episode-form'>
        <Form.Item label='Title'>
          {getFieldDecorator('title', {
            rules: [
              { required: true, message: 'Please input the title of segment!' },
            ],
            initialValue: !isNewSegment ? segment.title : `Hour ${order + 1}`,
          })(<Input />)}
        </Form.Item>
        <Form.Item label='Select Plans For Audio?'>
          {getFieldDecorator('audioAccess', {
            initialValue: this.props.isNewSegment
              ? defaultPlainOptions
              : segment.audioAccess,
          })(
            <Checkbox.Group>
              {ACCESS_LEVELS.map((level) => {
                return <Checkbox value={level.key}>{level.value}</Checkbox>
              })}
            </Checkbox.Group>,
          )}
        </Form.Item>
        <Form.Item label='Select Plans For Video?'>
          {getFieldDecorator('videoAccess', {
            initialValue: this.props.isNewSegment
              ? defaultPlainOptions
              : segment.videoAccess,
          })(
            <Checkbox.Group>
              {ACCESS_LEVELS.map((level) => {
                return <Checkbox value={level.key}>{level.value}</Checkbox>
              })}
            </Checkbox.Group>,
          )}
        </Form.Item>
        {discussionId && showLiveChatAccssField && !isNewSegment && (
          <Form.Item label='Select Plans For Chat?'>
            {getFieldDecorator('liveChatAccess', {
              initialValue: this.props.isNewSegment
                ? defaultPlainOptions
                : segment.liveChatAccess,
            })(
              <Checkbox.Group>
                {ACCESS_LEVELS.map((level) => {
                  return <Checkbox value={level.key}>{level.value}</Checkbox>
                })}
              </Checkbox.Group>,
            )}
          </Form.Item>
        )} 
        <Form.Item label='Schedule Access Level'>
          <Checkbox checked={isAllowScheduleAccessLevel} onChange={handleAccessLevelChange} />
        </Form.Item>
        {isAllowScheduleAccessLevel && <Form.Item label='Schedule Access At'>
          {getFieldDecorator('accessLevelScheduleAt', {
            rules: [
              { required: isAllowScheduleAccessLevel, message: 'Please enter the schedule access date time!' },
            ],
            initialValue: segment.accessLevelScheduleAt ? moment(segment.accessLevelScheduleAt) : null,
          })(
            <DatePicker
              style={{ minWidth: 220 }}
              placeholder='Select Date/Time'
              format='MM-DD-YYYY hh:mm A'
              showTime={{ use12Hours: true, format: 'hh:mm A' }}
              ranges={moment()}
              disabledDate={disabledPreviousDates}
            />,
          )}
        </Form.Item>}
        {isAllowScheduleAccessLevel && <Form.Item label='Select Plans For Schedule Audio?'>
          {getFieldDecorator('scheduleAudioAccess', {
            initialValue: this.props.isNewSegment
              ? defaultPlainOptions
              : segment.scheduleAudioAccess,
          })(
            <Checkbox.Group>
              {ACCESS_LEVELS.map((level) => {
                return <Checkbox value={level.key}>{level.value}</Checkbox>
              })}
            </Checkbox.Group>,
          )}
        </Form.Item>}
        {isAllowScheduleAccessLevel && <Form.Item label='Select Plans For Schedule Video?'>
          {getFieldDecorator('scheduleVideoAccess', {
            initialValue: this.props.isNewSegment
              ? defaultPlainOptions
              : segment.scheduleVideoAccess,
          })(
            <Checkbox.Group>
              {ACCESS_LEVELS.map((level) => {
                return <Checkbox value={level.key}>{level.value}</Checkbox>
              })}
            </Checkbox.Group>,
          )}
        </Form.Item>}
        <Form.Item label='order' style={{ display: 'none' }}>
          {getFieldDecorator('order', {
            initialValue: order,
          })(<Input />)}
        </Form.Item>
      </Form>
    )
  }
}
