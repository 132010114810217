import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  PageHeader,
  Spin,
  Checkbox,
  Modal,
  notification
} from 'antd'
import { get, isEmpty } from 'lodash'
import urlSlug from 'url-slug'
import * as Sentry from '@sentry/browser'
import client from '../../../apollo'
import { openNotification } from '../../../common/utility'
import { CREATE_MODULAR_PAGE, DELETE_CUSTOM_MODULAR_PAGE, UPDATE_MODULAR_PAGE } from '../graphql/Mutations'
import { GET_MODULAR_PAGE } from '../graphql/Queries'
import ModuleTypesWrapper from './ModuleTypesWrapper'
import {
  getModifiedClipCarouselData, getModifiedEpisodeCarouselData, getModifiedVideoCarouselData,
  getModifiedShowCarouselData, getModifiedProgrammaticCarouselData, getModifiedFreeFormModuleData,
  getModifiedShowPremierModuleData, getModifiedEpisodePremierModuleData, getModifiedVideoPremierModuleData, 
  getModifiedFreeFormHeadLinerModuleData, getModifiedPodcastCarouselData, getModifiedPodcastPremierModuleData,
  getModifiedPodcastEpisodeCarouselData, getModifiedPodcastEpisodePremierModuleData
} from './OperationHelper'
import '../modular-page.css'

const confirm = Modal.confirm

export function ModularPageWrapper(props) {
  const [formDataLoad, setFormDataLoad] = useState(false)
  const [modulerEditableData, setModulerEditableData] = useState('')
  const [isSubmit, setSubmit] = useState(false)
  const [modularPageId, setMopdularPageId] = useState()
  const [isModularPublish, setIsModularPublish] = useState(false)

  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form
  const { title, slug, isPublished } = modulerEditableData


  useEffect(() => {
    const { match: { params } } = props
    if (params && params.modularPageId) {
      setMopdularPageId(params.modularPageId)
      getModularCustomPage(params.modularPageId)
    }
  }, [])

  function handlePublishChange(e) {
    setIsModularPublish(e.target.checked)
    setFieldsValue({ isPublished: e.target.checked })
  }

  function handleRequestFail(e) {
    setSubmit(false)
    setFormDataLoad(false)
    if (e && e.message) {
      const message =
        (e && e.graphQLErrors && Array.isArray(e.graphQLErrors) && e.graphQLErrors[0] && e.graphQLErrors[0].message) || e.message
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function getModularCustomPage(modularPageId) {
    setFormDataLoad(true)
    client.query({
      query: GET_MODULAR_PAGE,
      fetchPolicy: 'no-cache',
      variables: { where: { id: modularPageId } }
    }).then(res => {
      setFormDataLoad(false)
      const getModularPageCustomData = get(res, 'data.getModularPage')
      if (getModularPageCustomData && getModularPageCustomData.id) {
        setModulerEditableData(getModularPageCustomData)
        setIsModularPublish(getModularPageCustomData.isPublished)
      } else {
        setModulerEditableData('')
      }
    }).catch((error) => {
      Sentry.captureException(error)
      handleRequestFail(error)
    }).finally(() => setFormDataLoad(false))
  }

  function handleCreate() {
    const { history, form } = props
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      setSubmit(true)
      const formData = {
        title: values.title,
        slug: values.slug,
      }
      await client.mutate({
        mutation: CREATE_MODULAR_PAGE,
        variables: formData
      }).then(res => {
        setSubmit(false)
        const createModularPageCustomData = get(res, 'data.createModularPageCustom')
        if (createModularPageCustomData && createModularPageCustomData.id && createModularPageCustomData.message) {
          openNotification('success', createModularPageCustomData.message)
        }
        history.push('/modular-pages')
      }).catch((error) => {
        Sentry.captureException(error)
        handleRequestFail(error)
      }).finally(() => setSubmit(false))
    })
  }

  function handleUpdate() {
    const { history, form } = props
    form.validateFields(async (error, values) => {
      if (error) {
        notification['error']({
          message: 'Please fill all required field',
        })
        return
      }
      setSubmit(true)
      let formData = {
        title: values.title,
        slug: values.slug,
        isPublished: values.isPublished,
        modules: {}
      }
      if (values.CLIP_CAROUSEL) {
        formData.modules.clipCarouselModules = await getModifiedClipCarouselData(values.CLIP_CAROUSEL)
      }
      if (values.VIDEO_CAROUSEL) {
        formData.modules.videoCarouselModules = await getModifiedVideoCarouselData(values.VIDEO_CAROUSEL)
      }
      if (values.EPISODE_CAROUSEL) {
        formData.modules.episodeCarouselModules = await getModifiedEpisodeCarouselData(values.EPISODE_CAROUSEL)
      }
      if (values.SHOW_CAROUSEL) {
        formData.modules.showCarouselModules = await getModifiedShowCarouselData(values.SHOW_CAROUSEL)
      }
      if (values.CONTINUE_WATCHING) {
        formData.modules.continueWatchingModules = await getModifiedProgrammaticCarouselData(values.CONTINUE_WATCHING)
      }
      if (values.FREEFORM_HEADLINER) {
        formData.modules.freeformHeadlinerModules = await getModifiedFreeFormHeadLinerModuleData(values.FREEFORM_HEADLINER)
      }
      try {
        if (values.FREEFORM_HIGHLIGHT) {
          formData.modules.freeformHighlightModules = {}
          formData.modules.freeformHighlightModules.create = []
          formData.modules.freeformHighlightModules.update = []
          const freeFormResponse = await getModifiedFreeFormModuleData(values.FREEFORM_HIGHLIGHT, modularPageId)
          for (const freeForm of freeFormResponse) {
            if (!isEmpty(freeForm.create)) {
              formData.modules.freeformHighlightModules.create.push(freeForm.create)
            }
            if (!isEmpty(freeForm.update)) {
              formData.modules.freeformHighlightModules.update.push(freeForm.update)
            }
          }
          if (formData.modules.freeformHighlightModules.create.length === 0) {
            delete formData.modules.freeformHighlightModules['create']
          }
          if (formData.modules.freeformHighlightModules.update.length === 0) {
            delete formData.modules.freeformHighlightModules['update']
          }
        }
        if (values.VIDEO_PREMIERE || values.SHOW_PREMIERE || values.EPISODE_PREMIERE || values.PODCAST_PREMIERE || values.PODCAST_EPISODE_PREMIERE) {
          formData.modules.premiereModules = {}
          formData.modules.premiereModules.create = []
          formData.modules.premiereModules.update = []
          if (values.VIDEO_PREMIERE) {
            const videoPremiereResponse = await getModifiedVideoPremierModuleData(values.VIDEO_PREMIERE, modularPageId)
            for (const videoPremiere of videoPremiereResponse) {
              if (!isEmpty(videoPremiere.create)) {
                formData.modules.premiereModules.create.push(videoPremiere.create)
              }
              if (!isEmpty(videoPremiere.update)) {
                formData.modules.premiereModules.update.push(videoPremiere.update)
              }
            }
          }
          if (values.SHOW_PREMIERE) {
            const showPremiereResponse = await getModifiedShowPremierModuleData(values.SHOW_PREMIERE, modularPageId)
            for (const showPremiere of showPremiereResponse) {
              if (!isEmpty(showPremiere.create)) {
                formData.modules.premiereModules.create.push(showPremiere.create)
              }
              if (!isEmpty(showPremiere.update)) {
                formData.modules.premiereModules.update.push(showPremiere.update)
              }
            }
          }
          if (values.EPISODE_PREMIERE) {
            const episodePremiereResponse = await getModifiedEpisodePremierModuleData(values.EPISODE_PREMIERE, modularPageId)
            for (const episodePremiere of episodePremiereResponse) {
              if (!isEmpty(episodePremiere.create)) {
                formData.modules.premiereModules.create.push(episodePremiere.create)
              }
              if (!isEmpty(episodePremiere.update)) {
                formData.modules.premiereModules.update.push(episodePremiere.update)
              }
            }
          }
          if (values.PODCAST_PREMIERE) {
            const podcastPremiereResponse = await getModifiedPodcastPremierModuleData(values.PODCAST_PREMIERE, modularPageId)
            for (const podcastPremiere of podcastPremiereResponse) {
              if (!isEmpty(podcastPremiere.create)) {
                formData.modules.premiereModules.create.push(podcastPremiere.create)
              }
              if (!isEmpty(podcastPremiere.update)) {
                formData.modules.premiereModules.update.push(podcastPremiere.update)
              }
            }
          }
          if (values.PODCAST_EPISODE_PREMIERE) {
            const podcastEpisodePremiereResponse = await getModifiedPodcastEpisodePremierModuleData(values.PODCAST_EPISODE_PREMIERE, modularPageId)
            for (const podcastEpisodePremiere of podcastEpisodePremiereResponse) {
              if (!isEmpty(podcastEpisodePremiere.create)) {
                formData.modules.premiereModules.create.push(podcastEpisodePremiere.create)
              }
              if (!isEmpty(podcastEpisodePremiere.update)) {
                formData.modules.premiereModules.update.push(podcastEpisodePremiere.update)
              }
            }
          }
          if (formData.modules.premiereModules.create.length === 0) {
            delete formData.modules.premiereModules['create']
          }
          if (formData.modules.premiereModules.update.length === 0) {
            delete formData.modules.premiereModules['update']
          }
        }
      } catch (error) {
        Sentry.captureException(error)
        handleRequestFail(error)
        return
      }

      if (values.PODCAST_CAROUSEL) {
        formData.modules.podcastCarouselModules = await getModifiedPodcastCarouselData(values.PODCAST_CAROUSEL)
      }
      
      if (values.PODCAST_EPISODE_CAROUSEL) {
        formData.modules.podcastEpisodeCarouselModules = await getModifiedPodcastEpisodeCarouselData(values.PODCAST_EPISODE_CAROUSEL)
      }

      if (values.CONTINUE_LISTENING) {
        formData.modules.continueListeningModules = await getModifiedProgrammaticCarouselData(values.CONTINUE_LISTENING)
      }

      await client.mutate({
        mutation: UPDATE_MODULAR_PAGE,
        variables: { id: modularPageId, data: formData }
      }).then(res => {
        setSubmit(false)
        const updateModularPageCustomData = get(res, 'data.updateModularPageCustom')
        if (updateModularPageCustomData && updateModularPageCustomData.id && updateModularPageCustomData.message) {
          openNotification('success', updateModularPageCustomData.message)
        }
        form.resetFields()
        getModularCustomPage(modularPageId)
      }).catch((error) => {
        Sentry.captureException(error)
        handleRequestFail(error)
        form.resetFields()
        getModularCustomPage(modularPageId)
      }).finally(() => setSubmit(false))
    })
  }

  function onSubmitHandle() {
    if (modularPageId) {
      handleUpdate()
    } else {
      handleCreate()
    }
  }

  function handleDeleteModularPage(modularPageId) {
    const { history } = props
    confirm({
      title: 'Are you sure you want to DELETE this modular page?',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          const response = await client.mutate({
            mutation: DELETE_CUSTOM_MODULAR_PAGE,
            variables: { id: modularPageId },
          })
          const deleteModularPageCustomData = get(response, 'data.deleteModularPageCustom')
          if (deleteModularPageCustomData && deleteModularPageCustomData.message) {
            openNotification('success', deleteModularPageCustomData.message)
            setTimeout(() => {
              history.push('/modular-pages')
            }, 500)
          }
        } catch (error) {
          Sentry.captureException(error)
          handleRequestFail(error)
        }
      },
    })
  }

  function handleBlurTitle() {
    const title = getFieldValue('title')
    const slug = urlSlug(title)
    setFieldsValue({ slug: slug })
  }

  const modules = Array.isArray(get(modulerEditableData, 'modules')) && get(modulerEditableData, 'modules') || []
  return (
    <Row gutter={24} type='flex' className='modular-page-wrapper'>
      <Col span={24}>
        <Spin spinning={formDataLoad}>
          <Form id='modular-page-form'>
            <PageHeader
              onBack={() => props.history.goBack()}
              title={'Modular Page Builder'}
              className='box page-header'
              extra={[
                <span key='chk-publish-modular-page-1'>
                  {getFieldDecorator('isPublished', {
                    initialValue: isPublished || false
                  })(
                    <Checkbox key='checkbox-modular-page-0' disabled={isSubmit}
                      checked={isModularPublish} onChange={handlePublishChange} >
                      Published
                </Checkbox>)}
                </span>,
                <Button
                  id='btn-save-modular-page'
                  key='btn-save-modular-page-1'
                  type='primary'
                  size='small'
                  loading={isSubmit}
                  onClick={() => onSubmitHandle()}
                >
                  Save
              </Button>,
                modularPageId && <Button
                  id='btn-delete-modular-page'
                  key='btn-delete-modular-page-2'
                  shape='circle'
                  icon='delete'
                  size='small'
                  type='danger'
                  disabled={isSubmit}
                  ghost={true}
                  onClick={() => handleDeleteModularPage(modularPageId)}
                />,
              ]}
            />
            <div className='modular-page-wrapper-body mt-2'>
              <Row className='modular-page-form-header mt-2'>
                <Col span={24}>
                  <Row gutter={8}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label='Title'>
                        {getFieldDecorator('title', {
                          initialValue: title ? title : '',
                          rules: [{ required: true, message: 'Please input your title!' }]
                        })(
                          <Input placeholder='Title' onBlur={handleBlurTitle} />,
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label='Slug'>
                        {getFieldDecorator('slug', {
                          initialValue: slug ? slug : '',
                        })(
                          <Input placeholder='Slug' />,
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <ModuleTypesWrapper {...props}
                  modularPageId={modularPageId}
                  nextOrder={modulerEditableData.nextOrder}
                  modules={modules}
                  getModularCustomPage={getModularCustomPage}
                />
              </Row>
            </div>
          </Form>
        </Spin>
      </Col>
    </Row >
  )
}

const WrappedModulerPageForm = Form.create({ name: 'modular_page' })(ModularPageWrapper)
export default WrappedModulerPageForm
