import React, { useState, useRef, useEffect } from 'react'
import {
  Row,
  Col,
  Table,
  Typography,
  Button,
  Icon,
  Tooltip,
} from 'antd'
import { useQuery } from '@apollo/react-hooks'
import urlSlug from 'url-slug'
import { get } from 'lodash'
import * as Sentry from '@sentry/browser'
import client from '../../apollo'
import Meta from '../../components/Meta'
import ModularPageForm from './components/ModularPageForm'
import { openNotification } from '../../common/utility'
import { GET_MODULAR_PAGES } from './graphql/Queries'
import { CREATE_MODULAR_PAGE } from './graphql/Mutations'
import './modular-page.css'

const { Title } = Typography

export default function ModularPages(props) {
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [tableLoading, setTableLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)

  const saveFormRef = useRef()
  const {
    data: { modulePages },
    networkStatus,
    fetchMore,
    loading
  } = useQuery(GET_MODULAR_PAGES, {
    variables: { first: 10 },
    fetchPolicy: 'network-only'
  })

  function handlePagination(pageNumber) {
    setCurrentPageNumber(pageNumber)
    setTableLoading(true)
    fetchMore({
      variables: {
        skip: (pageNumber - 1) * 10,
        first: 10,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        const { modulePages } = fetchMoreResult
        setTableLoading(false)
        return modulePages.length ? { modulePages: [...modulePages] } : prevResult
      },
    })
  }

  useEffect(() => {
    if (currentPageNumber) {
      handlePagination(currentPageNumber)
    }
  }, [currentPageNumber])

  function handleEditButton(data) {
    const { history } = props
    if (data && data.id) {
      history.push(`/modular-pages/${data.id}/edit`)
    }
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: false,
      className: 'break-word',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      sorter: false,
      className: 'break-word',
    },
    {
      title: 'Action',
      width: 150,
      dataIndex: 'operation',
      render: (text, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Icon
                id={`btn-icon-${record.id}-edit`}
                type="edit"
                theme="twoTone"
                onClick={() => handleEditButton(record)}
              />
            </Tooltip>
          </div>
        )
      }
    }
  ]

  function handleCreate() {
    const { history } = props
    const form = saveFormRef.current.props.form
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      setSubmit(true)
      const formData = {
        title: values.title,
        slug: values.slug,
      }
      await client.mutate({
        mutation: CREATE_MODULAR_PAGE,
        variables: formData
      }).then(res => {
        const createModularPageCustomData = get(res, 'data.createModularPageCustom')
        if (createModularPageCustomData && createModularPageCustomData.id && createModularPageCustomData.message) {
          openNotification('success', createModularPageCustomData.message)
        }
        setSubmit(false)
        form.resetFields()
        history.push(`/modular-pages/${createModularPageCustomData.id}/edit`)
      }).catch((error) => {
        Sentry.captureException(error)
        handleRequestFail(error)
      }).finally(() => setSubmit(false))
    })
  }

  function handleRequestFail(e) {
    setSubmit(false)
    if (e && e.message) {
      const message =
        (e && e.graphQLErrors && Array.isArray(e.graphQLErrors) && e.graphQLErrors[0] && e.graphQLErrors[0].message) || e.message
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleBlurTitle() {
    const form = saveFormRef.current.props.form
    const { getFieldValue, setFieldsValue } = form
    const title = getFieldValue('title')
    const slug = urlSlug(title)
    setFieldsValue({ slug: slug })
  }

  return (
    <Row gutter={24} type="flex" className="modular-page-wrapper">
      <Meta title="Modular Pages" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Modular Pages</Title>
          <Button
            id="btn-modular-page-create-modal"
            type="primary"
            shape="circle"
            icon="plus"
            size="default"
            onClick={() => setShowModal(true)}
          />
        </div>
        <Table
          loading={networkStatus === 1 || tableLoading || loading}
          columns={columns}
          dataSource={modulePages || []}
          pagination={false}
          rowKey={(record) => record.id}
        />
        <div className="page-change-btn-wrapper">
          <Button icon="left" onClick={() => setCurrentPageNumber(currentPageNumber - 1)} disabled={currentPageNumber === 1} />
          <Button icon="right" onClick={() => setCurrentPageNumber(currentPageNumber + 1)} disabled={modulePages?.length < 10} />
        </div>
      </Col>
      <ModularPageForm
        saveFormRef={saveFormRef}
        showModal={showModal}
        isSubmit={isSubmit}
        handleCancel={() => {
          setShowModal(false)
          setShowModal(false)
          saveFormRef.current.props.form.resetFields()
        }}
        handleCreate={handleCreate}
        handleBlurTitle={handleBlurTitle} />
    </Row>
  )
}