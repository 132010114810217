import React, { useState, useContext, useEffect } from 'react'
import { Layout, notification } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import { GET_CURRENT_USER, GET_CURRENT_PERSON } from './graphql/Queries'
import LayoutHeader from './components/Header'
import LayoutSider from './components/Sider'
import Podcast from '../modules/podcasts'
import PodcastDetailPage from '../modules/podcasts/detailpage'
import PodcastEpisode from '../modules/podcast-episode'
import Shows from '../modules/shows'
import DetailPage from '../modules/shows/detailpage'
import Episode from '../modules/episode'
import Discussions from '../modules/discussions'
import Page404 from '../components/Page404'
import DiscussionDetailPage from '../modules/discussions/discussionDetailPage'
import { AppContext } from '../AppContext'
import client, { chatClient } from '../apollo'
import LiveChat from '../modules/live-chat'
import LiveChatDetailPage from '../modules/live-chat/LiveChatDetailPage'
import UserManagement from '../modules/user-management'
import PersonManagement from '../modules/person-management'
import Spinner from '../components/loaders/Spinner'
import './layout.css'
import BadgeManagement from '../modules/badge-management'
import FeaturedManagement from '../modules/featured-management'
import Seasons from '../modules/season'
import SeasonDetailPage from '../modules/season/SeasonDetailPage'
import EpisodeListPage from '../modules/season/components/EpisodeListPage'
import EpisodeUpdateListPage from '../modules/season/components/EpisodeUpdateListPage'
import VideoManagement from '../modules/videos'
import VideoDetails from '../modules/videos/VideoDetails'
import ClipManagement from '../modules/clips'
import ClipDetails from '../modules/clips/ClipDetails'
import ModularPages from '../modules/modular-page'
import ModularPageWrapper from '../modules/modular-page/components/ModularPageWrapper'

const { Content } = Layout

export default function LayoutWrapper(props) {
  const [collapsed, setCollapsed] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)
  const [showModularPages, setModularPagesVisible] = useState(false)

  useEffect(() => {
    client
      .query({
        query: GET_CURRENT_USER,
      })
      .then((data) => {
        dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
        return chatClient.query({
          query: GET_CURRENT_PERSON,
        })
      })
      .then((data) => {
        setIsLoading(false)
        dispatch({ type: 'CURRENT_PERSON', data: data.data.currentPerson })
      })
      .catch((error) => {
        setIsLoading(false)
        notification['error']({
          message: error.message,
        })
      })
  }, [dispatch])
  function authorChange(value) {
    console.log(`selected ${value}`)
  }

  const {
    location: { pathname },
  } = props
  return isLoading ? (
    <div className="callback-wrapper">
      <Spinner />
    </div>
  ) : (
      state && state.currentUser && (
        <Layout>
          <LayoutSider
            collapsed={collapsed}
            pathname={pathname}
            role={state.currentUser.role}
            showModularPages={showModularPages}
          />
          <Layout>
            <LayoutHeader
              authorChange={authorChange}
              collapsed={collapsed}
              toggle={() => setCollapsed(!collapsed)}
            />
            <Content className="content-wrapper">
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/shows" />} />
                <Route exact path="/podcasts" component={Podcast} />
                <Route exact path="/podcasts/:podcastId" component={PodcastDetailPage} />
                <Route
                  exact
                  path="/podcasts/:podcastId/:episodeId"
                  component={PodcastEpisode}
                />
                <Route exact path="/shows" component={Shows} />
                <Route exact path="/shows/:showId" component={DetailPage} />
                <Route
                  exact
                  path="/shows/:showId/:episodeId"
                  component={Episode}
                />
                <Route exact path="/discussions" component={Discussions} />
                <Route
                  exact
                  path="/discussions/:discussionId"
                  component={DiscussionDetailPage}
                />
                <Route exact path="/live-chat" component={LiveChat} />
                <Route
                  exact
                  path="/live-chat/:liveChatId"
                  component={LiveChatDetailPage}
                />
                <Route
                  exact
                  path="/subscriber-management"
                  component={PersonManagement}
                />

                <Route exact path="/seasons" component={Seasons} />
                <Route
                  exact
                  path="/seasons/:seasonId"
                  component={SeasonDetailPage}
                />
                <Route
                  exact
                  path="/seasons/:seasonId/select"
                  component={EpisodeListPage}
                />
                <Route
                  exact
                  path="/seasons/:seasonId/update"
                  component={EpisodeUpdateListPage}
                />
                {state.currentUser.role === 'ADMIN' && (
                  <Route
                    exact
                    path="/user-management"
                    component={UserManagement}
                  />
                )}
                <Route
                  exact
                  path="/badge-management"
                  component={BadgeManagement}
                />
                {/* Remove "Featured Episodes" section from CMS DW-3743 */}
                {/* <Route
                  exact
                  path="/featured-episodes"
                  component={FeaturedManagement}
                />
                <Route
                  exact
                  path="/featured-episodes/:showId/:episodeId/:featured"
                  component={Episode}
                /> */}
                <Route exact path="/videos" component={VideoManagement} />
                <Route exact path="/videos/:videoId" component={VideoDetails} />
                <Route exact path="/clips" component={ClipManagement} />
                <Route exact path="/clips/:clipId" component={ClipDetails} />
                <Route exact path="/modular-pages" component={ModularPages} />
                <Route exact path="/modular-pages/:modularPageId/edit" component={ModularPageWrapper} />
                <Route component={Page404} />
              </Switch>
            </Content>
          </Layout>
         <p onClick={() => setModularPagesVisible(!showModularPages)}>.</p>
        </Layout>
      )
    )
}
