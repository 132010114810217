import React from 'react'
import { Modal } from 'antd'
import { Player, ControlBar, ClosedCaptionButton } from 'video-react'
import HLSSource from '../../../HLSSource'

export default function PlayerModal(props) {
  const { videoUrl, title, showPlayerModal, handleOk, captions } = props
  const tracks = captions && captions.length > 0 && captions.map((caption, i) =>
    <track
      key={i}
      kind="captions"
      src={caption.url}
      srcLang={caption.language}
      label={caption.name}
      default={i === 0 ? true : false}
    />
  )
  return (
    showPlayerModal && (
      <Modal
        title={title}
        width="60%"
        height="60vh"
        visible={showPlayerModal}
        footer={null}
        onOk={() => handleOk()}
        onCancel={() => handleOk()}
      >
        <div className="w-100 text-center">
          <Player children={tracks ? tracks : ''}>
            <HLSSource isVideoChild src={videoUrl} />
            <ControlBar autoHide={true}>
              {captions.length > 0 && <ClosedCaptionButton order={7} />}
            </ControlBar>
          </Player>
        </div>
      </Modal>
    )
  )
}
