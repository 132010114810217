import React, { Component } from 'react'
import { Col, notification, Modal, Button, Tag } from 'antd'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { DELETE_VIDEO } from '../graphql/Mutations'
import client from '../../../apollo'
import * as Sentry from '@sentry/browser'

const confirm = Modal.confirm

export default class VideoCard extends Component {
  openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      this.openNotification('error', message)
    } else {
      this.openNotification('error', 'Something Went Wrong')
    }
  }

  showConfirm(id) {
    confirm({
      title: `Are you sure you want to remove video`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        client
          .mutate({
            mutation: DELETE_VIDEO,
            variables: { videoId: id }
          })
          .then(
            ({
              data: {
                deleteVideo: { id },
              },
            }) => {
              setTimeout(() => {
                this.openNotification('success', 'Video Deleted Successfully')
                this.props.updateEpisodeList(id)
              }, 500)
            },
          )
          .catch((error) => {
            Sentry.captureException(error)
            this.handleRequestFail(error)
          })
      },
    })
  }

  render() {
    const { id, image, name, createdAt, status } = this.props
    return (
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        className="video-card-col-wrapper"
      >
        <div className="show-card-wrapper">
          <div className="absolute-container">
            <Tag className={status}>{status}</Tag>
            <Button
              id={`btn-video-${id}-delete`}
              key="2"
              size="default"
              icon="delete"
              type="danger"
              onClick={() => {
                this.showConfirm(id)
              }}
            />
          </div>
          <NavLink to={`/videos/${id}`} className="episode-card">
            <div className="text-center d-flex">
              <img src={`${image}?w=300&h=250`} alt={name} title={name} />
            </div>
            <div className="episode-details" style={{ textAlign: 'left' }}>
              <p className="episode-title">{name}</p>
              <p className="episode-date">
                {moment(createdAt).format('MMMM Do, YYYY')}
              </p>
            </div>
          </NavLink>
        </div>
      </Col>
    )
  }
}
