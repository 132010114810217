import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { get } from 'lodash'
import { Player, ControlBar, ClosedCaptionButton } from 'video-react'
import HLSSource from '../../../HLSSource'
import client from '../../../apollo'
import { GET_VIDEO } from '../graphql/Queries'

export default function PlayerModal(props) {
  const { videoUrl, title, videoId, showPlayerModal, handleOk, captions } = props
  const [playerVideoUrl, setPlayerVideoUrl] = useState()
  useEffect(() => {
    if (videoUrl.indexOf('token') === -1) {
      getVideoData()
    } else {
      setPlayerVideoUrl(videoUrl)
    }
  }, [videoUrl])

  function getVideoData() {
    client.query({
      query: GET_VIDEO,
      variables: { id: videoId },
      fetchPolicy: 'network-only'
    }).then(data => {
      if (data && data.data) {
        const updatedVideoUrl = get(data, 'data.video.videoState')
        setPlayerVideoUrl(updatedVideoUrl)
      }
    })
  }

  const tracks = captions && captions.length > 0 && captions.map((caption, i) =>
    <track
      key={i}
      kind="captions"
      src={caption.url}
      srcLang={caption.language}
      label={caption.name}
      default={i === 0 ? true : false}
    />
  )

  return (
    showPlayerModal && (
      <Modal
        title={title}
        width="60%"
        height="60vh"
        visible={showPlayerModal}
        footer={null}
        onOk={() => handleOk()}
        onCancel={() => handleOk()}
      >
        <div className="w-100 text-center">
          {playerVideoUrl && <Player children={tracks ? tracks : ''}>
            <HLSSource isVideoChild src={playerVideoUrl} />
            <ControlBar autoHide={true}>
              {captions.length > 0 && <ClosedCaptionButton order={7} />}
            </ControlBar>
          </Player>}
        </div>
      </Modal>
    )
  )
}
