import React, { useState } from 'react'
import { Row, Col, Divider, Tabs } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_EPISODE } from '../graphql/Queries'
import AddEpisodeCard from './AddEpisodeCard'
import EpisodeCard from './EpisodeCard'
import Spinner from '../../../components/loaders/Spinner'
import { GET_SEASONS, GET_CLIPS } from '../../shows/graphql/Queries'
import ClipCard from './ClipCard'
import SeasonCard from './SeasonCard'
const { TabPane } = Tabs
export default function (props) {
  const { showId, history, showData } = props
  const [stopLoading, setStopLoading] = useState(false)
  const [episodeLoading, setEpisodeLoading] = useState(false)
  const [seasonLoading, setSeasonLoading] = useState(false)
  const [clipLoading, setClipLoading] = useState(false)
  const [fetchMoreEpisodes, setFetchMoreEpisodes] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedTab, setSelectedTab] = useState('EPISODE')
  const {
    data: allEpisodesData,
    error: episodeError,
    fetchMore,
    networkStatus,
  } = useQuery(GET_ALL_EPISODE, {
    variables: { id: showId },
    fetchPolicy: 'network-only',
  })

  const {
    data: seasonData,
    error: seasonError,
    fetchMore: fetchSeasonMore,
    networkStatus: seasonsNetworkStatus,
  } = useQuery(GET_SEASONS, {
    variables: { first: 20, where: { show: { id: showId } } },
    fetchPolicy: 'network-only',
  })

  const {
    data: clipData,
    error: clipError,
    fetchMore: fetchClipMore,
    networkStatus: clipNetworkStatus,
  } = useQuery(GET_CLIPS, {
    variables: { first: 20, where: { show: { id: showId } } },
    fetchPolicy: 'network-only',
  })

  const onTabsChanged = (e) => {
    setSelectedTab(e)
  }

  let episodesData =
    allEpisodesData &&
      allEpisodesData.episodes &&
      allEpisodesData.episodes.length > 0
      ? allEpisodesData.episodes
      : []
  let seasonsData =
    seasonData && seasonData.seasons && seasonData.seasons.length > 0
      ? seasonData.seasons
      : []
  let clipsData =
    clipData && clipData.clips && clipData.clips.length > 0
      ? clipData.clips
      : []
  async function handleScroll() {
    const scroller = document.getElementById('episode-list-wrapper')
    var scrollTop = scroller.scrollTop
    var scrollHeight = scroller.scrollHeight
    var clientHeight = scroller.clientHeight
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight
    if (selectedTab === 'CLIPS' && scrolledToBottom) {
      setClipLoading(true)
      await fetchClipMore({
        variables: {
          first: 20,
          where: { show: { id: showId } },
          after: clipsData[clipsData.length - 1].id,
        },
        notifyOnNetworkStatusChange: true,
        updateQuery: async (prevResult, { fetchMoreResult }) => {
          const { clips } = fetchMoreResult
          if (clips && clips.length > 0) {
            await clips.forEach(async (element) => {
              if (
                clipsData.findIndex((clip) => clip.id === element.id) === -1
              ) {
                await clipsData.push(element)
              }
            })
          }
          setClipLoading(false)
        },
      })
    }
    if (selectedTab === 'SEASONS' && scrolledToBottom) {
      setSeasonLoading(true)
      await fetchSeasonMore({
        variables: {
          first: 20,
          where: { show: { id: showId } },
          after: seasonsData[seasonsData.length - 1].id,
        },
        notifyOnNetworkStatusChange: true,
        updateQuery: async (prevResult, { fetchMoreResult }) => {
          const { seasons } = fetchMoreResult
          if (seasons && seasons.length > 0) {
            await seasons.forEach(async (element) => {
              if (
                seasonsData.findIndex((clip) => clip.id === element.id) === -1
              ) {
                await seasonsData.push(element)
              }
            })
          }
          setSeasonLoading(false)
        },
      })
    }
    if (selectedTab === 'EPISODE') {
      if (episodesData.length > 0) {
        setStopLoading(false)
      }
      if (
        scrolledToBottom &&
        episodesData.length > 0 &&
        !episodeLoading &&
        !stopLoading && !isModalOpen
      ) {
        setEpisodeLoading(true)
        await fetchMore({
          variables: {
            id: showId,
            after: episodesData[episodesData.length - 1]?.id,
          },
          notifyOnNetworkStatusChange: true,
          updateQuery: async (prevResult, { fetchMoreResult }) => {
            const { episodes } = fetchMoreResult
            if (episodes?.length < 15) {
              setFetchMoreEpisodes(false)
            }
            if (episodes && episodes.length > 0) {
              await episodes.forEach(async (element) => {
                if (
                  episodesData.findIndex(
                    (episode) => episode.id === element.id,
                  ) === -1
                ) {
                  await episodesData.push(element)
                }
              })
            }
            if (episodesData) {
              setStopLoading(true)
              setEpisodeLoading(false)
            } else {
              setEpisodeLoading(false)
            }
          },
        })
      }
    }
  }
  return (
    <div>
      <div className="show-description-wrapper">
        <Row gutter={24} type="flex">
          <Col
            sm={24}
            md={16}
            lg={16}
            xl={18}
            className="episode-list-wrapper"
            id="episode-list-wrapper"
            onScroll={() => handleScroll()}
          >
            <div className="box">
              <div className="show-author">
                Author:
                <span>
                  {showData.show.author.firstName}{' '}
                  {showData.show.author.lastName}
                </span>
              </div>
              <div className="show-description">
                {showData.show.description}
              </div>
            </div>
            <Row gutter={24} type="flex" className="divider-wrapper">
              <Divider />
            </Row>
            <Tabs
              defaultActiveKey={selectedTab}
              style={{ width: '100%' }}
              onChange={onTabsChanged}
            >
              <TabPane tab="Episodes" key="EPISODE">
                <Row
                  gutter={24}
                  type="flex"
                  className="episode-list"
                  id="episode-list"
                >
                  {episodeError ? (
                    <>{episodeError.message}</>
                  ) : (
                    <>
                      <AddEpisodeCard showId={showId} history={history} setIsModalOpen={setIsModalOpen} />
                      {(networkStatus === 1 || episodeLoading) && (
                        <div className="episode-loader-wrapper">
                          <Spinner />
                        </div>
                      )}
                      {episodesData &&
                        episodesData.length > 0 &&
                        episodesData.map((episode, key) => (
                          <EpisodeCard
                            episode={episode}
                            showId={showId}
                            key={key}
                          />
                        ))}
                    </>
                  )}
                </Row>
              </TabPane>
              <TabPane tab="Seasons" key="SEASONS">
                <Row
                  gutter={24}
                  type="flex"
                  className="episode-list"
                  id="episode-list"
                >
                  {seasonError ? (
                    <>{seasonError.message}</>
                  ) : (
                    <>
                      {(seasonsNetworkStatus === 1 || seasonLoading) && (
                        <div className="episode-loader-wrapper">
                          <Spinner />
                        </div>
                      )}
                      {seasonsData && seasonsData.length > 0 ? (
                        seasonsData.map((season, key) => (
                          <SeasonCard {...season} key={key} />
                        ))
                      ) : (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          <h2>No seasons found</h2>
                        </div>
                      )}
                    </>
                  )}
                </Row>
              </TabPane>
              {/* <TabPane tab="Clips" key="CLIPS">
                <Row
                  gutter={24}
                  type="flex"
                  className="episode-list"
                  id="episode-list"
                >
                  {clipError ? (
                    <>{clipError.message}</>
                  ) : (
                      <>
                        {(clipNetworkStatus === 1 || clipLoading) && (
                          <div className="episode-loader-wrapper">
                            <Spinner />
                          </div>
                        )}
                        {clipsData && clipsData.length > 0 ? (
                          clipsData.map((clip, key) => (
                            <ClipCard {...clip} key={key} />
                          ))
                        ) : (
                            <div style={{ textAlign: 'center', width: '100%' }}>
                              <h2>No clips found</h2>
                            </div>
                          )}
                      </>
                    )}
                </Row>
              </TabPane> */}
            </Tabs>
          </Col>
          <Col sm={24} md={8} lg={8} xl={6}>
            <div className="box show-all-images">
              {showData && showData.show && <div className="show-card images-wrapper text-center">
                {showData.show.hostImage && (
                  <div>
                    <div className="show-author">Host Image</div>
                    <img
                      className="host-image"
                      src={`${showData.show.hostImage}?${performance.now()}`}
                      alt={showData.show.name}
                      title={showData.show.name}
                    />
                  </div>
                )}

                <div>
                  <div className="show-author">Thumbnail Image</div>
                  <img
                    src={`${showData.show.image}?${performance.now()}`}
                    alt={showData.show.name}
                    title={showData.show.name}
                  />
                </div>
                {showData.show.portraitImage && (
                  <div>
                    <div className="show-author">Thumbnail Portrait Image</div>
                    <img
                      className="show-logo"
                      src={`${showData.show.portraitImage
                        }?${performance.now()}`}
                      alt={showData.show.name}
                      title={showData.show.name}
                    />
                  </div>
                )}
                {showData.show.logoImage && (
                  <div>
                    <div className="show-author">Logo</div>
                    <img
                      className="show-logo"
                      src={`${showData.show.logoImage}?${performance.now()}`}
                      alt={showData.show.name}
                      title={showData.show.name}
                    />
                  </div>
                )}
                {showData.show.backgroundImage && (
                  <div>
                    <div className="show-author">Background Image</div>
                    <img
                      src={`${showData.show.backgroundImage
                        }?${performance.now()}`}
                      alt={showData.show.name}
                      title={showData.show.name}
                    />
                  </div>
                )}
                {showData.show.showDetailImage && (
                  <div>
                    <div className="show-author">OTT Show Banner</div>
                    <img
                      src={`${showData.show.showDetailImage
                        }?${performance.now()}`}
                      alt={showData.show.name}
                      title={showData.show.name}
                    />
                  </div>
                )}
                {showData.show.episodeDetailImage && (
                  <div>
                    <div className="show-author">OTT Episode Background</div>
                    <img
                      src={`${showData.show.episodeDetailImage
                        }?${performance.now()}`}
                      alt={showData.show.name}
                      title={showData.show.name}
                    />
                  </div>
                )}
              </div>}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
