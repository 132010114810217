import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import SeasonWrapper from './SeasonWrapper'
import CardLoader from '../../../components/loaders/CardLoader'
import Meta from '../../../components/Meta'
import { useQuery } from '@apollo/react-hooks'
import { GET_SEASONS } from '../graphql/Queries'
import { withRouter } from 'react-router-dom'

const SeasonListing = (props) => {
  const [seasons, setSeasons] = useState([])
  const [loading, setLoading] = useState([])
  const {
    match: {
      params: { showId },
    },
  } = props
  const {
    data,
    loading: isSeasonLoading,
    error: seasonsError,
    fetchMore,
  } = useQuery(GET_SEASONS, {
    variables: { first: 10, where: { show: { id: showId } } },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isSeasonLoading) {
      setLoading(false)
      setSeasons(data.seasons)
    } else {
      setLoading(true)
    }
  }, [isSeasonLoading])

  return (
    <div className="box">
      <Row gutter={24} type="flex" className="shows-wrapper shows-list">
        <Meta title="Video-Clips" description="" />
        {loading ? (
          <CardLoader />
        ) : (
          <SeasonWrapper
            seasons={seasons}
            // updateEpisodeList={onListForSeason}
            onLoadMore={() => {
              fetchMore({
                loading: loading,
                variables: {
                  first: 20,
                  after: seasons[seasons.length - 1].id,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  const { seasons } = fetchMoreResult
                  if (seasons.length) {
                    setSeasons([...prevResult.seasons, ...seasons])
                  }
                  return seasons.length
                    ? {
                        seasons: [...prevResult.seasons, ...seasons],
                      }
                    : prevResult
                },
              })
            }}
          />
        )}
      </Row>
    </div>
  )
}

export default withRouter(SeasonListing)
