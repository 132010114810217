import gql from 'graphql-tag'

export const SUBSCRIBE_PODCAST_EPISODE = gql`
subscription podcastEpisode($id: ID!){
  podcastEpisode(where: { node : { id: $id }}) {
    mutation
    node {
      id
      title
      description
      episodeNumber
      weight      
      status
      rating
      publishDate
      allowedContinents
      audioAccess
      audioWithAdsAccess
      duration
      audio
      audioState
      audioMuxAssetId
      durationWithAds
      audioWithAds
      audioWithAdsState
      audioWithAdsMuxAssetId
    }
  }
}
`