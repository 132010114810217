import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddClipForm from './AddClipForm'

const CollectionCreateForm = Form.create({ name: 'create_episode' })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        isSubmit,
        title,
        clipAccess,
        showId,
        videoId,
        description,
        clipNumber,
        scheduleAt,
        isEdit,
      } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${title ? 'Edit Clip Details' : 'Add New Clip'}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-clip-cancel' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id='btn-clip-save'
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              {title ? 'Save' : 'Add Clip'}
            </Button>,
          ]}
        >
          <AddClipForm
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
            title={title}
            description={description}
            clipNumber={clipNumber}
            scheduleAt={scheduleAt}
            clipAccess={clipAccess}
            showId={showId}
            videoId={videoId}
            isEdit={isEdit}
          />
        </Modal>
      )
    }
  },
)

export default class AddClipModal extends Component {
  render() {
    const {
      showModal,
      isSubmit,
      handleCancel,
      handleCreate,
      clipAccess,
      saveFormRef,
      title,
      description,
      clipNumber,
      videoId,
      showId,
      isEdit,
    } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        title={title}
        description={description}
        clipNumber={clipNumber}
        clipAccess={clipAccess}
        videoId={videoId}
        showId={showId}
        isEdit={isEdit}
      />
    )
  }
}
