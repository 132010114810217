import React from 'react'
import { Helmet } from 'react-helmet'

export default function ({ title, description }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || 'The Daily Wire'}{description && ` - ${description}`} | The Daily Wire</title>
      {description && <meta name="description" content={description} />}
      <meta property="al:ios:url" content="dwapp://story/1234" />
      <meta property="al:ios:app_store_id" content="1477317200" />
      <meta property="al:ios:app_name" content="The Daily Wire" />
      <meta property="al:android:package" content="com.dailywire.thedailywire" />
      <meta property="al:android:app_name" content="The Daily Wire" />
      <meta property="og:title" content="&#x27;Aquaman&#x27; Jason Momoa Scolds Chris Pratt For Using Plastic Water Bottle; Pratt Responds" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="The Daily Wire" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@realDailyWire" />
      <meta name="twitter:creator" content="@realDailyWire" />
      <meta name="twitter:description" content="&#x27;Aquaman&#x27; Jason Momoa Scolds Chris Pratt For Using Plastic Water Bottle; Pratt Responds" />
      <meta name="twitter:app:name:iphone" content="The Daily Wire" />
      <meta name="twitter:app:id:iphone" content="1477317200" />
      <meta name="twitter:app:url:iphone" content="dwapp://story/1234" />
      <meta name="twitter:app:name:ipad" content="The Daily Wire" />
      <meta name="twitter:app:id:ipad" content="1477317200" />
      <meta name="twitter:app:url:ipad" content="dwapp://story/1234" />
      <meta name="twitter:app:name:googleplay" content="The Daily Wire" />
      <meta name="twitter:app:id:googleplay" content="com.dailywire.thedailywire" />
      <meta name="twitter:app:url:googleplay" content="dwapp://story/1234" />
      <meta property="al:web:should_fallback" content="false" />
      <meta property="al:android:url" content="dwapp://story/1234" />
    </Helmet>
  )
}
