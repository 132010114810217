import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Upload, Icon, Modal, message, Progress, Divider } from 'antd'
import * as Sentry from '@sentry/browser'
import { createUpload } from '@mux/upchunk'
import PlayerModal from './PlayerModal'
import client from '../../../apollo'
import { GET_MUX_DATA } from '../../episode/graphql/Queries'
import { GET_VIDEO_SIGNED_VIDEO_PUT_URL, GET_VIDEO } from '../graphql/Queries'
import { REQUEST_MASTER_ACCESS } from '../../episode/graphql/Mutations'
import history from '../../../history'
import { UPDATE_VIDEO } from '../graphql/Mutations'
import AddSubTitleForm from './AddSubTitleForm'

const { confirm } = Modal
export default function SegmentDetails(props) {
  const [showPlayerModal, setShowPlayerModal] = useState(false)
  const [isDownload, setIsDownload] = useState(false)
  const [videoProgress, setVideoProgress] = useState(undefined)
  const {
    videoId,
    videoStatus,
    title,
    videoUrl,
    videoState,
    muxAssetId,
    handleRemove,
    setVideoLoading,
    videoLoading,
    openNotification,
    captions
  } = props
  function handleBeforeUnload(e) {
    if (!videoLoading) {
      return true
    }
    e.preventDefault()
    e.returnValue = 'Some task is in progress. Are you sure, you want to close?'
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    history.block((location, action) => {
      return videoLoading
        ? 'Some task is in progress. Are you sure, you want to close?'
        : true
    })
  })

  function handleOk(e) {
    setShowPlayerModal(false)
  }

  function handleModal() {
    setShowPlayerModal(true)
  }

  function handleRequestFail(e) {
    setVideoLoading(false)
    const message = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  async function handleVideoFileChange(info, videoId) {
    if (info.fileList.length > 1) {
      info.fileList.shift()
    }
    if (info.file && info.file.type === 'video/mp4') {
      try {
        setVideoLoading(true)
        setVideoProgress(0)
        if (videoId) {
          const getVideoSignedVideoPutUrlResult = await client.query({
            query: GET_VIDEO_SIGNED_VIDEO_PUT_URL,
            fetchPolicy: "network-only",
            variables: { videoId, corsOrigin: window.location.origin },
          })
          const upload = createUpload({
            endpoint:
              getVideoSignedVideoPutUrlResult.data.getVideoSignedVideoPutUrl
                .signedUrl,
            file: info.file.originFileObj,
          })
          upload.on('error', (err) => {
            setVideoLoading(false)
            setVideoProgress(undefined)
            if (err && err.message) {
              openNotification('error', err.message)
            } else {
              openNotification('error', 'Something Went Wrong')
            }
          })
          upload.on('progress', (progress) => {
            setVideoProgress(progress.detail)
          })
          upload.on('success', async () => {
            // console.log("Wrap it up, we're done here. 👋")
            await client.mutate({
              mutation: UPDATE_VIDEO,
              variables: {
                data: { videoState: 'PROCESSING' },
                where: { id: videoId },
              },
              refetchQueries: [
                {
                  query: GET_VIDEO,
                  fetchPolicy: 'network-only',
                  variables: { id: videoId },
                },
              ],
            })
            openNotification('success', 'Video Uploaded Successfully')
            setVideoLoading(false)
            setTimeout(() => {
              setVideoProgress(undefined)
            }, 500)
          })
        }
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail(e)
      }
    }
  }

  async function handleDownload(muxAssetId) {
    try {
      const muxAssetsResult = await client.query({
        query: GET_MUX_DATA,
        fetchPolicy: 'network-only',
        variables: { assetId: muxAssetId },
      })
      const master = muxAssetsResult.data.getMuxData.master
      if (master && master.url) {
        setIsDownload(false)
        window.open(master.url)
      } else {
        if (isDownload === false) {
          setIsDownload(true)
          await client.mutate({
            mutation: REQUEST_MASTER_ACCESS,
            fetchPolicy: 'no-cache',
            variables: { assetId: muxAssetId },
          })
        }
        setTimeout(() => {
          handleDownload(muxAssetId)
        }, 30000)
      }
    } catch (err) {
      Sentry.captureException(err)
      setIsDownload(false)
    }
  }

  async function videoReplaceConfirm(videoId) {
    if (captions && captions?.length > 0) {
      openNotification('error', 'Please delete subtitle first')
    }
    else {
      confirm({
        title: 'Are you sure you want to RE-UPLOAD video?',
        content:
          'Upon clicking RE-UPLOAD, you will be able to upload video again.',
        okText: 'RE-UPLOAD',
        async onOk() {
          try {
            await client.mutate({
              mutation: UPDATE_VIDEO,

              variables: {
                data: { videoState: 'UPLOAD_READY' },
                where: { id: videoId },
              },
              refetchQueries: [
                {
                  query: GET_VIDEO,
                  fetchPolicy: 'network-only',
                  variables: { id: videoId },
                },
              ],
            })
          } catch (e) {
            Sentry.captureException(e)
            handleRequestFail(e)
          }
        },
      })
    }
  }

  async function videoRemoveConfirm(videoId) {
    if (captions && captions?.length > 0) {
      openNotification('error', 'Please delete subtitle first')
    }
    else {
      confirm({
        title: 'Are you sure you want to DELETE this video?',
        content: 'Upon clicking DELETE, the video will be permanently erased.',
        okText: 'Delete',
        okType: 'danger',
        async onOk() {
          try {
            await client.mutate({
              mutation: UPDATE_VIDEO,
              variables: {
                data: { videoURL: '', videoState: 'IDLE' },
                where: { id: videoId },
              },
              refetchQueries: [
                {
                  query: GET_VIDEO,
                  fetchPolicy: 'network-only',
                  variables: { id: videoId },
                },
              ],
            })
            openNotification('success', 'Video Removed Successfully')
          } catch (e) {
            Sentry.captureException(e)
            handleRequestFail(e)
          }
        },
      })
    }
  }

  function beforeVideoUpload(file) {
    const isMP4 = file.type === 'video/mp4'
    if (!isMP4) {
      message.error('You can only upload MP4 file!')
    }
    return isMP4
  }

  const uploadVideoButton = (
    <div>
      <Icon type={videoLoading ? 'loading' : 'play-circle'} />
      <div className="ant-upload-text">Upload Video</div>
    </div>
  )
  return (
    <>
      <Col sm={24} md={24} lg={24} xl={24} className="segment-details-wrapper">
        {showPlayerModal && <PlayerModal
          showPlayerModal={showPlayerModal}
          videoUrl={videoUrl}
          title={title}
          videoId={videoId}
          handleOk={handleOk}
          captions={captions}
        />}
        <div className={`box`}>
          <div className={`box-insider`}>
            <div className="dropzone-wrapper">
              <Row
                gutter={24}
                type="flex"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Col span={12} style={{ paddingLeft: 24, paddingRight: 24 }}>
                  {videoState === 'PROCESSING' ? (
                    <div className="play-button">
                      {(videoStatus === 'LIVE_FINISHED' ||
                        videoStatus === 'DRAFT' ||
                        videoStatus === 'UNPUBLISHED') && (
                          <span
                            className="remove"
                            onClick={() => videoReplaceConfirm(videoId)}
                          >
                            <Icon type="close-circle" />
                          </span>
                        )}
                      <span>Video uploading is under process</span>
                    </div>
                  ) : videoUrl ? (
                    <div className="play-button download-play-wrapper">
                      <span
                        id='btn-video-remove'
                        className="remove"
                        onClick={() => videoRemoveConfirm(videoId)}
                      >
                        <Icon type="close-circle" />
                      </span>
                      <div>
                        <Button
                          id='btn-video-play'
                          type="primary"
                          icon="play-circle"
                          shape="circle"
                          size="large"
                          onClick={(e) => handleModal()}
                          style={{ marginRight: 10 }}
                        />
                        <Button
                          id='btn-video-download'
                          type="primary"
                          shape="circle"
                          icon="download"
                          size="large"
                          loading={isDownload}
                          onClick={(e) => handleDownload(muxAssetId)}
                        />
                      </div>
                      {isDownload && <span>Processing Download</span>}
                    </div>
                  ) : (
                    <Upload
                      name="video"
                      listType="picture-card"
                      className="video-uploader"
                      multiple={false}
                      disabled={videoLoading}
                      customRequest={() => {
                        return false
                      }}
                      onRemove={(e) => handleRemove(e)}
                      showUploadList={false}
                      beforeUpload={beforeVideoUpload}
                      onChange={(e) => handleVideoFileChange(e, videoId)}
                    >
                      {uploadVideoButton}
                      {videoProgress >= 0 && (
                        <Progress
                          percent={videoProgress}
                          size="small"
                          status="active"
                        />
                      )}
                    </Upload>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <Row gutter={24} type="flex" className="video-subtitle-divider">
            <Divider />
          </Row>
          <div className={`box-insider`}>
            <div className="segmenet-title">
              Upload Subtitle
            </div>
            {
              (!videoUrl || videoUrl === '') && <p>In order to upload subtitle, Please upload video first.</p>
            }
            {videoUrl && videoUrl !== '' &&
              <AddSubTitleForm muxAssetId={muxAssetId} entityId={videoId} captions={captions} />
            }
          </div>
        </div>
      </Col>
    </>
  )
}
