import React, { useState, useEffect } from "react"
import {
  Form,
  Input,
  Upload,
  Select,
  Icon,
  Button,
  message,
  DatePicker,
  Tooltip,
} from "antd"
import moment from "moment-timezone"
import { GET_BADGES } from "../../badge-management/graphql/Queries"
import { chatClient } from "../../../apollo"

const { Option } = Select

function beforeImageUpload(file) {
  if (
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg"
  ) {
    return true
  }
  message.error("You can only upload image file!")
  return false
}

export default function (props) {
  const [badgeLoading, setBadgeLoading] = useState(false)
  const [badges, setBadges] = useState([])
  const [removeFile, setRemoveFile] = useState(false)
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)
  const [scheduleTitle, setScheduleTitle] = useState("")

  const uploadProps = {
    customRequest() {
      return false
    },
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  useEffect(() => {
    setBadgeLoading(true)
    chatClient
      .query({
        query: GET_BADGES,
      })
      .then((data) => {
        setBadges(data.data.badges)
        setBadgeLoading(false)
      })
  }, [])

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  function handleTimeChange(currentTime) {
    if (currentTime) {
      const laTime = moment(currentTime.valueOf()).tz("America/Los_Angeles")
      setScheduleTitle(
        `Expiry Date: ${laTime.format("lll")} ${laTime.zoneAbbr()}`,
      )
      setTooltipIsVisible(true)
    } else {
      setScheduleTitle("")
      setTooltipIsVisible(false)
    }
  }

  function disabledStartDate(startValue) {
    return startValue.valueOf() < moment().valueOf()
  }

  const {
    getFieldDecorator,
    email,
    firstName,
    lastName,
    userName,
    promoPlan,
    badgeId,
    promoPlanExpiration,
    recurlyId,
    recurlyPlan,
    role,
    showModal,
    isUpdate,
    onChangeEmailClick,
    onChangeUserNameClick,
  } = props
  return (
    <Form layout="vertical" className="add-show-form">
      <Form.Item label="First Name">
        {getFieldDecorator("firstName", {
          initialValue: isUpdate ? firstName : "",
          rules: [{ required: true, message: "Please input the First Name!" }],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator("lastName", {
          initialValue: isUpdate ? lastName : "",
          rules: [{ required: true, message: "Please input the Last Name!" }],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator("email", {
          initialValue: isUpdate ? email : "",
          rules: [
            {
              type: "email",
              message: "The input is not valid Email!",
            },
            {
              required: !isUpdate,
              message: "Please input your Email!",
            },
          ],
        })(<Input disabled={isUpdate} />)}
        {isUpdate && <Button id="btn-change-email" type="link" className="email-link-button"
          onClick={() => onChangeEmailClick(false)}>
          Change Email
        </Button>}
      </Form.Item>
      {isUpdate && <Form.Item label="User Name">
        {getFieldDecorator("userName", {
          initialValue: isUpdate ? userName : "",          
        })(<Input disabled={isUpdate} />)}
        <Button id="btn-change-user-name" type="link" className="email-link-button"
          onClick={() => onChangeUserNameClick(false)}>
          Change Username
        </Button>
      </Form.Item>}
      <Form.Item label="Role">
        {getFieldDecorator("role", {
          initialValue: isUpdate ? role : "",
          rules: [{ required: true, message: "Please select the role!" }],
        })(
          <Select placeholder="Select Role">
            <Option value="AUTHOR">AUTHOR</Option>
            <Option value="ADMIN">ADMIN</Option>
          </Select>,
        )}
      </Form.Item>
      {isUpdate && (
        <Form.Item label="Recurly Id">
          {getFieldDecorator("recurlyId", { initialValue: recurlyId })(
            <Input />,
          )}
        </Form.Item>
      )}
      {isUpdate && (
        <Form.Item label="Recurly Plan">
          {getFieldDecorator('recurlyPlan',
            {
              initialValue: recurlyPlan
            })(
              <Select placeholder="Recurly Plan" disabled={true}>
                <Option value="FREE">FREE</Option>
                <Option value="INSIDER">INSIDER</Option>
                <Option value="INSIDER_PLUS">INSIDER ANNUAL</Option>
                <Option value="ALL_ACCESS">ALL ACCESS</Option>
              </Select>
            )}
        </Form.Item>
      )}
      {isUpdate && (
        <Form.Item label="Select Promotion Plan">
          {getFieldDecorator('promoPlan',
            {
              initialValue: promoPlan ? promoPlan : "FREE"
            })(
              <Select placeholder="Select Promotion Plan">
                <Option value="FREE">FREE</Option>
                <Option value="READER">READER</Option>
                <Option value="INSIDER">INSIDER</Option>
                <Option value="INSIDER_PLUS">INSIDER ANNUAL</Option>
                <Option value="ALL_ACCESS">ALL ACCESS</Option>
              </Select>
            )}
        </Form.Item>
      )}
      {isUpdate && (
        <Form.Item label="Promotion Expiration Date">
          <Tooltip
            title={scheduleTitle}
            visible={showModal && tooltipIsVisible}
            placement="right"
          >
            {getFieldDecorator("promoPlanExpiration", {
              rules: [{ required: false, message: "Please enter the date!" }],
              initialValue: promoPlanExpiration
                ? moment(promoPlanExpiration)
                : null,
            })(
              <DatePicker
                style={{ minWidth: 260 }}
                placeholder="Select Date/Time"
                disabledDate={disabledStartDate}
                format="MM-DD-YYYY hh:mm A"
                showTime={{
                  use12Hours: true,
                  format: "hh:mm A",
                  defaultValue: moment("23:59:59", "HH:mm:ss"),
                }}
                ranges={moment()}
                onChange={handleTimeChange}
                onOk={handleTimeChange}
              />,
            )}
          </Tooltip>
        </Form.Item>
      )}
      <Form.Item label="Profile Image">
        {getFieldDecorator("profileImage", {
          valuePropName: "profileImage",
          getValueFromEvent: normFile,
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id="btn-user-upload-logo">
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
      {isUpdate && (
        <Form.Item label="Select Badge">
          {getFieldDecorator("badgeId", {
            initialValue: badgeId,
          })(
            <Select placeholder="Select Host" loading={badgeLoading}>
              {badges.map((badge, key) => (
                <Option value={badge.id} key={key}>
                  <img
                    src={`${badge.image}?${performance.now()}`}
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      marginRight: "16px",
                    }}
                  />
                  {badge.name}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      )}
    </Form>
  )
}
