import React, { useState, useRef } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import {
  Row,
  Col,
  Button,
  PageHeader,
  Tag,
  notification,
  Modal,
  Divider,
  Tabs,
} from 'antd'
import * as Sentry from '@sentry/browser'
import { trim, get, cloneDeep } from 'lodash'
import { GET_VIDEO, GET_VIDEO_SIGNED_PUT_URL } from './graphql/Queries'
import { UPDATE_VIDEO, DELETE_VIDEO } from './graphql/Mutations'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import AddVideoModal from './components/AddVideoModal'
import client from '../../apollo'
import { fileUpload } from '../../common/fileUpload'
import './video.css'
import LiveVideoSegment from './components/LiveVideoSegment'
import SegmentDetails from './components/SegmentDetails'
import { SUBSCRIBE_VIDEO } from './graphql/Subscriptions'
import ClipListing from './components/ClipListing'
import { LOCATIONS } from '../../common/constants'
const { TabPane } = Tabs

const { confirm } = Modal
function checkStatus(status) {
  if (
    status === 'LIVE_FINISHED' ||
    status === 'DRAFT' ||
    status === 'UNPUBLISHED'
  ) {
    return false
  }
  return true
}

export default function (props) {
  const [videoUploading, setVideoUploading] = useState(false)
  const [currentVideoState, setCurrentVideoState] = useState()
  const {
    history,
    match: {
      params: { videoId },
    },
  } = props
  let {
    data,
    loading: videoLoading,
    error: videoError,
  } = useQuery(GET_VIDEO, { variables: { id: videoId }, fetchPolicy: 'network-only' })
  const [showModal, setShowModal] = useState(false)
  const [openImageModal, setOpenImageModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const saveFormRef = useRef()
  let videoData = cloneDeep(data)

  const {
    data: subscriptionData,
  } = useSubscription(SUBSCRIBE_VIDEO, { variables: { id: videoId } })
  if (subscriptionData) {
    const subscribeVideoState = get(subscriptionData, 'video.node.videoState')
    if (subscribeVideoState === 'READY' && subscribeVideoState !== currentVideoState) {
      setCurrentVideoState(subscribeVideoState)
      getVideoAfterMuxUpdateVideoState()
    }
  }

  function getVideoAfterMuxUpdateVideoState() {
    client.query({
      query: GET_VIDEO,
      variables: { id: videoId },
      fetchPolicy: 'network-only'
    }).then(data => {
      if (data && data.data) {
        videoData = data.data
      }
    })
  }

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleRequestFail(e) {
    setShowModal(false)
    setIsSubmit(false)
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleCancel() {
    setShowModal(false)
    saveFormRef.current.props.form.resetFields()
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setIsSubmit(true)
      const { title, description, image, logoImage, schedule, videoAccess, videoNumber, liveChatAccess, allowedContinents, rating, locationRestriction,
        limitBy,
        allowedCountries } = values
      const {
        match: {
          params: { videoId },
        },
      } = props
      const isLive = get(videoData, 'video.isLive')
      const discussionId = get(videoData, 'video.discussionId')
      try {
        let scheduleAt = null
        if (schedule) {
          scheduleAt = schedule
        }

        if (locationRestriction) {
          if (limitBy === LOCATIONS.CONTINENT) {
            values.allowedCountries = []
          }
          else {
            values.allowedContinents = []
          }
        }
        else if (!locationRestriction) {
          values.allowedContinents = []
          values.allowedCountries = []
        }

        const data = {
          name: trim(title),
          description: trim(description),
          scheduleAt,
          videoAccess: { set: videoAccess },
          videoNumber,
          allowedContinents: { set: values.allowedContinents },
          rating: rating || null,
          allowedCountries: values.allowedCountries,
        }

        if (isLive || discussionId) {
          data.liveChatAccess = { set: liveChatAccess }
        }
        await client.mutate({
          mutation: UPDATE_VIDEO,
          variables: {
            data: data,
            where: { id: videoId },
          },
          refetchQueries: [
            {
              query: GET_VIDEO,
              variables: { id: videoId },
              fetchPolicy: 'network-only',
            },
          ],
        })
        if (image && image.length > 0) {
          const tempFilename = image[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${videoId}-${new Date().getTime()}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_VIDEO_SIGNED_PUT_URL,
            variables: { fileName, videoId, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getVideoSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_VIDEO,
            variables: {
              data: {
                image: fileName,
              },
              where: { id: videoId },
            },
            refetchQueries: [
              {
                query: GET_VIDEO,
                variables: { id: videoId },
                fetchPolicy: 'network-only',
              },
            ],
          })
        }
        if (logoImage && logoImage.length > 0) {
          const tempLogoFilename = logoImage[0].name.split('.')
          const logoFileExt = tempLogoFilename[tempLogoFilename.length - 1]
          const logoImageFileName = `${videoId}-${new Date().getTime()}.${logoFileExt}`
          const contentType = logoImage[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_VIDEO_SIGNED_PUT_URL,
            variables: { fileName: logoImageFileName, videoId, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getVideoSignedPutUrl.signedUrl,
            logoImage[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_VIDEO,
            variables: {
              data: {
                logoImage: logoImageFileName,
              },
              where: { id: videoId },
            },
            refetchQueries: [
              {
                query: GET_VIDEO,
                variables: { id: videoId },
              },
            ],
          })
        }
        if (logoImage && logoImage.length > 0) {
          const tempLogoFilename = logoImage[0].name.split('.')
          const logoFileExt = tempLogoFilename[tempLogoFilename.length - 1]
          const logoImageFileName = `${videoId}-${new Date().getTime()}.${logoFileExt}`
          const contentType = logoImage[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_VIDEO_SIGNED_PUT_URL,
            variables: { fileName: logoImageFileName, videoId, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getVideoSignedPutUrl.signedUrl,
            logoImage[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_VIDEO,
            variables: {
              data: {
                logoImage: logoImageFileName,
              },
              where: { id: videoId },
            },
            refetchQueries: [
              {
                query: GET_VIDEO,
                variables: { id: videoId },
              },
            ],
          })
        }

        openNotification('success', 'Video Updated Successfully')
        form.resetFields()
        setShowModal(false)
        setIsSubmit(false)
      } catch (e) {
        console.log(e)
        Sentry.captureException(e)
        handleRequestFail(e)
      }
    })
  }

  function showConfirm(videoId) {
    const { history } = props
    confirm({
      title: 'Are you sure you want to DELETE this video?',
      content: 'Upon clicking DELETE, the video will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          await client.mutate({
            mutation: DELETE_VIDEO,
            variables: { videoId },
          })
          openNotification('success', 'Video Deleted Successfully')
          setTimeout(() => {
            history.push(`/videos`)
          }, 500)
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail(e)
        }
      },
    })
  }

  function publishVideo(videoId, status) {
    const title =
      status === 'PUBLISHED'
        ? 'Are you sure you want to publish?'
        : status === 'SCHEDULED'
          ? 'Are you sure you want to schedule?'
          : 'Are you sure you want to unpublish?'
    const content =
      status === 'PUBLISHED'
        ? 'Upon clicking publish, this video will become available to front end users'
        : status === 'SCHEDULED'
          ? 'Upon clicking schedule, this video will become available to front end users'
          : 'Upon clicking unpublish, this video will become unavailable to front end users'
    const okText =
      status === 'PUBLISHED'
        ? 'PUBLISH'
        : status === 'SCHEDULED'
          ? 'SCHEDULE'
          : 'UNPUBLISH'
    const okType = status === 'UNPUBLISHED' ? 'danger' : 'primary'
    const notification =
      status === 'PUBLISHED'
        ? 'Video Published Successfully'
        : status === 'SCHEDULED'
          ? 'Video Scheduled Successfully'
          : 'Video Was Successfully Unpublished'

    confirm({
      title,
      content,
      okText,
      okType,
      async onOk() {
        try {
          await client.mutate({
            mutation: UPDATE_VIDEO,
            variables: {
              data: { status },
              where: { id: videoId },
            },
            refetchQueries: [
              {
                query: GET_VIDEO,
                variables: { id: videoId },
              },
            ],
          })
          openNotification('success', notification)
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail(e)
        }
      },
    })
  }

  return (
    <div className="show-detail-page">
      {videoLoading ? (
        <div className="callback-wrapper">
          <Spinner />
        </div>
      ) : videoError ? (
        <>{videoError.message}</>
      ) : videoData && videoData.video ? (
        <>
          <Meta title={videoData.video.name || ''} description="Video" />
          <AddVideoModal
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            handleCancel={handleCancel}
            handleCreate={handleCreate}
            title={videoData.video.name}
            description={videoData.video.description}
            videoNumber={videoData.video.videoNumber}
            isLive={videoData.video.isLive}
            videoAccess={videoData.video.videoAccess}
            liveChatAccess={videoData.video.liveChatAccess}
            showIsLIve={videoData.video.isLive}
            scheduleAt={videoData.video.scheduleAt}
            allowedContinents={videoData.video.allowedContinents}
            rating={videoData.video.rating}
            isEdit={
              videoData.video.status === 'PUBLISHED' ||
                videoData.video.status === 'SCHEDULED' ||
                videoData.video.status === 'LIVE' ||
                videoData.video.status === 'LIVE_FINISHED'
                ? true
                : false
            }
            showCreatedAt={false}
            allowedCountries={videoData?.video?.allowedCountries}
          />
          <Row gutter={24} type="flex" className="episode-title-wrapper">
            <Col span={24}>
              <PageHeader
                onBack={() => {
                  history.push(`/videos`)
                }}
                title={videoData.video.name}
                tags={
                  <Tag className={videoData.video.status}>
                    {videoData.video.status}
                  </Tag>
                }
                className="d-flex box align-center"
                extra={[
                  <Button
                    id='btn-video-edit'
                    key="1"
                    shape="circle"
                    icon="edit"
                    size="small"
                    onClick={() => setShowModal(true)}
                  />,
                  <Button
                    id='btn-video-delete'
                    key="2"
                    shape="circle"
                    size="small"
                    icon="delete"
                    type="danger"
                    ghost={true}
                    onClick={() => showConfirm(videoId)}
                  />,
                  videoData.video.isLive &&
                    videoData.video.status !== 'SCHEDULED' ? (
                    <Button
                      id='btn-video-schedule'
                      key="3"
                      type="primary"
                      size="small"
                      disabled={
                        checkStatus(videoData.video.status) || videoUploading
                      }
                      onClick={() => publishVideo(videoId, 'SCHEDULED')}
                    >
                      SCHEDULE
                    </Button>
                  ) : videoData.video.status !== 'PUBLISHED' ? (
                    <Button
                      id='btn-video-publish'
                      key="3"
                      type="primary"
                      size="small"
                      disabled={
                        checkStatus(videoData.video.status) || videoUploading
                      }
                      onClick={() => publishVideo(videoId, 'PUBLISHED')}
                    >
                      PUBLISH
                    </Button>
                  ) : (
                    <Button
                      id='btn-video-unpublish'
                      key="3"
                      type="danger"
                      disabled={videoUploading}
                      size="small"
                      onClick={() => publishVideo(videoId, 'UNPUBLISHED')}
                    >
                      UNPUBLISH
                    </Button>
                  ),
                ]}
              />
            </Col>
          </Row>
          <div className="box episode-description-div-wrapper">
            <Row
              gutter={24}
              type="flex"
              className="episode-description-wrapper"
            >
              <Col sm={24} md={6} lg={9} xl={6}>
                <div className="episode-card text-center">
                  <img
                    src={videoData.video.image}
                    alt={videoData.video.name}
                    title={videoData.video.name}
                  />
                </div>
              </Col>
              <Col sm={24} md={18} lg={15} xl={18}>
                <div className="episode-description">
                  {videoData.video.description}
                </div>
                <Modal
                  visible={openImageModal}
                  maskClosable={false}
                  title="Featured Image"
                  onCancel={() => setOpenImageModal(false)}
                  onOk={() => setOpenImageModal(false)}
                >
                  <img
                    style={{
                      width: '100%',
                      height: ' 200px',
                      objectFit: ' contain',
                    }}
                    alt={videoData.video.name}
                    src={videoData.video.featuredBannerImage}
                  />
                </Modal>
                {videoData.video.isFeatured && (
                  <div
                    style={{ marginTop: '20px' }}
                    className="featured-image-link"
                    onClick={() => setOpenImageModal(true)}
                  >
                    View Featured Image
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <Row gutter={24} type="flex" style={{ margin: '0px 20px' }}>
            <Divider />
          </Row>
          <Row
            gutter={24}
            type="flex"
            style={{ margin: '0px 20px', padding: 0 }}
            className={`episode-wrapper ${videoData.video.isLive ? 'live-segment-wrapper' : ''
              }`}
          >
            {videoData.video.isLive ? (
              <LiveVideoSegment
                videoId={videoData.video.id}
                videoTitle={videoData.video.name}
                videoStatus={videoData.video.status}
                videoImage={videoData.video.image}
                scheduleAt={videoData.video.scheduleAt}
                videoState={videoData.video.videoState}
                discussionId={videoData.video.discussionId}
                updatedAt={videoData.video.updatedAt}
              />
            ) : (
              <>
                <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
                  <TabPane tab="Content" key="CONTENT">
                    <SegmentDetails
                      videoStatus={videoData.video.status}
                      videoState={videoData.video.videoState}
                      title={videoData.video.name}
                      videoId={videoId}
                      muxAssetId={videoData.video.muxAssetId}
                      videoUrl={videoData.video.videoURL}
                      image={videoData.video.image}
                      setVideoLoading={setVideoUploading}
                      videoLoading={videoUploading}
                      openNotification={openNotification}
                      captions={videoData.video.captions}
                    />
                  </TabPane>
                  <TabPane tab="Clips" key="CLIPS">
                    <ClipListing />
                  </TabPane>
                </Tabs>
              </>
            )}
          </Row>
        </>
      ) : (
        <Page404 />
      )}
    </div>
  )
}
