import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddShowForm from './AddShowForm'

const CollectionCreateForm = Form.create({ name: 'create_show' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        isSubmit,
        onCancel,
        onCreate,
        form,
        name,
        description,
        author,
        weight,
      } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${name ? 'Edit Show Details' : 'Add New Show'}`}
          // okText={`${name ? "Save" : "Add Show"}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-show-cancel' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id={name ? 'btn-show-edit' : 'btn-show-save'}
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              {name ? 'Save' : 'Add Show'}
            </Button>,
          ]}
        >
          <AddShowForm
            name={name}
            description={description}
            author={author}
            weight={weight}
            getFieldDecorator={getFieldDecorator}
          />
        </Modal>
      )
    }
  },
)

export default class AddShowModal extends Component {
  render() {
    const {
      showModal,
      isSubmit,
      handleCancel,
      handleCreate,
      saveFormRef,
      name,
      description,
      author,
      weight,
    } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        name={name}
        description={description}
        author={author}
        weight={weight}
      // image={image}
      />
    )
  }
}
