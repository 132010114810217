import gql from 'graphql-tag'

const GET_CURRENT_USER = gql`
query currentUser{
  currentUser {
    id
    firstName
    lastName
    email
    role
    profileImage
  }
}
`

const GET_CURRENT_PERSON = gql`
query currentPerson{
  currentPerson {
    id
    firstName
    lastName
    email
    role
    profileImage
  }
}
`

const CHANGE_PASSWORD = gql`
mutation changePassword($email: String!){
  changePassword(data: { email: $email }) {
    message
  }
}
`

export { GET_CURRENT_USER, GET_CURRENT_PERSON, CHANGE_PASSWORD }
