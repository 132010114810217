import React, { useState, useContext, useRef } from 'react'
import {
  Row,
  Col,
  Modal,
  Button,
  PageHeader,
  notification,
  Tooltip,
} from 'antd'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import client from '../../apollo'
import { AppContext } from '../../AppContext'
import { fileUpload } from '../../common/fileUpload'
import {
  GET_SHOW_WITH_EPISODES,
  GET_SHOW_SIGNED_PUT_URL,
} from './graphql/Queries'
import {
  UPDATE_SHOW,
  UPDATE_SHOW_IMAGE,
  DELETE_SHOW,
} from './graphql/Mutations'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import AddShowModal from './components/AddShowModal'
import EpisodeWrapper from '../episode/components/EpisodeWrapper'
import './shows.css'
import '../episode/episode.css'
import ClipListing from './components/ClipListing'
import SeasonListing from './components/SeasonListing'
import { GET_ALL_EPISODE } from '../episode/graphql/Queries'

const confirm = Modal.confirm
export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const {
    history,
    match: {
      params: { showId },
    },
  } = props
  const [isSubmit, setSubmit] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  const {
    data: showData,
    loading: isShowsLoading,
    error: showsError,
  } = useQuery(GET_SHOW_WITH_EPISODES, {
    variables: { id: showId },
    fetchPolicy: 'network-only',
  })

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  if (showsError) {
    openNotification('error', showsError.message)
  }

  function handleRequestFail(e) {
    setSubmit(false)
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setSubmit(true)
        const queryVariables = values
        const {
          image,
          backgroundImage,
          hostImage,
          logoImage,
          portraitImage,
          showDetailImage,
          episodeDetailImage
        } = values
        const {
          match: {
            params: { showId },
          },
        } = props
        queryVariables.showId = showId
        let backgroundImageName = ''
        let hostImageName = ''
        let logoImageName = ''
        let portraitImageName = ''
        let showDetailImageName = ''
        let episodeDetailImageName = ''
        if (queryVariables.image) delete queryVariables.image
        if (queryVariables.portraitImage) delete queryVariables.portraitImage
        if (queryVariables.logoImage) delete queryVariables.logoImage
        if (queryVariables.backgroundImage)
          delete queryVariables.backgroundImage
        if (queryVariables.hostImage) delete queryVariables.hostImage
        if (queryVariables.showDetailImage) delete queryVariables.showDetailImage
        if (queryVariables.episodeDetailImage) delete queryVariables.episodeDetailImage
        if (!queryVariables.author) {
          queryVariables.author = state.currentUser.id
        }
        if (queryVariables.weight) {
          queryVariables.weight = Number(queryVariables.weight)
        }
        const updateShowResult = await client.mutate({
          mutation: UPDATE_SHOW,
          variables: queryVariables,
          refetchQueries: [
            {
              query: GET_SHOW_WITH_EPISODES,
              variables: { id: showId },
            },
          ],
        })

        if (image && image.length > 0) {
          const imageFileName = image[0].name
          const tempFilename = imageFileName.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${updateShowResult.data.updateShow.id
            }-${new Date().getTime()}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_SHOW,
            variables: {
              image: fileName,
              author: queryVariables.author,
              showId,
            },
          })
        }
        if (portraitImage && portraitImage.length > 0) {
          const tempBgImgFilenameArray = portraitImage[0].name.split('.')
          const bgFileExt =
            tempBgImgFilenameArray[tempBgImgFilenameArray.length - 1]
          const bgFileName = `${showId}-portraitImage-${new Date().getTime()}.${bgFileExt}`
          const bgContentType = portraitImage[0].type
          const getBISignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: bgFileName, contentType: bgContentType },
          })
          portraitImageName = bgFileName
          await fileUpload(
            getBISignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            portraitImage[0].originFileObj,
          )
        }
        if (backgroundImage && backgroundImage.length > 0) {
          const tempBgImgFilenameArray = backgroundImage[0].name.split('.')
          const bgFileExt =
            tempBgImgFilenameArray[tempBgImgFilenameArray.length - 1]
          const bgFileName = `${showId}-backgroundImage-${new Date().getTime()}.${bgFileExt}`
          const bgContentType = backgroundImage[0].type
          const getBISignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: bgFileName, contentType: bgContentType },
          })
          backgroundImageName = bgFileName
          await fileUpload(
            getBISignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            backgroundImage[0].originFileObj,
          )
        }
        if (hostImage && hostImage.length > 0) {
          const tempHostImgFilenameArray = hostImage[0].name.split('.')
          const hostFileExt =
            tempHostImgFilenameArray[tempHostImgFilenameArray.length - 1]
          const hostFileName = `${showId}-hostImage-${new Date().getTime()}.${hostFileExt}`
          const hostContentType = hostImage[0].type
          const getHostSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: hostFileName, contentType: hostContentType },
          })
          hostImageName = hostFileName
          await fileUpload(
            getHostSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            hostImage[0].originFileObj,
          )
        }
        if (logoImage && logoImage.length > 0) {
          const tempLogoFilenameArray = logoImage[0].name.split('.')
          const logoFileExt =
            tempLogoFilenameArray[tempLogoFilenameArray.length - 1]
          const logoFileName = `${showId}-logoImage-${new Date().getTime()}.${logoFileExt}`
          const logoContentType = logoImage[0].type
          const getLogoSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: logoFileName, contentType: logoContentType },
          })
          await fileUpload(
            getLogoSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            logoImage[0].originFileObj,
          )
          logoImageName = logoFileName
        }
        if (showDetailImage && showDetailImage.length > 0) {
          const tempShowDetailImgArray = showDetailImage[0].name.split('.')
          const showDetailFileExt =
            tempShowDetailImgArray[tempShowDetailImgArray.length - 1]
          const showDetailFileName = `${showId}-showDetailImage-${new Date().getTime()}.${showDetailFileExt}`
          const showDetailContentType = showDetailImage[0].type
          const getShowDetailSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: showDetailFileName, contentType: showDetailContentType },
          })
          await fileUpload(
            getShowDetailSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            showDetailImage[0].originFileObj,
          )
          showDetailImageName = showDetailFileName
        }
        if (episodeDetailImage && episodeDetailImage.length > 0) {
          const tempEpisodeDetailImgArray = episodeDetailImage[0].name.split('.')
          const episodeDetailFileExt =
            tempEpisodeDetailImgArray[tempEpisodeDetailImgArray.length - 1]
          const episodeDetailFileName = `${showId}-episodeDetailImage-${new Date().getTime()}.${episodeDetailFileExt}`
          const episodeDetailContentType = episodeDetailImage[0].type
          const getEpisodeDetailSignedPutUrlResult = await client.query({
            query: GET_SHOW_SIGNED_PUT_URL,
            variables: { fileName: episodeDetailFileName, contentType: episodeDetailContentType },
          })
          await fileUpload(
            getEpisodeDetailSignedPutUrlResult.data.getShowSignedPutUrl.signedUrl,
            episodeDetailImage[0].originFileObj,
          )
          episodeDetailImageName = episodeDetailFileName
        }

        if (
          (logoImage && logoImage.length > 0) ||
          (backgroundImage && backgroundImage.length > 0) ||
          (hostImage && hostImage.length > 0) ||
          (portraitImage && portraitImage.length > 0) ||
          (showDetailImage && showDetailImage.length > 0) ||
          (episodeDetailImage && episodeDetailImage.length > 0)
        ) {
          let updateVariables = {
            logoImage: logoImageName,
            backgroundImage: backgroundImageName,
            hostImage: hostImageName,
            portraitImage: portraitImageName,
            showDetailImage: showDetailImageName,
            episodeDetailImage: episodeDetailImageName,
            showId,
          }
          if (!(logoImage && logoImage.length > 0))
            delete updateVariables.logoImage
          if (!(portraitImage && portraitImage.length > 0))
            delete updateVariables.portraitImage
          if (!(backgroundImage && backgroundImage.length > 0))
            delete updateVariables.backgroundImage
          if (!(hostImage && hostImage.length > 0))
            delete updateVariables.hostImage
          if (!(showDetailImage && showDetailImage.length > 0))
            delete updateVariables.showDetailImage
          if (!(episodeDetailImage && episodeDetailImage.length > 0))
            delete updateVariables.episodeDetailImage
          await client.mutate({
            mutation: UPDATE_SHOW_IMAGE,
            variables: updateVariables,
            refetchQueries: [
              {
                query: GET_SHOW_WITH_EPISODES,
                variables: { id: showId },
                fetchPolicy: 'network-only',
              },
            ],
          })
        }
        openNotification('success', 'Show Updated Successfully')
        form.resetFields()
        setShowModal(false)
        setSubmit(false)
      } catch (error) {
        console.log(error)
        Sentry.captureException(error)
        handleRequestFail(error)
      }
    })
  }

  function showConfirm(showId) {
    const { history } = props
    confirm({
      title: 'Are you sure you want to DELETE this show?',
      content: 'Upon clicking DELETE, the show will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        client
          .mutate({
            mutation: DELETE_SHOW,
            variables: { showId },
          })
          .then((e) => {
            openNotification('success', 'Show Deleted Successfully')
            setTimeout(() => {
              history.push('/shows')
            }, 500)
          })
          .catch((error) => {
            Sentry.captureException(error)
            handleRequestFail(error)
          })
      },
    })
  }
  return (
    <div className="show-detail-page">
      {isShowsLoading ? (
        <Spinner />
      ) : showData && showData.show ? (
        <>
          <Meta title={showData.show.name || ''} description="Show" />
          <AddShowModal
            name={showData.show.name}
            description={showData.show.description}
            author={showData.show.author.id}
            weight={showData.show.weight}
            image={showData.show.image}
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            handleCancel={() => {
              setShowModal(false)
              saveFormRef.current.props.form.resetFields()
            }}
            handleCreate={handleCreate}
          />
          <Row gutter={24} type="flex" className="shows-title-wrapper">
            <Col span={24}>
              <PageHeader
                onBack={() => history.push('/shows')}
                title={showData.show.name}
                className="box page-header"
                extra={[
                  <Button
                    id='btn-show-edit'
                    key="1"
                    shape="circle"
                    icon="edit"
                    size="small"
                    onClick={() => setShowModal(true)}
                  />,
                  <Button
                    id='btn-show-delete'
                    key="2"
                    shape="circle"
                    icon="delete"
                    size="small"
                    type="danger"
                    ghost={true}
                    onClick={() => showConfirm(showId)}
                  />
                ]}
              />
            </Col>
          </Row>
          <EpisodeWrapper
            history={history}
            showId={showId}
            showData={showData}
          />
        </>
      ) : (
        <Page404 />
      )}
    </div>
  )
}
