import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddEpisodeForm from './AddEpisodeForm'

const CollectionCreateForm = Form.create({ name: 'create_episode' })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const {
        showId,
        visible,
        onCancel,
        onCreate,
        form,
        isSubmit,
        title,
        videoAccess,
        liveChatAccess,
        description,
        episodeNumber,
        scheduleAt,
        createdAt,
        isLive,
        simulateLive,
        isFeatured,
        featureBannerWeight,
        featuredBannerImage,
        showIsLIve,
        isEdit,
        handleLiveChange,
        handlesimulateLiveChange,
        episodeId,
        isAllowScheduleAccessLevel,
        accessLevelScheduleAt,
        scheduleVideoAccess,
        scheduleAudioAccess,
        handleAccessLevelChange,
        seasonEpisode,
        seasonId,
        allowedContinents,
        rating,
        allowedCountries
      } = this.props
      const { getFieldDecorator, setFieldsValue , ...rest } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${title ? 'Edit Episode Details' : 'Add New Episode'}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-episode-cancel' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id='btn-episode-save'
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              {title ? 'Save' : 'Add Episode'}
            </Button>,
          ]}
        >
          <AddEpisodeForm
            showId={showId}
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
            title={title}
            description={description}
            episodeNumber={episodeNumber}
            scheduleAt={scheduleAt}
            createdAt={createdAt}
            isLive={isLive}
            simulateLive={simulateLive}
            isFeatured={isFeatured}
            featuredBannerImage={featuredBannerImage}
            featureBannerWeight={featureBannerWeight}
            videoAccess={videoAccess}
            liveChatAccess={liveChatAccess}
            showIsLIve={showIsLIve}
            isEdit={isEdit}
            handleLiveChange={handleLiveChange}
            handlesimulateLiveChange={handlesimulateLiveChange}
            episodeId={episodeId}
            accessLevelScheduleAt={accessLevelScheduleAt}
            scheduleVideoAccess={scheduleVideoAccess}
            scheduleAudioAccess={scheduleAudioAccess}
            handleAccessLevelChange={handleAccessLevelChange}
            isAllowScheduleAccessLevel={isAllowScheduleAccessLevel}
            seasonEpisode={seasonEpisode}
            seasonId={seasonId}
            allowedContinents={allowedContinents}
            rating={rating}
            setFieldsValue={setFieldsValue}
            allowedCountries={allowedCountries}
          />
        </Modal>
      )
    }
  },
)

export default class AddEpisodeModal extends Component {
  render() {
    const {
      showId,
      showModal,
      isSubmit,
      handleCancel,
      handleCreate,
      videoAccess,
      liveChatAccess,
      saveFormRef,
      title,
      description,
      episodeNumber,
      scheduleAt,
      createdAt,
      handleIsFeaturedChanged,
      isEdit,
      isLive,
      simulateLive,
      isFeatured,
      featuredBannerImage,
      featureBannerWeight,
      showIsLIve,
      handleLiveChange,
      handlesimulateLiveChange,
      episodeId,
      isAllowScheduleAccessLevel,
      accessLevelScheduleAt,
      scheduleVideoAccess,
      scheduleAudioAccess,
      handleAccessLevelChange,
      seasonEpisode,
      seasonId,
      allowedContinents,
      rating,
      allowedCountries
    } = this.props
    return (
      <CollectionCreateForm
        showId={showId}
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        title={title}
        description={description}
        episodeNumber={episodeNumber}
        scheduleAt={scheduleAt}
        createdAt={createdAt}
        videoAccess={videoAccess}
        liveChatAccess={liveChatAccess}
        isEdit={isEdit}
        isLive={isLive}
        simulateLive={simulateLive}
        isFeatured={isFeatured}
        featuredBannerImage={featuredBannerImage}
        featureBannerWeight={featureBannerWeight}
        showIsLIve={showIsLIve}
        handleLiveChange={handleLiveChange}
        handlesimulateLiveChange={handlesimulateLiveChange}
        handleIsFeaturedChanged={handleIsFeaturedChanged}
        episodeId={episodeId}
        accessLevelScheduleAt={accessLevelScheduleAt}
        scheduleVideoAccess={scheduleVideoAccess}
        scheduleAudioAccess={scheduleAudioAccess}
        handleAccessLevelChange={handleAccessLevelChange}
        isAllowScheduleAccessLevel={isAllowScheduleAccessLevel}
        seasonEpisode={seasonEpisode}
        seasonId={seasonId}
        allowedContinents={allowedContinents}
        rating={rating}
        allowedCountries={allowedCountries}
      />
    )
  }
}
