import React, { useEffect, useState, Fragment } from 'react'
import { Row, Col, PageHeader, Tag, notification, Button, Modal, Menu, Dropdown, Icon } from 'antd'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import screenfull from 'screenfull'
import * as Sentry from '@sentry/browser'
import { chatClient } from '../../apollo'
import { GET_DISCUSSION_THREAD } from '../../components/comments/graphql/Queries'
import { SUBSCRIBE_DISCUSSION } from '../../components/comments/graphql/Subscriptions'
import { UPDATE_DISCUSSION_STATUS } from './graphql/Mutations'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import Comments from '../../components/comments/Comments'
import './livechat.css'

const { confirm } = Modal

export default function LiveChatDetailPage({ history, match: { params: { liveChatId } } }) {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [fullScreenModeSupported, setFullScreenModeSupported] = useState(screenfull.isEnabled)
  const [isAdminMessages, setIsAdminMessages] = useState(false)

  useEffect(() => {
    if (!screenfull || !screenfull.isEnabled) {
      setFullScreenModeSupported(false)
    } else {
      setFullScreenModeSupported(true)
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', onScreenChange)
    }

    return () => {
      screenfull.exit()
      // screenfull.off('change', onScreenChange)
    }
  }, [])

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsAdminMessages(false)}>All Comments</Menu.Item>
      <Menu.Item onClick={() => setIsAdminMessages(true)}>Team Comments</Menu.Item>
    </Menu>
  )

  const { data, loading: isDiscussionsLoading, error: discussionsError, fetchMore, networkStatus, subscribeToMore } = useQuery(GET_DISCUSSION_THREAD, { client: chatClient, variables: { id: liveChatId }, fetchPolicy: "network-only" })
  const { data: subscriptionData } = useSubscription(SUBSCRIBE_DISCUSSION, { variables: { id: liveChatId }, client: chatClient })
  const discussionStatus = data && data.discussionThread ?
    subscriptionData && subscriptionData.discussion && subscriptionData.discussion.node && subscriptionData.discussion.node.status ?
      subscriptionData.discussion.node.status.toUpperCase()
      : data.discussionThread.status.toUpperCase()
    : ''

  function checkStatus(status) {
    return <Tag className={status}>{status}</Tag>
  }

  function onScreenChange() {
    const bodyElement = document.body
    if (screenfull.isFullscreen && bodyElement) {
      bodyElement.classList.add("fullscreen-mode")
    } else if (!screenfull.isFullscreen && bodyElement) {
      bodyElement.classList.remove("fullscreen-mode")
    }
    setIsFullScreen(screenfull.isFullscreen)
  }

  function handleScreenControllerClick(value) {
    if (!fullScreenModeSupported) {
      console.warn('Full Screen feature is not available')
      return
    }
    const bodyElement = document.body
    if (value && bodyElement) {
      bodyElement.classList.add("fullscreen-mode")
    } else if (!value && bodyElement) {
      bodyElement.classList.remove("fullscreen-mode")
    }
    screenfull.toggle()
    const addNewComment = document.getElementById('add-new-comment')
    if (addNewComment) {
      addNewComment.focus()
    }
  }

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleRequestFail() {
    openNotification("error", "Something Went Wrong")
  }

  function handleStartStop(value) {
    let title = value ? "Are you sure you want to START this live chat?" : "Are you sure you want to END this live chat?"
    let okText = value ? "START NOW" : "END NOW"
    let okType = value ? "primary" : "danger"
    let message = value ? "Live Chat has been started" : "Live Chat has been ended"
    let status = value ? "OPEN" : "ENDED"
    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          await chatClient.mutate({
            mutation: UPDATE_DISCUSSION_STATUS,
            variables: { id: liveChatId, isLive: value, status },
            refetchQueries: [{
              query: GET_DISCUSSION_THREAD,
              variables: { id: liveChatId }
            }]
          })
          openNotification("success", message)
        }
        catch (error) {
          Sentry.captureException(error)
          handleRequestFail()
        }
      },
    })
  }

  return (
    <div className={`fullscreen ${isFullScreen ? "fullscreen-enabled" : ""}`}>
      <div className="livechat-detailpage-wrapper">
        {
          networkStatus === 1 ? <Spinner /> :
            discussionsError ? <>{discussionsError.message}</> :
              data && data.discussionThread ?
                <>
                  <Meta title={data.discussionThread.title || ''} description="Episode Live Chat" />
                  <Row gutter={24} type="flex" className="live-chat-wrapper">
                    <Col span={24}>
                      <PageHeader
                        onBack={() => history.push('/live-chat')}
                        title={data.discussionThread.title}
                        tags={checkStatus(discussionStatus)}
                        className="box page-header"
                        extra={[
                          <Dropdown key="0" overlay={menu} placement="bottomCenter" className="comments-filter-dropdown">
                            <Button>{isAdminMessages ? "Team Comments" : "All Comments"}<Icon type="down" /></Button>
                          </Dropdown>,
                          <Button key="1" shape="circle" size="small" icon={isFullScreen ? "fullscreen-exit" : "fullscreen"} onClick={() => handleScreenControllerClick(!isFullScreen)} />,
                          <Fragment key={2}>
                            {discussionStatus === 'OPEN' ?
                              <Button key="1" type="danger" size="small" onClick={() => handleStartStop(false)}>END CHAT</Button>
                              :
                              discussionStatus !== 'ENDED' &&
                              <Button key="1" type="primary" size="small" onClick={() => handleStartStop(true)}>START CHAT</Button>
                            }
                          </Fragment>
                        ]}
                      />
                    </Col>
                  </Row>
                  <Comments isFullscreen={screenfull.isFullscreen} isDiscussion={true} isAdminMessages={isAdminMessages} subscribeToMore={subscribeToMore} discussionId={liveChatId} discussionThreadMessages={data.discussionThread.messages} fetchMore={fetchMore} isDiscussionsLoading={isDiscussionsLoading} isLive={discussionStatus === 'OPEN'} />
                </>
                :
                <Page404 />
        }
      </div>
    </div>
  )
}
