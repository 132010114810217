import React from 'react'
import { Col, Typography } from 'antd'

const { Text } = Typography

function EpisodeCard(props) {
  const { image, name, description } = props
  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
      >
        <img src={`${image}?w=300&h=250`} alt={name} title={name} className="tile-card-premiere-img-wrapper" />
      </Col>
      <Col xs={24} sm={24} md={16} lg={18} xl={18} className="tile-card-premiere-text-wrapper">
        <h4>{name}</h4>
        <p className="line-height-1">{description}</p>
      </Col>
    </>
  )
}

export default EpisodeCard