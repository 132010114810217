import React, { Component } from 'react'
import { Col } from 'antd'
import { NavLink } from 'react-router-dom'
import { truncateName } from '../../../common/utility'
export default class PodcastCard extends Component {
  render() {
    const { id, coverImage, name } = this.props
    return (
      <Col xs={24} sm={24} md={8} lg={4} xl={4} className="text-center podcast-card">
        <div className="podcast-card-wrapper">
          <NavLink to={`/podcasts/${id}`} className="podcast-card">
            <img
              src={`${coverImage}?w=300&h=300`}
              alt={name}
              title={name}
            />
            <p className="podcast-title">{truncateName(name)}</p>
          </NavLink>
        </div>
      </Col>
    )
  }
}
