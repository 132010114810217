import React, { Component } from 'react'
import { Col, Tag, Button, Modal, notification } from 'antd'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import client from '../../../apollo'
import { DELETE_SEASON_EPISODES } from '../graphql/Mutations'
import * as Sentry from '@sentry/browser'
import { GET_SEASON_WITH_EPISODES } from '../graphql/Queries'
import { withRouter } from 'react-router-dom'
const confirm = Modal.confirm

class EpisodeCard extends Component {
  openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  handleRequestFail() {
    this.openNotification('error', 'Something Went Wrong')
  }

  showConfirm(id, seasonId, title, seasonName) {
    confirm({
      title: `Are you sure you want to remove "${title}" from "${seasonName}"`,
      okText: 'Remove',
      okType: 'danger',
      onOk: async () => {
        try {
          await client.mutate({
            mutation: DELETE_SEASON_EPISODES,
            variables: { id: id },
            refetchQueries: [
              {
                query: GET_SEASON_WITH_EPISODES,
                variables: { id: seasonId, first: 10 },
                fetchPolicy: 'network-only',
              },
            ],
          })
          setTimeout(() => {
            this.props.updateEpisodeList()
            this.openNotification(
              'success',
              'Season Episode Deleted Successfully',
            )
          }, 500)
        } catch (error) {
          Sentry.captureException(error)
          this.handleRequestFail()
        }
      },
    })
  }

  render() {
    const {
      match: {
        params: { seasonId },
      },
    } = this.props
    const {
      seasonName,
      id,      
      episode: { title, image, status, createdAt },
    } = this.props

    return (
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={8}
        xl={8}
        xxl={6}
        className="episode-card-col-wrapper"
      >
        <div className="season-card-wrapper">
          <Tag className={status}>{status}</Tag>
          <div className="season-episode-delete-btn">
            <Button
              id={`btn-season-episode-${id}-delete`}
              key="2"
              size="default"
              icon="delete"
              type="danger"
              onClick={() => {
                this.showConfirm(id, seasonId, title, seasonName)
              }}
            />
          </div>
          <div className="episode-card">
            <div className="text-center d-flex">
              <img src={`${image}?w=300&h=250`} alt={title} title={title} />
            </div>
            <div className="episode-details">
              <p className="episode-title">{title}</p>
              <p className="episode-date">
                {moment(createdAt).format('MMMM Do, YYYY')}
              </p>
            </div>
          </div>
        </div>
      </Col>
    )
  }
}

export default withRouter(EpisodeCard)
