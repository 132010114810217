import React, { useEffect, useState, Fragment } from 'react'
import { Button } from 'antd'
import AddModuleTypeModal from './AddModuleTypeModal'
import ClipCarousel from '../components/clip-carousel/ClipCarousel'
import { MODULE_TYPES_CONST } from '../../../common/constants'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'
import VideoCarousel from './video-carousel/VideoCarousel'
import VideoPremiereCarousel from './video-premiere/VideoPremiereCarousel'
import EpisodeCarousel from './episode-carousel/EpisodeCarousel'
import ShowCarousel from './show-carousel/ShowCarousel'
import EpisodePremiereCarousel from './episode-permiere/EpisodePremiere'
import ShowPremiereCarousel from './show-premiere/ShowPremiere'
import Programmatic from './programmatic/Programmatic'
import FreeFormHighlight from './freeform-highlight/FreeFormHighlight'
import FreeFormHeadLiner from './freeform-headliner/FreeFormHeadLiner'
import PodcastCarousel from './podcast-carousel/PodcastCarousel'
import PodcastPremiereCarousel from './podcast-premiere/PodcastPremiere'
import PodcastEpisodeCarousel from './podcastepisode-carousel/PodcastEpisodeCarousel'
import PodcastEpisodePremiereCarousel from './podcastepisode-premiere/PodcastEpisodePremiere'
import ContinueListening from './continue-listening/ContinueListening'

function ModuleTypesWrapper(props) {
  const [modulesList, setModuleList] = useState([])
  const [showMouduleTypeModal, setShowMouduleTypeModal] = useState(false)
  const [nextOrder, setNextOrder] = useState(1)

  useEffect(() => {
    // console.log('--ModuleTypesWrapper--props--: ', props)
    setModuleList(props.modules)
  }, [props.modules])

  useEffect(() => {
    setNextOrder(props.nextOrder || 1)
  }, [props.nextOrder])

  function onAddModule(formData, moduleTypeFormInstance) {
    if (formData && formData.moduleType) {
      moduleTypeFormInstance.resetFields()
      setShowMouduleTypeModal(false)
      const currentOrder = nextOrder
      let newModule = {
        id: uuidv4(),
        type: formData.moduleType,
        order: currentOrder,
      }
      setNextOrder(currentOrder + 1)
      setModuleList([...cloneDeep(modulesList), newModule])
    }
  }

  function updateFields(fieldsData) {
    props.form.setFieldsValue({ ...fieldsData })
  }

  return (
    <Fragment>
      {modulesList.length > 0 && <section>
        {modulesList.map((moduleObj, index) => {
          const moduleType = moduleObj.type
          switch (moduleType) {
            case MODULE_TYPES_CONST.CLIP_CAROUSEL:
              return <ClipCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                clipModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.VIDEO_CAROUSEL:
              return <VideoCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                videoModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.VIDEO_PREMIERE:
              return <VideoPremiereCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                videoPremiereModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.EPISODE_CAROUSEL:
              return <EpisodeCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                episodeModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.SHOW_CAROUSEL:
              return <ShowCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                showModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.EPISODE_PREMIERE:
              return <EpisodePremiereCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                episodePremiereModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.SHOW_PREMIERE:
              return <ShowPremiereCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                showPremiereModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.CONTINUE_WATCHING:
              return <Programmatic key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                programModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT:
              return <FreeFormHighlight key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                freeFormModuleData={moduleObj}
                indexId={index}
                modularPageId={props.modularPageId}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.FREEFORM_HEADLINER:
              return <FreeFormHeadLiner key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                freeFormModuleData={moduleObj}
                indexId={index}
                modularPageId={props.modularPageId}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.PODCAST_CAROUSEL:
              return <PodcastCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                podcastModuleData={moduleObj}
                indexId={index}
                modularPageId={props.modularPageId}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.PODCAST_PREMIERE:
              return <PodcastPremiereCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                podcastPremiereModuleData={moduleObj}
                indexId={index}
                modularPageId={props.modularPageId}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.PODCAST_EPISODE_CAROUSEL:
              return <PodcastEpisodeCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                podcastEpisodeModuleData={moduleObj}
                indexId={index}
                modularPageId={props.modularPageId}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.PODCAST_EPISODE_PREMIERE:
              return <PodcastEpisodePremiereCarousel key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                podcastEpisodePremiereModuleData={moduleObj}
                indexId={index}
                modularPageId={props.modularPageId}
                updateFields={updateFields}
              />
            case MODULE_TYPES_CONST.CONTINUE_LISTENING:
              return <ContinueListening key={moduleObj.id} {...props}
                setModuleList={setModuleList}
                modulesList={modulesList}
                programModuleData={moduleObj}
                indexId={index}
                updateFields={updateFields}
              />
            default:
              return null
          }
        })}
      </section>}
      {props.modularPageId && <section>
        <div className='d-flex justify-content-center align-items-center'>
          <div className='m-2'>
            <Button className='justify-content-center align-items-center' icon='plus'
              onClick={() => setShowMouduleTypeModal(true)}>
              Add Module
            </Button>
          </div>
        </div>
      </section>}
      {showMouduleTypeModal && <AddModuleTypeModal
        showMouduleTypeModal={showMouduleTypeModal}
        handleCancel={() => setShowMouduleTypeModal(false)}
        handleCreate={onAddModule}
      />}
    </Fragment>
  )
}

export default ModuleTypesWrapper