import { isEmpty, cloneDeep, get } from 'lodash'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import { GET_PREMIER_IMG_PUT_URL } from '../graphql/Mutations'
import { MODULE_TYPES_CONST } from '../../../common/constants'

// CLIP_CAROUSEL Module
function getModifiedClipCarouselData(clipCarousel) {
  let clipCarouselModules = {}
  if (!isEmpty(clipCarousel)) {
    clipCarouselModules.create = []
    clipCarouselModules.update = []
    for (const property in clipCarousel) {
      if (clipCarousel[property]) {
        if (property.indexOf('-') > 0) {
          if (clipCarousel[property].title && clipCarousel[property].clips && clipCarousel[property].clips.length > 0) {
            const newClips = clipCarousel[property].clips.filter((clipObj) => !clipObj.carouselClipId)
            if (newClips && newClips.length > 0) {
              const clipIds = newClips.map((clipObj) => { return { 'clipId': clipObj.id, 'order': clipObj.carouselClipOrder } })
              if (clipIds && clipIds.length > 0) {
                clipCarouselModules.create.push({
                  title: clipCarousel[property].title,
                  order: clipCarousel[property].order,
                  isPublished: clipCarousel[property].isPublished,
                  carouselClips: clipIds
                })
              }
            }
          }
        } else {
          if (clipCarousel[property].title) {
            const dataObj = {
              data: { title: clipCarousel[property].title, isPublished: clipCarousel[property].isPublished, order: clipCarousel[property].order },
              where: { id: property }
            }
            const oldClips = clipCarousel[property].clips.filter((clipObj) => clipObj.carouselClipId)
            const newClips = clipCarousel[property].clips.filter((clipObj) => !clipObj.carouselClipId)
            if (newClips && newClips.length > 0) {
              const clipIds = newClips.map((clipObj) => { return { 'clipId': clipObj.id, 'order': clipObj.carouselClipOrder } })
              if (clipIds && clipIds.length > 0) {
                dataObj.data.carouselClips = { create: clipIds }
              }
            }
            if (oldClips && oldClips.length > 0) {
              const clipIds = oldClips.map((clipObj) => { return { where: { id: clipObj.carouselClipId }, data: { order: clipObj.carouselClipOrder } } })
              if (dataObj.data.carouselClips) {
                dataObj.data.carouselClips.update = clipIds
              } else {
                dataObj.data.carouselClips = { update: clipIds }
              }
            }
            clipCarouselModules.update.push(dataObj)
          }
        }
      }
    }
  }
  let cloneClipCarouselModules = cloneDeep(clipCarouselModules)
  if (cloneClipCarouselModules.create.length === 0) {
    delete cloneClipCarouselModules['create']
  }
  if (cloneClipCarouselModules.update.length === 0) {
    delete cloneClipCarouselModules['update']
  }
  return cloneClipCarouselModules
}

// VIDEO_CAROUSEL Module
function getModifiedVideoCarouselData(videoCarousel) {
  let videoCarouselModules = {}
  if (!isEmpty(videoCarousel)) {
    videoCarouselModules.create = []
    videoCarouselModules.update = []
    for (const property in videoCarousel) {
      if (videoCarousel[property]) {
        if (property.indexOf('-') > 0) {
          if (videoCarousel[property].title && videoCarousel[property].videos && videoCarousel[property].videos.length > 0) {
            const newVideos = videoCarousel[property].videos.filter((videoObj) => !videoObj.carouselVideoId)
            if (newVideos && newVideos.length > 0) {
              const videoIds = newVideos.map((videoObj) => { return { 'videoId': videoObj.id, 'order': videoObj.carouselVideoOrder } })
              if (videoIds && videoIds.length > 0) {
                videoCarouselModules.create.push({
                  title: videoCarousel[property].title,
                  order: videoCarousel[property].order,
                  isPublished: videoCarousel[property].isPublished,
                  carouselVideos: videoIds
                })
              }
            }
          }
        } else {
          if (videoCarousel[property].title) {
            const dataObj = {
              data: { title: videoCarousel[property].title, isPublished: videoCarousel[property].isPublished, order: videoCarousel[property].order },
              where: { id: property }
            }
            const oldVideos = videoCarousel[property].videos.filter((videoObj) => videoObj.carouselVideoId)
            const newVideos = videoCarousel[property].videos.filter((videoObj) => !videoObj.carouselVideoId)
            if (newVideos && newVideos.length > 0) {
              const videoIds = newVideos.map((videoObj) => { return { 'videoId': videoObj.id, 'order': videoObj.carouselVideoOrder } })
              if (videoIds && videoIds.length > 0) {
                dataObj.data.carouselVideos = { create: videoIds }
              }
            }
            if (oldVideos && oldVideos.length > 0) {
              const videoIds = oldVideos.map((videoObj) => { return { where: { id: videoObj.carouselVideoId }, data: { order: videoObj.carouselVideoOrder } } })
              if (dataObj.data.carouselVideos) {
                dataObj.data.carouselVideos.update = videoIds
              } else {
                dataObj.data.carouselVideos = { update: videoIds }
              }
            }
            videoCarouselModules.update.push(dataObj)
          }
        }
      }
    }
  }
  let cloneVideoCarouselModules = cloneDeep(videoCarouselModules)
  if (cloneVideoCarouselModules.create.length === 0) {
    delete cloneVideoCarouselModules['create']
  }
  if (cloneVideoCarouselModules.update.length === 0) {
    delete cloneVideoCarouselModules['update']
  }
  return cloneVideoCarouselModules
}

// EPISODE_CAROUSEL Module
function getModifiedEpisodeCarouselData(episodeCarousel) {
  let episodeCarouselModules = {}
  if (!isEmpty(episodeCarousel)) {
    episodeCarouselModules.create = []
    episodeCarouselModules.update = []
    for (const property in episodeCarousel) {
      if (episodeCarousel[property]) {
        if (property.indexOf('-') > 0) {
          if (episodeCarousel[property].title && episodeCarousel[property].episodes && episodeCarousel[property].episodes.length > 0) {
            const newEpisodes = episodeCarousel[property].episodes.filter((episodeObj) => !episodeObj.carouselEpisodeId)
            if (newEpisodes && newEpisodes.length > 0) {
              const episodeIds = newEpisodes.map((episodeObj) => { return { 'episodeId': episodeObj.id, 'order': episodeObj.carouselEpisodeOrder } })
              if (episodeIds && episodeIds.length > 0) {
                episodeCarouselModules.create.push({
                  title: episodeCarousel[property].title,
                  order: episodeCarousel[property].order,
                  isPublished: episodeCarousel[property].isPublished,
                  carouselEpisodes: episodeIds
                })
              }
            }
          }
        } else {
          if (episodeCarousel[property].title) {
            const dataObj = {
              data: { title: episodeCarousel[property].title, isPublished: episodeCarousel[property].isPublished, order: episodeCarousel[property].order },
              where: { id: property }
            }
            const oldEpisodes = episodeCarousel[property].episodes.filter((episodeObj) => episodeObj.carouselEpisodeId)
            const newEpisodes = episodeCarousel[property].episodes.filter((episodeObj) => !episodeObj.carouselEpisodeId)
            if (newEpisodes && newEpisodes.length > 0) {
              const episodeIds = newEpisodes.map((episodeObj) => { return { 'episodeId': episodeObj.id, 'order': episodeObj.carouselEpisodeOrder } })
              if (episodeIds && episodeIds.length > 0) {
                dataObj.data.carouselEpisodes = { create: episodeIds }
              }
            }
            if (oldEpisodes && oldEpisodes.length > 0) {
              const episodeIds = oldEpisodes.map((episodeObj) => { return { where: { id: episodeObj.carouselEpisodeId }, data: { order: episodeObj.carouselEpisodeOrder } } })
              if (dataObj.data.carouselEpisodes) {
                dataObj.data.carouselEpisodes.update = episodeIds
              } else {
                dataObj.data.carouselEpisodes = { update: episodeIds }
              }
            }
            episodeCarouselModules.update.push(dataObj)
          }
        }
      }
    }
  }
  let cloneEpisodeCarouselModules = cloneDeep(episodeCarouselModules)
  if (cloneEpisodeCarouselModules.create.length === 0) {
    delete cloneEpisodeCarouselModules['create']
  }
  if (cloneEpisodeCarouselModules.update.length === 0) {
    delete cloneEpisodeCarouselModules['update']
  }
  return cloneEpisodeCarouselModules
}

// SHOW_CAROUSEL Module
function getModifiedShowCarouselData(showCarousel) {
  let showCarouselModules = {}
  if (!isEmpty(showCarousel)) {
    showCarouselModules.create = []
    showCarouselModules.update = []
    for (const property in showCarousel) {
      if (showCarousel[property]) {
        if (property.indexOf('-') > 0) {
          if (showCarousel[property].title && showCarousel[property].shows && showCarousel[property].shows.length > 0) {
            const newShows = showCarousel[property].shows.filter((showObj) => !showObj.carouselShowId)
            if (newShows && newShows.length > 0) {
              const showIds = newShows.map((showObj) => { return { 'showId': showObj.id, 'order': showObj.carouselShowOrder } })
              if (showIds && showIds.length > 0) {
                showCarouselModules.create.push({
                  title: showCarousel[property].title,
                  order: showCarousel[property].order,
                  isPublished: showCarousel[property].isPublished,
                  orientation: showCarousel[property].orientation,
                  carouselShows: showIds
                })
              }
            }
          }
        } else {
          if (showCarousel[property].title) {
            const dataObj = {
              data: { title: showCarousel[property].title, isPublished: showCarousel[property].isPublished, orientation: showCarousel[property].orientation, order: showCarousel[property].order },
              where: { id: property }
            }
            const oldShows = showCarousel[property].shows.filter((showObj) => showObj.carouselShowId)
            const newShows = showCarousel[property].shows.filter((showObj) => !showObj.carouselShowId)
            if (newShows && newShows.length > 0) {
              const showIds = newShows.map((showObj) => { return { 'showId': showObj.id, 'order': showObj.carouselShowOrder } })
              if (showIds && showIds.length > 0) {
                dataObj.data.carouselShows = { create: showIds }
              }
            }
            if (oldShows && oldShows.length > 0) {
              const showIds = oldShows.map((showObj) => { return { where: { id: showObj.carouselShowId }, data: { order: showObj.carouselShowOrder } } })
              if (dataObj.data.carouselShows) {
                dataObj.data.carouselShows.update = showIds
              } else {
                dataObj.data.carouselShows = { update: showIds }
              }
            }
            showCarouselModules.update.push(dataObj)
          }
        }
      }
    }
  }
  let cloneShowCarouselModules = cloneDeep(showCarouselModules)
  if (cloneShowCarouselModules.create.length === 0) {
    delete cloneShowCarouselModules['create']
  }
  if (cloneShowCarouselModules.update.length === 0) {
    delete cloneShowCarouselModules['update']
  }
  return cloneShowCarouselModules
}

// CONTINUE_WATCHING Module
function getModifiedProgrammaticCarouselData(programmaticCarousel) {
  let continueWatchingModules = {}
  if (!isEmpty(programmaticCarousel)) {
    continueWatchingModules.create = []
    continueWatchingModules.update = []
    for (const property in programmaticCarousel) {
      if (programmaticCarousel[property]) {
        if (property.indexOf('-') > 0) {
          if (programmaticCarousel[property].title) {
            continueWatchingModules.create.push({
              title: programmaticCarousel[property].title,
              order: programmaticCarousel[property].order,
              isPublished: programmaticCarousel[property].isPublished,
            })
          }
        } else {
          if (programmaticCarousel[property].title) {
            const dataObj = {
              data: { title: programmaticCarousel[property].title, isPublished: programmaticCarousel[property].isPublished, order: programmaticCarousel[property].order },
              where: { id: property }
            }
            continueWatchingModules.update.push(dataObj)
          }
        }
      }
    }
  }
  let cloneProgrammaticCarouselModules = cloneDeep(continueWatchingModules)
  if (cloneProgrammaticCarouselModules.create.length === 0) {
    delete cloneProgrammaticCarouselModules['create']
  }
  if (cloneProgrammaticCarouselModules.update.length === 0) {
    delete cloneProgrammaticCarouselModules['update']
  }
  return cloneProgrammaticCarouselModules
}

// FREEFORM_HIGHLIGHT Module
async function getModifiedFreeFormModuleData(freeFormModuleData, moduleId) {
  const keys = Object.keys(freeFormModuleData)
  const promise = await keys.map(async property => {
    let freeFormModule = {}
    if (!isEmpty(freeFormModuleData)) {
      freeFormModule.create = {}
      freeFormModule.update = {}
    }
    if (freeFormModuleData[property]) {
      let dataObj = {
        title: freeFormModuleData[property].title,
        CTAText: freeFormModuleData[property].CTAText,
        description: freeFormModuleData[property].description,
        ...(freeFormModuleData[property].routing !== '' && { routing: freeFormModuleData[property].routing }),
        order: freeFormModuleData[property].order,
        isPublished: freeFormModuleData[property].isPublished,
      }
      if (freeFormModuleData[property].routing === "REFERENCE") {
        dataObj.referenceId = freeFormModuleData[property].referenceId
        dataObj.referenceType = freeFormModuleData[property].referenceType
        dataObj.link = null
      } else {
        dataObj.link = freeFormModuleData[property].link
        dataObj.referenceId = null
        dataObj.referenceType = null
      }

      if (property.indexOf('-') > 0) {
        if (freeFormModuleData[property].image && freeFormModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(freeFormModuleData[property].image, moduleId, MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT)
          if (signVerifiedImageFileName) {
            dataObj.image = signVerifiedImageFileName
          }
        }
        if (freeFormModuleData[property].title) {
          freeFormModule.create = dataObj
        }
      } else {
        if (freeFormModuleData[property].title) {
          if (freeFormModuleData[property].image && freeFormModuleData[property].image.length > 0) {
            const signVerifiedImageFileName = await getPremierImageSignPutUrl(freeFormModuleData[property].image, moduleId, MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT)
            if (signVerifiedImageFileName) {
              dataObj.image = signVerifiedImageFileName
            }
          } else {
            if (freeFormModuleData[property].removeImage) {
              dataObj.image = ''
            }
          }
          const updateDataObj = {
            data: dataObj,
            where: { id: property }
          }
          freeFormModule.update = updateDataObj
        }
      }
    }
    return freeFormModule
  })
  return await Promise.all(promise)
}

// FREEFORM_HEADLINER Module
async function getModifiedFreeFormHeadLinerModuleData(freeFormModuleData) {
  let freeFormHeadLinerModule = {}
  if (!isEmpty(freeFormModuleData)) {
    freeFormHeadLinerModule.create = []
    freeFormHeadLinerModule.update = []
    for (const property in freeFormModuleData) {
      if (freeFormModuleData[property]) {
        let dataObj = {
          title: freeFormModuleData[property].title,
          CTAText: freeFormModuleData[property].CTAText,
          description: freeFormModuleData[property].description,
          ...(freeFormModuleData[property].routing !== '' && { routing: freeFormModuleData[property].routing }),
          order: freeFormModuleData[property].order,
          isPublished: freeFormModuleData[property].isPublished,
        }
        if (freeFormModuleData[property].routing === "REFERENCE") {
          dataObj.referenceId = freeFormModuleData[property].referenceId
          dataObj.referenceType = freeFormModuleData[property].referenceType
          dataObj.link = null
        } else {
          dataObj.link = freeFormModuleData[property].link
          dataObj.referenceId = null
          dataObj.referenceType = null
        }

        if (property.indexOf('-') > 0) {
          if (freeFormModuleData[property].title) {
            freeFormHeadLinerModule.create.push(dataObj)

          }
        } else {
          if (freeFormModuleData[property].title) {
            const updateDataObj = {
              data: dataObj,
              where: { id: property }
            }
            freeFormHeadLinerModule.update.push(updateDataObj)
          }
        }
      }
    }
  }
  let cloneEpisodeCarouselModules = cloneDeep(freeFormHeadLinerModule)
  if (cloneEpisodeCarouselModules.create.length === 0) {
    delete cloneEpisodeCarouselModules['create']
  }
  if (cloneEpisodeCarouselModules.update.length === 0) {
    delete cloneEpisodeCarouselModules['update']
  }
  return cloneEpisodeCarouselModules
}

/*  START: PREMIER MODULES */
async function getPremierImageSignPutUrl(image, moduleId, moduleType) {
  const tempPremierImageArray = image[0].name.split('.')
  const premierImageFileExt = tempPremierImageArray[tempPremierImageArray.length - 1]
  const premierImageFileName = `${moduleId}-${moduleType}-${new Date().getTime()}.${premierImageFileExt}`
  try {
    const getSignedPutUrlResult = await client.query({
      query: GET_PREMIER_IMG_PUT_URL,
      variables: { fileName: premierImageFileName },
    })
    const signImgUrl = get(getSignedPutUrlResult, 'data.getPremiereImageSignedPutUrl.signedUrl')
    const getImgUrl = get(getSignedPutUrlResult, 'data.getPremiereImageSignedPutUrl.getUrl')
    if (signImgUrl && getImgUrl) {
      await fileUpload(
        getSignedPutUrlResult.data.getPremiereImageSignedPutUrl.signedUrl,
        image[0].originFileObj,
      )
      return premierImageFileName
    }
    return null
  } catch (error) {
    return null
  }
}

// VIDEO_PREMIER Module
async function getModifiedVideoPremierModuleData(videoPremierModuleData, moduleId) {
  const keys = Object.keys(videoPremierModuleData)
  const promise = await keys.map(async property => {
    let videoPremierModule = {}
    if (!isEmpty(videoPremierModuleData)) {
      videoPremierModule.create = {}
      videoPremierModule.update = {}
    }
    if (videoPremierModuleData[property]) {
      if (property.indexOf('-') > 0) {
        let dataObj = {
          order: videoPremierModuleData[property].order,
          type: MODULE_TYPES_CONST.VIDEO_PREMIERE,
          referenceId: videoPremierModuleData[property].video.id,
          isPublished: videoPremierModuleData[property].isPublished,
        }
        if (videoPremierModuleData[property].image && videoPremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(videoPremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.VIDEO_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.premiereImage = signVerifiedImageFileName
          }
        }
        videoPremierModule.create = dataObj
      } else {
        let dataObj = {
          data: {
            type: MODULE_TYPES_CONST.VIDEO_PREMIERE,
            referenceId: videoPremierModuleData[property].video.id,
            isPublished: videoPremierModuleData[property].isPublished,
            order: videoPremierModuleData[property].order
          },
          where: { id: property }
        }
        if (videoPremierModuleData[property].image && videoPremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(videoPremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.VIDEO_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.data.premiereImage = signVerifiedImageFileName
          }
        } else {
          if (videoPremierModuleData[property].removeImage) {
            dataObj.data.premiereImage = ''
          }
        }
        videoPremierModule.update = dataObj
      }
    }
    return videoPremierModule
  })

  return await Promise.all(promise)
}

// SHOW_PREMIERE Module
async function getModifiedShowPremierModuleData(showPremierModuleData, moduleId) {
  const keys = Object.keys(showPremierModuleData)
  const promise = await keys.map(async property => {
    let showPremierModule = {}
    if (!isEmpty(showPremierModuleData)) {
      showPremierModule.create = {}
      showPremierModule.update = {}
    }

    if (showPremierModuleData[property]) {
      if (property.indexOf('-') > 0) {
        let dataObj = {
          order: showPremierModuleData[property].order,
          type: MODULE_TYPES_CONST.SHOW_PREMIERE,
          referenceId: showPremierModuleData[property].show.id,
          isPublished: showPremierModuleData[property].isPublished,
        }
        if (showPremierModuleData[property].image && showPremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(showPremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.SHOW_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.premiereImage = signVerifiedImageFileName
          }
        }
        showPremierModule.create = dataObj
      } else {
        let dataObj = {
          data: {
            type: MODULE_TYPES_CONST.SHOW_PREMIERE,
            referenceId: showPremierModuleData[property].show.id,
            isPublished: showPremierModuleData[property].isPublished,
            order: showPremierModuleData[property].order
          },
          where: { id: property }
        }
        if (showPremierModuleData[property].image && showPremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(showPremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.SHOW_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.data.premiereImage = signVerifiedImageFileName
          }
        } else {
          if (showPremierModuleData[property].removeImage) {
            dataObj.data.premiereImage = ''
          }
        }
        showPremierModule.update = dataObj
      }
    }
    return showPremierModule
  })
  return await Promise.all(promise)
}

// EPISODE_PREMIERE Module
async function getModifiedEpisodePremierModuleData(episodePremierModuleData, moduleId) {
  const keys = Object.keys(episodePremierModuleData)
  const promise = await keys.map(async property => {
    let episodePremierModule = {}
    if (!isEmpty(episodePremierModuleData)) {
      episodePremierModule.create = {}
      episodePremierModule.update = {}
    }
    if (episodePremierModuleData[property]) {
      if (property.indexOf('-') > 0) {
        let dataObj = {
          type: MODULE_TYPES_CONST.EPISODE_PREMIERE,
          order: episodePremierModuleData[property].order,
          referenceId: episodePremierModuleData[property].episode.id,
          isPublished: episodePremierModuleData[property].isPublished,
        }
        if (episodePremierModuleData[property].image && episodePremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(episodePremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.SHOW_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.premiereImage = signVerifiedImageFileName
          }
        }
        episodePremierModule.create = dataObj
      } else {
        let dataObj = {
          data: {
            type: MODULE_TYPES_CONST.EPISODE_PREMIERE,
            referenceId: episodePremierModuleData[property].episode.id,
            isPublished: episodePremierModuleData[property].isPublished,
            order: episodePremierModuleData[property].order
          },
          where: { id: property }
        }
        if (episodePremierModuleData[property].image && episodePremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(episodePremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.SHOW_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.data.premiereImage = signVerifiedImageFileName
          }
        } else {
          if (episodePremierModuleData[property].removeImage) {
            dataObj.data.premiereImage = ''
          }
        }
        episodePremierModule.update = dataObj
      }
    }
    return episodePremierModule
  })

  return await Promise.all(promise)
}

// PODCAST_CAROUSEL Module
function getModifiedPodcastCarouselData(podcastCarousel) {
  let podcastCarouselModules = {}
  if (!isEmpty(podcastCarousel)) {
    podcastCarouselModules.create = []
    podcastCarouselModules.update = []
    for (const property in podcastCarousel) {
      if (podcastCarousel[property]) {
        if (property.indexOf('-') > 0) {
          if (podcastCarousel[property].title && podcastCarousel[property].podcasts && podcastCarousel[property].podcasts.length > 0) {
            const newPodcasts = podcastCarousel[property].podcasts.filter((podcastObj) => !podcastObj.carouselShowId)
            if (newPodcasts && newPodcasts.length > 0) {
              const podcastIds = newPodcasts.map((podcastObj) => { return { 'podcastId': podcastObj.id, 'order': podcastObj.carouselPodcastOrder } })
              if (podcastIds && podcastIds.length > 0) {
                podcastCarouselModules.create.push({
                  title: podcastCarousel[property].title,
                  order: podcastCarousel[property].order,
                  isPublished: podcastCarousel[property].isPublished,
                  carouselPodcasts: podcastIds
                })
              }
            }
          }
        } else {
          if (podcastCarousel[property].title) {
            const dataObj = {
              data: { title: podcastCarousel[property].title, isPublished: podcastCarousel[property].isPublished, order: podcastCarousel[property].order },
              where: { id: property }
            }
            const oldPodcasts = podcastCarousel[property].podcasts.filter((podcastObj) => podcastObj.carouselPodcastId)
            const newPodcasts = podcastCarousel[property].podcasts.filter((podcastObj) => !podcastObj.carouselPodcastId)
            if (newPodcasts && newPodcasts.length > 0) {
              const podcastIds = newPodcasts.map((podcastObj) => { return { 'podcastId': podcastObj.id, 'order': podcastObj.carouselPodcastOrder } })
              if (podcastIds && podcastIds.length > 0) {
                dataObj.data.carouselPodcasts = { create: podcastIds }
              }
            }
            if (oldPodcasts && oldPodcasts.length > 0) {
              const podcastIds = oldPodcasts.map((podcastObj) => { return { where: { id: podcastObj.carouselPodcastId }, data: { order: podcastObj.carouselPodcastOrder } } })
              if (dataObj.data.carouselPodcasts) {
                dataObj.data.carouselPodcasts.update = podcastIds
              } else {
                dataObj.data.carouselPodcasts = { update: podcastIds }
              }
            }
            podcastCarouselModules.update.push(dataObj)
          }
        }
      }
    }
  }
  let clonePodcastCarouselModules = cloneDeep(podcastCarouselModules)
  if (clonePodcastCarouselModules.create.length === 0) {
    delete clonePodcastCarouselModules['create']
  }
  if (clonePodcastCarouselModules.update.length === 0) {
    delete clonePodcastCarouselModules['update']
  }
  return clonePodcastCarouselModules
}

// PODCAST_PREMIERE Module
async function getModifiedPodcastPremierModuleData(podcastPremierModuleData, moduleId) {
  const keys = Object.keys(podcastPremierModuleData)
  const promise = await keys.map(async property => {
    let podcastPremierModule = {}
    if (!isEmpty(podcastPremierModuleData)) {
      podcastPremierModule.create = {}
      podcastPremierModule.update = {}
    }

    if (podcastPremierModuleData[property]) {
      if (property.indexOf('-') > 0) {
        let dataObj = {
          order: podcastPremierModuleData[property].order,
          type: MODULE_TYPES_CONST.PODCAST_PREMIERE,
          referenceId: podcastPremierModuleData[property].podcast.id,
          isPublished: podcastPremierModuleData[property].isPublished,
        }
        if (podcastPremierModuleData[property].image && podcastPremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(podcastPremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.PODCAST_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.premiereImage = signVerifiedImageFileName
          }
        }
        podcastPremierModule.create = dataObj
      } else {
        let dataObj = {
          data: {
            type: MODULE_TYPES_CONST.PODCAST_PREMIERE,
            referenceId: podcastPremierModuleData[property].podcast.id,
            isPublished: podcastPremierModuleData[property].isPublished,
            order: podcastPremierModuleData[property].order
          },
          where: { id: property }
        }
        if (podcastPremierModuleData[property].image && podcastPremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(podcastPremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.PODCAST_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.data.premiereImage = signVerifiedImageFileName
          }
        } else {
          if (podcastPremierModuleData[property].removeImage) {
            dataObj.data.premiereImage = ''
          }
        }
        podcastPremierModule.update = dataObj
      }
    }
    return podcastPremierModule
  })
  return await Promise.all(promise)
}

// PODCAST_EPISODE_CAROUSEL Module
function getModifiedPodcastEpisodeCarouselData(podcastEpisodeCarousel) {
  let podcastEpisodeCarouselModules = {}
  if (!isEmpty(podcastEpisodeCarousel)) {
    podcastEpisodeCarouselModules.create = []
    podcastEpisodeCarouselModules.update = []
    for (const property in podcastEpisodeCarousel) {
      if (podcastEpisodeCarousel[property]) {
        if (property.indexOf('-') > 0) {
          if (podcastEpisodeCarousel[property].title && podcastEpisodeCarousel[property].podcastEpisodes && podcastEpisodeCarousel[property].podcastEpisodes.length > 0) {
            const newPodcastEpisodes = podcastEpisodeCarousel[property].podcastEpisodes.filter((episodeObj) => !episodeObj.carouselPodcastEpisodeId)
            if (newPodcastEpisodes && newPodcastEpisodes.length > 0) {
              const podcastEpisodeIds = newPodcastEpisodes.map((episodeObj) => { return { 'podcastEpisodeId': episodeObj.id, 'order': episodeObj.carouselPodcastEpisodeOrder } })
              if (podcastEpisodeIds && podcastEpisodeIds.length > 0) {
                podcastEpisodeCarouselModules.create.push({
                  title: podcastEpisodeCarousel[property].title,
                  order: podcastEpisodeCarousel[property].order,
                  isPublished: podcastEpisodeCarousel[property].isPublished,
                  carouselPodcastEpisodes: podcastEpisodeIds
                })
              }
            }
          }
        } else {
          if (podcastEpisodeCarousel[property].title) {
            const dataObj = {
              data: { title: podcastEpisodeCarousel[property].title, isPublished: podcastEpisodeCarousel[property].isPublished, order: podcastEpisodeCarousel[property].order },
              where: { id: property }
            }
            const oldEpisodes = podcastEpisodeCarousel[property].podcastEpisodes.filter((episodeObj) => episodeObj.carouselPodcastEpisodeId)
            const newPodcastEpisodes = podcastEpisodeCarousel[property].podcastEpisodes.filter((episodeObj) => !episodeObj.carouselPodcastEpisodeId)
            if (newPodcastEpisodes && newPodcastEpisodes.length > 0) {
              const podcastEpisodeIds = newPodcastEpisodes.map((episodeObj) => { return { 'podcastEpisodeId': episodeObj.id, 'order': episodeObj.carouselPodcastEpisodeOrder } })
              if (podcastEpisodeIds && podcastEpisodeIds.length > 0) {
                dataObj.data.carouselPodcastEpisodes = { create: podcastEpisodeIds }
              }
            }
            if (oldEpisodes && oldEpisodes.length > 0) {
              const podcastEpisodeIds = oldEpisodes.map((episodeObj) => { return { where: { id: episodeObj.carouselPodcastEpisodeId }, data: { order: episodeObj.carouselPodcastEpisodeOrder } } })
              if (dataObj.data.carouselPodcastEpisodes) {
                dataObj.data.carouselPodcastEpisodes.update = podcastEpisodeIds
              } else {
                dataObj.data.carouselPodcastEpisodes = { update: podcastEpisodeIds }
              }
            }
            podcastEpisodeCarouselModules.update.push(dataObj)
          }
        }
      }
    }
  }
  let clonePodcastEpisodeCarouselModules = cloneDeep(podcastEpisodeCarouselModules)
  if (clonePodcastEpisodeCarouselModules.create.length === 0) {
    delete clonePodcastEpisodeCarouselModules['create']
  }
  if (clonePodcastEpisodeCarouselModules.update.length === 0) {
    delete clonePodcastEpisodeCarouselModules['update']
  }
  return clonePodcastEpisodeCarouselModules
}

// PODCAST_EPISODE_PREMIERE Module
async function getModifiedPodcastEpisodePremierModuleData(podcastEpisodePremierModuleData, moduleId) {
  const keys = Object.keys(podcastEpisodePremierModuleData)
  const promise = await keys.map(async property => {
    let podcastEpisodePremierModule = {}
    if (!isEmpty(podcastEpisodePremierModuleData)) {
      podcastEpisodePremierModule.create = {}
      podcastEpisodePremierModule.update = {}
    }

    if (podcastEpisodePremierModuleData[property]) {
      if (property.indexOf('-') > 0) {
        let dataObj = {
          order: podcastEpisodePremierModuleData[property].order,
          type: MODULE_TYPES_CONST.PODCAST_EPISODE_PREMIERE,
          referenceId: podcastEpisodePremierModuleData[property].podcastEpisode.id,
          isPublished: podcastEpisodePremierModuleData[property].isPublished,
        }
        if (podcastEpisodePremierModuleData[property].image && podcastEpisodePremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(podcastEpisodePremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.PODCAST_EPISODE_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.premiereImage = signVerifiedImageFileName
          }
        }
        podcastEpisodePremierModule.create = dataObj
      } else {
        let dataObj = {
          data: {
            type: MODULE_TYPES_CONST.PODCAST_PREMIERE,
            referenceId: podcastEpisodePremierModuleData[property].podcastEpisode.id,
            isPublished: podcastEpisodePremierModuleData[property].isPublished,
            order: podcastEpisodePremierModuleData[property].order
          },
          where: { id: property }
        }
        if (podcastEpisodePremierModuleData[property].image && podcastEpisodePremierModuleData[property].image.length > 0) {
          const signVerifiedImageFileName = await getPremierImageSignPutUrl(podcastEpisodePremierModuleData[property].image, moduleId, MODULE_TYPES_CONST.PODCAST_PREMIERE)
          if (signVerifiedImageFileName) {
            dataObj.data.premiereImage = signVerifiedImageFileName
          }
        } else {
          if (podcastEpisodePremierModuleData[property].removeImage) {
            dataObj.data.premiereImage = ''
          }
        }
        podcastEpisodePremierModule.update = dataObj
      }
    }
    return podcastEpisodePremierModule
  })
  return await Promise.all(promise)
}

/*  END: PREMIER MODULES */

export {
  getModifiedClipCarouselData,
  getModifiedVideoCarouselData,
  getModifiedEpisodeCarouselData,
  getModifiedShowCarouselData,
  getModifiedProgrammaticCarouselData,
  getModifiedFreeFormModuleData,
  getModifiedVideoPremierModuleData,
  getModifiedShowPremierModuleData,
  getModifiedEpisodePremierModuleData,
  getModifiedFreeFormHeadLinerModuleData,
  getModifiedPodcastCarouselData,
  getModifiedPodcastPremierModuleData,
  getModifiedPodcastEpisodeCarouselData,
  getModifiedPodcastEpisodePremierModuleData
}
