import React, { useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { LIST_EPISODE_FOR_SEASON, GET_SEASON, LIST_PODCAST_EPISODE_FOR_SEASON } from '../graphql/Queries'
import { UPDATE_SEASONS_EPISODES, UPDATE_SEASONS_PODCAST_EPISODES } from '../graphql/Mutations'
import client from '../../../apollo'
import {
  Table,
  Pagination,
  Input,
  Row,
  Col,
  Typography,
  Button,
  PageHeader,
  Spin,
} from 'antd'
import { debounce, uniqBy, find, findIndex, get  } from 'lodash'
import { useState } from 'react'
import './EpisodeListPage.css'
import { AppContext } from '../../../AppContext'
import Meta from '../../../components/Meta'
const { Search } = Input
const EpisodeListPage = (props) => {
  const [currentSeason, setCurrentSeason] = useState({})
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [selectionEnabled, setSelectionEnabled] = useState(false)
  const [showName, setShowName] = useState()
  const [episodes, setEpisodes] = useState([])
  const [visitedEpisodes, setVisitedEpisodes] = useState([])
  const [episodeLoading, setAddEpisodeLoading] = useState(false)
  const [episodeCount, setEpisodeCount] = useState()
  const [searching, setSearching] = useState(false)
  const [podcastName, setPodcastName] = useState()
  const [podcastEpisodes, setPodcastEpisodes] = useState([])
  const [visitedPodcastEpisodes, setVisitedPodcastEpisodes] = useState([])
  const [addPodcastEpisodeLoading, setAddPodcastEpisodeLoading] = useState(false)
  const [podcastEpisodeCount, setPodcastEpisodeCount] = useState()
  const { state } = useContext(AppContext)
  const {
    match: {
      params: { seasonId },
    },
  } = props

  const { data: { listEpisodeForSeason } = {}, loading, fetchMore } = useQuery(
    LIST_EPISODE_FOR_SEASON,
    {
      variables: { id: seasonId, first: 10 },
      fetchPolicy: 'network-only',
      client: client,
    },
  )

  useEffect(() => {
    getCurrentSeasonData()
  }, [])

  useEffect(() => {
    if (listEpisodeForSeason) {
      setEpisodeCount(listEpisodeForSeason?.count)
      setEpisodes(listEpisodeForSeason?.episodes)
      setVisitedEpisodes(listEpisodeForSeason?.episodes)
      setShowName(listEpisodeForSeason?.show?.name)
    }
  }, [listEpisodeForSeason])

  const getCurrentSeasonData = async () => {
    const getSeasonData = await client.query({
      query: GET_SEASON,
      fetchPolicy: 'network-only',
      variables: { seasonId: seasonId },
    })
    setCurrentSeason(getSeasonData)
  }

  const onSelectChange = (selectedRowKeys) => {
    setSelectionEnabled(!!selectedRowKeys.length)
    setSelectedRowKeys(selectedRowKeys)
  }

  const onChangeInput = (value, id) => {
    const matchGlobalEpisode = visitedEpisodes.find((episode) => episode.id === id)
    if (matchGlobalEpisode) {
      const foundIndex = findIndex(visitedEpisodes, (episode) => episode.id === id)
      visitedEpisodes[foundIndex].featureBannerWeight = value
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      sorter: false,
      render: (image, render) => (
        <img
          className="season-episode-table-img"
          src={image}
          alt={render.title}
        />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: false,
    },
    {
      title: 'Weight',
      sorter: false,
      render: (value, original) => {
        const matchObj = find(visitedEpisodes, (episode) => episode.id === original.id)
        if (matchObj && matchObj.featureBannerWeight) {
          value = matchObj.featureBannerWeight
        }
        return <Input
          id={original.id}
          className="seasonepisode-update-weight-input"
          defaultValue={value}
          onChange={(e) => onChangeInput(+e.target.value, original.id)}
          type="number"
        />
      },
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  function handlePagination(pageNumber) {
    if (episodeCount > episodes.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { listEpisodeForSeason } = fetchMoreResult
          if (listEpisodeForSeason && listEpisodeForSeason.count && listEpisodeForSeason.episodes) {
            setEpisodeCount(listEpisodeForSeason.count)
            setEpisodes(listEpisodeForSeason.episodes)
            setVisitedEpisodes(uniqBy([...visitedEpisodes, ...listEpisodeForSeason.episodes], 'id'))
          }
          setTableLoading(false)
        },
      })
    }
  }

  const addEpisodes = () => {
    const { data: { season } } = currentSeason
    setAddEpisodeLoading(true)
    const create = selectedRowKeys.map((row) => {
      const episode = visitedEpisodes.find((episode) => episode.id === row) || {}
      return {
        episode: { connect: { id: episode.id } },
        weight: episode.featureBannerWeight,
        createdBy: { connect: { id: state.currentUser.id } },
        publishDate: episode.createdAt
      }
    })
    if (create && Array.isArray(create) && create.length) {
      const foundWeightNull = find(create, (data) => data.weight === null || data.weight === undefined)
      if (foundWeightNull && season && season.orderBy === 'Manual') {
        setAddEpisodeLoading(false)
        return alert('Please add weight for selected episodes.')
      }
    }
    client
      .mutate({
        mutation: UPDATE_SEASONS_EPISODES,
        variables: {
          seasonEpisodes: { create },
          id: seasonId,
        },
        refetchQueries: [
          {
            query: LIST_EPISODE_FOR_SEASON,
            variables: { id: seasonId, first: 10 },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setSelectedRowKeys([])
        setSelectionEnabled(false)
        setEpisodes([])
        setVisitedEpisodes([])
        props.history.push(`/seasons/${seasonId}`)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setSelectedRowKeys([])
        setSelectionEnabled(false)
        setAddEpisodeLoading(false)
      })
  }

  const onSearch = (value) => {
    debounce(() => {
      setSearching(true)
      client
        .query({
          query: LIST_EPISODE_FOR_SEASON,
          fetchPolicy: 'network-only',
          variables: { id: seasonId, first: 10, search: value },
        })
        .then(({ data: { listEpisodeForSeason } = {} }) => {
          setEpisodeCount(listEpisodeForSeason.count)
          setEpisodes(listEpisodeForSeason.episodes)
          setVisitedEpisodes(uniqBy([...visitedEpisodes, ...listEpisodeForSeason.episodes], 'id'))
          setSearching(false)
        })
        .catch((e) => {
          console.log(e)
          setSearching(false)
        })
    }, 500)()
  }

  const { data: { listPodcastEpisodeForSeason } = {}, loading:podcastEpisodesLoading, fetchMore:fetchMorePodcasts } = useQuery(
    LIST_PODCAST_EPISODE_FOR_SEASON,
    {
      variables: { id: seasonId, first: 10 },
      fetchPolicy: 'network-only',
      client: client,
    },
  )

  useEffect(() => {
    if (listPodcastEpisodeForSeason) {
      setPodcastEpisodeCount(listPodcastEpisodeForSeason?.count)
      setPodcastEpisodes(listPodcastEpisodeForSeason?.podcastEpisodes)
      setVisitedPodcastEpisodes(listPodcastEpisodeForSeason?.podcastEpisodes)
      setPodcastName(listPodcastEpisodeForSeason?.podcast?.name)
    }
  }, [listPodcastEpisodeForSeason])

  const onPodcastChangeInput = (value, id) => {
    const matchGlobalEpisode = visitedPodcastEpisodes.find((episode) => episode.id === id)
    if (matchGlobalEpisode) {
      const foundIndex = findIndex(visitedPodcastEpisodes, (episode) => episode.id === id)
      visitedPodcastEpisodes[foundIndex].weight = value
    }
  }

  const columnsForPodcasts = [
    {
      title: '',
      dataIndex: 'thumbnail',
      sorter: false,
      render: (thumbnail, render) => (
        <img
          className="season-podcastepisode-table-img"
          src={thumbnail}
          alt={render.title}
        />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: false,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      sorter: false,
      render: (value, original) => {
        const matchObj = find(visitedPodcastEpisodes, (episode) => episode.id === original.id)
        if (matchObj && matchObj.featureBannerWeight) {
          value = matchObj.featureBannerWeight
        }
        return <Input
          id={original.id}
          className="seasonepisode-update-weight-input"
          defaultValue={value}
          onChange={(e) => onPodcastChangeInput(+e.target.value, original.id)}
          type="number"
        />
      },
    },
  ]

  function handlePodcastPagination(pageNumber) {
    if (podcastEpisodeCount > episodes.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMorePodcasts({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { listPodcastEpisodeForSeason } = fetchMoreResult
          if (listPodcastEpisodeForSeason && listPodcastEpisodeForSeason.count && listPodcastEpisodeForSeason.episodes) {
            setPodcastEpisodeCount(listPodcastEpisodeForSeason.count)
            setPodcastEpisodes(listPodcastEpisodeForSeason.podcastEpisodes)
            setVisitedPodcastEpisodes(uniqBy([...visitedEpisodes, ...listPodcastEpisodeForSeason.podcastEpisodes], 'id'))
          }
          setTableLoading(false)
        },
      })
    }
  }

  const addPodcastEpisodes = () => {
    const { data: { season } } = currentSeason
    setAddPodcastEpisodeLoading(true)
    const connect = selectedRowKeys.map((row) => {
      const episode = visitedPodcastEpisodes.find((episode) => episode.id === row) || {}
      return {
        id:episode.id,
      }
    })
    const updateMany = selectedRowKeys.map((row) => {
      const episode = visitedPodcastEpisodes.find((episode) => episode.id === row) || {}
      return {
        where: { id: episode.id },
        data: {
          weight: episode.weight,
        },
      }
    })
    if (updateMany && Array.isArray(updateMany) && updateMany.length) {
      const foundWeightNull = find(updateMany, (data) => data && data.data && (data.data.weight === null || data.data.weight === undefined))
      if (foundWeightNull && season && season.orderBy === 'Manual') {
        setAddEpisodeLoading(false)
        return alert('Please add weight for selected episodes.')
      }
    }
    client
      .mutate({
        mutation: UPDATE_SEASONS_PODCAST_EPISODES,
        variables: {
          podcastEpisodes: { connect },
          id: seasonId,
        }
      }).then((res)=>{
        client.mutate({
          mutation: UPDATE_SEASONS_PODCAST_EPISODES,
          variables: {
            podcastEpisodes: { updateMany },
            id: seasonId,
          },
          refetchQueries: [
            {
              query: LIST_PODCAST_EPISODE_FOR_SEASON,
              variables: { id: seasonId, first: 10 },
              fetchPolicy: 'network-only',
            },
          ],
        })
      })
      .then((res) => {
        setSelectedRowKeys([])
        setSelectionEnabled(false)
        setPodcastEpisodes([])
        setVisitedPodcastEpisodes([])
        props.history.push(`/seasons/${seasonId}`)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setSelectedRowKeys([])
        setSelectionEnabled(false)
        setAddPodcastEpisodeLoading(false)
      })
  }

  const onPodcastSearch = (value) => {
    debounce(() => {
      setSearching(true)
      client
        .query({
          query: LIST_PODCAST_EPISODE_FOR_SEASON,
          fetchPolicy: 'network-only',
          variables: { id: seasonId, first: 10, search: value },
        })
        .then(({ data: { listPodcastEpisodeForSeason } = {} }) => {
          setPodcastEpisodeCount(listPodcastEpisodeForSeason.count)
          setPodcastEpisodes(listPodcastEpisodeForSeason.podcastEpisodes)
          setVisitedPodcastEpisodes(uniqBy([...visitedPodcastEpisodes, ...listPodcastEpisodeForSeason.podcastEpisodes], 'id'))
          setSearching(false)
        })
        .catch((e) => {
          console.log(e)
          setSearching(false)
        })
    }, 500)()
  }

  if(get(currentSeason,'data.season.type')==='PODCAST'){
    return (
      <div className="add-seasonepisode-wrapper">
        <Row gutter={24} type="flex" className="shows-title-wrapper">
          <Meta title={podcastName || ''} description="Episode list" />
          <Col span={24}>
            <PageHeader
              onBack={() => props.history.goBack()}
              title={`Select Episode from ${podcastName || ''}`}
              className="box page-header"
            />
          </Col>
        </Row>
        <Row gutter={24} type="flex" className="person-management-wrapper">
          <Col span={24}>
            <Spin spinning={searching}>
              <div className="box seasonepisode-action-buttons-wrapper">
                <div className="episode-header">
                  <Search
                    className="search-input"
                    placeholder="Search Episodes"
                    onChange={(e) => onPodcastSearch(e.target.value)}
                    enterButton
                  />
                  {selectionEnabled && (
                    <div className="action-btn">
                      <Button
                        className="cancel-btn"
                        onClick={() => {
                          setSelectionEnabled(false)
                          setSelectedRowKeys([])
                        }}
                      >
                        Cancel
                    </Button>
                      <Button
                        type="primary"
                        loading={addPodcastEpisodeLoading}
                        onClick={() => addPodcastEpisodes()}
                      >
                        Add Selected
                    </Button>
                    </div>
                  )}
                </div>
              </div>
              <Table
                loading={podcastEpisodesLoading || tableLoading}
                columns={columnsForPodcasts}
                rowSelection={rowSelection}
                rowKey={(record) => record.id}
                dataSource={podcastEpisodes}
                pagination={false}
              />
              <Pagination
                onChange={handlePodcastPagination}
                current={currentPageNumber}
                total={podcastEpisodeCount}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className="add-seasonepisode-wrapper">
      <Row gutter={24} type="flex" className="shows-title-wrapper">
        <Meta title={showName || ''} description="Episode list" />
        <Col span={24}>
          <PageHeader
            onBack={() => props.history.goBack()}
            title={`Select Episode from ${showName || ''}`}
            className="box page-header"
          />
        </Col>
      </Row>
      <Row gutter={24} type="flex" className="person-management-wrapper">
        <Col span={24}>
          <Spin spinning={searching}>
            <div className="box seasonepisode-action-buttons-wrapper">
              <div className="episode-header">
                <Search
                  className="search-input"
                  placeholder="Search Episodes"
                  onChange={(e) => onSearch(e.target.value)}
                  enterButton
                />
                {selectionEnabled && (
                  <div className="action-btn">
                    <Button
                      className="cancel-btn"
                      onClick={() => {
                        setSelectionEnabled(false)
                        setSelectedRowKeys([])
                      }}
                    >
                      Cancel
                  </Button>
                    <Button
                      type="primary"
                      loading={episodeLoading}
                      onClick={() => addEpisodes()}
                    >
                      Add Selected
                  </Button>
                  </div>
                )}
              </div>
            </div>
            <Table
              loading={loading || tableLoading}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              dataSource={episodes}
              pagination={false}
            />
            <Pagination
              onChange={handlePagination}
              current={currentPageNumber}
              total={episodeCount}
            />
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

export default EpisodeListPage
