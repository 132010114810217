import React, { useState, useRef, useEffect } from "react"
import {
  Row,
  Col,
  Table,
  Typography,
  notification,
  Button,
  Tooltip,
  Icon,
  Modal,
  Popconfirm,
} from "antd"
import * as Sentry from "@sentry/browser"
import { useQuery } from "@apollo/react-hooks"
import { chatClient } from "../../apollo"
import {
  GET_BADGES,
  GET_BADGE_SIGNED_PUT_URL
} from "./graphql/Queries"
import { CREATE_BADGE, UPDATE_BADGE, DELETE_BADGE } from "./graphql/Mutations"
import BadgeModal from "./components/BadgeModal"
import { fileUpload } from "../../common/fileUpload"
import "./badge-management.css"
const { confirm } = Modal
const { Title } = Typography

const BadgeManagement = ({ history }) => {
  const { data: badgeData, loading, fetchMore, networkStatus } = useQuery(
    GET_BADGES,
    {
      variables: { first: 10 },
      fetchPolicy: "network-only",
      client: chatClient,
    },
  )
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [badgeEditableData, setBadgeEditableData] = useState("")
  const [isSubmit, setSubmit] = useState(false)

  const saveFormRef = useRef()

  useEffect(() => {
    async function handlePageChange() {
      if (!loading) {
        try {
          setTableLoading(true)
          fetchMore({
            variables: {
              skip: (currentPageNumber - 1) * 10,
              first: 10
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { badges } = fetchMoreResult
                if (badges?.length < 10) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                setTableLoading(false)
                return badges?.length ? { badges: [...badges] } : prevResult
              }
            }
          })
        } catch (error) {
          setTableLoading(false)
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber])

  useEffect(() => {
    if (!loading && badgeData?.badges) {
      if (badgeData?.badges?.length < 10) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [loading])

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleRequestFail(e) {
    setTableLoading(false)
    if (e && e.message) {
      const message =
        (e && e.graphQLErrors && Array.isArray(e.graphQLErrors) && e.graphQLErrors[0] && e.graphQLErrors[0].message) || e.message
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleEditButton(data) {
    setBadgeEditableData(data)
    setShowModal(true)
  }

  function handleDeleteButton(data) {
    confirm({
      title: "Are you sure you want to DELETE the badge?",
      okText: "Delete",
      async onOk() {
        try {
          await chatClient.mutate({
            mutation: DELETE_BADGE,
            variables: { id: data.id },
            refetchQueries: [
              {
                query: GET_BADGES,
              },
            ],
          })
          openNotification("success", "Badge Deleted Successfully")
        } catch (error) {
          Sentry.captureException(error)
          handleRequestFail(error)
        }
      },
    })
  }

  function handleActive(id, isActive, type, name) {
    setTableLoading(true)
    chatClient
      .mutate({
        mutation: UPDATE_BADGE,
        variables: { id, isActive, type, name },
        refetchQueries: [
          {
            query: GET_BADGES,
            variables: {
              skip: (currentPageNumber - 1) * 10,
              first: 10,
            },
          },
        ],
      })
      .then(() => {
        setTimeout(() => {
          openNotification("success", "Badge updated successfully")
          setTableLoading(false)
        }, 500)
      })
      .catch((e) => {
        handleRequestFail(e)
      })
  }

  const columns = [
    {
      title: "Badge Image",
      dataIndex: "image",
      render: (image, record) =>
        image && (
          <img
            className="badgemgnt-table-image"
            alt={record.name}
            src={`${image}?${performance.now()}`}
          />
        ),
    },
    {
      title: "Default PlaceHolder Image",
      dataIndex: "defaultPlaceHolderImage",
      render: (image, record) => (
        <img
          className="badgemgnt-table-image"
          alt={record.name}
          src={image}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      render:(text)=> text ? text.split('_').join(' ') : ''      
    },
    {
      title: "Weight",
      dataIndex: "weight",
    },
    {
      title: "Slug",
      dataIndex: "slug",
    },
    {
      title: "Description",
      dataIndex: "description",
      className: "badge-description",
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "10%",
      render: (text, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Icon
                id={`btn-icon-${record.id}-edit`}
                type="edit"
                theme="twoTone"
                className="badgemgnt-edit-btn"
                onClick={() => handleEditButton(record)}
              />
            </Tooltip>
            <Popconfirm
              title={`Are you sure you want to ${record.isActive ? "deactivate" : "activate"
                } this Badge?`}
              onConfirm={() =>
                handleActive(
                  record.id,
                  !record.isActive,
                  record.type,
                  record.name,
                )
              }
            >
              <Tooltip title={`${record.isActive ? "Deactivate" : "Activate"}`}>
                {record.isActive ? (
                  <Icon id={`btn-icon-${record.id}-active`} type="stop" />
                ) : (
                  <Icon
                    id={`btn-icon-${record.id}-deactive`}
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                  />
                )}
              </Tooltip>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  function handleChange(sorter) {
    if (sorter?.field && sorter?.order) {
      setCurrentPageNumber(1)
      setLastPage(false)
    }
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      setSubmit(true)
      try {
        const {
          name,
          image,
          description,
          type,
          weight,
          isActive,
          isHidden,
          defaultPlaceHolderImage,
          canAssignUsingScript,
          shopifySkuList,
        } = values
        let badgeResult
        let imageFileName = image && image[0] && image[0].url
        let defaultPlaceHolderImageFileName =
          defaultPlaceHolderImage &&
          defaultPlaceHolderImage[0] &&
          defaultPlaceHolderImage[0].url
        if (
          typeof image !== "string" &&
          image &&
          image.length > 0 &&
          image[0] &&
          !image[0].status
        ) {
          imageFileName = image[0].name
        }
        if (
          typeof defaultPlaceHolderImage !== "string" &&
          defaultPlaceHolderImage &&
          defaultPlaceHolderImage.length > 0 &&
          defaultPlaceHolderImage[0] &&
          !defaultPlaceHolderImage[0].status
        ) {
          defaultPlaceHolderImageFileName = defaultPlaceHolderImage[0].name
        }
        if (badgeEditableData) {
          badgeResult = await chatClient.mutate({
            mutation: UPDATE_BADGE,
            variables: {
              name,
              image: imageFileName || null,
              description,
              type,
              weight: parseInt(weight),
              isHidden,
              defaultPlaceHolderImage: defaultPlaceHolderImageFileName || null,
              isActive,
              canAssignUsingScript: shopifySkuList ? false : canAssignUsingScript,      //when shopify reward selected then sending false
              id: badgeEditableData?.id,
              ...((badgeEditableData?.shopifySkuList || shopifySkuList) && { shopifySkuList: { set: shopifySkuList || [] } })           // if type change from shopify reward to other option
            },
            refetchQueries: [
              {
                query: GET_BADGES,
                variables: {
                  skip: (currentPageNumber - 1) * 10,
                  first: 10,
                },
              }
            ],
          })
        } else {
          setCurrentPageNumber(1)
          badgeResult = await chatClient.mutate({
            mutation: CREATE_BADGE,
            variables: {
              name,
              image: imageFileName || null,
              description,
              type,
              weight: parseInt(weight),
              isHidden,
              isActive,
              canAssignUsingScript: shopifySkuList ? false : canAssignUsingScript,              //when shopify reward selected then sending false
              defaultPlaceHolderImage: defaultPlaceHolderImageFileName || null,
              ...(shopifySkuList && { shopifySkuList: { set: shopifySkuList } })        //only send when field is available 
            },
            refetchQueries: [
              {
                query: GET_BADGES,
                variables: {
                  first: 10,
                },
              }
            ],
          })
        }
        if (
          typeof image !== "string" &&
          image &&
          image.length > 0 &&
          image[0] &&
          !image[0].status
        ) {
          const imageFileName = image[0].name
          const tempFilename = imageFileName.split(".")
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${badgeEditableData                        //using same logic which used for default placeholder image 
            ? badgeEditableData.id
            : badgeResult.data.createBadge.id
            }-${new Date().getTime()}.${fileExt}`

          const contentType = image[0].type
          const getSignedPutUrlResult = await chatClient.query({
            query: GET_BADGE_SIGNED_PUT_URL,
            variables: { fileName, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getBadgeSignedPutUrl.signedUrl,
            image[0],
          )
          await chatClient.mutate({
            mutation: UPDATE_BADGE,
            variables: {
              name,
              type,
              image: getSignedPutUrlResult.data.getBadgeSignedPutUrl.getUrl,
              id: badgeEditableData
                ? badgeEditableData.id
                : badgeResult.data.createBadge.id,
            },
            refetchQueries: [
              {
                query: GET_BADGES,
                variables: {
                  skip: (currentPageNumber - 1) * 10,
                  first: 10,
                },
              },
            ],
          })
        }
        if (
          typeof defaultPlaceHolderImage !== "string" &&
          defaultPlaceHolderImage &&
          defaultPlaceHolderImage.length > 0 &&
          defaultPlaceHolderImage[0] &&
          !defaultPlaceHolderImage[0].status
        ) {
          const imageFileName = defaultPlaceHolderImage[0].name
          const tempFilename = imageFileName.split(".")
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${badgeEditableData
            ? badgeEditableData.id
            : badgeResult.data.createBadge.id
            }-${new Date().getTime()}.${fileExt}`
          const contentType = defaultPlaceHolderImage[0].type
          const getSignedPutUrlResult = await chatClient.query({
            query: GET_BADGE_SIGNED_PUT_URL,
            variables: { fileName, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getBadgeSignedPutUrl.signedUrl,
            defaultPlaceHolderImage[0],
          )
          await chatClient.mutate({
            mutation: UPDATE_BADGE,
            variables: {
              name,
              type,
              defaultPlaceHolderImage:
                getSignedPutUrlResult.data.getBadgeSignedPutUrl.getUrl,
              id: badgeEditableData
                ? badgeEditableData.id
                : badgeResult.data.createBadge.id,
            },
            refetchQueries: [
              {
                query: GET_BADGES,
                variables: {
                  skip: (currentPageNumber - 1) * 10,
                  first: 10,
                },
              },
            ],
          })
        }
        setShowModal(false)
        setSubmit(false)
        setBadgeEditableData("")
      } catch (e) {
        console.log(e)
        Sentry.captureException(e)
        handleRequestFail(e)
        setShowModal(false)
      }
    })
  }

  let badgeTableData = []
  if (badgeData && badgeData.badges) {
    badgeTableData = [...badgeData.badges]
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowModal(false)
    setBadgeEditableData("")
  }
  return (
    <Row gutter={24} type="flex" className="person-management-wrapper">
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Badge Management</Title>
          <Button
            id="btn-show-badge-modal"
            type="primary"
            shape="circle"
            icon="plus"
            size="default"
            onClick={() => setShowModal(true)}
          />
        </div>
        <Table
          rowKey={(record) => record.id}
          loading={networkStatus === 1 || tableLoading || loading}
          columns={columns}
          pagination={false}
          dataSource={badgeTableData}
          onChange={handleChange}
        />
        <div className="page-change-btn-wrapper">
          <Button icon="left" onClick={() => setCurrentPageNumber(currentPageNumber - 1)} disabled={currentPageNumber === 1} />
          <Button icon="right" onClick={() => setCurrentPageNumber(currentPageNumber + 1)} disabled={lastPage} />
        </div>
        {showModal && (
          <BadgeModal
            badgeEditableData={badgeEditableData}
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            handleOk={handleCreate}
            handleCancel={handleCancel}
            isUpdate={badgeEditableData ? true : false}
          />
        )}
      </Col>
    </Row>
  )
}

export default BadgeManagement
