import gql from 'graphql-tag'

export const GET_PODCASTS = gql`
  query listPodcastDetails($first: Int!, $after: String) {
    listPodcastDetails(orderBy: weight_DESC, first: $first, after: $after) {
      id
      name
      weight
      coverImage
      author {
        id
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_PODCAST_SIGNED_PUT_URL = gql`
  query getPodcastSignedPutUrl($fileName: String!, $contentType: String!) {
    getPodcastSignedPutUrl(
      data: { fileName: $fileName, contentType: $contentType }
    ) {
      signedUrl
      getUrl
    }
  }
`

export const GET_APP_AUTHORS = gql`
  query persons($first: Int, $after: String, $where: UserWhereInput) {
    users(
      first: $first
      after: $after    
      where: $where  
    ) { 
      id
      email
      firstName
      lastName
    }
  }
`

export const GET_PODCAST = gql`
  query getPodcastDetails($id: ID!) {
    getPodcastDetails(where: { id: $id }) {
      id
      name
      weight
      status
      coverImage
      backgroundImage
      logoImage
      description
      author {
        id
        firstName
        lastName
      }
    }
  }
`