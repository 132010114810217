import React, { useState, useRef } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import {
  Row,
  Col,
  Button,
  PageHeader,
  Tag,
  notification,
  Modal,
  Divider,
} from 'antd'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import AddEpisodeModal from './components/AddEpisodeModal'
import client from '../../apollo'
import { fileUpload } from '../../common/fileUpload'
import './episode.css'
import { GET_PODCAST_EPISODE, GET_PODCAST_EPISODE_SIGNED_PUT_URL } from './grapql/Queries'
import { SUBSCRIBE_PODCAST_EPISODE } from './grapql/Subscriptions'
import { DELETE_PODCAST_EPISODE, UPDATE_PODCAST_EPISODE } from './grapql/Mutations'
import SegmentDetails from './components/SegmentDetails'
import { get } from 'lodash'
import { LOCATIONS } from '../../common/constants'

const { confirm } = Modal
function checkStatus(status) {
  if (
    status === 'LIVE_FINISHED' ||
    status === 'DRAFT' ||
    status === 'UNPUBLISHED'
  ) {
    return false
  }
  return true
}

export default function (props) {
  const {
    history,
    match: {
      params: { episodeId, podcastId, featured },
    },
  } = props
  const {
    data: episodeData,
    loading: episodeLoading,
    error: episodeError,
    subscribeToMore,
  } = useQuery(GET_PODCAST_EPISODE, { variables: { id: episodeId }, fetchPolicy: 'network-only' })
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const saveFormRef = useRef()
  const [showAudioRequireMsg, setShowAudioRequiredMsg] = useState(false)

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleRequestFail(e) {
    setShowModal(false)
    setIsSubmit(false)
    openNotification('error', e || 'Something Went Wrong')
  }

  function handleCancel() {
    setShowModal(false)
    saveFormRef.current.props.form.resetFields()
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setIsSubmit(true)
      const {
        title,
        schedule,
        description,
        episodeNumber,
        seasonId,
        publishDate,
        audioWithAdsAccess,
        audioAccess,
        allowedContinents,
        rating,
        thumbnail,
        locationRestriction,
        limitBy,
        allowedCountries
      } = values
      const {
        match: {
          params: { episodeId },
        },
      } = props
      try {

        if (locationRestriction) {
          if (limitBy === LOCATIONS.CONTINENT) {
            values.allowedCountries = []
          }
          else {
            values.allowedContinents = []
          }
        }
        else if (!locationRestriction) {
          values.allowedContinents = []
          values.allowedCountries = []
        }

        const data = {
          title,
          description,
          episodeNumber,
          seasonId: seasonId,
          podcastId: podcastId,
          scheduleAt: schedule,
          publishDate,
          audioWithAdsAccess: { set: audioWithAdsAccess },
          audioAccess: { set: audioAccess },
          allowedContinents: { set: values.allowedContinents },
          rating,
          allowedCountries: values.allowedCountries,
        }

        await client.mutate({
          mutation: UPDATE_PODCAST_EPISODE,
          variables: {
            data: data,
            where: { id: episodeId },
          },
          refetchQueries: [
            {
              query: GET_PODCAST_EPISODE,
              variables: { id: episodeId },
            },
          ],
        })
        if (thumbnail && thumbnail.length > 0) {
          const tempFilename = thumbnail[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${episodeId}-${new Date().getTime()}.${fileExt}`
          const contentType = thumbnail[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_PODCAST_EPISODE_SIGNED_PUT_URL,
            variables: { fileName, podcastEpisodeId: episodeId, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getPodcastEpisodeSignedPutUrl.signedUrl,
            thumbnail[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_PODCAST_EPISODE,
            variables: {
              data: {
                thumbnail: fileName,
              },
              where: { id: episodeId },
            },
            refetchQueries: [
              {
                query: GET_PODCAST_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
        }
        openNotification('success', 'Episode Updated Successfully')
        form.resetFields()
        setShowModal(false)
        setIsSubmit(false)
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail()
      }
    })
  }

  function showConfirm(podcastId, episodeId, episodeTitle) {
    const { history } = props
    confirm({
      title: `Are you sure you want to DELETE "${episodeTitle}" episode?`,
      content: `Upon clicking DELETE, the "${episodeTitle}" episode will be permanently erased.`,
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          await client.mutate({
            mutation: DELETE_PODCAST_EPISODE,
            variables: { episodeId },
          })
          openNotification('success', 'Episode Deleted Successfully')
          setTimeout(() => {
            if (featured) {
              history.push(`/featured-episodes`)
            } else {
              history.push(`/podcasts/${podcastId}`)
            }
          }, 500)
        } catch (e) {
          Sentry.captureException(e)
          let errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
          handleRequestFail(errorMessage)
        }
      },
    })
  }

  function publishEpisode(podcastId, episodeId, status) {
    if (!episodeData?.getPodcastEpisodeDetails?.audioWithAds) {
      setShowAudioRequiredMsg(true)
      openNotification('error', `Can't publish podcast episode, please upload audios`)
      return
    }

    const title =
      status === 'PUBLISHED'
        ? 'Are you sure you want to publish?'
        : 'Are you sure you want to unpublish?'
    const content =
      status === 'PUBLISHED'
        ? 'Upon clicking publish, this episode will become available to front end users'
        : 'Upon clicking unpublish, this episode will become unavailable to front end users'
    const okText =
      status === 'PUBLISHED'
        ? 'PUBLISH'
        : 'UNPUBLISH'
    const okType = status === 'UNPUBLISHED' ? 'danger' : 'primary'
    const notification =
      status === 'PUBLISHED'
        ? 'Episode Published Successfully'
        : 'Episode Was Successfully Unpublished'

    confirm({
      title,
      content,
      okText,
      okType,
      async onOk() {
        try {
          await client.mutate({
            mutation: UPDATE_PODCAST_EPISODE,
            variables: { data: { status, podcastId: podcastId }, where: { id: episodeId } },
            refetchQueries: [
              {
                query: GET_PODCAST_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', notification)
        } catch (e) {
          Sentry.captureException(e)
          let errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
          handleRequestFail(errorMessage)
        }
      },
    })
  }

  function subscribeToAudioUpdate() {
    subscribeToMore({
      document: SUBSCRIBE_PODCAST_EPISODE,
      variables: { id: episodeId },
      updateQuery: (prev, { subscriptionData }) => {
        if (get(subscriptionData, 'data.podcastEpisode.node.audioWithAds')) {
          setShowAudioRequiredMsg(false)
        }
        if (!get(subscriptionData, 'data.podcastEpisode.node')) return prev;
        return {
          ...prev,
          duration: get(subscriptionData, 'data.podcastEpisode.node.duration'),
          audio: get(subscriptionData, 'data.podcastEpisode.node.audio'),
          audioState: get(subscriptionData, 'data.podcastEpisode.node.audioState'),
          audioMuxAssetId: get(subscriptionData, 'data.podcastEpisode.node.audioMuxAssetId'),
          durationWithAds: get(subscriptionData, 'data.podcastEpisode.node.durationWithAds'),
          audioWithAds: get(subscriptionData, 'data.podcastEpisode.node.audioWithAds'),
          audioWithAdsState: get(subscriptionData, 'data.podcastEpisode.node.audioWithAdsState'),
          audioWithAdsMuxAssetId: get(subscriptionData, 'data.podcastEpisode.node.audioWithAdsMuxAssetId'),
        }
      }
    })
  }

  return (
    <>
      {episodeLoading ? (
        <div className="callback-wrapper">
          <Spinner />
        </div>
      ) : episodeError ? (
        <>{episodeError.message}</>
      ) : episodeData &&
        episodeData.getPodcastEpisodeDetails &&
        episodeData.getPodcastEpisodeDetails.podcast &&
        episodeData.getPodcastEpisodeDetails.podcast.id === podcastId ? (
        <>
          <Meta title={episodeData.getPodcastEpisodeDetails.title || ''} description="Episode" />
          <AddEpisodeModal
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            handleCancel={handleCancel}
            handleCreate={handleCreate}
            title={episodeData.getPodcastEpisodeDetails.title}
            description={episodeData.getPodcastEpisodeDetails.description}
            episodeNumber={episodeData.getPodcastEpisodeDetails.episodeNumber}
            isEdit={
              episodeData.getPodcastEpisodeDetails.status === 'PUBLISHED' ||
                episodeData.getPodcastEpisodeDetails.status === 'SCHEDULED' ||
                episodeData.getPodcastEpisodeDetails.status === 'LIVE' ||
                episodeData.getPodcastEpisodeDetails.status === 'LIVE_FINISHED'
                ? true
                : false
            }
            episodeId={episodeData.getPodcastEpisodeDetails.id}
            podcastId={podcastId}
            seasonId={episodeData.getPodcastEpisodeDetails.season?.id}
            publishDate={episodeData.getPodcastEpisodeDetails.publishDate}
            scheduleAt={episodeData?.getPodcastEpisodeDetails?.scheduleAt}
            audioWithAdsAccess={episodeData.getPodcastEpisodeDetails.audioWithAdsAccess}
            audioAccess={episodeData.getPodcastEpisodeDetails.audioAccess}
            allowedContinents={episodeData.getPodcastEpisodeDetails.allowedContinents}
            rating={episodeData.getPodcastEpisodeDetails.rating}
            allowedCountries={episodeData?.getPodcastEpisodeDetails?.allowedCountries}
          />
          <Row gutter={24} type="flex" className="episode-title-wrapper">
            <Col span={24}>
              <PageHeader
                onBack={() => {
                  if (featured) {
                    history.push(`/featured-episodes`)
                  } else {
                    history.push(`/podcasts/${episodeData.getPodcastEpisodeDetails.podcast.id}`)
                  }
                }}
                title={episodeData.getPodcastEpisodeDetails.title}
                tags={
                  <Tag className={episodeData.getPodcastEpisodeDetails.status}>
                    {episodeData.getPodcastEpisodeDetails.status}
                  </Tag>
                }
                className="d-flex box align-center podcast-pageheader-wordbreak"
                extra={[
                  <Button
                    id='btn-episode-edit'
                    key="1"
                    shape="circle"
                    icon="edit"
                    size="small"
                    onClick={() => setShowModal(true)}
                  />,
                  <Button
                    id='btn-episode-delete'
                    key="2"
                    shape="circle"
                    size="small"
                    icon="delete"
                    type="danger"
                    ghost={true}
                    onClick={() =>
                      showConfirm(episodeData.getPodcastEpisodeDetails.podcast.id, episodeId, episodeData.getPodcastEpisodeDetails.title)
                    }
                    disabled={(episodeData.getPodcastEpisodeDetails.isLive && episodeData.getPodcastEpisodeDetails.status === 'LIVE') ? true : false}
                  />,
                  episodeData.getPodcastEpisodeDetails.status !== 'PUBLISHED' ? (
                    <Button
                      id='btn-episode-publish'
                      key="3"
                      type="primary"
                      size="small"
                      disabled={checkStatus(episodeData.getPodcastEpisodeDetails.status)}
                      onClick={() =>
                        publishEpisode(
                          episodeData.getPodcastEpisodeDetails.podcast.id,
                          episodeId,
                          'PUBLISHED',
                        )
                      }
                    >
                      PUBLISH
                    </Button>
                  ) : (
                    <Button
                      id='btn-episode-unpublish'
                      key="3"
                      type="danger"
                      size="small"
                      onClick={() =>
                        publishEpisode(
                          episodeData.getPodcastEpisodeDetails.podcast.id,
                          episodeId,
                          'UNPUBLISHED',
                        )
                      }
                    >
                      UNPUBLISH
                    </Button>
                  ),
                ]}
              />
            </Col>
          </Row>
          <div className="box episode-description-div-wrapper">
            <Row
              type="flex"
              className="podcastepisode-description-wrapper"
            >
              <Col sm={24} md={4} lg={4} xl={4}>
                <div className="podcast-episode episode-card text-center">
                  <img
                    src={episodeData.getPodcastEpisodeDetails.thumbnail}
                    alt={episodeData.getPodcastEpisodeDetails.title}
                    title={episodeData.getPodcastEpisodeDetails.title}
                  />
                </div>
              </Col>
              <Col sm={24} md={20} lg={20} xl={20}>
                <div className="episode-description">
                  {episodeData.getPodcastEpisodeDetails.description}
                </div>
              </Col>
            </Row>
          </div>
          <Row gutter={24} type="flex" className="podcastepisode-description-divider">
            <Divider />
          </Row>
          <Row
            gutter={24}
            type="flex"
            className={`episode-wrapper`}
          >
            <SegmentDetails
              episodeId={episodeId}
              episode={episodeData.getPodcastEpisodeDetails}
              subscribeToAudioUpdate={subscribeToAudioUpdate}
              showAudioRequireMsg={showAudioRequireMsg}
            />
          </Row>
        </>
      ) : (
        <Page404 />
      )}
    </>
  )
}
