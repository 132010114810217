import React, { Component } from 'react'
import { Col, notification, Modal, Button } from 'antd'
import { NavLink } from 'react-router-dom'
import { DELETE_CLIP } from '../graphql/Mutations'
import { GET_CLIPS } from '../graphql/Queries'
import client from '../../../apollo'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
const confirm = Modal.confirm
export default class ClipCard extends Component {
  openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      this.openNotification('error', message)
    } else {
      this.openNotification('error', 'Something Went Wrong')
    }
  }

  // showConfirm(id) {
  //   confirm({
  //     title: `Are you sure you want to remove clip`,
  //     okText: 'Delete',
  //     okType: 'danger',
  //     onOk: async () => {
  //       try {
  //         await client.mutate({
  //           mutation: DELETE_CLIP,
  //           variables: { clipId: id },
  //           refetchQueries: [
  //             {
  //               query: GET_CLIPS,
  //               variables: { first: 20 },
  //               fetchPolicy: 'network-only',
  //             },
  //           ],
  //         })
  //         setTimeout(() => {
  //           this.openNotification('success', 'Clip Deleted Successfully')
  //         }, 500)
  //       } catch (error) {
  //         Sentry.captureException(error)
  //         this.handleRequestFail()
  //       } finally {
  //         setTimeout(() => {
  //           this.props.updateEpisodeList()
  //         }, 500)
  //       }
  //     },
  //   })
  // }

  render() {
    const { id, image, name, createdAt } = this.props
    return (
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        className="episode-card-col-wrapper"
      >
        <div className="show-card-wrapper" style={{ position: 'relative' }}>
          {/* <div
            style={{
              position: 'absolute',
              right: '10px',
              zIndex: '111',
              top: '8px',
            }}
          >
            <Button
              key="2"
              size="default"
              icon="delete"
              type="danger"
              onClick={() => {
                this.showConfirm(id)
              }}
            />
          </div> */}
          <NavLink to={`/clips/${id}`} className="episode-card">
            <div className="text-center d-flex">
              <img src={`${image}?w=300&h=250`} alt={name} title={name} />
            </div>
            <div className="episode-details" style={{ textAlign: 'left' }}>
              <p className="episode-title">{name}</p>
              <p className="episode-date">
                {moment(createdAt).format('MMMM Do, YYYY')}
              </p>
            </div>
          </NavLink>
        </div>
      </Col>
    )
  }
}
