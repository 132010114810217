import gql from "graphql-tag"

export const CREATE_BADGE = gql`
  mutation createBadge(
    $name: String!
    $image: String
    $defaultPlaceHolderImage: String
    $description: String
    $type: BadgeType!
    $weight: Int
    $isHidden: Boolean
    $isActive: Boolean
    $canAssignUsingScript: Boolean
    $shopifySkuList: BadgeCreateshopifySkuListInput
  ) {
    createBadge(
      data: {
        name: $name
        image: $image
        defaultPlaceHolderImage: $defaultPlaceHolderImage
        description: $description
        type: $type
        weight: $weight
        isHidden: $isHidden
        isActive: $isActive
        canAssignUsingScript: $canAssignUsingScript
        shopifySkuList: $shopifySkuList
      }
    ) {
      id
      image
      defaultPlaceHolderImage
    }
  }
`

export const UPDATE_BADGE = gql`
  mutation updateBadge(
    $name: String!
    $image: String
    $defaultPlaceHolderImage: String
    $description: String
    $type: BadgeType!
    $weight: Int
    $isHidden: Boolean
    $isActive: Boolean
    $canAssignUsingScript: Boolean
    $id: ID!
    $shopifySkuList: BadgeUpdateshopifySkuListInput
  ) {
    updateBadge(
      data: {
        name: $name
        image: $image
        defaultPlaceHolderImage: $defaultPlaceHolderImage
        description: $description
        type: $type
        weight: $weight
        isHidden: $isHidden
        isActive: $isActive
        canAssignUsingScript: $canAssignUsingScript
        shopifySkuList: $shopifySkuList
      }
      where: { id: $id }
    ) {
      id
      image
      defaultPlaceHolderImage
    }
  }
`

export const DELETE_BADGE = gql`
  mutation deleteBadge($id: ID!) {
    deleteBadge(where: { id: $id }) {
      id
    }
  }
`
