import React, { Component } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Modal
} from 'antd'
import '../modular-page.css'

const CollectionCreateForm = Form.create({ name: 'create_modular_form' })(
  class extends Component {
    render() {
      const { title, slug, form, visible, onCancel, onCreate, isSubmit, handleBlurTitle } = this.props
      const { getFieldDecorator } = form
      // console.log('---ModularPageForm--form: ', form)
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={'Create Modular Page'}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-cancel-modular-page' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id='btn-create-modular-page'
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              Add
            </Button>,
          ]}
        >
          <Form>
            <Row gutter={8}>
              <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label='Title'>
                  {getFieldDecorator('title', {
                    initialValue: title ? title : '',
                    rules: [{ required: true, message: 'Please input your title!' }]
                  })(
                    <Input placeholder='Title' onBlur={handleBlurTitle} />,
                  )}
                </Form.Item>
              </Col>
              <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item label='Slug'>
                  {getFieldDecorator('slug', {
                    initialValue: slug ? slug : '',
                  })(
                    <Input placeholder='Slug' />,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )
    }
  }
);

class ModularPageForm extends Component {
  render() {
    const { saveFormRef, title, slug, showModal, handleCancel, handleCreate, isSubmit, handleBlurTitle } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        title={title}
        slug={slug}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        handleBlurTitle={handleBlurTitle}
      />
    )
  }
}

export default ModularPageForm