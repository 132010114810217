import React, { useState } from "react"
import {
  Form,
  Input,
  Select,
  message,
  Upload,
  Button,
  Icon,
  Switch,
} from "antd"

const { Option } = Select

function beforeImageUpload(file) {
  if (
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg"
  ) {
    return true
  }
  message.error("You can only upload image file!")
  return false
}

export default function (props) {
  const {
    getFieldDecorator,
    getFieldValue,
    id,
    name,
    description,
    type,
    weight,
    isActive,
    isHidden,
    canAssignUsingScript,
    defaultPlaceHolderImage,
    image,
    shopifySkuList,
    showModal,
  } = props
  const [removeFile, setRemoveFile] = useState(false)

  const uploadProps = {
    customRequest() {
      return false
    },
    beforeUpload() {
      return false
    },
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  return (
    <Form layout="vertical" className="update-person-form">
      <Form.Item label="Name">
        {getFieldDecorator("name", {
          rules: [{ required: true, message: "Please input the Name!" }],
          initialValue: name,
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Description">
        {getFieldDecorator("description", {
          initialValue: description,
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Type">
        {getFieldDecorator("type", {
          initialValue: type ? type : "GENERIC",
          rules: [{ required: true, message: "Please input the Type!" }],
        })(
          <Select placeholder="Select Promotion Plan">
            <Option value="GENERIC">GENERIC</Option>
            <Option value="SUBSCRIPTION">SUBSCRIPTION</Option>
            <Option value="DEFAULT">DEFAULT</Option>
            <Option value="RESTRICTED">RESTRICTED</Option>
            <Option value="SHOPIFY_REWARDED">SHOPIFY REWARDED</Option>
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="Badge Weight">
        {getFieldDecorator("weight", {
          initialValue: weight,
        })(<Input type="number" />)}
      </Form.Item>
      {getFieldValue('type') === 'SHOPIFY_REWARDED' &&
        <Form.Item label="Shopify SKU">
          {getFieldDecorator("shopifySkuList", {
            initialValue: shopifySkuList,
            rules: [{ required: true, message: "Please input at least one Shopify SKU!" }],
          })(
            <Select
              mode="tags"
              size="default"
              placeholder="Please Type"
              dropdownStyle={{ display: 'none' }}
            >
              <Option value="Please Type" disabled>Please Type</Option>
            </Select>
          )}
        </Form.Item>
      }
      <Form.Item label="Active">
        {getFieldDecorator("isActive", {
          initialValue: isActive !== undefined ? isActive : true,          //in create this will make by default true
          valuePropName: "checked",
        })(<Switch />)}
      </Form.Item>
      <Form.Item label="Hidden">
        {getFieldDecorator("isHidden", {
          initialValue: isHidden,
          valuePropName: "checked",
        })(<Switch />)}
      </Form.Item>
      {getFieldValue('type') !== 'SHOPIFY_REWARDED' && 
        <Form.Item label="Allow Access From API">
          {getFieldDecorator("canAssignUsingScript", {
            initialValue: canAssignUsingScript,
            valuePropName: "checked",
          })(<Switch />)}
        </Form.Item>
      }
      <Form.Item label="Default PlaceHolder Image">
        {getFieldDecorator("defaultPlaceHolderImage", {
          valuePropName: "fileList",
          initialValue: defaultPlaceHolderImage
            ? [
              {
                uid: "-1",
                name:
                  defaultPlaceHolderImage &&
                  defaultPlaceHolderImage.split("/") &&
                  defaultPlaceHolderImage.split("/")[
                  defaultPlaceHolderImage.split("/").length - 1
                  ],
                status: "done",
                url: defaultPlaceHolderImage,
                thumbUrl: defaultPlaceHolderImage,
              },
            ]
            : [],
          getValueFromEvent: normFile,
          rules: [
            {
              required: true,
              message: "Please upload Default placeholder image!",
            },
          ],
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id="btn-badge-placeholder-img">
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
      <Form.Item label="Badge Image">
        {getFieldDecorator("image", {
          valuePropName: "fileList",
          initialValue: image
            ? [
              {
                uid: "-1",
                name:
                  image &&
                  image.split("/") &&
                  image.split("/")[image.split("/").length - 1],
                status: "done",
                url: image,
                thumbUrl: image,
              },
            ]
            : [],
          getValueFromEvent: normFile,
          rules: [
            {
              required: true,
              message: "Please upload Badge image!",
            },
          ],
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id="btn-badge-img">
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
    </Form>
  )
}
