import gql from 'graphql-tag'

const GET_DISCUSSIONS_SUBSCRIPTION = gql`
subscription discussion {
  discussion {
    mutation
    node{
      id
      title
      isLive
      status
      owner{
        id
        firstName
        lastName
        email
      }
      createdAt
      scheduleAt
    }
  }
}
`

export { GET_DISCUSSIONS_SUBSCRIPTION }
