import React, { useEffect, useState, useRef, useContext } from 'react'
import { Row, Col, Table, Typography, Button, Popconfirm, Form, Input, Select, Icon, notification, Tooltip, DatePicker, Pagination } from 'antd'
import moment from 'moment-timezone'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { NavLink, withRouter } from 'react-router-dom'
import { get } from 'lodash'
import * as Sentry from '@sentry/browser'
import { chatClient } from '../../apollo'
import { AppContext } from '../../AppContext'
import { GET_DISCUSSIONS } from './graphql/Queries'
import { GET_DISCUSSIONS_SUBSCRIPTION } from './graphql/Subscription'
import { CREATE_DISCUSSION, UPDATE_DISCUSSION, DELETE_DISCUSSION } from './graphql/Mutations'
import { GET_AUTHOR_LIST } from './graphql/Queries'
import AddDiscussionModal from './components/AddDiscussionModal'
import Spinner from '../../components/loaders/Spinner'
import Meta from '../../components/Meta'
import { getDownloadDiscussionData } from './api'
import './discussions.css'
const { Title } = Typography
const FormItem = Form.Item
const Option = Select.Option
const EditableContext = React.createContext()

function EditableCell(props) {
  const [authorLoading, setAuthorLoading] = useState(false)
  const [authors, setAuthors] = useState([])
  const { state } = useContext(AppContext)

  useEffect(() => {
    if (state.currentUser.role !== 'AUTHOR') {
      const queryFilter = { first: 60, skip: 0 }
      setAuthorLoading(true)
      chatClient.query({
        query: GET_AUTHOR_LIST,
        variables: queryFilter,
        fetchPolicy: 'network-only'
      }).then(data => {
        setAuthors(data.data.authorList)
        setAuthorLoading(false)
      })
    }
  }, [state.currentUser])


  function disabledStartDate(startValue) {
    return startValue.valueOf() < moment().valueOf();
  }
  function getInput() {
    if (props.inputType === 'select') {
      return <Select placeholder="Select Host" loading={authorLoading}>
        {authors && authors.length > 0 && authors.map((author, key) => (
          <Option value={author.email} key={key}>{author.firstName} {author.lastName}</Option>
        ))}
      </Select>
    }
    if (props.inputType === 'date') {
      return <DatePicker
        placeholder="Select Date/Time"
        format="MM-DD-YYYY hh:mm A"
        showTime={{ use12Hours: true, format: "hh:mm A" }}
        disabledDate={disabledStartDate}
      />
    }
    return <Input />
  }
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    ...restProps
  } = props
  return (
    <EditableContext.Consumer>
      {(form) => {
        const { getFieldDecorator } = form
        return (
          <td {...restProps}>
            {editing ?
              <>
                {dataIndex === "scheduleAt" && (record.isLive === 'OPEN' || record.isLive === 'ENDED' || record.isLive === 'UPCOMING') ?
                  restProps.children
                  :
                  (<FormItem style={{ margin: 0 }}>
                    {getFieldDecorator(dataIndex, {
                      rules: [{ required: dataIndex === 'scheduleAt' ? false : true, message: `Please Input ${title}!` }],
                      initialValue: (dataIndex === 'scheduleAt' && record[dataIndex]) ? moment(record[dataIndex]) : (dataIndex === 'host' && record[dataIndex]) ? record[dataIndex].email : record[dataIndex],
                    })(getInput())}
                  </FormItem>)}
              </> :
              restProps.children
            }
          </td>
        )
      }}
    </EditableContext.Consumer>
  )
}

function EditableTable(props) {
  const [editingKey, setEditingKey] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  // eslint-disable-next-line
  const [isSubmit, setSubmit] = useState(false)
  const { state } = useContext(AppContext)
  const [discussions, setDiscussions] = useState([])
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [lastPage, setLastPage] = useState(false)

  const { data: discussionsData, loading: isDiscussionsLoading, error: DiscussionsError, fetchMore, networkStatus } = useQuery(GET_DISCUSSIONS, { client: chatClient, variables: { first: 10 }, fetchPolicy: "network-only" })
  const { data: discussionSubscriptionData } = useSubscription(GET_DISCUSSIONS_SUBSCRIPTION, { client: chatClient })
  const isAdmin = get(state, 'currentUser.role') === 'ADMIN'

  useEffect(() => {
    if (discussionsData && discussionsData.discussions) {
      setDiscussions([...discussionsData.discussions])
    }
  }, [discussionsData])

  useEffect(() => {
    async function handlePageChange() {
      if (!isDiscussionsLoading) {
        try {
          setTableLoading(true)
          fetchMore({
            variables: {
              skip: (currentPageNumber - 1) * 10,
              first: 10
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { discussions } = fetchMoreResult
                if (discussions?.length < 10) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                setTableLoading(false)
                return discussions?.length ? { discussions: [...discussions] } : prevResult
              }
            }
          })
        } catch (error) {
          setTableLoading(false)
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber])

  useEffect(() => {
    if (!isDiscussionsLoading && discussionsData?.discussions) {
      if (discussionsData?.discussions?.length < 10) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [isDiscussionsLoading])

  function exportDetails(discussion) {
    if (discussion && discussion.id && discussion.subject) {
      getDownloadDiscussionData(discussion.id).then((res) => {
        if (res && res.data && res.hasData) {
          const csvData = new Blob([res.data], { type: 'text/csv' })
          let link = document.createElement('a')
          let csvUrl = URL.createObjectURL(csvData)
          link.href = csvUrl
          link.setAttribute('download', `${discussion.subject}.csv`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else if (res && !res.hasData && res.message) {
          openNotification("error", res.message)
        } else {
          openNotification("error", "No discussion messages are available.")
        }
      }).catch((error) => {
        if (error && error.key && error.key === 'INVALID_TOKEN' && error.message) {
          openNotification("error", error.message)
        } else {
          openNotification("error", "Something Went Wrong")
        }
        Sentry.captureException(new Error(JSON.stringify(error)))
      })
    } else {
      openNotification("error", "Something Went Wrong. Discussion id not avaialble.")
    }
  }

  const column = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      editable: true,
      width: '35%',
      render: (text, record, index) => <NavLink to={`/discussions/${record.id}`} className="discussions-subject">{text}</NavLink>
    },
    {
      title: 'Status',
      dataIndex: 'isLive',
      editable: false,
      width: '10%'
    },
    {
      title: 'Time',
      dataIndex: 'scheduleAt',
      editable: true,
      width: '15%',
      render: (text, record, index) => moment(text).tz('America/Los_Angeles').format('Do MMMM YYYY, hh:mm:ss a')
    },
    {
      title: 'Host',
      dataIndex: 'host',
      editable: state.currentUser.role !== 'AUTHOR' ? true : false,
      width: '15%',
      render: (text, record, index) => `${text.firstName} ${text.lastName}`
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      editable: false,
      width: '15%',
      render: text => `${text ? `${text} Comments` : ''}`,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      width: '25%',
      render: (text, record) => {
        const editable = isEditing(record);
        const { isLive } = record
        return (
          <div>
            {editable ? (
              <>
                <EditableContext.Consumer>
                  {form => (
                    <Tooltip title="Save">
                      {/* eslint-disable-next-line */}
                      <a onClick={() => editDiscussions(form, record.id)} style={{ marginRight: 8 }}>
                        <Icon type="check-circle" className='discussion-save check-cirlce-icon' />
                      </a>
                    </Tooltip>
                  )}
                </EditableContext.Consumer>
                <Tooltip title="Cancel">
                  {/* eslint-disable-next-line */}
                  <a onClick={() => cancel()}>
                    <Icon type="close-circle" className='discussion-cancel close-button-icon' />
                  </a>
                </Tooltip>
              </>
            ) : (
                <>
                  <Tooltip title="Edit Discussion">
                    <Icon type="edit" theme="twoTone" onClick={() => edit(record.key)} />
                  </Tooltip>
                  {
                    record && record.isLive && record.isLive === "OPEN" ?
                      <Tooltip title="Unable to delete! Discussion is live!" trigger="click">
                        <Icon type="delete" theme="twoTone" className='link-button-icon' />
                      </Tooltip>
                      :
                      <Popconfirm title="Sure to delete discussion?" onConfirm={() => handleDelete(record.id)}>
                        <Tooltip title="Delete Discussion">
                          <Icon type="delete" theme="twoTone" className='link-button-icon' />
                        </Tooltip>
                      </Popconfirm>
                  }
                  {isAdmin && isLive === 'ENDED' && <Tooltip title="Export Messages">
                    <Button id={`btn-download-${record.id}-discussion`} type='link' className='link-button' onClick={() => exportDetails(record)}>
                      <Icon type="download" className='link-button-icon' />
                    </Button>
                  </Tooltip>}
                </>
              )}
          </div>
        )
      },
    },
  ]

  function isEditing(record) {
    return record.key === editingKey
  }

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleRequestFail() {
    setSubmit(false)
    setTableLoading(false)
    openNotification("error", "Something Went Wrong")
  }

  function cancel() {
    setEditingKey('')
  }

  async function handleDelete(discussionId) {
    setTableLoading(true)
    try {
      await chatClient.mutate({
        mutation: DELETE_DISCUSSION,
        variables: { id: discussionId },
        refetchQueries: [{
          query: GET_DISCUSSIONS,
          variables: {
            skip: (currentPageNumber - 1) * 10,
            first: 10,
            fetchPolicy: "network-only"
          }
        }
        ]
      })

      setTimeout(() => {
        openNotification("success", "Discussion Deleted successfully")
        setTableLoading(false)
        chatClient.query({
          query: GET_DISCUSSIONS,
          variables: {
            skip: (currentPageNumber - 1) * 10,
            first: 10,
            fetchPolicy: "network-only"
          }
        }).then(({ data }) => {
          if (data && data.discussions) {
            setDiscussions([...data.discussions])
          }
        })
      }, 500)
    } catch (err) {
      console.log(err)
      handleRequestFail()
    } finally {
      setTableLoading(false)
    }
  }

  function editDiscussions(form, discussionId) {
    form.validateFields(async (error, row) => {
      if (error)
        return
      const { subject, host } = row
      // let scheduleAtTime = null
      // if (scheduleAt) {
      //   scheduleAtTime = scheduleAt.toISOString()
      // }
      setTableLoading(true)
      await chatClient.mutate({
        mutation: UPDATE_DISCUSSION,
        variables: { title: subject, email: host || state.currentUser.email, id: discussionId }, // scheduleAt: scheduleAtTime,
        refetchQueries: [{
          query: GET_DISCUSSIONS,
          variables: {
            skip: (currentPageNumber - 1) * 10,
            first: 10,
            fetchPolicy: "network-only"
          },
        }]
      })
        .then(async updateDiscussionResult => {
          setTimeout(() => {
            openNotification("success", "Discussion updated successfully")
            setTableLoading(false)
            setEditingKey('')
            chatClient.query({
              query: GET_DISCUSSIONS,
              variables: {
                skip: (currentPageNumber - 1) * 10,
                first: 10,
                fetchPolicy: "network-only"
              },
            }).then(({ data }) => {
              if (data && data.discussions) {
                setDiscussions([...data.discussions])
              }
            })
          }, 500)
        })
        .catch((e) => {
          handleRequestFail()
        })
    })
  }

  function edit(key) {
    setEditingKey(key)
  }
  const components = {
    body: {
      cell: EditableCell,
    },
  }

  const columns = column.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'host' ? 'select' : col.dataIndex === 'scheduleAt' ? 'date' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  function handleChange(sorter) {
    if (sorter?.field && sorter?.order) {
      setCurrentPageNumber(1)
      setLastPage(false)
    }
  }

  let discussionTableData = []

  if (discussions && discussions.length) {
    discussionTableData = discussions.map((discussion, key) => (
      {
        id: discussion.id,
        key: key.toString(),
        subject: discussion.title,
        isLive: discussion.status.toUpperCase(),
        host: discussion.owner,
        comments: discussion.messagesCount,
        scheduleAt: discussion.scheduleAt
      }
    ))
  }
  if (get(discussionSubscriptionData, 'discussion') && discussionSubscriptionData.discussion.mutation === "DELETED") {
    let checkInDis = discussionTableData.findIndex((dis) => dis.id === get(discussionSubscriptionData, 'discussion.node.id'))
    let checkInDisMain = discussions.findIndex((dis) => dis.id === get(discussionSubscriptionData, 'discussion.node.id'))
    if (checkInDis > -1) {
      discussionTableData.splice(checkInDis, 1)
    }
    if (checkInDisMain > -1) {
      discussions.splice(checkInDisMain, 1)
    }
  } else if (get(discussionSubscriptionData, 'discussion') && discussionSubscriptionData.discussion.mutation === "CREATED") {
    let checkInDis = discussionTableData.findIndex((dis) => dis.id === get(discussionSubscriptionData, 'discussion.node.id'))
    if (checkInDis === -1) {
      const { discussion: { node = {} } = {} } = discussionSubscriptionData
      discussionTableData.unshift({
        id: node.id,
        key: discussionTableData.length + 1,
        subject: node.title,
        isLive: node.status.toUpperCase(),
        host: node.owner,
        comments: 0,
        scheduleAt: node.scheduleAt
      })
    }
  }
  return (
    networkStatus === 1 ? <div className="callback-wrapper"><Spinner /></div> :
      DiscussionsError ? <>{DiscussionsError.message}</> :
        <EditableContext.Provider value={props.form}>
          <Table
            bordered
            rowClassName="editable-row"
            loading={tableLoading || isDiscussionsLoading}
            components={components}
            dataSource={discussionTableData}
            columns={columns}
            pagination={false}
            onChange={handleChange}
          />
          <div className="page-change-btn-wrapper">
            <Button icon="left" onClick={() => setCurrentPageNumber(currentPageNumber - 1)} disabled={currentPageNumber === 1} />
            <Button icon="right" onClick={() => setCurrentPageNumber(currentPageNumber + 1)} disabled={lastPage} />
          </div>
        </EditableContext.Provider>
  )
}

const EditableFormTable = withRouter(Form.create()(EditableTable))

export default function DiscussionsManagement(props) {
  const [isSubmit, setSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  // eslint-disable-next-line
  const [tableLoading, setTableLoading] = useState(false)
  const saveFormRef = useRef()
  const { state } = useContext(AppContext)

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function openNewDiscussionModal() {
    setShowModal(true)
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    setShowModal(false)
    setSubmit(false)
  }

  function handleRequestFail(errorMessage) {
    setSubmit(false)
    setTableLoading(false)
    openNotification("error", errorMessage || "Something Went Wrong")
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (error, values) => {
      if (error)
        return
      setSubmit(true)
      setTableLoading(true)
      const { subject, host, description, schedule } = values
      let scheduleAt = schedule ? schedule.toISOString() : null
      await chatClient.mutate({
        mutation: CREATE_DISCUSSION,
        variables: { title: subject, email: host || state.currentUser.email, scheduleAt, description },
        refetchQueries: [{
          query: GET_DISCUSSIONS
        }]
      })
        .then(async createDiscussionResult => {
          setTimeout(() => {
            setShowModal(false)
            setSubmit(false)
            setTableLoading(false)
            form.resetFields()
            props.history.push(`/discussions/${createDiscussionResult.data.createDiscussion.id}`)
          }, 500)
        })
        .catch((e) => {
          let errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message && e.graphQLErrors[0].message === "Same title already exist" ? e.graphQLErrors[0].message : "Something Went Wrong"
          handleRequestFail(errorMessage)
          // form.resetFields()
        })
    })
  }
  return (
    <Row gutter={24} type="flex" className="discussions-wrapper">
      <Meta title="Discussions" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Discussions</Title>
          <Button id='btn-new-discussion-modal' type="primary" shape="circle" icon="plus" size="default" onClick={() => openNewDiscussionModal()} />
        </div>
        <EditableFormTable />
        <AddDiscussionModal
          saveFormRef={saveFormRef}
          showModal={showModal}
          isSubmit={isSubmit}
          handleOk={handleCreate}
          handleCancel={handleCancel}
        />
      </Col>
    </Row>
  )
}
