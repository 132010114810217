import React, { Component } from 'react'
import { Col, Tag, Button, Modal, notification } from 'antd'
import moment from 'moment'
import client from '../../../apollo'
import { DELETE_SEASON_PODCAST_EPISODES } from '../graphql/Mutations'
import * as Sentry from '@sentry/browser'
import { GET_SEASON_WITH_EPISODES } from '../graphql/Queries'
import { withRouter } from 'react-router-dom'
import { truncateName } from '../../../common/utility'
const confirm = Modal.confirm

class EpisodeCard extends Component {
  openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  handleRequestFail() {
    this.openNotification('error', 'Something Went Wrong')
  }

  showConfirm(id, seasonId, title, seasonName) {
    confirm({
      title: `Are you sure you want to remove "${title}" from "${seasonName}"`,
      okText: 'Remove',
      okType: 'danger',
      onOk: async () => {
        try {
          await client.mutate({
            mutation: DELETE_SEASON_PODCAST_EPISODES,
            variables: { id: id },
            refetchQueries: [
              {
                query: GET_SEASON_WITH_EPISODES,
                variables: { id: seasonId, first: 10 },
                fetchPolicy: 'network-only',
              },
            ],
          })
          setTimeout(() => {
            this.props.updateEpisodeList()
            this.openNotification(
              'success',
              'Season Episode Deleted Successfully',
            )
          }, 500)
        } catch (error) {
          Sentry.captureException(error)
          this.handleRequestFail()
        }
      },
    })
  }

  render() {
    const {
      match: {
        params: { seasonId },
      },
    } = this.props
    const {
      seasonName,
      id,      
      title, 
      thumbnail, 
      status, 
      publishDate
    } = this.props

    return (
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={6}
        xl={6}
        xxl={6}
        className="season-podcast-card-col-wrapper"
      >
        <div className="season-card-wrapper season-podcast-card-wrapper">
          <Tag className={status}>{status}</Tag>
          <div className="season-episode-delete-btn">
            <Button
              id={`btn-season-episode-${id}-delete`}
              key="2"
              size="default"
              icon="delete"
              type="danger"
              onClick={() => {
                this.showConfirm(id, seasonId, title, seasonName)
              }}
            />
          </div>
          <div className="episode-card season-podcast-episode">
            <div className="text-center d-flex">
              <img src={`${thumbnail}?w=300&h=300`} alt={title} title={title} />
            </div>
            <div className="episode-details">
              <p className="episode-title">{truncateName(title)}</p>
              <p className="episode-date">
                {publishDate ? moment(publishDate).format('MMMM Do, YYYY'): ''}
              </p>
            </div>
          </div>
        </div>
      </Col>
    )
  }
}

export default withRouter(EpisodeCard)
