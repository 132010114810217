import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import ReactAudioPlayer from 'react-audio-player'

export default function PlayerModal(props) {
  const { episode, audioPlayType, showPlayerModal, handleOk } = props
  const [playerAudioUrl, setPlayerAudioUrl] = useState(null)

  useEffect(() => {
    if (audioPlayType === 'audioWithAds') {
      setPlayerAudioUrl(episode?.audioWithAds)
    } else {
      setPlayerAudioUrl(episode?.audio)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    showPlayerModal &&
    <Modal
      title={`${episode?.title} ${audioPlayType === 'audioWithAds' ? '(With Ads)' : '(Without Ads)'}`}
      width={'40%'}
      height={'40vh'}
      visible={showPlayerModal}
      footer={null}
      onOk={() => handleOk()}
      onCancel={() => handleOk()}
    >
      <div className="w-100 text-center podcast-audio-player">
        {playerAudioUrl &&
          <ReactAudioPlayer
            src={playerAudioUrl}
            autoPlay
            controls
            controlsList="nodownload"
          />
        }
      </div>
    </Modal>
  )
}
