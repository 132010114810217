import React, { useState, useContext, useRef } from 'react'
import {
  Row,
  Col,
  Modal,
  Button,
  PageHeader,
  notification,
  Tooltip,
  Tag
} from 'antd'
import { useQuery } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import client from '../../apollo'
import { AppContext } from '../../AppContext'
import { fileUpload } from '../../common/fileUpload'
import {
  GET_PODCAST,
  GET_PODCAST_SIGNED_PUT_URL,
} from './graphql/Queries'
import {
  UPDATE_PODCAST,
  UPDATE_PODCAST_IMAGE,
  DELETE_PODCAST,
  UPDATE_PODCAST_STATUS
} from './graphql/Mutations'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import EpisodeWrapper from '../podcast-episode/components/EpisodeWrapper'
import './podcasts.css'
import '../episode/episode.css'
import AddPodcastModal from './components/AddPodcastModal'

const confirm = Modal.confirm
function checkStatus(status) {
  if (
    status === 'LIVE_FINISHED' ||
    status === 'DRAFT' ||
    status === 'UNPUBLISHED'
  ) {
    return false
  }
  return true
}

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const {
    history,
    match: {
      params: { podcastId },
    },
  } = props
  const [isSubmit, setSubmit] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  const {
    data: podcastData,
    loading: isShowsLoading,
    error: showsError,
  } = useQuery(GET_PODCAST, {
    variables: { id: podcastId },
    fetchPolicy: 'network-only',
  })

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  if (showsError) {
    openNotification('error', showsError.message)
  }

  function handleRequestFail(e) {
    setSubmit(false)
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setSubmit(true)
        const queryVariables = values
        const {
          coverImage,
          backgroundImage,
          logoImage
        } = values
        const {
          match: {
            params: { podcastId },
          },
        } = props
        queryVariables.podcastId = podcastId
        let backgroundImageName = ''
        let logoImageName = ''
        if (queryVariables.coverImage) delete queryVariables.coverImage
        if (queryVariables.logoImage) delete queryVariables.logoImage
        if (queryVariables.backgroundImage)
          delete queryVariables.backgroundImage
        if (!queryVariables.author) {
          queryVariables.author = state.currentUser.id
        }
        if (queryVariables.weight) {
          queryVariables.weight = Number(queryVariables.weight)
        }
        const updatePodcastResult = await client.mutate({
          mutation: UPDATE_PODCAST,
          variables: queryVariables,
          refetchQueries: [
            {
              query: GET_PODCAST,
              variables: { id: podcastId },
            },
          ],
        })

        if (coverImage && coverImage.length > 0) {
          const imageFileName = coverImage[0].name
          const tempFilename = imageFileName.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${updatePodcastResult.data.updatePodcastCustom.id
            }-${new Date().getTime()}.${fileExt}`
          const contentType = coverImage[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_PODCAST_SIGNED_PUT_URL,
            variables: { fileName, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getPodcastSignedPutUrl.signedUrl,
            coverImage[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_PODCAST,
            variables: {
              coverImage: fileName,
              author: queryVariables.author,
              podcastId,
            },
          })
        }
        if (backgroundImage && backgroundImage.length > 0) {
          const tempBgImgFilenameArray = backgroundImage[0].name.split('.')
          const bgFileExt =
            tempBgImgFilenameArray[tempBgImgFilenameArray.length - 1]
          const bgFileName = `${podcastId}-backgroundImage-${new Date().getTime()}.${bgFileExt}`
          const bgContentType = backgroundImage[0].type
          const getBISignedPutUrlResult = await client.query({
            query: GET_PODCAST_SIGNED_PUT_URL,
            variables: { fileName: bgFileName, contentType: bgContentType },
          })
          backgroundImageName = bgFileName
          await fileUpload(
            getBISignedPutUrlResult.data.getPodcastSignedPutUrl.signedUrl,
            backgroundImage[0].originFileObj,
          )
        }
        if (logoImage && logoImage.length > 0) {
          const tempLogoFilenameArray = logoImage[0].name.split('.')
          const logoFileExt =
            tempLogoFilenameArray[tempLogoFilenameArray.length - 1]
          const logoFileName = `${podcastId}-logoImage-${new Date().getTime()}.${logoFileExt}`
          const logoContentType = logoImage[0].type
          const getLogoSignedPutUrlResult = await client.query({
            query: GET_PODCAST_SIGNED_PUT_URL,
            variables: { fileName: logoFileName, contentType: logoContentType },
          })
          await fileUpload(
            getLogoSignedPutUrlResult.data.getPodcastSignedPutUrl.signedUrl,
            logoImage[0].originFileObj,
          )
          logoImageName = logoFileName
        }

        if (
          (logoImage && logoImage.length > 0) ||
          (backgroundImage && backgroundImage.length > 0)
        ) {
          let updateVariables = {
            logoImage: logoImageName,
            backgroundImage: backgroundImageName,
            podcastId,
          }
          if (!(logoImage && logoImage.length > 0))
            delete updateVariables.logoImage
          if (!(backgroundImage && backgroundImage.length > 0))
            delete updateVariables.backgroundImage
          await client.mutate({
            mutation: UPDATE_PODCAST_IMAGE,
            variables: updateVariables,
            refetchQueries: [
              {
                query: GET_PODCAST,
                variables: { id: podcastId },
                fetchPolicy: 'network-only',
              },
            ],
          })
        }
        openNotification('success', 'Podcast Updated Successfully')
        form.resetFields()
        setShowModal(false)
        setSubmit(false)
      } catch (error) {
        console.log(error)
        Sentry.captureException(error)
        handleRequestFail(error)
      }
    })
  }

  function showConfirm(podcastId) {
    const { history } = props
    confirm({
      title: 'Are you sure you want to DELETE this podcast?',
      content: 'Upon clicking DELETE, the podcast will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        client
          .mutate({
            mutation: DELETE_PODCAST,
            variables: { podcastId },
          })
          .then((e) => {
            openNotification('success', 'Podcast Deleted Successfully')
            setTimeout(() => {
              history.push('/podcasts')
            }, 500)
          })
          .catch((error) => {
            Sentry.captureException(error)
            handleRequestFail(error)
          })
      },
    })
  }

  function publishPodcast(podcastId, status) {
    const title =
      status === 'PUBLISHED'
        ? 'Are you sure you want to publish?'
        : 'Are you sure you want to unpublish?'
    const content =
      status === 'PUBLISHED'
        ? 'Upon clicking publish, this podcast will become available to front end users'
        : 'Upon clicking unpublish, this podcast will become unavailable to front end users'
    const okText =
      status === 'PUBLISHED'
        ? 'PUBLISH'
        : 'UNPUBLISH'
    const okType = status === 'UNPUBLISHED' ? 'danger' : 'primary'
    const notification =
      status === 'PUBLISHED'
        ? 'Podcast Published Successfully'
        : 'Podcast Was Successfully Unpublished'

    confirm({
      title,
      content,
      okText,
      okType,
      async onOk() {
        try {
          await client.mutate({
            mutation: UPDATE_PODCAST_STATUS,
            variables: { podcastId, status },
            refetchQueries: [
              {
                query: GET_PODCAST,
                variables: { id: podcastId },
                fetchPolicy: 'network-only',
              },
            ],
          })
          openNotification('success', notification)
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail()
        }
      },
    })
  }

  return (
    <div className="podcast-detail-page">
      {isShowsLoading ? (
        <Spinner />
      ) : podcastData && podcastData.getPodcastDetails ? (
        <>
          <Meta title={podcastData.getPodcastDetails.name || ''} description="Show" />
          <AddPodcastModal
            name={podcastData.getPodcastDetails.name}
            description={podcastData.getPodcastDetails.description}
            author={podcastData.getPodcastDetails.author.id}
            weight={podcastData.getPodcastDetails.weight}
            coverImage={podcastData.getPodcastDetails.coverImage}
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            handleCancel={() => {
              setShowModal(false)
              saveFormRef.current.props.form.resetFields()
            }}
            handleCreate={handleCreate}
            isEdit={true}
          />
          <Row gutter={24} type="flex" className="podcasts-title-wrapper">
            <Col span={24}>
              <PageHeader
                onBack={() => history.push('/podcasts')}
                title={podcastData.getPodcastDetails.name}
                tags={
                  <Tag className={podcastData.getPodcastDetails.status}>
                    {podcastData.getPodcastDetails.status}
                  </Tag>
                }
                className="box page-header podcast-pageheader-wordbreak"
                extra={[
                  <Button
                    id='btn-podcast-edit'
                    key="1"
                    shape="circle"
                    icon="edit"
                    size="small"
                    onClick={() => setShowModal(true)}
                  />,
                    <Button
                      id='btn-podcast-delete'
                      key="2"
                      shape="circle"
                      icon="delete"
                      size="small"
                      type="danger"
                      ghost={true}
                      onClick={() => showConfirm(podcastId)}
                  />,
                  podcastData.getPodcastDetails.status !== 'PUBLISHED' ? (
                    <Button
                      id='btn-podcast-publish'
                      key="3"
                      type="primary"
                      size="small"
                      disabled={checkStatus(podcastData.getPodcastDetails.status)}
                      onClick={() =>
                        publishPodcast(
                          podcastData.getPodcastDetails.id,
                          'PUBLISHED',
                        )
                      }
                    >
                      PUBLISH
                    </Button>
                  ) : (
                    <Button
                      id='btn-podcast-unpublish'
                      key="3"
                      type="danger"
                      size="small"
                      onClick={() =>
                        publishPodcast(
                          podcastData.getPodcastDetails.id,
                          'UNPUBLISHED',
                        )
                      }
                    >
                      UNPUBLISH
                    </Button>
                  )
                ]}
              />
            </Col>
          </Row>
          <EpisodeWrapper
            history={history}
            podcastId={podcastId}
            podcastData={podcastData}
          />
        </>
      ) : (
        <Page404 />
      )}
    </div>
  )
}
