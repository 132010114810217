import React, { Component } from 'react'
import FeaturedEpisodeCard from './FeaturedEpisodeCard'

export default class FeaturedEpisodeWrapper extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnScroll)
  }

  handleOnScroll = () => {
    // http://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop
    var scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight
    var clientHeight =
      document.documentElement.clientHeight || window.innerHeight
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight
    if (scrolledToBottom) {
      this.props.onLoadMore()
    }
  }

  render() {
    const { episodes } = this.props
    return (
      episodes &&
      episodes.map((episode, id) => (
        <FeaturedEpisodeCard {...episode} key={id} />
      ))
    )
  }
}
