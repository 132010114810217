import gql from "graphql-tag"

const CREATE_DISCUSSION = gql`
mutation createDiscussion($title: String!, $description: String, $email: String!, $scheduleAt: DateTime){
  createDiscussion(data: { type: DISCUSSION, title: $title, description: $description, status: "UPCOMING", createdBy: { connect: { email: $email } }, owner: { connect: { email: $email } }, scheduleAt: $scheduleAt }) {
    id
  }
}
`

const UPDATE_DISCUSSION = gql`
mutation updateDiscussion($title: String, $description: String, $email: String, $scheduleAt: DateTime, $id: ID!){
  updateDiscussion(data: { title: $title, description: $description, scheduleAt: $scheduleAt, owner: { connect: { email: $email } } }, where: { id: $id }) {
    id
  }
}
`

const UPDATE_DISCUSSION_STATUS = gql`
mutation updateDiscussion($isLive: Boolean, $status: String, $id: ID!){
  updateDiscussion(data: { isLive: $isLive, status: $status }, where: { id: $id }) {
    id
  }
}
`

const DELETE_DISCUSSION = gql`
mutation deleteDiscussion($id: ID!){
  deleteDiscussion(where: { id: $id }) {
    id
  }
}
`

export { CREATE_DISCUSSION, UPDATE_DISCUSSION, UPDATE_DISCUSSION_STATUS, DELETE_DISCUSSION }
