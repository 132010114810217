import gql from 'graphql-tag'

const GET_LIVECHATS = gql`
query discussions($skip: Int, $first: Int) {
  discussions(orderBy: scheduleAt_DESC, where: { type: EPISODE }, skip: $skip, first: $first) {
    id
    title
    messagesCount
    isLive
    status
    owner{
      id
      firstName
      lastName
    }
    createdAt
    scheduleAt
  }
}
`

export { GET_LIVECHATS }
