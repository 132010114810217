import React, { useEffect, useState } from 'react'
import { Row, Select, notification } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_FILTERED_SEASONS, GET_SEASONS, LIST_SHOW_FOR_SEASON, PODCASTS, SHOWS } from './graphql/Queries'
import CardLoader from '../../components/loaders/CardLoader'
import SeasonWrapper from './components/SeasonWrapper'
import AddShowCard from './components/AddShowCard'
import Meta from '../../components/Meta'
import * as Sentry from '@sentry/browser'
import './shows.css'
import client from '../../apollo'
import { get } from 'lodash'

const Option = Select.Option

export default function (props) {
  const [seasons, setSeasons] = useState([])
  const [loading, setLoading] = useState([])
  const [lastSeasonId, setLastSeasonId] = useState()
  const [showId, setShowId] = useState()
  const [seasonsType, setSeasonsType] = useState(null)
  const [podcastId, setPodcastId] = useState(null)
  const [loadingMoreShows, setLoadingMoreShows] = useState(false)
  const [showDataEndReached, setShowDataEndReached] = useState(false)
  const [loadingMorePodcasts, setLoadingMorePodcasts] = useState(false)
  const [podcastDataEndReached, setPodcastDataEndReached] = useState(false)
  const {
    data,
    loading: isSeasonsLoading,
    error: seasonsError,
    fetchMore,
  } = useQuery(GET_FILTERED_SEASONS, {
    variables: {
      first: 20,
      where: {
        ...(seasonsType && { type: seasonsType }),
        ...(podcastId && { podcast: { id: podcastId } }),
        ...(showId && { show: { id: showId } })
      }
    },
    fetchPolicy: 'network-only',
  })

  const { data: podcastData, loading: isPodcastsLoading, error: podcastsError, fetchMore: fetchMorePodcasts } = useQuery(PODCASTS, {
    variables: { first: 20 },
    fetchPolicy: 'network-only'
  })

  const { data: showData, loading: isShowsLoading, error: showsError, fetchMore: fetchMoreShows } = useQuery(SHOWS, {
    variables: { first: 20 },
    fetchPolicy: 'network-only'
  })

  const {
    data: { listShowForSeason },
    loading: showsLoading,
  } = useQuery(LIST_SHOW_FOR_SEASON, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isSeasonsLoading && data?.seasons) {
      setLoading(false)
      setSeasons(data?.seasons)
      if(data?.seasons?.length > 0){
        setLastSeasonId(
          data.seasons &&
          data.seasons[data.seasons.length - 1] &&
          data.seasons[data.seasons.length - 1]?.id,
        )
      }
    } else {
      setLoading(true)
    }
  }, [isSeasonsLoading])

  const onShowSelect = (e) => {
    if (e !== 'ALL') {
      setShowId(e)
    } else {
      setShowId(null)
    }
  }

  const seasonsTypeChange = (e) => {
    setShowId(null)
    setPodcastId(null)
    if (e !== 'ALL') {
      setSeasonsType(e)
    } else {
      setSeasonsType(null)
    }
  }

  const onPodcastSelect = (e) => {
    if (e !== 'ALL') {
      setPodcastId(e)
    } else {
      setPodcastId(null)
    }
  }

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleRequestFail(e) {
    openNotification("error", e || "Something Went Wrong")
  }

  const loadMoreShows = async () => {
    try {
      setLoadingMoreShows(true)
      let showLength = get(showData, 'shows').length
      let lastId = get(showData, `shows.${showLength - 1}.id`)
      let variables = {
        first: 20,
        after: lastId,
      }
      await fetchMoreShows({
        variables: variables,
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { shows: newSeasons } = fetchMoreResult
          if (newSeasons.length < 20) {
            setShowDataEndReached(true)
          }
          if (!newSeasons) return prevResult;
          return { ...prevResult, shows: [...prevResult.shows, ...newSeasons] }
        },
      })

    } catch (e) {
      Sentry.captureException(e)
      let errorMessage = get(e, 'graphQLErrors.0.message', 'Something Went Wrong')
      handleRequestFail(errorMessage)
    } finally {
      setLoadingMoreShows(false)
    }
  }

  const showDropDownScroll = async (event) => {

    let target = event.target
    let scrollHeight = target.scrollHeight
    if (!showDataEndReached && target.scrollTop + target.offsetHeight >= target.scrollHeight - 5) {
      await loadMoreShows()
      target.scrollTo(0, scrollHeight - 40)
    }
  }

  const loadMorePodcasts = async () => {
    try {
      setLoadingMorePodcasts(true)
      let podcastLength = get(podcastData, 'listPodcastDetails').length
      let lastId = get(podcastData, `listPodcastDetails.${podcastLength - 1}.id`)
      let variables = {
        first: 20,
        after: lastId,
      }
      await fetchMorePodcasts({
        variables: variables,
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { listPodcastDetails: newPodcasts } = fetchMoreResult
          if (newPodcasts.length < 20) {
            setPodcastDataEndReached(true)
          }
          if (!newPodcasts) return prevResult;
          return { ...prevResult, listPodcastDetails: [...prevResult.listPodcastDetails, ...newPodcasts] }
        },
      })

    } catch (e) {
      Sentry.captureException(e)
      let errorMessage = get(e, 'graphQLErrors.0.message', 'Something Went Wrong')
      handleRequestFail(errorMessage)
    } finally {
      setLoadingMorePodcasts(false)
    }
  }

  const podcastDropDownScroll = async (event) => {

    let target = event.target
    let scrollHeight = target.scrollHeight
    if (!podcastDataEndReached && target.scrollTop + target.offsetHeight >= target.scrollHeight - 5) {
      await loadMorePodcasts()
      target.scrollTo(0, scrollHeight - 40)
    }
  }

  const { history } = props
  return !seasonsError ? (
    <div className="shows-scroll">
      <div className="shows-list season-filter">
        <Select
          defaultValue={'ALL'}
          placeholder="Select Season Type"
          onChange={(e) => seasonsTypeChange(e)}
        >
          <Option value={'ALL'}>All Seasons</Option>
          <Option value={'SHOW'}>Shows</Option>
          <Option value={'PODCAST'}>Podcasts</Option>

        </Select>
        {seasonsType === 'PODCAST' && <Select
          loading={isPodcastsLoading || loadingMorePodcasts}
          defaultValue={'ALL'}
          placeholder="Select Podcast"
          onChange={(e) => onPodcastSelect(e)}
          onPopupScroll={podcastDropDownScroll}
          getPopupContainer={trigger => trigger.parentNode}
          dropdownStyle={{height:'250px'}}
        >
          <Option key={'ALL'} value={'ALL'}>All Podcasts</Option>
          {podcastData &&
            podcastData.listPodcastDetails?.map(({ name, id }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
        </Select>}
        {seasonsType === 'SHOW' && <Select
          loading={isShowsLoading || loadingMoreShows}
          defaultValue={'ALL'}
          placeholder="Select Shows"
          onChange={(e) => onShowSelect(e)}
          onPopupScroll={showDropDownScroll}
          getPopupContainer={trigger => trigger.parentNode}
          dropdownStyle={{height:'250px'}}
        >
          <Option key={'ALL'} value={'ALL'}>
            All Shows
          </Option>
          {showData &&
            showData.shows?.map(({ name, id }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
        </Select>}
      </div>
      <Row gutter={24} type="flex" className="shows-wrapper shows-list">
        <Meta title="Seasons" description="" />
        <AddShowCard history={history} />
        {loading ? (
          <CardLoader />
        ) : (
          <SeasonWrapper
            seasons={seasons}
            onLoadMore={() => {
              let variables = {
                first: 20,
                after: lastSeasonId,
                where: {
                  ...(seasonsType && { type: seasonsType }),
                  ...(podcastId && { podcast: { id: podcastId } }),
                  ...(showId && { show: { id: showId } })
                }
              }
              fetchMore({
                loading: isSeasonsLoading,
                variables: variables,
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  const { seasons: newSeasons } = fetchMoreResult
                  if (newSeasons.length) {
                    setLastSeasonId(newSeasons[newSeasons.length - 1].id)
                    setSeasons([...seasons, ...newSeasons])
                  }
                },
              })
            }}
          />
        )}
      </Row>
    </div>
  ) : (
    <>{seasonsError.message}</>
  )
}
