import gql from "graphql-tag"

export const GET_BADGES = gql`
  query badges($skip: Int, $first: Int) {
    badges(orderBy: createdAt_DESC, skip: $skip, first: $first) {
      id
      name
      image
      description
      slug
      defaultPlaceHolderImage
      type
      weight
      isHidden
      isActive
      canAssignUsingScript
      shopifySkuList
    }
  }
`
export const GET_BADGE_SIGNED_PUT_URL = gql`
  query getBadgeSignedPutUrl($fileName: String!, $contentType: String!) {
    getBadgeSignedPutUrl(
      data: { fileName: $fileName, contentType: $contentType }
    ) {
      signedUrl
      getUrl
    }
  }
`
