import React, { useState } from 'react'
import {
  Col,
  Table,
  Tag,
  PageHeader,
  Button,
  Icon,
  notification,
  Modal,
  Tooltip,
  Spin,
} from 'antd'
import moment from 'moment-timezone'
import * as Sentry from '@sentry/browser'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Player, ControlBar } from 'video-react'
import { Parser } from 'json2csv'
import HLSSource from '../../../HLSSource'
import client, { chatClient } from '../../../apollo'
import { GET_VIDEO_LIVE_STREAM_URL } from '../graphql/Queries'
import { UPDATE_VIDEO } from '../graphql/Mutations'
import Comments from '../../../components/comments/Comments'
import { GET_DISCUSSION_THREAD } from '../../../components/comments/graphql/Queries'
import { UPDATE_DISCUSSION_STATUS } from '../../discussions/graphql/Mutations'
import Spinner from '../../../components/loaders/Spinner'
import { SUBSCRIBE_DISCUSSION } from '../../../components/comments/graphql/Subscriptions'

const { confirm } = Modal

const columns = [
  {
    title: 'Live Details',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
  },
]

export default function LiveVideoSegment(props) {
  const [isLiveStart, setisLiveStart] = useState(false)
  const [isLiveLoaderStart, setisLiveLoaderStart] = useState(false)

  const {
    videoId,
    videoTitle,
    videoStatus,
    videoImage,
    updatedAt,
    scheduleAt,
    videoState,
    discussionId,
  } = props
  const {
    data,
    loading: isLiveSegmentLoading,
    error: liveSegmentError,
  } = useQuery(GET_VIDEO_LIVE_STREAM_URL, { variables: { id: videoId } })
  const {
    data: discussionsData,
    loading: isDiscussionsLoading,
    error: discussionsError,
    fetchMore,
    subscribeToMore,
  } = useQuery(GET_DISCUSSION_THREAD, {
    client: chatClient,
    variables: { id: discussionId || '' },
    fetchPolicy: 'network-only',
  })

  const { data: subscriptionData } = useSubscription(SUBSCRIBE_DISCUSSION, {
    variables: { id: discussionId || '' },
    client: chatClient,
  })

  const discussionStatus =
    discussionsData && discussionsData.discussionThread
      ? subscriptionData &&
        subscriptionData.discussion &&
        subscriptionData.discussion.node &&
        subscriptionData.discussion.node.status
        ? subscriptionData.discussion.node.status.toUpperCase()
        : discussionsData.discussionThread.status.toUpperCase()
      : ''

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function manualLive(status) {
    const checkStatus = status === 'LIVE'
    const title = checkStatus
      ? 'Are you sure you want to start streaming manually?'
      : 'Are you sure you want to finish the live streaming?'
    // const content = checkStatus ? "" : ""
    const okText = checkStatus ? 'Manual Go Live' : 'Finish Streaming'
    const isLive = checkStatus
    const disStatus = checkStatus ? 'OPEN' : 'ENDED'
    confirm({
      title,
      okText,
      async onOk() {
        try {
          if (discussionId) {
            await chatClient.mutate({
              mutation: UPDATE_DISCUSSION_STATUS,
              variables: { isLive, status: disStatus, id: discussionId },
              refetchQueries: [
                {
                  query: GET_DISCUSSION_THREAD,
                  variables: { id: discussionId },
                },
              ],
            })
          }
          await client.mutate({
            mutation: UPDATE_VIDEO,
            variables: {
              data: {
                status,
                isLive,
              },
              where: {
                id: videoId,
              },
            },
          })
          openNotification('success', 'Video Status Updated')
        } catch (error) {
          Sentry.captureException(error)
          openNotification('error', 'Something went wrong')
        }
      },
    })
  }

  function handleStartStop(value) {
    // let content
    let title = value
      ? 'Are you sure you want to START this live chat?'
      : 'Are you sure you want to END this live chat?'
    let okText = value ? 'START NOW' : 'END NOW'
    let okType = value ? 'primary' : 'danger'
    let message = value
      ? 'Live Chat has been started'
      : 'Live Chat has been ended'
    let status = value ? 'OPEN' : 'ENDED'
    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          await chatClient.mutate({
            mutation: UPDATE_DISCUSSION_STATUS,
            variables: { id: discussionId, isLive: value, status },
            refetchQueries: [
              {
                query: GET_DISCUSSION_THREAD,
                variables: { id: discussionId },
              },
            ],
          })
          openNotification('success', message)
        } catch (error) {
          Sentry.captureException(error)
          openNotification('error', 'Something Went Wrong')
        }
      },
    })
  }

  function exportDetails() {
    const fields = ['videoUrl', 'RTMPURL', 'StreamKey']
    const liveDetails = [
      {
        videoUrl: data.getVideoLiveStreamUrl.videoURL,
        RTMPURL: 'rtmp://live.mux.com/app',
        StreamKey: data.getVideoLiveStreamUrl.streamKey,
      },
    ]
    const json2csvParser = new Parser({ fields })
    let csv = json2csvParser.parse(liveDetails)
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv
    }
    csv = encodeURI(csv)
    let link = document.createElement('a')
    document.body.appendChild(link)
    link.setAttribute('href', csv)
    link.setAttribute('download', `${videoTitle}.csv`)
    link.click()
  }

  let tableData = []
  if (data && data.getVideoLiveStreamUrl) {
    tableData = [
      {
        key: '1',
        title: 'Video URL',
        value: (
          <>
            {data.getVideoLiveStreamUrl.videoURL}
            <CopyToClipboard text={data.getVideoLiveStreamUrl.videoURL}>
              <Tooltip title="Click to copy!" placement="bottom">
                <span>
                  <Tooltip title="Copied!" trigger={'click'}>
                    <Icon type="copy" title="Click to copy!" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </>
        ),
      },
      {
        key: '2',
        title: 'RTMP URL',
        value: (
          <>
            rtmp://live.mux.com/app
            <CopyToClipboard text="rtmp://live.mux.com/app">
              <Tooltip title="Click to copy!" placement="bottom">
                <span>
                  <Tooltip title="Copied!" trigger={'click'}>
                    <Icon type="copy" title="Click to copy!" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </>
        ),
      },
      {
        key: '3',
        title: 'Stream Key',
        value: (
          <>
            {data.getVideoLiveStreamUrl.streamKey}
            <CopyToClipboard text={data.getVideoLiveStreamUrl.streamKey}>
              <Tooltip title="Click to copy!" placement="bottom">
                <span>
                  <Tooltip title="Copied!" trigger={'click'}>
                    <Icon type="copy" title="Click to copy!" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </>
        ),
      },
      {
        key: '4',
        title: 'Live Status',
        value: (
          <>
            <Tag className={videoState}>{videoState}</Tag>
            {videoState !== 'ERROR' && (
              <>
                {
                  // videoStatus === 'SCHEDULED' && videoState === 'IDLE' &&
                  videoStatus === 'SCHEDULED' && (
                    <Button
                      id="btn-manual-go-live-video"
                      type="primary"
                      size="small"
                      onClick={() => manualLive('LIVE')}
                    >
                      Manual Go Live
                    </Button>
                  )
                }
                {videoStatus === 'LIVE' && (
                  <Button
                    id="btn-manual-finish-live-video"
                    type="primary"
                    size="small"
                    onClick={() => manualLive('LIVE_FINISHED')}
                  >
                    Manual Finish Live
                  </Button>
                )}
              </>
            )}
          </>
        ),
      },
      {
        key: '5',
        title: 'Scheduled Time',
        value: moment(scheduleAt)
          .tz('America/Los_Angeles')
          .format('Do MMMM YYYY, hh:mm:ss a'),
      },
      {
        key: '6',
        title: 'Export Details',
        value: (
          <Button id='btn-export' type="primary" onClick={() => exportDetails()}>
            Export
          </Button>
        ),
      },
    ]
  }
  if (videoState === 'RECORDING') {
    if (!isLiveLoaderStart) {
      setisLiveLoaderStart(true)
    }
    const setTime =
      Math.round(
        (new Date().getTime() - new Date(updatedAt).getTime()) / 1000,
      ) > 30
        ? 1000
        : 15000
    if (!isLiveStart && isLiveLoaderStart) {
      setTimeout(() => {
        setisLiveStart(true)
        setisLiveLoaderStart(false)
      }, setTime)
    }
  }
  return isLiveSegmentLoading ? (
    <div className="callback-wrapper">
      <Spinner />
    </div>
  ) : liveSegmentError ? (
    <>{liveSegmentError.message}</>
  ) : (
    <>
      <Col lg={12} xl={12} className="live-details-section-wrapper">
        <div className="box">
          <Table
            columns={columns}
            showHeader={false}
            title={() => 'Live Details'}
            dataSource={tableData}
            pagination={false}
            className="segment-live-table"
          />
        </div>
      </Col>
      <Col lg={12} xl={12} className="player-section-wrapper">
        <div className="box">
          {(videoState === 'RECORDING' || videoState === 'DISCONNECTED') &&
          isLiveStart ? (
            <Player playsInline poster={videoImage}>
              <ControlBar className="live-player" />
              <HLSSource
                isVideoChild
                src={data.getVideoLiveStreamUrl.videoURL}
              />
            </Player>
          ) : (
            videoImage && (
              <div className="segment-live-image-wrapper">
                {isLiveLoaderStart ? (
                  <Spin tip="Please Wait...">
                    <img src={videoImage} alt={videoId} width={500} />
                  </Spin>
                ) : (
                  <img src={videoImage} alt={videoId} width={500} />
                )}
              </div>
            )
          )}
        </div>
      </Col>
      <Col lg={24} xl={24}>
        <PageHeader
          title="Live Chat"
          tags={<Tag className={discussionStatus}>{discussionStatus}</Tag>}
          className="d-flex box align-center"
          extra={[
            discussionStatus === 'OPEN' ? (
              <Button
                id='btn-end-chat'
                key="3"
                type="danger"
                size="small"
                onClick={() => handleStartStop(false)}
              >
                END CHAT
              </Button>
            ) : (
              discussionStatus !== 'ENDED' && (
                <Button
                  id='btn-start-chat'
                  key="3"
                  type="primary"
                  size="small"
                  onClick={() => handleStartStop(true)}
                >
                  START CHAT
                </Button>
              )
            ),
          ]}
        />
      </Col>
      <Col lg={24} xl={24}>
        {discussionsError ? (
          <>{discussionsError.message}</>
        ) : (
          discussionsData &&
          discussionsData.discussionThread && (
            <Comments
              discussionId={discussionId}
              discussionThreadMessages={
                discussionsData.discussionThread.messages
              }
              subscribeToMore={subscribeToMore}
              fetchMore={fetchMore}
              isDiscussionsLoading={isDiscussionsLoading}
              isLive={discussionStatus === 'OPEN'}
            />
          )
        )}
      </Col>
    </>
  )
}
