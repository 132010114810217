import React, { useState, useContext, useEffect, Fragment, useRef } from 'react'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { Row, Col, Tooltip, Icon, Avatar, Input, Dropdown, Menu, Modal, notification, Button, Switch, Comment, Collapse, Badge } from 'antd'
import { CREATE_MESSAGE, UPDATE_MESSAGE, CREATE_REPLY_MESSAGE, UPDATE_MESSAGE_LIKE, DELETE_MESSAGE, UPDATE_FLAG_MESSAGE, REMOVE_FLAG_MESSAGE } from './graphql/Mutations'
import { chatClient } from '../../apollo'
import { AppContext } from '../../AppContext'
import discussionsImg from '../../assets/discussions.png'
import { SUBSCRIBE_MESSAGE } from './graphql/Subscriptions'
import Spinner from '../loaders/Spinner'
import { Virtuoso } from 'react-virtuoso'
import './comments.css'
import { debounce, cloneDeep } from 'lodash'

const { confirm } = Modal
const { TextArea } = Input
const { Panel } = Collapse
export default function DiscussionDetailPage(props) {
  const { state } = useContext(AppContext)
  const userId = state.currentPerson.id || ''
  const { discussionId, isDiscussion, isFullscreen, isAdminMessages, subscribeToMore, fetchMore, isDiscussionsLoading, isLive, isCommentDisabled, description } = props

  const [lastId, setLastId] = useState(undefined)
  const [commentContent, setCommentContent] = useState("")
  const [replyToCommentId, setReplyToCommentId] = useState(undefined)
  const [replyLoading, setReplyLoading] = useState(false)
  const [replyCommentMessage, setReplyCommentMessage] = useState('')
  const [dropDownId, setDropDownId] = useState(undefined)
  const [commentLoading, setCommentLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isPause, setIsPause] = useState(false)
  const [isPauseMessages, setIsPauseMessages] = useState([])
  const isPauseMessagesMetaData = useRef([])
  const [newReplyMessageCount, setNewReplyMessageCount] = useState([])
  const newReplayMessageCountMetaData = useRef([])
  const [discussionMessages, setDiscussionMessages] = useState([])
  const [discussionMessagesTempState, setDiscussionMessagesTempState] = useState(discussionMessages)
  const [discussionMessagesTemp, setDiscussionMessagesTemp] = useState(discussionMessages || [])
  const [isCommentEditing, setIsCommentEditing] = useState(false)
  const [editingCommentId, setEditingCommentId] = useState(undefined)
  const [editingCommentComment, setEditingCommentComment] = useState('')
  const [editCommentLoading, setEditCommentLoading] = useState(false)
  const commentsScroller = document.getElementsByClassName('comments-scroller')

  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false)
  const [rowCount, setRowCount] = useState(1)
  const [scrollToBottom, setScrollToBottom] = useState(false)
  const [forceRender, setForceRender] = useState(false)
  const virtuoso = useRef(null)
  const isPauseForUseEffect = useRef(null)
  const inputData = commentLoading && { value: '' }
  const debounceJob = useRef(null)
  const scrolledToBottom = useRef(false)
  const adminMessageCount = useRef(0)
  const lastAdminMessageCount = useRef(0)
  const requestedForMoreMessages = useRef(false)
  const noNewMessagesFound = useRef(false)
  const isTeamMessages = useRef(false)
  const [teamMessagesLoading, setTeamMessagesLoading] = useState(false)
  const [adminDiscussionMessages, setAdminDiscussionMessages] = useState([])
  const [updateFound, setUpdateFound] = useState({})
  const isFirstTime = useRef(false)
  const isReplyEnabled = useRef(false)
  const [adminDiscussionMessagesTemp, setAdminDiscussionMessagesTemp] = useState([])
  const [adminDiscussionWhenReply, setAdminDiscussionWhenReply] = useState([])

  useEffect(() => {
    if (forceRender) {
      setForceRender(false)
    }
  }, [forceRender])

  useEffect(() => {
    if (updateFound) {
      if (!updateFound.parent) {
        const adminMesagesIndex = adminDiscussionMessagesTemp.findIndex((discussion) => discussion && discussion.id === updateFound.id)
        const liveMessagesIndex = discussionMessagesTempState.findIndex((discussion) => discussion && discussion.id === updateFound.id)
        if (adminMesagesIndex === -1) {
          const checkMsg = (msg) =>
            ((msg && msg.createdBy && msg.createdBy.role && msg.createdBy.role !== "USER") ||
              (msg && msg.replies && msg.replies.length > 0 && msg.replies.map((replyMsg) => replyMsg && replyMsg.createdBy && replyMsg.createdBy.role && replyMsg.createdBy.role !== "USER").includes(true))) && msg
          if (checkMsg(discussionMessagesTempState[liveMessagesIndex])) {
            if (!isReplyEnabled.current) {
              adminDiscussionMessagesTemp.push(discussionMessagesTempState[liveMessagesIndex])
              setAdminDiscussionMessagesTemp(adminDiscussionMessagesTemp)
            } else {
              adminDiscussionWhenReply.push(discussionMessagesTempState[liveMessagesIndex])
              setAdminDiscussionWhenReply(adminDiscussionWhenReply)
            }
            setForceRender(true)
            if (scrolledToBottom.current) {
              setScrollToBottom(true)
            }
          }
        } else {
          adminDiscussionMessagesTemp[adminMesagesIndex] = discussionMessagesTempState[liveMessagesIndex]
          setAdminDiscussionMessagesTemp(adminDiscussionMessagesTemp)
        }
      } else {
        const adminMesagesIndex = adminDiscussionMessagesTemp.findIndex((discussion) => discussion && discussion.id === updateFound.parent.id)
        const liveMessagesIndex = discussionMessagesTempState.findIndex((discussion) => discussion && discussion.id === updateFound.parent.id)
        adminDiscussionMessagesTemp[adminMesagesIndex] = discussionMessagesTempState[liveMessagesIndex]
        setAdminDiscussionMessagesTemp(adminDiscussionMessagesTemp)
      }
    }
  }, [updateFound])



  useEffect(() => {
    if (scrollToBottom && !isFirstTime.current) {
      setTimeout(() => {
        scrolledToBottom.current = true
        if (commentsScroller && Array.isArray(commentsScroller) && commentsScroller.length >= 0 && commentsScroller[0].scrollHeight) {
          commentsScroller[0].scrollTop = commentsScroller[0].scrollHeight
        }
      }, 10);
      setScrollToBottom(false)
    }
  }, [scrollToBottom])

  useEffect(() => {
    if (loading) {
      fetchMoreMessages()
    }
    if (!loading && isFirstTime.current) {
      setTimeout(() => {
        scrolledToBottom.current = true
        if (commentsScroller && Array.isArray(commentsScroller) && commentsScroller.length >= 0 && commentsScroller[0].scrollHeight) {
          commentsScroller[0].scrollTop = commentsScroller[0].scrollHeight
        }        
      }, 50);
      isFirstTime.current = false
    }
  }, [loading])

  useEffect(() => {
    if (noNewMessagesFound.current) {
      return
    }
    if (requestedForMoreMessages.current && adminDiscussionMessages.length <= (lastAdminMessageCount.current || 0) + 8) {
      fetchMoreMessages(true)
    } else {
      if (adminDiscussionMessages.length !== lastAdminMessageCount.current) {
        virtuoso.current.adjustForPrependedItems(adminDiscussionMessages.length - lastAdminMessageCount.current)
        adminMessageCount.current = adminDiscussionMessages.length
      }
      setAdminDiscussionMessagesTemp(adminDiscussionMessages)
      lastAdminMessageCount.current = adminDiscussionMessages.length
      requestedForMoreMessages.current = false
    }
  }, [adminDiscussionMessages])

  useEffect(() => {
    if (noNewMessagesFound.current) {
      setAdminDiscussionMessagesTemp(adminDiscussionMessages)
      if (adminDiscussionMessages.length !== lastAdminMessageCount.current) {
        virtuoso.current.adjustForPrependedItems(adminDiscussionMessages.length - lastAdminMessageCount.current)
        adminMessageCount.current = adminDiscussionMessages.length
      }
      return
    }
  }, [teamMessagesLoading])

  useEffect(() => {
    if (isAdminMessages && adminDiscussionMessages.length <= 8) {
      fetchMoreMessages()
    }
    if (isTeamMessages.current === undefined) {
      isTeamMessages.current = isAdminMessages
      return
    }
    if (isTeamMessages.current !== isAdminMessages) {
      if (!isAdminMessages) {
        virtuoso.current.scrollToIndex(discussionMessages.length - 1)
      }
    }
    if (isAdminMessages) {
      setTimeout(() => {
        commentsScroller[0].scrollTop = commentsScroller[0].scrollHeight - 500
        setTimeout(() => {
          commentsScroller[0].scrollTop = commentsScroller[0].scrollHeight
        }, 500);
      }, 500);
      scrolledToBottom.current = true
    }
    if (isTeamMessages.current !== isAdminMessages) {
      isTeamMessages.current = isAdminMessages
    }
  }, [isAdminMessages])

  useEffect(() => {
    _loadMoreRows(!isPause, true)
    isFirstTime.current = true
    isTeamMessages.current = isAdminMessages
    const unsubscribeThread = subscribeToMore({
      document: SUBSCRIBE_MESSAGE,
      variables: { id: discussionId },
      client: chatClient,
      updateQuery: (prev, { subscriptionData: { data: discussionData } }) => {
        const isMessagesPause = isPauseForUseEffect.current
        const isPauseMessagesForSubscribe = (isPauseMessagesMetaData && isPauseMessagesMetaData.current) || []
        const newReplyMessageCountForSubscribe = (newReplayMessageCountMetaData && newReplayMessageCountMetaData.current) || []
        updateGrid()
        setForceRender(true)
        if (discussionData && discussionData.message) {
          let parentId = discussionMessagesTemp.findIndex((message) => message.id === discussionData.message.node.id) // get if id exists in parent comments
          if (discussionData.message.node.parent) { // if parent comment ID exists in new comment
            let checkParent = discussionMessagesTemp.findIndex((message) => message.id === discussionData.message.node.parent.id)
            if (checkParent !== -1) {
              if (discussionMessagesTemp[checkParent].replies) {
                let checkInReply = discussionMessagesTemp[checkParent].replies.findIndex((message) => message.id === discussionData.message.node.id)
                if (checkInReply === -1) {
                  if (discussionData.message.node.isDeleted !== true) {
                    discussionMessagesTemp[checkParent].replies.unshift(discussionData.message.node)
                    setDiscussionMessagesTempState(discussionMessagesTemp)
                    if (isMessagesPause && isPauseMessagesForSubscribe.length > 0) {
                      let checkPauseParent = isPauseMessagesForSubscribe.findIndex((message) => message.id === discussionData.message.node.parent.id)
                      if (checkPauseParent > -1) {
                        const tempIsPauseMessages = JSON.parse(JSON.stringify(isPauseMessagesForSubscribe || []))
                        tempIsPauseMessages[checkPauseParent].replies.unshift(discussionData.message.node)
                        setIsPauseMessages(tempIsPauseMessages)
                        isPauseMessagesMetaData.current = tempIsPauseMessages
                      }
                    }
                    if (!newReplyMessageCountForSubscribe.includes(discussionMessagesTemp[checkParent].id)) {
                      setNewReplyMessageCount([...newReplyMessageCountForSubscribe, discussionMessagesTemp[checkParent].id])
                      newReplayMessageCountMetaData.current = [...newReplyMessageCountForSubscribe, discussionMessagesTemp[checkParent].id]
                    }
                  }
                } else {
                  const checkReplyDiff = JSON.stringify(discussionMessagesTemp[checkParent].replies[checkInReply]) === JSON.stringify(discussionData.message.node)
                  if (!checkReplyDiff) {
                    if (discussionData.message.mutation === "UPDATED" && discussionData.message.node.isDeleted === true) {
                      discussionMessagesTemp[checkParent].replies.splice(checkInReply, 1)
                      setDiscussionMessagesTempState(discussionMessagesTemp)
                      if (isMessagesPause && isPauseMessagesForSubscribe.length > 0) {
                        let checkPauseParent = isPauseMessagesForSubscribe.findIndex((message) => message.id === discussionData.message.node.parent.id)
                        if (checkPauseParent > -1) {
                          let pauseCheckInReply = isPauseMessagesForSubscribe[checkPauseParent].replies.findIndex((message) => message.id === discussionData.message.node.id)
                          if (pauseCheckInReply > -1) {
                            const tempIsPauseMessages = JSON.parse(JSON.stringify(isPauseMessagesForSubscribe || []))
                            tempIsPauseMessages[checkPauseParent].replies.splice(pauseCheckInReply, 1)
                            setIsPauseMessages(tempIsPauseMessages)
                            isPauseMessagesMetaData.current = tempIsPauseMessages
                          }
                        }
                      }
                    } else {
                      if (discussionData.message.node.isDeleted !== true) {
                        discussionMessagesTemp[checkParent].replies[checkInReply] = discussionData.message.node
                        setDiscussionMessagesTempState(discussionMessagesTemp)
                        if (isMessagesPause && isPauseMessagesForSubscribe.length > 0) {
                          let checkPauseParent = isPauseMessagesForSubscribe.findIndex((message) => message.id === discussionData.message.node.parent.id)
                          if (checkPauseParent > -1) {
                            let pauseCheckInReply = isPauseMessagesForSubscribe[checkPauseParent].replies.findIndex((message) => message.id === discussionData.message.node.id)
                            if (pauseCheckInReply > -1) {
                              const tempIsPauseMessages = JSON.parse(JSON.stringify(isPauseMessagesForSubscribe || []))
                              tempIsPauseMessages[checkPauseParent].replies[pauseCheckInReply] = discussionData.message.node
                              setIsPauseMessages(tempIsPauseMessages)
                              isPauseMessagesMetaData.current = tempIsPauseMessages
                            }
                          }
                        }
                      }
                    }
                  }
                }
              } else {
                discussionMessagesTemp[checkParent].replies = []
                discussionMessagesTemp[checkParent].replies[0] = discussionData.message.node
                setDiscussionMessagesTempState(discussionMessagesTemp)
                if (isMessagesPause && isPauseMessagesForSubscribe.length > 0) {
                  let checkPauseParent = isPauseMessagesForSubscribe.findIndex((message) => message.id === discussionData.message.node.parent.id)
                  if (checkPauseParent > -1) {
                    const tempIsPauseMessages = JSON.parse(JSON.stringify(isPauseMessagesForSubscribe || []))
                    tempIsPauseMessages[checkPauseParent].replies = []
                    tempIsPauseMessages[checkPauseParent].replies[0] = discussionData.message.node
                    setIsPauseMessages(tempIsPauseMessages)
                    isPauseMessagesMetaData.current = tempIsPauseMessages
                  }
                }
                if (!newReplyMessageCountForSubscribe.includes(discussionMessagesTemp[checkParent].id)) {
                  setNewReplyMessageCount([...newReplyMessageCountForSubscribe, discussionMessagesTemp[checkParent].id])
                  newReplayMessageCountMetaData.current = [...newReplyMessageCountForSubscribe, discussionMessagesTemp[checkParent].id]
                }
              }
            }
          } else { // if parent comment ID not exists in new comment
            if (parentId === -1) {
              if (discussionData.message.node.isDeleted !== true) {
                discussionMessagesTemp.push(discussionData.message.node) // if ID not exists in parent comments push comment to all data
                if (!isReplyEnabled.current) {
                  setDiscussionMessages(discussionMessagesTemp)
                }
                if (!isMessagesPause && scrolledToBottom.current) {
                  setScrollToBottom(true)
                }
                if (!isMessagesPause) {
                  if (!isReplyEnabled.current) {
                    setRowCount(discussionMessagesTemp.length)
                  }
                }
              }
            } else {
              const checkDiff = JSON.stringify(discussionMessagesTemp[parentId]) === JSON.stringify(discussionData.message.node)
              if (!checkDiff) {
                if (discussionData.message.mutation === "UPDATED" && discussionData.message.node.isDeleted === true) {
                  discussionMessagesTemp.splice(parentId, 1)
                  setRowCount(discussionMessagesTemp.length)
                  if (isMessagesPause && isPauseMessagesForSubscribe.length > 0) {
                    let checkPauseParent = isPauseMessagesForSubscribe.findIndex((message) => message.id === discussionData.message.node.id)
                    if (checkPauseParent > -1) {
                      const tempIsPauseMessages = JSON.parse(JSON.stringify(isPauseMessagesForSubscribe || []))
                      tempIsPauseMessages.splice(checkPauseParent, 1)
                      setIsPauseMessages(tempIsPauseMessages)
                      isPauseMessagesMetaData.current = tempIsPauseMessages
                    }
                  }
                } else {
                  if (discussionData.message.node.isDeleted !== true) {
                    if (discussionMessagesTemp[parentId].replies) {
                      let tempReplies = discussionMessagesTemp[parentId].replies
                      discussionMessagesTemp[parentId] = discussionData.message.node
                      discussionMessagesTemp[parentId].replies = tempReplies
                      setDiscussionMessagesTempState(discussionMessagesTemp)
                      if (isMessagesPause && isPauseMessagesForSubscribe.length > 0) {
                        let checkPauseParent = isPauseMessagesForSubscribe.findIndex((message) => message.id === discussionData.message.node.id)
                        if (checkPauseParent > -1 && isPauseMessagesForSubscribe[checkPauseParent].replies) {
                          const tempIsPauseMessages = JSON.parse(JSON.stringify(isPauseMessagesForSubscribe || []))
                          let tempPauseReplies = tempIsPauseMessages[checkPauseParent].replies
                          tempIsPauseMessages[checkPauseParent] = discussionData.message.node
                          tempIsPauseMessages[checkPauseParent].replies = tempPauseReplies
                          setIsPauseMessages(tempIsPauseMessages)
                          isPauseMessagesMetaData.current = tempIsPauseMessages
                        }
                      }
                    } else {
                      discussionMessagesTemp[parentId] = discussionData.message.node
                      setDiscussionMessagesTempState(discussionMessagesTemp)
                      if (isMessagesPause && isPauseMessagesForSubscribe.length > 0) {
                        let checkPauseParent = isPauseMessagesForSubscribe.findIndex((message) => message.id === discussionData.message.node.id)
                        if (checkPauseParent > -1) {
                          const tempIsPauseMessages = JSON.parse(JSON.stringify(isPauseMessagesForSubscribe || []))
                          tempIsPauseMessages[checkPauseParent] = discussionData.message.node
                          setIsPauseMessages(tempIsPauseMessages)
                          isPauseMessagesMetaData.current = tempIsPauseMessages
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          setUpdateFound(discussionData.message.node)
        }
      }
    })
    if (commentsScroller[0]) {
      commentsScroller[0].addEventListener("scroll", onScrollHandler);
    }
    return () => {
      unsubscribeThread()
      if (commentsScroller[0]) {
        commentsScroller[0].removeEventListener("scroll", onScrollHandler);
      }
    }
  }, [])

  function _loadMoreRows(isPauseCheck, firstTime) {
    if (lastId !== '' && isPauseCheck) {
      setLoading(true)
    }
  }

  function fetchMoreMessages(requestAdminMessages) {
    if (isAdminMessages && !requestAdminMessages) {
      requestedForMoreMessages.current = true
    }
    if (requestAdminMessages && !noNewMessagesFound.current) {
      setTeamMessagesLoading(true)
    }
    fetchMore({
      variables: { id: discussionId, after: lastId },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (fetchMoreResult.discussionThread.messages && fetchMoreResult.discussionThread.messages.length > 0) {
          const { messages } = fetchMoreResult.discussionThread
          let newMessages = []
          messages.forEach(element => {
            if (discussionMessages.findIndex((discussionMessage) => discussionMessage.id === element.id) === -1) {
              newMessages.push(element)
            }
          })
          if (newMessages && newMessages[newMessages.length - 1]) {
            setLastId(newMessages[newMessages.length - 1].id)
          }
          const newDiscussionMessages = discussionMessages
          newDiscussionMessages.unshift(...(newMessages.reverse()))
          const adminMessages = newDiscussionMessages.filter(
            (msg) =>
              ((msg && msg.createdBy && msg.createdBy.role && msg.createdBy.role !== "USER") ||
                (msg && msg.replies && msg.replies.length > 0 && msg.replies.map((replyMsg) => replyMsg && replyMsg.createdBy && replyMsg.createdBy.role && replyMsg.createdBy.role !== "USER").includes(true))) && msg
          )
          setAdminDiscussionMessages(adminMessages)
          const newRowCount = newDiscussionMessages.length
          if (isPause) {
            const newIsPauseMessages = isPauseMessages
            newIsPauseMessages.unshift(...(newMessages))
            setIsPauseMessages(newIsPauseMessages)
            isPauseMessagesMetaData.current = newIsPauseMessages
            if (!requestedForMoreMessages.current) {
              setRowCount(newIsPauseMessages.length)
            }
            if (!requestAdminMessages) {
              virtuoso.current.adjustForPrependedItems(newIsPauseMessages.length - rowCount)
            }
          } else {
            setDiscussionMessages(newDiscussionMessages)
            if (!requestedForMoreMessages.current) {
              setRowCount(newRowCount)
            }
            if (!requestAdminMessages) {
              virtuoso.current.adjustForPrependedItems(newRowCount - rowCount)
            }
          }
          setTeamMessagesLoading(false)
          setLoading(false)
        } else {
          noNewMessagesFound.current = true
          setLoading(false)
          setTeamMessagesLoading(false)
          setLastId('')
        }
      }
    })
  }

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleRequestFail(e) {
    setCommentLoading(false)
    openNotification("error", e || "Something Went Wrong")
  }

  function clearReply() {
    setReplyToCommentId(undefined)
    if (isReplyEnabled.current) {
      setDiscussionMessages(discussionMessagesTemp)
      setRowCount(discussionMessagesTemp.length)
      if (isAdminMessages) {
        setAdminDiscussionMessagesTemp(cloneDeep(adminDiscussionWhenReply))
      }
    }
    isReplyEnabled.current = false
    if (isAdminMessages) {
      setAdminDiscussionWhenReply([])
    }
    setReplyCommentMessage("")
    const addNewComment = document.getElementById('add-new-comment')
    if (addNewComment)
      addNewComment.focus()
  }

  function setReply(id) {
    isReplyEnabled.current = true
    if (isAdminMessages) {
      setAdminDiscussionWhenReply(cloneDeep(adminDiscussionMessagesTemp))
    }
    setReplyToCommentId(id)
    setReplyCommentMessage('')
    setTimeout(() => {
      const addNewComment = document.getElementById('reply-comment-input')
      if (addNewComment)
        addNewComment.focus()
    }, 300)
  }

  async function handleUserLike(messageId, isLiked) {
    const variables = isLiked ? { likes: { disconnect: { id: userId } }, id: messageId }
      : { likes: { connect: { id: userId } }, id: messageId }
    await chatClient.mutate({
      mutation: UPDATE_MESSAGE_LIKE,
      variables: variables
    })
  }

  async function addNewComment(newCommentContent) {
    setCommentLoading(true)
    try {
      await chatClient.mutate({ mutation: CREATE_MESSAGE, variables: { discussionId, id: userId, message: newCommentContent } })
      setCommentLoading(false)
      setCommentContent('')
      const addNewComment = document.getElementById('add-new-comment')
      if (addNewComment) {
        addNewComment.focus()
        setTimeout(() => {
          if (!isPause) {
            commentsScroller[0].scrollTop = commentsScroller[0].scrollHeight
            scrolledToBottom.current = true
          }
        }, 200);
      }
    } catch (e) {
      Sentry.captureException(e)
      let errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message && e.graphQLErrors[0].message === "Please, Don’t Use Bad Words" ? e.graphQLErrors[0].message : "Something Went Wrong"
      handleRequestFail(errorMessage)
    }
  }

  async function updateComment(commentId, message) {
    setEditCommentLoading(true)
    try {
      await chatClient.mutate({ mutation: UPDATE_MESSAGE, variables: { id: commentId, message: message } })
      setEditCommentLoading(false)
      setEditingCommentComment('')
      setIsCommentEditing(false)
    } catch (e) {
      Sentry.captureException(e)
      let errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message && e.graphQLErrors[0].message === "Please, Don’t Use Bad Words" ? e.graphQLErrors[0].message : "Something Went Wrong"
      handleRequestFail(errorMessage)
    }
  }

  async function addReplyToComment(parentId, replyContent) {
    setReplyLoading(true)
    try {
      await chatClient.mutate({ mutation: CREATE_REPLY_MESSAGE, variables: { discussionId, id: userId, message: replyContent, parentId } })
      setReplyLoading(false)
      clearReply()
    } catch (e) {
      Sentry.captureException(e)
      let errorMessage = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message && e.graphQLErrors[0].message === "Please, Don’t Use Bad Words" ? e.graphQLErrors[0].message : "Something Went Wrong"
      handleRequestFail(errorMessage)
    }
  }

  async function approveFlagComment(commentId, isBlocked) {
    confirm({
      title: `Are you sure, you want to ${isBlocked ? 'block' : 'unblock'} this comment?`,
      okText: isBlocked ? "BLOCK" : "UNBLOCK",
      okType: isBlocked ? "danger" : "primary",
      async onOk() {
        try {
          await chatClient.mutate({ mutation: UPDATE_FLAG_MESSAGE, variables: { id: commentId, isBlocked } })
          openNotification("success", `Comment has been ${isBlocked ? 'blocked' : 'unblocked'}`)
        }
        catch (error) {
          Sentry.captureException(error)
          handleRequestFail()
        }
      },
    })
  }

  async function editComment(commentId) {
    setIsCommentEditing(true)
    setEditingCommentId(commentId)
  }

  async function deleteComment(commentId) {
    confirm({
      title: `Are you sure, you want to delete this comment?`,
      okText: "DELETE",
      okType: "danger",
      async onOk() {
        try {
          await chatClient.mutate({ mutation: DELETE_MESSAGE, variables: { id: commentId, isDeleted: true } })
          if (commentId === replyToCommentId) {
            clearReply()
          }
          openNotification("success", `Comment has been deleted`)
        }
        catch (error) {
          Sentry.captureException(error)
          handleRequestFail()
        }
      },
    })
  }

  async function deleteFlagComment(commentId) {
    confirm({
      title: "Are you sure, you want to remove all flags for this comment?", okText: "REMOVE", okType: "danger",
      async onOk() {
        try {
          await chatClient.mutate({ mutation: REMOVE_FLAG_MESSAGE, variables: { id: commentId } })
          openNotification("success", "Flags has been removed")
        }
        catch (error) {
          Sentry.captureException(error)
          handleRequestFail()
        }
      },
    })
  }

  function updateGrid() {
    setTimeout(() => {
      if (virtuoso && virtuoso.current && virtuoso.current.forceUpdate) {
        virtuoso.current.forceUpdate()
      }
    }, 0);
  }


  function getAvatar(createdBy) {
    if (!createdBy || !(createdBy.firstName && createdBy.lastName))
      return <Avatar>U</Avatar>
    if ((createdBy.role === 'AUTHOR' || createdBy.role === "ADMIN") && createdBy.profileImage)
      return <Avatar src={createdBy.profileImage} />
    return <Avatar>{createdBy.firstName && createdBy.firstName.charAt(0)}{createdBy.lastName && createdBy.lastName.charAt(0)}</Avatar>
  }

  function getAuthorName(createdBy) {
    return `${createdBy && createdBy.userName ? createdBy.userName : createdBy.firstName ? `${createdBy.firstName} ${createdBy.lastName && createdBy.lastName}` : "User"}`
  }

  function flagsCountAndDropDown(message, id, count, isBlocked) {
    return <>
      {
        message.createdBy.id === userId &&
        <span className="comment-edit" onClick={() => editComment(id)}><Icon type="edit" /></span>
      }
      {count > 0 && <span className="comment-flag">&nbsp;{count}+<Icon type="flag" /></span>}
      <Dropdown
        visible={dropDownId === id}
        onVisibleChange={(visible) => { if (!visible && dropDownId) { setDropDownId(undefined) } }}
        onClick={() => setDropDownId(id)}
        trigger={["click"]}
        placement="bottomRight"
        overlay={<Menu>
          <Menu.Item onClick={() => { approveFlagComment(id, !isBlocked); setDropDownId(undefined); }}>
            <span>{isBlocked ? 'Unblock' : 'Block'} Comment</span>
          </Menu.Item>
          {count > 0 && <Menu.Item onClick={() => { deleteFlagComment(id); setDropDownId(undefined); }}>
            <span>Remove all flags</span>
          </Menu.Item>}
        </Menu>
        }>
        <span className="comment-flag block">{!isBlocked ? <Icon type="stop" /> : <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />} </span>
      </Dropdown>
      <span className="comment-delete" onClick={() => deleteComment(id)}><Icon type="delete" /></span>
    </>
  }

  function getContent(content, isReply) {
    return <span className={`comment-content ${content.isBlocked ? "blocked" : ""}`}>
      {isCommentEditing && content.id === editingCommentId ?
        <div className="add-new-comment-wrapper">
          <TextArea
            id='reply-comment-input'
            rows={2}
            disabled={editCommentLoading}
            defaultValue={content.message}
            placeholder="Write a comment and press shift and enter to send..."
            onKeyUp={(e) => {
              if (e.keyCode === 13 && e.target.value.trim() && e.shiftKey) {
                updateComment(content.id, e.target.value.trim())
                e.target.value = ''
                e.target.removeAttribute('value')
              } else {
                setEditingCommentComment(e.target.value)
              }
            }}
          />
          <Button
            type="primary"
            size="small"
            disabled={editingCommentComment.trim() ? false : true}
            onClick={() => {
              if (editingCommentComment.trim()) {
                updateComment(content.id, editingCommentComment)
              }
            }}
            loading={editCommentLoading}>Update
          </Button>
          &nbsp;
          <Button
            type="default"
            size="small"
            onClick={() => {
              setCommentLoading(false)
              setEditingCommentComment('')
              setIsCommentEditing(false)
            }}>Cancel</Button>
        </div>
        :
        <>
          <span className="comment-message">{content.message}
          </span>
          <span>
            <span className="like-button">
              <Tooltip title={content.likes && content.likes.length > 0 ? content.likes.findIndex((like) => like.id === userId) > -1 ? "Unlike" : "Like" : "Like"}>
                <Icon type="like"
                  onClick={() => isLive && handleUserLike(content.id, content.likes && content.likes.length > 0 ? content.likes.findIndex((like) => like.id === userId) > -1 ? true : false : false)}
                  theme={content.likes && content.likes.length > 0 ? content.likes.findIndex((like) => like.id === userId) > -1 ? 'filled' : 'outlined' : 'outlined'}
                />
                {content.likes && content.likes.length > 0 && ` ${content.likes.length}`}
              </Tooltip>
            </span>
            {!isReply && <>
              {isLive && !isCommentDisabled && <span className="reply-button" onClick={() => {
                replyToCommentId === content.id ? clearReply() : setReply(content.id)
              }}>
                <Tooltip title="Reply to this comment">
                  <span><Icon type="enter" /></span>
                </Tooltip>
              </span>}
              {replyToCommentId === content.id &&
                <div className="reply add-new-comment">
                  <TextArea
                    id='reply-comment-input'
                    rows={2}
                    placeholder="Write a comment and press shift and enter to send..."
                    disabled={replyLoading}
                    defaultValue={replyCommentMessage}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && e.target.value.trim() && e.shiftKey) {
                        addReplyToComment(content.id, e.target.value)
                        e.target.value = ''
                        e.target.removeAttribute('value')
                      } else {
                        setReplyCommentMessage(e.target.value)
                      }
                    }}
                  />
                  <Button
                    type="primary"
                    disabled={replyCommentMessage.trim() ? false : true}
                    onClick={() => {
                      if (replyCommentMessage.trim()) {
                        addReplyToComment(replyToCommentId, replyCommentMessage.trim())
                      }
                    }}
                    loading={replyLoading}>
                    Add Reply
                  </Button>
                </div>
              }
            </>
            }
          </span>
        </>}
    </span>
  }

  function getDateTime(createdAt) {
    return <Tooltip title={moment(createdAt).format('Do MMMM, hh:mm A')}>
      <span>{moment(createdAt).fromNow()}</span>
    </Tooltip>
  }

  function getAuthorAdminClass(msg) {
    if (msg && msg.createdBy && (msg.createdBy.role === 'AUTHOR' || msg.createdBy.role === "ADMIN"))
      return 'author'
    return ''
  }

  function handleMessages() {
    setIsPauseMessages(JSON.parse(JSON.stringify(discussionMessagesTempState || [])))
    isPauseMessagesMetaData.current = JSON.parse(JSON.stringify(discussionMessagesTempState || []))
    setRowCount(discussionMessages.length)
  }

  function handleNewReplyMessageCount(id) {
    if (newReplyMessageCount.includes(id)) {
      const newReplayCount = newReplyMessageCount.filter((msg) => msg !== id)
      setNewReplyMessageCount(newReplayCount)
      newReplayMessageCountMetaData.current = newReplayCount
    }
  }

  function onScrollHandler(e) {
    const commentsScroller = e.target
    if (!((commentsScroller.scrollTop + commentsScroller.offsetHeight) - commentsScroller.scrollHeight <= 1 && (commentsScroller.scrollTop + commentsScroller.offsetHeight) - commentsScroller.scrollHeight >= -2)) {
      scrolledToBottom.current = false
    }
    if (debounceJob.current) {
      debounceJob.current.cancel()
    }
    debounceJob.current = debounce(() => {
      if (commentsScroller && commentsScroller.scrollTop === 0) {
        if (!isPause) {
          _loadMoreRows(!isPause)
        }
      }
      if (isPause) {
        if (commentsScroller && commentsScroller.scrollTop === 0) {
          setShowLoadMoreBtn(true)
        } else {
          setShowLoadMoreBtn(false)
        }
      }
      if ((commentsScroller.scrollTop + commentsScroller.offsetHeight) - commentsScroller.scrollHeight <= 1 && (commentsScroller.scrollTop + commentsScroller.offsetHeight) - commentsScroller.scrollHeight >= -2) {
        scrolledToBottom.current = true
      }
    }, 50)
    debounceJob.current()
  }

  let discussion
  if (isAdminMessages) {
    discussion = adminDiscussionMessagesTemp
  } else {
    discussion = (isPause ? isPauseMessages : discussionMessagesTempState) || []
  }

  return (
    <Row gutter={24} type="flex" id="comments-wrapper" className="comments-wrapper">
      <Col span={24}>
        <div className="box">
          {(isDiscussionsLoading || loading || teamMessagesLoading) && <div className="comments-loader-wrapper"><Spinner /></div>}
          <div className="loadMore-wrapper" style={!showLoadMoreBtn ? { visibility: "hidden" } : { visibility: "visible" }} >
            {((isAdminMessages && discussionMessages && discussionMessages.length > 8) || isPause) && lastId &&
              <Button size="small" disabled={loading} onClick={() => { _loadMoreRows(true) }}>Load More</Button>
            }
          </div>
          {discussion && discussion.length === 0 ? <div className="discussions-image">
            <img src={discussionsImg} alt="Discussions" />{isLive && <p>Start Your Discussion Now</p>}
          </div> : ''}
          <Virtuoso
            className="comments-scroller"
            ref={virtuoso}
            overscan={100}
            style={discussion && discussion.length === 0 ? { height: 0 } : !isFullscreen ? { height: 'calc((((100vh - 233px) - 42px) - 88px) - 10px)' } : { height: 'calc((((100vh - 152px) - 42px) - 88px) - 10px)' }}
            totalCount={!isAdminMessages ? rowCount : discussion.length}
            item={index => {
              const messages = discussion[index]
              if (!messages) {
                return null
              }
              return <Comment
                id={messages.id}
                className={`${getAuthorAdminClass(messages)}`}
                author={getAuthorName(messages.createdBy)}
                avatar={getAvatar(messages.createdBy)}
                content={getContent(messages, false)}
                datetime={<div>
                  {getDateTime(messages.createdAt)}
                  {flagsCountAndDropDown(messages, messages.id, messages.flags.length, messages.isBlocked)}
                </div>}
                children={messages.replies && messages.replies.length > 0 &&
                  <Collapse bordered={false} onChange={() => handleNewReplyMessageCount(messages.id)}>
                    <Panel
                      className="unselectable"
                      header={<Fragment>View Replies ({messages.replies.length}) {newReplyMessageCount.includes(messages.id) && <Badge dot />}</Fragment>}
                      key={messages.id}
                    >
                      {messages.replies.map((reply, key) => (
                        <Comment
                          id={reply.id}
                          key={key}
                          className={`${getAuthorAdminClass(reply)} replied-message`}
                          author={getAuthorName(reply.createdBy)}
                          avatar={getAvatar(reply.createdBy)}
                          content={getContent(reply, true)}
                          datetime={<div>
                            {getDateTime(reply.createdAt)}
                            {flagsCountAndDropDown(reply, reply.id, reply.flags.length, reply.isBlocked)}
                          </div>}
                        />
                      ))}
                    </Panel>
                  </Collapse>
                }
              />
            }}
          />
          <div className={`add-new-comment`}>
            {isLive && !isCommentDisabled &&
              <Fragment>
                {
                  !isAdminMessages && isDiscussion &&
                  <div className="comment-filter-wrapper">
                    {
                      isPause &&
                      <Button size="small" onClick={() => handleMessages()}>
                        {(discussionMessagesTempState.length - isPauseMessages.length) || 0} New Comment{discussionMessagesTempState.length - isPauseMessages.length !== 1 && "s"}
                      </Button>
                    }
                    <div>
                      <Switch defaultChecked={!isPause} size="small" onChange={() => {
                        setIsPauseMessages(!isPause ? JSON.parse(JSON.stringify(discussionMessagesTempState || [])) : [])
                        isPauseMessagesMetaData.current = !isPause ? JSON.parse(JSON.stringify(discussionMessagesTempState || [])) : []
                        setIsPause(!isPause)
                        isPauseForUseEffect.current = !isPause
                        setRowCount(discussionMessagesTempState.length)
                      }} />
                      Show Live Feed
                  </div>
                  </div>
                }
                <div className="add-new-comment-wrapper">
                  <TextArea
                    rows={3}
                    id="add-new-comment"
                    placeholder="Write a comment and press shift and enter to send..."
                    disabled={commentLoading}
                    {...inputData}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && e.target.value.trim() && e.shiftKey) {
                        addNewComment(e.target.value.trim())
                        e.target.removeAttribute('value')
                        e.target.value = ''
                      } else {
                        setCommentContent(e.target.value)
                      }
                    }}
                  />
                  <Button
                    id="btn-add-new-comment"
                    type="primary"
                    disabled={commentContent.trim() ? false : true}
                    onClick={() => {
                      if (commentContent.trim()) {
                        addNewComment(commentContent.trim())
                      }
                    }}
                    loading={commentLoading}>
                    Add Comment
                  </Button>
                </div>
              </Fragment>
            }
          </div>
        </div>
      </Col>
    </Row >
  )
}
