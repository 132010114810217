import gql from "graphql-tag"

export const GET_USERS = gql`
query users($skip: Int, $first: Int) {
  users(skip: $skip, first: $first) {
    id
    firstName
    lastName
    email
    role
    isActive
    profileImage
  }
}
`

export const GET_USER_BY_EMAIL = gql`
query user($email: String) {
  user(where: {email: $email}) {
    id
  }
}
`

export const GET_USER_PROFILE_SIGNED_PUT_URL = gql`
query getUserProfileSignedPutUrl($fileName: String!, $contentType: String!){
  getUserProfileSignedPutUrl(data: {fileName: $fileName, contentType: $contentType }) {
    signedUrl
    getUrl
  }
}
`

export const GET_PERSONS = gql`
query persons($skip: Int, $first: Int, $filters: PersonWhereInput, $orderByFilter: PersonOrderByInput) {
  persons(where: $filters, skip: $skip, first: $first, orderBy: $orderByFilter) {
    id
    email
    firstName
    profileImage
    lastName
    userName
    authZeroID
    recurlyId
    recurlyPlan
    badgeId
    promoPlan
    promoPlanExpiration
    role
    isActive
    createdAt
    updatedAt
  }
}
`

export const SYNC_RECURLY_PLAN = gql`
query syncRecurlyPlan($recurlyId: String!) {
  syncRecurlyPlan(where:{ recurlyId: $recurlyId })
}
`
