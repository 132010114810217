import gql from "graphql-tag"

const CREATE_MESSAGE = gql`
mutation createMessage($discussionId: ID!, $message: String!, $id: ID!){
  createMessage(data: { message: $message, createdBy: { connect: { id: $id } }, discussion: { connect: { id: $discussionId } } }) {
    id
  }
}
`

const UPDATE_MESSAGE = gql`
mutation updateMessage($message: String, $id: ID!){
  updateMessage(data: { message: $message }, where: { id: $id }) {
    id
  }
}
`

const CREATE_REPLY_MESSAGE = gql`
mutation createMessage($discussionId: ID!, $message: String!, $id: ID!, $parentId: ID!){
  createMessage(data: { createdBy: { connect: { id: $id } }, parent: { connect: { id: $parentId } }, message: $message, discussion: { connect: { id: $discussionId } } }) {
    id
  }
}
`

const UPDATE_MESSAGE_LIKE = gql`
mutation updateMessage($likes: PersonUpdateManyInput!, $id: ID!){
  updateMessage(data: { likes: $likes }, where: { id: $id }) {
    id
  }
}
`

const UPDATE_FLAG_MESSAGE = gql`
mutation updateMessage($id: ID!, $isBlocked: Boolean!){
  updateMessage(data: { isBlocked: $isBlocked }, where: { id: $id }) {
    id
  }
}
`

const REMOVE_FLAG_MESSAGE = gql`
mutation updateMessage($id: ID!){
  updateMessage(data: { flags: { set: [] } }, where: { id: $id }) {
    id
  }
}
`

const DELETE_MESSAGE = gql`
mutation updateMessage($id: ID!, $isDeleted: Boolean!){
  updateMessage(data: { isDeleted: $isDeleted }, where: { id: $id }) {
    id
  }
}
`

export { CREATE_MESSAGE, UPDATE_MESSAGE, CREATE_REPLY_MESSAGE, UPDATE_MESSAGE_LIKE, UPDATE_FLAG_MESSAGE, REMOVE_FLAG_MESSAGE, DELETE_MESSAGE }
