import gql from 'graphql-tag'

const SUBSCRIBE_EPISODE_SEGMENTS = gql`
subscription episodeSegment($nodeArray: [EpisodeSegmentSubscriptionWhereInput!]){
  episodeSegment(where: { OR: $nodeArray }) {
    mutation
    node {
      id
      title
      description
      order
      image
      duration
      video
      audio
      videoAccess
      audioAccess
      muxAssetId
      videoState
      updatedAt
    }
  }
}
`

const SUBSCRIBE_EPISODE = gql`
subscription episode($id: ID!){
  episode(where: { node : { id: $id }}) {
    mutation
    node {
      id
    title
    description
    image
    status
    isLive
    segments(orderBy: order_ASC){
      id
      title
      description
      image
      audio
      video
      videoAccess
      audioAccess
      duration
      order
      videoState
      muxAssetId
      updatedAt
    }
    show {
      id
      name
    }
    scheduleAt
    createdAt
    }
  }
}
`

export { SUBSCRIBE_EPISODE_SEGMENTS, SUBSCRIBE_EPISODE }
