import gql from 'graphql-tag'

export const CREATE_VIDEO = gql`
  mutation createVideo(
    $title: String!
    $description: String!
    $videoNumber: String!
    $image: String
    $logoImage: String
    $scheduleAt: DateTime
    $isLive: Boolean
    $createdBy: ID!
    $videoAccess: [String!]
    $liveChatAccess: [String!]
    $allowedContinents: [String!]
    $rating: String
    $allowedCountries: Json
  ) {
    createVideo(
      data: {
        name: $title
        description: $description
        videoNumber: $videoNumber
        image: $image
        logoImage: $logoImage
        isLive: $isLive
        scheduleAt: $scheduleAt
        videoAccess: { set: $videoAccess }
        liveChatAccess: { set: $liveChatAccess }
        allowedContinents: { set: $allowedContinents }
        rating: $rating
        createdBy: { connect: { id: $createdBy } }
        allowedCountries:$allowedCountries
      }
    ) {
      id
      name
      description
      image
      logoImage
      status
      isLive
    }
  }
`

export const UPDATE_VIDEO = gql`
  mutation updateVideo(
    $data: VideoUpdateInput!
    $where: VideoWhereUniqueInput!
  ) {
    updateVideo(data: $data, where: $where) {
      id
      name
      description
      image
      logoImage
      status
      isLive
    }
  }
`

export const DELETE_VIDEO = gql`
  mutation deleteVideo($videoId: ID!) {
    deleteVideo(where: { id: $videoId }) {
      id
    }
  }
`

export const REQUEST_MASTER_ACCESS = gql`
  mutation requestMasterAccess($assetId: String!) {
    requestMasterAccess(where: { assetId: $assetId })
  }
`
export const CREATE_CAPTION_CUSTOM = gql`
  mutation createCaptionCustom($data: [CreateCaptionInput!]!) {
    createCaptionCustom(data: $data){
      message
    }
  }
`
export const DELETE_CAPTION_CUSTOM = gql`
  mutation deleteCaptionCustom($where: DeleteCaptionCustomInput) {
    deleteCaptionCustom(where: $where){
      message
    }
  }
`