import React, { useState } from 'react'
import { message, Row, Form, Button, Input, Col, Select, Upload, Icon, Modal } from 'antd'
import { LANGUAGE } from '../../../common/constants'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import { CAPTION_SIGNED_PUT_URL } from '../graphql/Queries'
import { CREATE_CAPTION_CUSTOM, DELETE_CAPTION_CUSTOM } from '../graphql/Mutations'
import { get } from 'lodash'
import { openNotification } from '../../../common/utility'
import * as Sentry from '@sentry/browser'
import { GET_VIDEO } from '../graphql/Queries'

const { Option } = Select
const confirm = Modal.confirm

function beforeImageUpload(file) {
  let fileNameArray = file.name.split('.')
  let fileExt = file.name.split('.')[fileNameArray.length - 1].toLowerCase()
  if (fileExt && fileExt === 'vtt') {
    return true
  }
  message.error('You can only upload vtt file!')
  return false
}

function AddSubTitleForm(props) {
  const { muxAssetId, entityId, captions } = props
  const [removeFile, setRemoveFile] = useState(false)
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form
  const [submit, setSubmit] = useState(false)

  function handleRequestFail(e) {
    setSubmit(false)
    if (e && e.message) {
      const message =
        (e && e.graphQLErrors && Array.isArray(e.graphQLErrors) && e.graphQLErrors[0] && e.graphQLErrors[0].message) || e.message
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function checkLanguageSelection(subtitles) {
    for (let i = 0; i < subtitles.length - 1; i++) {
      for (let j = i + 1; j < subtitles.length; j++) {
        if (subtitles[i].language === subtitles[j].language) {
          return false
        }
      }
    }
    return true
  }

  function handleSubmit() {
    const { form } = props
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      if (values.subtitles.length > 0) {
        if (!checkLanguageSelection(values.subtitles)) {
          openNotification('error', 'Please select different language for each subtitle.')
          return
        }
        setSubmit(true)
        try {

          const promise = await values.subtitles.map(async (subtitleObj, i) => {
            if (subtitleObj.id === '') {
              const tempSubtitleArray = subtitleObj.subtitle[0].name.split('.')
              const subtitleFileExt = tempSubtitleArray[tempSubtitleArray.length - 1]
              const subtitleFileName = `subtitle-${new Date().getTime()}.${subtitleFileExt}`
              const getCaptionSignedPutUrlResult = await client.query({
                query: CAPTION_SIGNED_PUT_URL,
                variables: { fileName: subtitleFileName },
              })
              const signUrl = get(getCaptionSignedPutUrlResult, 'data.captionSingedPutUrl.signedUrl')
              const getUrl = get(getCaptionSignedPutUrlResult, 'data.captionSingedPutUrl.getUrl')
              if (signUrl && getUrl) {
                await fileUpload(
                  getCaptionSignedPutUrlResult.data.captionSingedPutUrl.signedUrl,
                  subtitleObj.subtitle[0],
                )
              }
              let newSubtitle = {
                fileName: subtitleFileName,
                language: subtitleObj.language,
                name: subtitleObj.name,
                muxAssetId: muxAssetId,
                entityId: entityId,
                entityType: 'VIDEO'
              }
              form.resetFields(`subtitles[${i}].subtitle`)
              return newSubtitle
            }
            return false
          })
          let variables = await Promise.all(promise)
          let data = variables.filter(item => item)

          const res = await client.mutate({
            mutation: CREATE_CAPTION_CUSTOM,
            variables: { data: data },
            refetchQueries: [{ query: GET_VIDEO, variables: { id: entityId } }]
          })
          const createCaptionCustomData = get(res, 'data.createCaptionCustom')
          if (createCaptionCustomData && createCaptionCustomData.message) {
            openNotification('success', createCaptionCustomData.message)
          }

        } catch (error) {
          Sentry.captureException(error)
          handleRequestFail(error)
        } finally {
          setSubmit(false)
        }
      }
    })
  }

  getFieldDecorator('keys', { initialValue: [...Array(captions.length).keys()] })
  const keys = getFieldValue('keys')

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  const uploadProps = {
    customRequest() {
      return false
    },
    beforeUpload: file => {
      return false
    }
  }

  async function removeSubtitle(k) {

    confirm({
      title: `Are you sure you want to remove subtitle`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        setSubmit(true)
        if (captions[k]) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAPTION_CUSTOM,
              variables: { where: { id: captions[k].id, muxAssetId: muxAssetId } },
              refetchQueries: [{ query: GET_VIDEO, variables: { id: entityId } }]
            })
            const deleteCaptionCustom = get(response, 'data.deleteCaptionCustom')
            if (deleteCaptionCustom && deleteCaptionCustom.message) {
              openNotification('success', deleteCaptionCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            setSubmit(false)
            return
          }
        }
        const keys = getFieldValue('keys');
        setFieldsValue({
          keys: keys.filter((key, index) => index !== k),
        });
        setSubmit(false)
      },
    })
  }

  function addsubtitle() {
    const keys = getFieldValue('keys');
    let id = keys.length
    const nextKeys = keys.concat(id);

    setFieldsValue({
      keys: nextKeys,
    });
  }

  const formItems = keys.map((key, i) => (
    <Row key={i} type="flex" justify="space-between" align="top" gutter={8}>
      <Col xs={12} sm={12} md={6}>
        <Form.Item label="id" className="d-none">
          {getFieldDecorator(`subtitles[${i}].id`, { initialValue: captions[i] ? captions[i].id : '' })(
            <Input placeholder='id' />
          )}
        </Form.Item>
        <Form.Item label="Select Language">
          {getFieldDecorator(`subtitles[${i}].language`,
            {
              initialValue: captions[i] ? captions[i].language : undefined,
              rules: [{ required: true, message: 'Please Select Language' }]
            })(
              <Select placeholder="Select Language" disabled={captions[i] ? true : false}>
                {LANGUAGE.map(language =>
                  <Option key={language.value} value={language.value}>{language.label}</Option>
                )}
              </Select>,
            )}
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} md={5}>
        <Form.Item label="Language Label">
          {getFieldDecorator(`subtitles[${i}].name`,
            {
              initialValue: captions[i] ? captions[i].name : '',
              rules: [{ required: true, message: 'Please Input Language Label' }]
            })(
              <Input placeholder="Language Label" disabled={captions[i] ? true : false} />
            )}
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} md={3}>
        <Form.Item label="Subtitle">
          {getFieldDecorator(`subtitles[${i}].subtitle`, {
            valuePropName: "fileList",
            rules: [{ required: true, message: 'Please Select Subtitle' }],
            getValueFromEvent: normFile,
            initialValue: (captions[i] && captions[i].url) ? [{
              uid: "-1",
              name:
                captions[i].url.split('/')[
                captions[i].url.split('/').length - 1
                ],
              status: 'done',
              url: captions[i].url,
              thumbUrl: captions[i].url

            }] : []
          })(
            <Upload
              name="logo"
              {...uploadProps}
              multiple={false}
              showUploadList={{
                showRemoveIcon: (captions[i] && captions[i].url) ? false : true
              }}
              onRemove={(e) => handleRemove(e)}>
              <Button id={`addsubtitle-upload-${i}`} disabled={captions[i] ? true : false}>
                <Icon type="upload" /> Click to upload
                </Button>
            </Upload>,
          )}
        </Form.Item>
      </Col>
      <Col xs={12} sm={12} md={9}>
        <Button type="danger" className="remove-video-subtitle-icon" onClick={() => removeSubtitle(i)} id={`subtitle-remove-btn-${i}`}>
          <Icon type="delete" />
        </Button>
      </Col>
    </Row>
  ))

  return (
    <Form layout="vertical" className="add-video-subtitles-form">
      {formItems}
      <Form.Item>
        {keys.length < LANGUAGE.length && <Button type="link" className="p-0" onClick={addsubtitle} id={'add-new-subtitle-btn'}>
          <Icon type="plus" /> New Subtitle
        </Button>}
      </Form.Item>
      <Form.Item className="submit-video-subtitle-btn">
        {keys.length > 0 && <Button type="primary" onClick={handleSubmit} loading={submit} id={'subtitle-submit-btn'} >
          Submit
        </Button>}
      </Form.Item>
    </Form>
  )
}

const WrappedAddSubTitleForm = Form.create({ name: 'addvideosubtitles_form' })(AddSubTitleForm);

export default WrappedAddSubTitleForm
