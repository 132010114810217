import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Row, Col, Button, Upload, Icon, Modal, message, Progress, notification, Tooltip } from 'antd'
import * as Sentry from '@sentry/browser'
import { createUpload } from '@mux/upchunk'
import PlayerModal from './PlayerModal'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import history from '../../../history'
import { get } from 'lodash'
import { GET_MUX_DATA, GET_PODCAST_EPISODE, GET_PODCAST_EPISODE_SIGNED_AUDIO_PUT_URL } from '../grapql/Queries'
import { REQUEST_MASTER_ACCESS, UPDATE_PODCAST_EPISODE } from '../grapql/Mutations'

const { confirm } = Modal
export default function SegmentDetails(props) {
  const [showPlayerModal, setShowPlayerModal] = useState(false)
  const [audioDownload, setIsDownload] = useState(false)
  const [isAudioWithAdsDownload, setIsAudioWithAdsDownload] = useState(false)
  const [audioPlayType, setAudioPlayType] = useState(false)
  const [audioProgress, setAudioProgress] = useState(undefined)
  const [audioWithAdsProgress, setAudioWithAdsProgress] = useState(undefined)
  const [audioLoading, setAudioLoading] = useState(false)
  const [audioWithAdsLoading, setAudioWithAdsLoading] = useState(false)

  const {
    episodeId,
    episode,
    handleRemove,
    videoLoading,
    subscribeToAudioUpdate,
    showAudioRequireMsg,
  } = props

  function handleBeforeUnload(e) {
    if (!(videoLoading || audioLoading || audioWithAdsLoading)) {
      return true
    }
    e.preventDefault()
    e.returnValue = 'Some task is in progress. Are you sure, you want to close?'
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    history.block((location, action) => {
      return videoLoading || audioLoading || audioWithAdsLoading
        ? 'Some task is in progress. Are you sure, you want to close?'
        : true
    })
  })

  useEffect(() => {
    subscribeToAudioUpdate()
  }, [])

  function handleOk(e) {
    setShowPlayerModal(false)
    setAudioPlayType(false)
  }

  function handleModal(e, type) {
    setAudioPlayType(type)
    setShowPlayerModal(true)
  }
  function openNotification(type, message) {
    notification[type]({
      message
    })
  }
  function handleRequestFail() {
    setAudioLoading(false)
    setAudioWithAdsLoading(false)
    openNotification('error', 'Something Went Wrong')
  }

  async function handleDownload(muxAssetId, withAds) {
    try {
      const muxAssetsResult = await client.query({
        query: GET_MUX_DATA,
        fetchPolicy: 'network-only',
        variables: { assetId: muxAssetId },
      })
      const master = muxAssetsResult.data.getMuxData.master
      if (master && master.url) {
        if (withAds) {
          setIsAudioWithAdsDownload(false)
        } else {
          setIsDownload(false)
        }
        window.open(master.url)
      } else {
        if (audioDownload === false || isAudioWithAdsDownload === false) {
          if (withAds) {
            setIsAudioWithAdsDownload(true)
          } else {
            setIsDownload(true)
          }
          await client.mutate({
            mutation: REQUEST_MASTER_ACCESS,
            fetchPolicy: 'no-cache',
            variables: { assetId: muxAssetId },
          })
        }
        setTimeout(() => {
          handleDownload(muxAssetId, withAds)
        }, 30000)
      }
    } catch (err) {
      Sentry.captureException(err)
      setIsDownload(false)
    }
  }

  function computeAudioDuration(file) {
    return new Promise((resolve) => {
      const objectURL = URL.createObjectURL(file);
      const mySound = new Audio([objectURL]);
      mySound.addEventListener(
        "canplaythrough",
        () => {
          URL.revokeObjectURL(objectURL);
          resolve({
            file,
            audioDuration: mySound.duration
          });
        },
        false,
      );
    });
  }

  async function handleAudioChange(info, episodeId) {

    if (info?.fileList?.length > 1) {
      info.fileList.shift()
    }
    if (
      info?.fileList &&
      info?.fileList?.length > 0 &&
      (info?.fileList[0]?.type === 'audio/mp3' ||
        info?.fileList[0]?.type === 'audio/mpeg')
    ) {
      try {

        setAudioLoading(true)
        setAudioProgress(0)

        const getSignedPutUrlResult = await client.query({
          query: GET_PODCAST_EPISODE_SIGNED_AUDIO_PUT_URL,
          fetchPolicy: 'network-only',
          variables: { where: { id: episodeId }, corsOrigin: window?.location?.origin, withAds: false },
        })

        const uploadAudio = createUpload({
          endpoint:
            getSignedPutUrlResult?.data?.getPodcastEpisodeSignedAudioPutUrl?.signedUrl,
          file: info?.file?.originFileObj,
        })
        uploadAudio.on('error', (err) => {

          setAudioLoading(false)
          setAudioProgress(undefined)

        })
        uploadAudio.on('progress', (progress) => {
          setAudioProgress(progress?.detail)
        })
        uploadAudio.on('success', async () => {

          let data = {}
          data.audioState = 'PROCESSING'
          await client.mutate({
            mutation: UPDATE_PODCAST_EPISODE,
            variables: { data, where: { id: episodeId } },
            refetchQueries: [
              {
                query: GET_PODCAST_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', 'Audio Uploaded Successfully')

          setAudioLoading(false)
          setTimeout(() => {
            setAudioProgress(undefined)
          }, 500)
        })
      } catch (e) {
        Sentry.captureException(e)
        setAudioProgress(undefined)
        handleRequestFail()
      }
    }
  }

  async function handleAudioWithAdsChange(info, episodeId) {

    if (info?.fileList?.length > 1) {
      info.fileList.shift()
    }
    if (
      info?.fileList &&
      info?.fileList?.length > 0 &&
      (info?.fileList[0]?.type === 'audio/mp3' ||
        info?.fileList[0]?.type === 'audio/mpeg')
    ) {
      try {
        setAudioWithAdsLoading(true)
        setAudioWithAdsProgress(0)

        const getSignedPutUrlResult = await client.query({
          query: GET_PODCAST_EPISODE_SIGNED_AUDIO_PUT_URL,
          fetchPolicy: 'network-only',
          variables: { where: { id: episodeId }, corsOrigin: window?.location?.origin, withAds: true },
        })

        const uploadAudioWithAds = createUpload({
          endpoint:
            getSignedPutUrlResult?.data?.getPodcastEpisodeSignedAudioPutUrl?.signedUrl,
          file: info?.file?.originFileObj,
        })
        uploadAudioWithAds.on('error', (err) => {

          setAudioWithAdsLoading(false)
          setAudioWithAdsProgress(undefined)
        })
        uploadAudioWithAds.on('progress', (progress) => {

          setAudioWithAdsProgress(progress?.detail)
        })
        uploadAudioWithAds.on('success', async () => {

          let data = {}
          data.audioWithAdsState = 'PROCESSING'

          await client.mutate({
            mutation: UPDATE_PODCAST_EPISODE,
            variables: { data, where: { id: episodeId } },
            refetchQueries: [
              {
                query: GET_PODCAST_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', 'Audio Uploaded Successfully')

          setAudioWithAdsLoading(false)
          setTimeout(() => {
            setAudioWithAdsProgress(undefined)
          }, 500)
        })
      } catch (e) {
        Sentry.captureException(e)
        setAudioWithAdsProgress(undefined)
        handleRequestFail()
      }
    }
  }

  async function audioReplaceConfirm(episodeId, withAds) {
    confirm({
      title: 'Are you sure you want to RE-UPLOAD audio?',
      content:
        'Upon clicking RE-UPLOAD, you will be able to upload audio again.',
      okText: 'RE-UPLOAD',
      async onOk() {
        try {
          let data = {}
          if (withAds) {
            data.audioWithAdsState = 'UPLOAD_READY'
          } else {
            data.audioState = 'UPLOAD_READY'
          }
          await client.mutate({
            mutation: UPDATE_PODCAST_EPISODE,
            variables: { data, where: { id: episodeId } },
            refetchQueries: [
              {
                query: GET_PODCAST_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail()
        }
      },
    })
  }

  async function audioRemoveConfirm(episodeId, withAds) {
    confirm({
      title: 'Are you sure you want to DELETE this audio?',
      content: 'Upon clicking DELETE, the audio will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          let data = {}
          if (withAds) {
            data = { audioWithAds: null, durationWithAds: null, audioWithAdsState: 'IDLE' }
          } else {
            data = { audio: null, duration: null, audioState: 'IDLE' }
          }

          await client.mutate({
            mutation: UPDATE_PODCAST_EPISODE,
            variables: { data, where: { id: episodeId } },
            refetchQueries: [
              {
                query: GET_PODCAST_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', "Episode's Audio Removed Successfully")
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail()
        }
      },
    })
  }

  function beforeAudioUpload(file) {
    const isMP3 = file.type === 'audio/mp3' || file.type === 'audio/mpeg'
    if (!isMP3) {
      message.error('You can only upload MP3 file!')
    }
    return isMP3
  }

  const uploadAudioButton = (
    <div id="btn-upload-episode-audio">
      <Icon type={audioLoading ? 'loading' : 'sound'} />
      <div className="ant-upload-text">Upload Audio Without Ads</div>
    </div>
  )
  const uploadAudioWithAdsButton = (
    <div id="btn-upload-episode-audio-withads">
      <Icon type={audioWithAdsLoading ? 'loading' : 'sound'} />
      <div className="ant-upload-text">Upload Audio With Ads</div>
      {showAudioRequireMsg ? <div className="text-center audio-required-warning">Please Upload Audio</div> : ''}
    </div>
  )
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="segment-details-wrapper">
        {showPlayerModal && <PlayerModal
          showPlayerModal={showPlayerModal}
          handleOk={handleOk}
          episode={episode}
          audioPlayType={audioPlayType}
        />}
        <div className={`box`}>
          <div className={`box-insider`}>
            <div className="dropzone-wrapper">
              <Row
                gutter={24}
                type="flex"
                justify="center"
              >
                <Col span={12} className="audio-upload-wrapper">
                  {episode?.audioState === 'PROCESSING' ? (
                    <div className="play-button">
                      {(episode?.status === 'DRAFT' ||
                        episode?.status === 'UNPUBLISHED') && (
                          <span
                            className="remove"
                            onClick={() =>
                              audioReplaceConfirm(
                                episodeId,
                                false
                              )
                            }
                          >
                            <Icon type="close-circle" />
                          </span>
                        )}
                      <span>Audio uploading is under process</span>
                    </div>
                  ) : episode?.audio ? (
                    <div className="play-button download-play-wrapper">
                      {
                        episode?.status === 'PUBLISHED' ?
                          <Tooltip
                            key="2"
                            placement="topRight"
                            title={`Unpublish episode in order to change audio content`}
                            trigger="click"
                          >
                            <span className="remove">
                              <Icon type="close-circle" />
                            </span>
                          </Tooltip>
                          : <span
                            className="remove"
                            onClick={() =>
                              audioRemoveConfirm(episodeId, false)
                            }
                          >
                            <Icon type="close-circle" />
                          </span>
                      }
                      <div>
                        <Button
                          id='btn-audiowithoutads-play'
                          type="primary"
                          icon="play-circle"
                          shape="circle"
                          size="large"
                          onClick={(e) => handleModal(e, 'audio')}
                        />
                        <Button
                          id='btn-audiowithoutads-download'
                          type="primary"
                          shape="circle"
                          icon="download"
                          size="large"
                          loading={audioDownload}
                          onClick={(e) =>
                            handleDownload(episode.audioMuxAssetId, false)
                          }
                        />
                      </div>
                      <span className="audio-label">Audio Without Ads</span>
                      {audioDownload && <span className="audio-label">Processing Download</span>}
                    </div>
                  ) : (
                    <Upload
                      name="audio"
                      listType="picture-card"
                      className={`audio-uploader d-flex`}
                      showUploadList={false}
                      multiple={false}
                      disabled={audioLoading}
                      customRequest={() => {
                        return false
                      }}
                      onRemove={(e) => handleRemove(e)}
                      beforeUpload={beforeAudioUpload}
                      onChange={(e) =>
                        handleAudioChange(e, episodeId)
                      }
                    >
                      {uploadAudioButton}
                      {audioProgress >= 0 && (
                        <Progress
                          percent={audioProgress}
                          size="small"
                          status="active"
                        />
                      )}
                    </Upload>
                  )}
                </Col>
                <Col span={12} className="audio-upload-wrapper">
                  {episode?.audioWithAdsState === 'PROCESSING' ? (
                    <div className="play-button">
                      {(episode?.status === 'DRAFT' ||
                        episode?.status === 'UNPUBLISHED') && (
                          <span
                            className="remove"
                            onClick={() =>
                              audioReplaceConfirm(
                                episodeId,
                                true
                              )
                            }
                          >
                            <Icon type="close-circle" />
                          </span>
                        )}
                      <span>Audio uploading is under process</span>
                    </div>
                  ) : episode?.audioWithAds ? (
                    <div className="play-button download-play-wrapper">
                      {
                        episode?.status === 'PUBLISHED' ?
                          <Tooltip
                            key="3"
                            placement="topRight"
                            title={`Unpublish episode in order to change audio content`}
                            trigger="click"
                          >
                            <span className="remove">
                              <Icon type="close-circle" />
                            </span>
                          </Tooltip>
                          : <span
                            className="remove"
                            onClick={() =>
                              audioRemoveConfirm(episodeId, true)
                            }
                          >
                            <Icon type="close-circle" />
                          </span>
                      }
                      <div>
                        <Button
                          id='btn-audiowithads-play'
                          type="primary"
                          icon="play-circle"
                          shape="circle"
                          size="large"
                          onClick={(e) => handleModal(e, 'audioWithAds')}
                        />
                        <Button
                          id='btn-audiowithads-download'
                          type="primary"
                          shape="circle"
                          icon="download"
                          size="large"
                          loading={isAudioWithAdsDownload}
                          onClick={(e) =>
                            handleDownload(episode.audioWithAdsMuxAssetId, true)
                          }
                        />
                      </div>
                      <span className="audio-label">Audio With Ads</span>
                      {isAudioWithAdsDownload && <span className="audio-label">Processing Download</span>}
                    </div>
                  ) : (
                    <Upload
                      name="audio"
                      listType="picture-card"
                      className={`audio-uploader d-flex ${showAudioRequireMsg ? 'audio-required' : ''}`}
                      showUploadList={false}
                      multiple={false}
                      disabled={audioWithAdsLoading}
                      customRequest={() => {
                        return false
                      }}
                      onRemove={(e) => handleRemove(e)}
                      beforeUpload={beforeAudioUpload}
                      onChange={(e) =>
                        handleAudioWithAdsChange(e, episodeId)
                      }
                    >
                      {uploadAudioWithAdsButton}
                      {audioWithAdsProgress >= 0 && (
                        <Progress
                          percent={audioWithAdsProgress}
                          size="small"
                          status="active"
                        />
                      )}
                    </Upload>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </>
  )
}
