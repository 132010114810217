import React, { useState, Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Layout, Menu, Icon } from 'antd'
import logo from '../../assets/logo.png'
const { Sider } = Layout

export default class LayoutSider extends Component {
  render() {
    const { collapsed, pathname, role, showModularPages } = this.props
    const selectedItem = pathname.split('/')[1]
    return (
      <Sider
        trigger={null}
        width={250}
        collapsible
        collapsed={collapsed}
        theme="light"
      >
        <NavLink to="/" className="nav-text">
          <div className="logo">
            {collapsed ? (
              'DW'
            ) : (
                <img src={logo} alt="DAILYWIRE" title="DAILYWIRE" />
              )}
          </div>
        </NavLink>
        <Menu mode="inline" selectable={false}>
          <Menu.Item
            key="shows"
            className={`menu-list ${selectedItem === 'shows' ? 'ant-menu-item-selected' : ''
              }`}
          >
            <NavLink to="/shows" className="nav-text">
              <Icon type="video-camera" />
              <span>Shows</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="podcasts"
            className={`menu-list ${selectedItem === 'podcasts' ? 'ant-menu-item-selected' : ''
              }`}
          >
            <NavLink to="/podcasts" className="nav-text">
              <Icon type="audio" />
              <span>Podcasts</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="discussions"
            className={`menu-list ${selectedItem === 'discussions' ? 'ant-menu-item-selected' : ''
              }`}
          >
            <NavLink to="/discussions" className="nav-text">
              <i className="anticon custom-discussions">
                <svg viewBox="0 -26 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m256 100c-5.519531 0-10 4.480469-10 10s4.480469 10 10 10 10-4.480469 10-10-4.480469-10-10-10zm0 0"></path>
                  <path d="m90 280c5.519531 0 10-4.480469 10-10s-4.480469-10-10-10-10 4.480469-10 10 4.480469 10 10 10zm0 0"></path>
                  <path d="m336 0c-90.027344 0-163.917969 62.070312-169.632812 140.253906-85.738282 4.300782-166.367188 66.125-166.367188 149.746094 0 34.945312 13.828125 68.804688 39 95.632812 4.980469 20.53125-1.066406 42.292969-16.070312 57.296876-2.859376 2.859374-3.714844 7.160156-2.167969 10.898437 1.546875 3.734375 5.191406 6.171875 9.238281 6.171875 28.519531 0 56.003906-11.183594 76.425781-30.890625 19.894531 6.78125 45.851563 10.890625 69.574219 10.890625 90.015625 0 163.898438-62.054688 169.628906-140.222656 20.9375-.929688 42.714844-4.796875 59.945313-10.667969 20.421875 19.707031 47.90625 30.890625 76.425781 30.890625 4.046875 0 7.691406-2.4375 9.238281-6.171875 1.546875-3.738281.691407-8.039063-2.167969-10.898437-15.003906-15.003907-21.050781-36.765626-16.070312-57.296876 25.171875-26.828124 39-60.6875 39-95.632812 0-86.886719-86.839844-150-176-150zm-160 420c-23.601562 0-50.496094-4.632812-68.511719-11.800781-3.859375-1.539063-8.269531-.527344-11.078125 2.539062-12.074218 13.199219-27.773437 22.402344-44.878906 26.632813 9.425781-18.058594 11.832031-39.347656 6.097656-59.519532-.453125-1.589843-1.292968-3.042968-2.445312-4.226562-22.6875-23.367188-35.183594-53.066406-35.183594-83.625 0-70.46875 71.4375-130 156-130 79.851562 0 150 55.527344 150 130 0 71.683594-67.289062 130-150 130zm280.816406-186.375c-1.152344 1.1875-1.992187 2.640625-2.445312 4.226562-5.734375 20.171876-3.328125 41.460938 6.097656 59.519532-17.105469-4.226563-32.804688-13.433594-44.878906-26.632813-2.808594-3.0625-7.21875-4.078125-11.078125-2.539062-15.613281 6.210937-37.886719 10.511719-58.914063 11.550781-2.921875-37.816406-21.785156-73.359375-54.035156-99.75h130.4375c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10h-161.160156c-22.699219-11.554688-48.1875-18.292969-74.421875-19.707031 5.746093-67.164063 70.640625-120.292969 149.582031-120.292969 84.5625 0 156 59.53125 156 130 0 30.558594-12.496094 60.257812-35.183594 83.625zm0 0"></path>
                  <path d="m256 260h-126c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h126c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm0 0"></path>
                  <path d="m256 320h-166c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h166c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm0 0"></path>
                  <path d="m422 100h-126c-5.523438 0-10 4.476562-10 10s4.476562 10 10 10h126c5.523438 0 10-4.476562 10-10s-4.476562-10-10-10zm0 0"></path>
                </svg>
              </i>
              <span>Discussions</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="live-chat"
            className={`menu-list ${selectedItem === 'live-chat' ? 'ant-menu-item-selected' : ''
              }`}
          >
            <NavLink to="/live-chat" className="nav-text">
              <Icon type="message" />
              <span>Episode Live Chat</span>
            </NavLink>
          </Menu.Item>
          {role && role === 'ADMIN' && (
            <Menu.Item
              key="subscriber-management"
              className={`menu-list ${selectedItem === 'subscriber-management'
                  ? 'ant-menu-item-selected'
                  : ''
                }`}
            >
              <NavLink to="/subscriber-management" className="nav-text">
                {/* <Icon type="user" /> */}
                <i className="anticon custom-users">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </i>
                <span>Subscribers</span>
              </NavLink>
            </Menu.Item>
          )}
          {role && role === 'ADMIN' && (
            <Menu.Item
              key="user-management"
              className={`menu-list ${selectedItem === 'user-management'
                  ? 'ant-menu-item-selected'
                  : ''
                }`}
            >
              <NavLink to="/user-management" className="nav-text">
                <Icon type="user" />
                <span>User Management</span>
              </NavLink>
            </Menu.Item>
          )}
          {role && role === 'ADMIN' && (
            <Menu.Item
              key="badge-management"
              className={`menu-list ${selectedItem === 'badge-management'
                  ? 'ant-menu-item-selected'
                  : ''
                }`}
            >
              <NavLink to="/badge-management" className="nav-text">
                <Icon type="profile" />
                <span>Badge Management</span>
              </NavLink>
            </Menu.Item>
          )}
          {/* Remove "Featured Episodes" section from CMS DW-3743 */}
          {/* {role && role === 'ADMIN' && (
            <Menu.Item
              key="featured-episodes"
              className={`menu-list ${selectedItem === 'featured-episodes'
                  ? 'ant-menu-item-selected'
                  : ''
                }`}
            >
              <NavLink to="/featured-episodes" className="nav-text">
                <Icon type="profile" />
                <span>Featured Episodes</span>
              </NavLink>
            </Menu.Item>
          )} */}
          <Menu.Item
            key="seasons"
            className={`menu-list ${selectedItem === 'seasons' ? 'ant-menu-item-selected' : ''
              }`}
          >
            <NavLink to="/seasons" className="nav-text">
              <Icon type="video-camera" />
              <span>Season Management</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="videos"
            className={`menu-list ${selectedItem === 'videos' ? 'ant-menu-item-selected' : ''
              }`}
          >
            <NavLink to="/videos" className="nav-text">
              <Icon type="video-camera" />
              <span>Video Management</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="clips"
            className={`menu-list ${selectedItem === 'clips' ? 'ant-menu-item-selected' : ''
              }`}
          >
            <NavLink to="/clips" className="nav-text">
              <Icon type="video-camera" />
              <span>Clip Management</span>
            </NavLink>
          </Menu.Item>
          {showModularPages && (
          <Menu.Item
            key="modular-pages"
            className={`menu-list ${
              selectedItem === 'modular-pages' ? 'ant-menu-item-selected' : ''
            }`}
          >
            <NavLink to="/modular-pages" className="nav-text">
              <Icon type="profile" />
              <span>Modular Pages</span>
            </NavLink>
          </Menu.Item>)}
        </Menu>
      </Sider>
    )
  }
}
