import React, { useEffect, useContext, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { Form, Input, Upload, Icon, Button, Select, message, Radio } from 'antd'
import { useLazyQuery } from '@apollo/react-hooks'
import client from '../../../apollo'
import { openNotification } from '../../../common/utility'
import { AppContext } from '../../../AppContext'
import { GET_PODCASTS, GET_SHOWS } from '../graphql/Queries'
import { trimStart, debounce, get } from 'lodash'
import { useRef } from 'react'
const { Option } = Select

function beforeImageUpload(file) {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg'
  ) {
    return true
  }
  message.error('You can only upload image file!')
  return false
}

export default function (props) {
  const [removeFile, setRemoveFile] = useState(false)
  const [showsLoading, setShowsLoading] = useState(false)
  const [shows, setShows] = useState([])
  const [podcastsLoading, setPodcastsLoading] = useState(false)
  const [loadingMorePodcasts, setLoadingMorePodcasts] = useState(false)
  const [podcastDataEndReached, setPodcastDataEndReached] = useState(false)
  const debounceJob = useRef()
  const { state } = useContext(AppContext)

  useEffect(() => {
    setShowsLoading(true)
    client
      .query({
        query: GET_SHOWS,
      })
      .then((data) => {
        setShows(data.data.shows)
      })
      .catch((e) => {
        console.log({ e })
      })
      .finally(() => {
        setShowsLoading(false)
      })
  }, [state.currentUser])

  const [getPodcasts, {
    data: podcastData,
    fetchMore: fetchMorePodcasts
  }] = useLazyQuery(GET_PODCASTS, {
    variables: { first: 20 },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getPodcasts();
  }, [state.currentUser])

  const onPodcastSearch = (e) => {
    setPodcastsLoading(true)
    if (debounceJob && debounceJob.current) {
      debounceJob.current.cancel()
    }
    debounceJob.current = debounce(() => {
      getPodcasts({
        variables: {
          where: {
            OR: [
              { name_starts_with: e },
              { name_contains: e },
              { name_ends_with: e },
            ],
          },
        }
      })
    }, 500)

    debounceJob.current()
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }
  const {
    getFieldDecorator,
    getFieldValue,
    name,
    description,
    weight,
    show,
    orderBy,
    type,
    podcast,
  } = props

  const showId = get(show, 'id')
  const podcastId = get(podcast, 'id')

  const uploadProps = {
    customRequest() {
      return false
    },
  }

  const onShowSearch = (e) => {
    setShowsLoading(true)
    if (debounceJob && debounceJob.current) {
      debounceJob.current.cancel()
    }
    debounceJob.current = debounce(() => {
      client
        .query({
          query: GET_SHOWS,
          fetchPolicy: 'network-only',
          variables: {
            where: {
              OR: [
                { name_starts_with: e },
                { name_contains: e },
                { name_ends_with: e },
              ],
            },
          },
        })
        .then((data) => {
          setShows(data.data.shows)
        })
        .catch((e) => {
          console.log({ e })
        })
        .finally(() => {
          setShowsLoading(false)
        })
    }, 500)

    debounceJob.current()
  }


  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  const normalize = (value) => trimStart(value)

  const loadMorePodcasts = async () => {
    try {
      setLoadingMorePodcasts(true)
      let podcastLength = get(podcastData, 'listPodcastDetails').length
      let lastId = get(podcastData, `listPodcastDetails.${podcastLength - 1}.id`)
      let variables = {
        first: 20,
        after: lastId,
      }
      await fetchMorePodcasts({
        variables: variables,
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { listPodcastDetails: newPodcasts } = fetchMoreResult
          if (newPodcasts.length < 20) {
            setPodcastDataEndReached(true)
          }
          if (!newPodcasts) return prevResult;
          return { ...prevResult, listPodcastDetails: [...prevResult.listPodcastDetails, ...newPodcasts] }
        },
      })

    } catch (e) {
      Sentry.captureException(e)
      let errorMessage = get(e, 'graphQLErrors.0.message', 'Something Went Wrong')
      handleRequestFail(errorMessage)
    } finally {
      setLoadingMorePodcasts(false)
    }
  }

  const podcastDropDownScroll = async (event) => {

    let target = event.target
    let scrollHeight = target.scrollHeight
    if (!podcastDataEndReached && target.scrollTop + target.offsetHeight >= target.scrollHeight - 5) {
      await loadMorePodcasts()
      target.scrollTo(0, scrollHeight - 40)
    }
  }

  return (
    <Form layout="vertical" className="add-show-form">
      <Form.Item label="Season Name">
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Please write the season name' }],
          initialValue: name,
          normalize: normalize,
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Description">
        {getFieldDecorator('description', {
          rules: [{ required: true, message: 'Please write the description' }],
          initialValue: description,
          normalize: normalize,
        })(<Input.TextArea rows={5} />)}
      </Form.Item>
      <Form.Item label="Season Type">
        {getFieldDecorator('type', {
          rules: [{ required: true, message: 'Please select season type' }],
          initialValue: type,
        })(
          <Radio.Group disabled={type}>
            <Radio value="SHOW">Show</Radio>
            <Radio value="PODCAST">Podcast</Radio>
          </Radio.Group>,
        )}
      </Form.Item>
      {getFieldValue('type') === 'SHOW' &&
        <Form.Item label="Select Show">
          {getFieldDecorator('showId', {
            rules: [{ required: true, message: 'Please select show' }],
            initialValue: showId,
          })(
            <Select
              className='season-show'
              showSearch
              onSearch={onShowSearch}
              optionFilterProp="children"
              placeholder="Select Show"
              loading={showsLoading}
              disabled={showId}
            >
              {shows &&
                shows.map(({ id, name }) => (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                ))}
            </Select>,
          )}
        </Form.Item>
      }
      {getFieldValue('type') === 'PODCAST' &&
        <Form.Item label="Select Podcast">
          {getFieldDecorator('podcastId', {
            rules: [{ required: true, message: 'Please select podcast' }],
            initialValue: podcastId,
          })(
            <Select
              className='season-show'
              showSearch
              onSearch={onPodcastSearch}
              optionFilterProp="children"
              placeholder="Select Podcast"
              loading={podcastsLoading || loadingMorePodcasts}
              disabled={podcastId}
              onPopupScroll={podcastDropDownScroll}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {podcastData &&
                podcastData.listPodcastDetails?.map(({ id, name }) => (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                ))}
            </Select>,
          )}
        </Form.Item>
      }
      <Form.Item label="Thumbnail Image">
        {getFieldDecorator('image', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
          // rules: [{ required: true, message: "Please upload show image!" }],
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id='btn-season-thumbnail-image'>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
      <Form.Item label="Episode order">
        {getFieldDecorator('orderBy', {
          rules: [{ required: true, message: 'Please select Episode order' }],
          initialValue: orderBy,
        })(
          <Select className='season-episode-order' placeholder="Select Episode Order" loading={showsLoading}>
            <Option value={'CreatedAt_ASC'}>Oldest First</Option>
            <Option value={'CreatedAt_DESC'}>Newest First</Option>
            <Option value={'Manual'}>Custom (Weight)</Option>
          </Select>,
        )}
      </Form.Item>
      <Form.Item label="Season Weight">
        {getFieldDecorator('weight', {
          rules: [{ required: true, message: 'Please write Season Weight' }],
          initialValue: weight,
          normalize: normalize,
        })(<Input type="number" />)}
      </Form.Item>
    </Form>
  )
}
