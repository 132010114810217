import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Row, Col, Button, Upload, Icon, Modal, message, Progress, Divider } from 'antd'
import * as Sentry from '@sentry/browser'
import { createUpload } from '@mux/upchunk'
import PlayerModal from './PlayerModal'
import client from '../../../apollo'
import {
  GET_SEGMENT_SIGNED_PUT_URL,
  GET_SEGMENT_SIGNED_VIDEO_PUT_URL,
  GET_EPISODE,
  GET_MUX_DATA,
} from '../../episode/graphql/Queries'
import {
  UPDATE_SEGMENT,
  UPDATE_SEGMENT_VIDEO_STATE,
  DELETE_SEGMENT_VIDEO,
  DELETE_SEGMENT_AUDIO,
  REQUEST_MASTER_ACCESS,
} from '../../episode/graphql/Mutations'
import { fileUpload } from '../../../common/fileUpload'
import { GET_SEGMENT } from '../graphql/Queries'
import history from '../../../history'
import { get } from 'lodash'
import AddSubTitleForm from './AddSubTitleForm'

const { confirm } = Modal
export default function SegmentDetails(props) {
  const [showPlayerModal, setShowPlayerModal] = useState(false)
  const [isDownload, setIsDownload] = useState(false)
  const [isAudioPlay, setIsAudioPlay] = useState(false)
  const [videoProgress, setVideoProgress] = useState(undefined)
  const [uploadPercentage, setUploadPercentage] = useState(undefined)
  const {
    episodeId,
    episodeStatus,
    segment = {},
    handleRemove,
    setVideoLoading,
    setAudioLoading,
    videoLoading,
    audioLoading,
    openNotification,
  } = props

  function handleBeforeUnload(e) {
    if (!(videoLoading || audioLoading)) {
      return true
    }
    e.preventDefault()
    e.returnValue = 'Some task is in progress. Are you sure, you want to close?'
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    history.block((location, action) => {
      return videoLoading || audioLoading
        ? 'Some task is in progress. Are you sure, you want to close?'
        : true
    })
  })

  function handleOk(e) {
    setShowPlayerModal(false)
  }

  const { data: segmentData } = useQuery(GET_SEGMENT, {
    variables: { id: segment.id },
  })

  function handleModal(e, type) {
    setShowPlayerModal(true)
    setIsAudioPlay(type === 'video' ? false : true)
  }
  function handleRequestFail() {
    setVideoLoading(false)
    setAudioLoading(false)
    openNotification('error', 'Something Went Wrong')
  }

  async function handleVideoFileChange(info, segmentId) {
    if (info.fileList.length > 1) {
      info.fileList.shift()
    }
    if (info.file && info.file.type === 'video/mp4') {
      try {
        setVideoLoading(true)
        setVideoProgress(0)
        if (segmentId) {
          const getSegmentSignedVideoPutUrlResult = await client.query({
            query: GET_SEGMENT_SIGNED_VIDEO_PUT_URL,
            fetchPolicy: 'network-only',
            variables: { segmentId, corsOrigin: window.location.origin },
          })

          const upload = createUpload({
            endpoint:
              getSegmentSignedVideoPutUrlResult.data.getSegmentSignedVideoPutUrl
                .signedUrl,
            file: info.file.originFileObj,
          })
          upload.on('error', (err) => {
            setVideoLoading(false)
            setVideoProgress(undefined)
            // openNotification("error", "Something Went Wrong")
          })
          upload.on('progress', (progress) => {
            setVideoProgress(progress.detail)
          })
          upload.on('success', async () => {
            // console.log("Wrap it up, we're done here. 👋");
            await client.mutate({
              mutation: UPDATE_SEGMENT_VIDEO_STATE,
              variables: { segmentId, videoState: 'PROCESSING' },
              refetchQueries: [
                {
                  query: GET_EPISODE,
                  variables: { id: episodeId },
                },
              ],
            })
            openNotification('success', 'Video Uploaded Successfully')
            setVideoLoading(false)
            setTimeout(() => {
              setVideoProgress(undefined)
            }, 500)
          })
        }
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail()
      }
    }
  }

  async function handleDownload(muxAssetId) {
    try {
      const muxAssetsResult = await client.query({
        query: GET_MUX_DATA,
        fetchPolicy: 'network-only',
        variables: { assetId: muxAssetId },
      })
      const master = muxAssetsResult.data.getMuxData.master
      if (master && master.url) {
        setIsDownload(false)
        window.open(master.url)
      } else {
        if (isDownload === false) {
          setIsDownload(true)
          await client.mutate({
            mutation: REQUEST_MASTER_ACCESS,
            fetchPolicy: 'no-cache',
            variables: { assetId: muxAssetId },
          })
        }
        setTimeout(() => {
          handleDownload(muxAssetId)
        }, 30000)
      }
    } catch (err) {
      Sentry.captureException(err)
      setIsDownload(false)
    }
  }

  function onUploadProgress(value) {
    setUploadPercentage(value)
  }

  function computeAudioDuration(file) {
    return new Promise((resolve) => {
      const objectURL = URL.createObjectURL(file);
      const mySound = new Audio([objectURL]);
      mySound.addEventListener(
        "canplaythrough",
        () => {
          URL.revokeObjectURL(objectURL);
          resolve({
            file,
            audioDuration: mySound.duration
          });
        },
        false,
      );
    });
  }

  async function handleAudioChange(info, segmentId, episodeId) {
    if (info.fileList.length > 1) {
      info.fileList.shift()
    }
    if (
      info.fileList &&
      info.fileList.length > 0 &&
      (info.fileList[0].type === 'audio/mp3' ||
        info.fileList[0].type === 'audio/mpeg')
    ) {
      try {
        setAudioLoading(true)
        if (segmentId) {
          const tempFilename = info.fileList[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${segmentId}-${new Date().getTime()}.${fileExt}`
          const contentType = info.fileList[0].type
          const getSegmentPutUrlResult = await client.query({
            query: GET_SEGMENT_SIGNED_PUT_URL,
            fetchPolicy: 'network-only',
            variables: { fileName, episodeId, segmentId, contentType },
          })
          await fileUpload(
            getSegmentPutUrlResult.data.getSegmentSignedPutUrl.signedUrl,
            info.fileList[0].originFileObj,
            onUploadProgress,
          )

          let duration = null

          const response = await client.query({
            query: GET_SEGMENT,
            variables: { id: segment.id },
          })

          if (response && get(response, 'data.episodeSegment') && !get(response, 'data.episodeSegment.video') && (!get(response, 'data.episodeSegment.duration') || get(response, 'data.episodeSegment.duration') !== '')) {
            const { audioDuration } = await computeAudioDuration(info.fileList[0].originFileObj)
            duration = parseFloat(audioDuration.toFixed(2))
          }

          await client.mutate({
            mutation: UPDATE_SEGMENT,
            variables: { audio: fileName, segmentId, ...(duration && { duration }) },
            refetchQueries: [
              {
                query: GET_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', 'Audio Uploaded Successfully')
          setAudioLoading(false)
          setTimeout(() => {
            setUploadPercentage(undefined)
          }, 500)
        }
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail()
      }
    }
  }

  async function SegmentVideoReplaceConfirm(segmentId, episodeId) {
    if (segmentData?.episodeSegment?.captions && segmentData?.episodeSegment?.captions?.length > 0) {
      openNotification('error', 'Please delete subtitle first')
    }
    else {
      confirm({
        title: 'Are you sure you want to RE-UPLOAD video?',
        content:
          'Upon clicking RE-UPLOAD, you will be able to upload video again.',
        okText: 'RE-UPLOAD',
        async onOk() {
          try {
            await client.mutate({
              mutation: UPDATE_SEGMENT_VIDEO_STATE,
              variables: { segmentId, videoState: 'UPLOAD_READY' },
              refetchQueries: [
                {
                  query: GET_EPISODE,
                  variables: { id: episodeId },
                },
              ],
            })
          } catch (e) {
            Sentry.captureException(e)
            handleRequestFail()
          }
        },
      })
    }
  }

  async function SegmentVideoRemoveConfirm(segmentId, episodeId) {
    if (segmentData?.episodeSegment?.captions && segmentData?.episodeSegment?.captions?.length > 0) {
      openNotification('error', 'Please delete subtitle first')
    }
    else {
      confirm({
        title: 'Are you sure you want to DELETE this video?',
        content: 'Upon clicking DELETE, the video will be permanently erased.',
        okText: 'Delete',
        okType: 'danger',
        async onOk() {
          try {
            await client.mutate({
              mutation: DELETE_SEGMENT_VIDEO,
              variables: { segmentId },
              refetchQueries: [
                {
                  query: GET_EPISODE,
                  variables: { id: episodeId },
                },
              ],
            })
            openNotification('success', "Segment's Video Removed Successfully")
          } catch (e) {
            Sentry.captureException(e)
            handleRequestFail()
          }
        },
      })
    }
  }

  async function SegmentAudioRemoveConfirm(segmentId, episodeId) {
    confirm({
      title: 'Are you sure you want to DELETE this audio?',
      content: 'Upon clicking DELETE, the audio will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          const data = { audio: "", audioState: "IDLE" }

          if (!get(segmentData, 'episodeSegment.video')) {
            data.duration = null    // only add when there is no video
          }

          await client.mutate({
            mutation: DELETE_SEGMENT_AUDIO,
            variables: { segmentId, data },
            refetchQueries: [
              {
                query: GET_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', "Segment's Audio Removed Successfully")
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail()
        }
      },
    })
  }

  function beforeAudioUpload(file) {
    const isMP3 = file.type === 'audio/mp3' || file.type === 'audio/mpeg'
    if (!isMP3) {
      message.error('You can only upload MP3 file!')
    }
    return isMP3
  }

  function beforeVideoUpload(file) {
    const isMP4 = file.type === 'video/mp4'
    if (!isMP4) {
      message.error('You can only upload MP4 file!')
    }
    return isMP4
  }

  const uploadVideoButton = (
    <div id="btn-upload-segment-video">
      <Icon type={videoLoading ? 'loading' : 'play-circle'} />
      <div className="ant-upload-text">Upload Video</div>
    </div>
  )
  const uploadAudioButton = (
    <div id="btn-upload-segment-audio">
      <Icon type={audioLoading ? 'loading' : 'sound'} />
      <div className="ant-upload-text">Upload Audio</div>
    </div>
  )
  return segmentData && segmentData.episodeSegment ? (
    <>
      <Col sm={24} md={24} lg={16} xl={16} className="segment-details-wrapper">
        <PlayerModal
          showPlayerModal={showPlayerModal}
          handleOk={handleOk}
          segment={segmentData.episodeSegment}
          isAudioPlay={isAudioPlay}
          captions={segmentData.episodeSegment.captions}
        />
        <div
          className={`box`}
        //  ${segmentData.episodeSegment.image ? 'box-image' : ''}
        // style={{
        //   position: "relative",
        //   backgroundImage: `url(${segmentData.episodeSegment.image})`,
        //   backgroundPosition: "center",
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        // }}
        >
          {/* {
            segmentData.episodeSegment.image &&
            <div className="mask"></div>
          } */}
          <div className={`box-insider`}>
            {/* ${segmentData.episodeSegment.image ? 'box-image' : ''} */}
            <div className="segmenet-title">
              {segmentData.episodeSegment.title}
            </div>
            <div className="dropzone-wrapper">
              <Row
                gutter={24}
                type="flex"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Col span={12} style={{ paddingLeft: 24, paddingRight: 24 }}>
                  {segmentData.episodeSegment.videoState === 'PROCESSING' ? (
                    <div className="play-button">
                      {(episodeStatus === 'LIVE_FINISHED' ||
                        episodeStatus === 'DRAFT' ||
                        episodeStatus === 'UNPUBLISHED') && (
                          <span
                            className="remove"
                            onClick={() =>
                              SegmentVideoReplaceConfirm(
                                segmentData.episodeSegment.id,
                                episodeId,
                              )
                            }
                          >
                            <Icon type="close-circle" />
                          </span>
                        )}
                      <span>Video uploading is under process</span>
                    </div>
                  ) : segmentData.episodeSegment.video ? (
                    <div className="play-button download-play-wrapper">
                      <span
                        className="remove"
                        onClick={() =>
                          SegmentVideoRemoveConfirm(
                            segmentData.episodeSegment.id,
                            episodeId,
                          )
                        }
                      >
                        <Icon type="close-circle" />
                      </span>
                      <div>
                        <Button
                          id='btn-segment-play'
                          type="primary"
                          icon="play-circle"
                          shape="circle"
                          size="large"
                          onClick={(e) => handleModal(e, 'video')}
                          style={{ marginRight: 10 }}
                        />
                        <Button
                          id='btn-segment-download'
                          type="primary"
                          shape="circle"
                          icon="download"
                          size="large"
                          loading={isDownload}
                          onClick={(e) =>
                            handleDownload(
                              segmentData.episodeSegment.muxAssetId,
                            )
                          }
                        />
                      </div>
                      {isDownload && <span>Processing Download</span>}
                      {/* <Button type="primary" icon="play-circle" size="large" onClick={(e) => handleModal(e, 'video')}>Download</Button> */}
                    </div>
                  ) : (
                    <Upload
                      name="video"
                      listType="picture-card"
                      className="video-uploader"
                      multiple={false}
                      disabled={videoLoading}
                      customRequest={() => {
                        return false
                      }}
                      onRemove={(e) => handleRemove(e)}
                      showUploadList={false}
                      beforeUpload={beforeVideoUpload}
                      onChange={(e) =>
                        handleVideoFileChange(e, segmentData.episodeSegment.id)
                      }
                    >
                      {uploadVideoButton}
                      {videoProgress >= 0 && (
                        <Progress
                          percent={videoProgress}
                          size="small"
                          status="active"
                        />
                      )}
                    </Upload>
                  )}
                </Col>
                <Col span={12} style={{ paddingLeft: 24, paddingRight: 24 }}>
                  {segmentData.episodeSegment.audio ? (
                    <div className="play-button">
                      <span
                        className="remove"
                        onClick={() =>
                          SegmentAudioRemoveConfirm(
                            segmentData.episodeSegment.id,
                            episodeId,
                          )
                        }
                      >
                        <Icon type="close-circle" />
                      </span>
                      <Button
                        type="primary"
                        icon="play-circle"
                        size="large"
                        onClick={(e) => {
                          handleModal(e, 'audio')
                        }}
                      >
                        Play Audio
                      </Button>
                    </div>
                  ) : (
                    <Upload
                      name="audio"
                      listType="picture-card"
                      className="audio-uploader"
                      showUploadList={false}
                      multiple={false}
                      disabled={audioLoading}
                      customRequest={() => {
                        return false
                      }}
                      onRemove={(e) => handleRemove(e)}
                      beforeUpload={beforeAudioUpload}
                      onChange={(e) =>
                        handleAudioChange(
                          e,
                          segmentData.episodeSegment.id,
                          episodeId,
                        )
                      }
                    >
                      {uploadAudioButton}
                      {uploadPercentage >= 0 && (
                        <Progress
                          percent={uploadPercentage}
                          size="small"
                          status="active"
                        />
                      )}
                    </Upload>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <Row gutter={24} type="flex" className="episode-subtitle-divider">
            <Divider />
          </Row>
          <div className={`box-insider`}>
            <div className="segmenet-title">
              Upload Subtitle
            </div>
            {
              segmentData &&
              segmentData.episodeSegment &&
              (segmentData.episodeSegment.video === '' || segmentData.episodeSegment.video === null) &&
              <p>In order to upload subtitle, Please upload video first.</p>
            }
            {segmentData &&
              segmentData.episodeSegment &&
              segmentData.episodeSegment.muxAssetId &&
              segmentData.episodeSegment.video !== '' &&
              <AddSubTitleForm
                muxAssetId={segmentData.episodeSegment.muxAssetId}
                entityId={segmentData.episodeSegment.id}
                captions={segmentData.episodeSegment.captions}
                segment={segment}
              />
            }
          </div>
        </div>
      </Col>
      {/* {
        segmentData.episodeSegment.description && <Row gutter={24} type="flex" style={{ width: '100%' }}>
          <Col span={16} style={{ paddingLeft: 24, paddingRight: 24 }} offset={8}>
              <div className="segment-description">
                <div className="fw-600">Segment Description</div>
                {segmentData.episodeSegment.description}
              </div>
            </Col>
        </Row>
      } */}
    </>
  ) : null
}
