import React, { useEffect, useState, useContext } from 'react'
import { Form, Input, Select, DatePicker, Tooltip } from 'antd'
import moment from 'moment-timezone'
import { debounce } from 'lodash'
import { GET_AUTHOR_LIST } from '../graphql/Queries'
import { AppContext } from '../../../AppContext'
import { chatClient } from '../../../apollo'

const { Option } = Select

export default function AddDiscussionForm(props) {
  const [authorLoading, setAuthorLoading] = useState(false)
  const [authors, setAuthors] = useState([])
  const [scheduleTitle, setScheduleTitle] = useState('')
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)
  const { state } = useContext(AppContext)
  let authorDebounceJob

  function disabledStartDate(startValue) {
    return startValue.valueOf() < moment().valueOf();
  }

  function handleScheduleChange(currentTime) {
    if (currentTime) {
      const laTime = moment(currentTime.valueOf()).tz('America/Los_Angeles')
      setScheduleTitle(`Schedule at: ${laTime.format('lll')} ${laTime.zoneAbbr()}`)
      setTooltipIsVisible(true)
    } else {
      setScheduleTitle('')
      setTooltipIsVisible(false)
    }
  }

  useEffect(() => {
    if (state.currentUser.role !== 'AUTHOR') {
      getAuthorList()
    }
  }, [state.currentUser])

  function getAuthorList(searchFilter = undefined) {        
    let queryFilter = { first: 60, skip: 0, where: { searchTerm: searchFilter } }
    setAuthorLoading(true)
    chatClient
      .query({
        query: GET_AUTHOR_LIST,
        variables: queryFilter,
        fetchPolicy: 'network-only',
      })
      .then((data) => {
        setAuthors(data.data.authorList)
        setAuthorLoading(false)
      })
      .catch((e) => {
        console.log({ e })
        setAuthorLoading(false)
      })
  }

  function onHostChange(author){    
    if (!author) {
      getAuthorList()
    }
  }

  function onHostSearch(e){
    if (authorDebounceJob) {
      authorDebounceJob.cancel()
    }
    authorDebounceJob = debounce(() => {
      getAuthorList(e)
    }, 500)
    authorDebounceJob()
  }

  const { getFieldDecorator, subject, description, scheduleAt, host, isEdit, showModal } = props
  return (
    <Form layout="vertical" className="add-show-form">
      <Form.Item label="Subject">
        {getFieldDecorator('subject', {
          rules: [{ required: true, message: 'Please enter the subject!' }],
          initialValue: subject
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Description">
        {getFieldDecorator('description',
          {
            // rules: [{ required: true, message: "Please write the description" }],
            initialValue: description
          })(<Input.TextArea rows={5} />)}
      </Form.Item>
      {state.currentUser.role !== 'AUTHOR' &&
        <Form.Item label="Host">
          {getFieldDecorator('host', {
            rules: [{ required: true, message: 'Please select the host!' }],
            initialValue: host
          })(
            <Select placeholder="Select Host" 
              loading={authorLoading}            
              showSearch
              allowClear
              onChange={onHostChange}
              onSearch={onHostSearch}
              optionFilterProp="children"
            >            
              {authors.map((author, key) => (
                <Option value={author.email} key={key}>{author.firstName} {author.lastName} ({author.email})</Option>
              ))}
            </Select>
          )}
        </Form.Item>
      }
      <Form.Item label="Schedule Time">
        <Tooltip title={scheduleTitle} visible={showModal && tooltipIsVisible} placement="right">
          {getFieldDecorator('schedule', {
            rules: [{ required: true, message: 'Please enter the schedule time!' }],
            initialValue: scheduleAt ? moment(scheduleAt) : null
          })(
            <DatePicker
              style={{ minWidth: 220 }}
              placeholder="Select Date/Time"
              format="MM-DD-YYYY hh:mm A"
              showTime={{ use12Hours: true, format: "hh:mm A" }}
              disabledDate={disabledStartDate}
              disabled={isEdit}
              onChange={handleScheduleChange}
              onOk={handleScheduleChange}
            />
          )}
        </Tooltip>
      </Form.Item>
    </Form>
  )
}
