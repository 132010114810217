import React from 'react'
import { Row, Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import {
  GET_CLIPS,
  LIST_SHOW_FOR_CLIP,
  LIST_VIDEO_FOR_CLIP,
} from './graphql/Queries'
import CardLoader from '../../components/loaders/CardLoader'
import ClipWrapper from './components/ClipWrapper'
// import ClipWrapper.js from './components/AddShowCard'
import Meta from '../../components/Meta'
import './clip.css'
import client from '../../apollo'
import { useEffect } from 'react'
import { useState } from 'react'
import AddClipCard from './components/AddClipCard'
const Option = Select.Option

export default function (props) {
  const [clips, setClips] = useState([])
  const [loading, setLoading] = useState([])
  const [listVariable, setListVariable] = useState({
    showId: 'ALL',
    videoId: 'ALL',
  })
  const {
    data,
    loading: isClipLoading,
    error: showsError,
    fetchMore,
  } = useQuery(GET_CLIPS, {
    variables: { first: 50 },
    fetchPolicy: 'network-only',
  })

  const {
    data: { listShowForClip },
    loading: showsLoading,
  } = useQuery(LIST_SHOW_FOR_CLIP, {
    fetchPolicy: 'network-only',
  })

  const {
    data: { listVideoForClip },
    loading: videoLoading,
  } = useQuery(LIST_VIDEO_FOR_CLIP, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!isClipLoading) {
      setLoading(false)
      setClips(data.clips)
    } else {
      setLoading(true)
    }
  }, [isClipLoading])

  const onListForSeason = (options) => {
    setLoading(true)
    let variables = { first: 20 }
    let where = {}
    if (listVariable.showId && listVariable.showId !== 'ALL') {
      where.show = { id: listVariable.showId }
    }
    if (listVariable.videoId && listVariable.videoId !== 'ALL') {
      where.video = { id: listVariable.videoId }
    }
    where = { ...where, ...options }
    variables = { ...variables, where }
    client
      .query({
        query: GET_CLIPS,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({ data: { clips } }) => {
        setClips(clips)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { history } = props
  return !showsError ? (
    <div className="shows-scroll">
      <div
        className="shows-list"
        style={{
          margin: '10px 24px ',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {/* <Select
          style={{ width: '250px', marginRight: '12px' }}
          loading={showsLoading}
          defaultValue={'ALL'}
          placeholder="Select Shows"
          onChange={(e) => {
            listVariable.showId = e
            setListVariable({ ...listVariable })
            if (e !== 'ALL') {
              onListForSeason({ show: { id: e } })
            } else {
              onListForSeason()
            }
          }}
        >
          <Option key={'ALL'} value={'ALL'}>
            All Shows
          </Option>
          {listShowForClip &&
            listShowForClip.map(({ name, id }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
        </Select> */}

        <Select
          className='clip-video'
          style={{ width: '250px' }}
          loading={showsLoading}
          defaultValue={'ALL'}
          placeholder="Select Videos"
          onChange={(e) => {
            listVariable.videoId = e
            setListVariable({ ...listVariable })
            if (e !== 'ALL') {
              onListForSeason({ video: { id: e } })
            } else {
              onListForSeason()
            }
          }}
        >
          <Option key={'ALL'} value={'ALL'}>
            All Videos
          </Option>
          {listVideoForClip &&
            listVideoForClip.map(({ name, id }) => (
              <Option key={id} value={id}>
                {name}
              </Option>
            ))}
        </Select>
      </div>

      <Row gutter={24} type="flex" className="shows-wrapper shows-list">
        <Meta title="Clips" description="" />
        <AddClipCard history={history} />
        {loading ? (
          <CardLoader />
        ) : (
          <ClipWrapper
            clips={clips}
            updateEpisodeList={onListForSeason}
            onLoadMore={() => {
              fetchMore({
                loading: isClipLoading,
                variables: {
                  first: 20,
                  after: data.clips[data.clips.length - 1].id,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  const { clips } = fetchMoreResult
                  if (clips.length) {
                    setClips([...prevResult.clips, ...clips])
                  }
                  return clips.length
                    ? {
                        clips: [...prevResult.clips, ...clips],
                      }
                    : prevResult
                },
              })
            }}
          />
        )}
      </Row>
    </div>
  ) : (
    <>{showsError.message}</>
  )
}
