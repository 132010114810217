import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddSegmentForm from './AddSegmentForm'

const CollectionCreateForm = Form.create({ name: 'create_segment' })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const { visible, onCancel, onCreate, form, isSubmit, segment, order, isNewSegment, currentEpisode, showLiveChatAccssField,
        handleAccessLevelChange, isAllowScheduleAccessLevel } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${!isNewSegment && segment ? 'Edit Segment Details' : 'Add New Segment'}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-segment-cancel' key='back' onClick={onCancel}>Cancel</Button>,
            <Button id='btn-segment-save' key='submit' type='primary' loading={isSubmit} onClick={onCreate}>
              {!isNewSegment && segment ? 'Save' : 'Add Segment'}
            </Button>
          ]}
        >
          <AddSegmentForm
            getFieldDecorator={getFieldDecorator}
            segment={segment}
            isNewSegment={isNewSegment}
            order={order}
            currentEpisode={currentEpisode}
            showLiveChatAccssField={showLiveChatAccssField}
            handleAccessLevelChange={handleAccessLevelChange}
            isAllowScheduleAccessLevel={isAllowScheduleAccessLevel}
          />
        </Modal>
      )
    }
  }
)

export default class AddSegmentModal extends Component {
  render() {
    const { showModal, isSubmit, handleCancel, handleCreate, saveFormRef, segment, order, isNewSegment, currentEpisode, showLiveChatAccssField,
      handleAccessLevelChange, isAllowScheduleAccessLevel } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        segment={segment}
        order={order}
        isNewSegment={isNewSegment}
        currentEpisode={currentEpisode}
        showLiveChatAccssField={showLiveChatAccssField}
        handleAccessLevelChange={handleAccessLevelChange}
        isAllowScheduleAccessLevel={isAllowScheduleAccessLevel}
      />
    )
  }
}
