import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddPodcastForm from './AddPodcastForm'

const CollectionCreateForm = Form.create({ name: 'create_podcast' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        isSubmit,
        onCancel,
        onCreate,
        form,
        name,
        description,
        author,
        weight,
        isEdit
      } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${name ? 'Edit Podcast Details' : 'Add New Podcast'}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-show-cancel' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id={name ? 'btn-podcast-edit' : 'btn-podcast-save'}
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              {name ? 'Save' : 'Add Podcast'}
            </Button>,
          ]}
        >
          <AddPodcastForm
            name={name}
            description={description}
            author={author}
            weight={weight}
            isEdit={isEdit}
            getFieldDecorator={getFieldDecorator}
          />
        </Modal>
      )
    }
  },
)

export default class AddPodcastModal extends Component {
  render() {
    const {
      showModal,
      isSubmit,
      handleCancel,
      handleCreate,
      saveFormRef,
      name,
      description,
      author,
      weight,
      isEdit
    } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        name={name}
        description={description}
        author={author}
        weight={weight}
        isEdit={isEdit}
      />
    )
  }
}
