import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Select, Icon } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce, cloneDeep, isEqual, uniqBy } from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import client from '../../../../apollo'
import { GET_EPISODES, LIST_SHOW_FOR_SEASON } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE, DELETE_CAROUSEL_EPISODE } from '../../graphql/Mutations'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import EpisodeListCard from './EpisodeListCard'
import MoveArrow from '../MoveArrow'
import usePrevious from '../usePrevious'

const confirm = Modal.confirm
const Option = Select.Option
let debounceJob
const defaultPageSize = 7

function EpisodeCarousel(props) {
  const { episodeModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [search, setSearch] = useState('')
  const [episodes, setEpisodes] = useState(episodeModuleData.episodes || [])
  const [isPublished, setIsPublished] = useState(episodeModuleData.isPublished || false)
  const [show, setShow] = useState('')
  const [episodeNextOrder, setEpisodeNextOrder] = useState(episodeModuleData.nextOrder || 1)
  const [orderChangeModal, setOrderChangeModal] = useState(false)
  const [current, setCurrent] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const oldEpisodesProp = usePrevious(episodeModuleData.episodes || []);

  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {
    if (!isEqual(oldEpisodesProp, episodeModuleData.episodes)) {
      setEpisodes(episodeModuleData.episodes)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeModuleData.episodes])

  const {
    data: { listShowForSeason },
    loading: showsLoading,
  } = useQuery(LIST_SHOW_FOR_SEASON, {
    fetchPolicy: 'network-only',
  })

  const id_not = episodes.map(({ id }) => (id));
  const [getEpisodes, {
    data: listEpisodes,
    loading: dataLoading
  }] = useLazyQuery(GET_EPISODES, {
    variables: {
      first: 5,
      where: {
        ...(show !== '' && { show: { id: show } }),
        OR: [{ title_starts_with: titleCase(search) },
          { title_starts_with: search.toUpperCase() },
          { title_starts_with: search.toLowerCase() },
          { title_starts_with: search },
          { title_contains: titleCase(search) },
          { title_contains: search.toUpperCase() },
          { title_contains: search.toLowerCase() },
          { title_contains: search },
          { title_ends_with: search },
          { title_ends_with: search.toUpperCase() },
          { title_ends_with: search.toLowerCase() },
        ],
        id_not_in: id_not
      }
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getEpisodes()
  }, [])

  useEffect(() => {
    if (search.length) {
      if (debounceJob) {
        debounceJob.cancel()
      }
      debounceJob = debounce(() => {
        getEpisodes()
      }, 500)
      debounceJob()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, show])

  const onSeasonSelect = (e) => {
    setSearch('')
    if (e === 'ALL') {
      setShow('')
    } else {
      setShow(e)
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      sorter: false,
      width: '20%',
      render: (image) => (
        <img
          className="table-image"
          src={image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'title',
      sorter: false,
      render: (title) => (<div className="table-name">
        {title}
      </div>)
    },

  ]

  const columnsOrderChange = [
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (record) => (
        <img
          className="table-image"
          src={record.image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      sorter: false,
      render: (_, record, index) => (<div className="table-name">{record.title}</div>),
    },
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (_, record, index) => {
        let indexId = index + (current - 1) * defaultPageSize
        return <div>
          {(indexId !== (episodes.length - 1)) &&
            <Icon type="down" className="p-2" onClick={() => changeOrder(indexId, episodes, 1)} />}

          {(indexId !== 0) &&
            <Icon type="up" className="p-2" onClick={() => changeOrder(indexId, episodes, -1)} />}
        </div>
      },
    },

  ]

  function changeOrder(indexId, episodes, direction) {
    const item = cloneDeep(episodes[indexId])
    const nextItem = cloneDeep(episodes[indexId + direction])
    const itemOrder = item.carouselEpisodeOrder
    item.carouselEpisodeOrder = nextItem.carouselEpisodeOrder
    nextItem.carouselEpisodeOrder = itemOrder
    const newEpisodeList = episodes.filter(episode => {
      if (episode.id === item.id || episode.id === nextItem.id) {
        return false
      }
      return true
    })

    if (direction === -1) {
      newEpisodeList.splice(indexId + direction, 0, item, nextItem)
    } else {
      newEpisodeList.splice(indexId, 0, nextItem, item)
    }
    setEpisodes(newEpisodeList)
    setFieldsValue({ [`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}.${episodeModuleData.id}.episodes`]: newEpisodeList })
  }


  function handleCancel() {
    setCurrent(1)
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    setShow('')
    getEpisodes()
    setShowModal(true)
  }

  function handleOrderChangeModalCancel() {
    setOrderChangeModal(false)
  }

  function openOrderChangeModal() {
    setOrderChangeModal(true)
  }

  const onSelectChange = (selectedRowKeys, pageSelectedRowsData) => {
    let newSelectedRowData = uniqBy([...selectedRows, ...pageSelectedRowsData], 'id')
    setSelectedRows(newSelectedRowData)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  let filteredEpisodesData = listEpisodes?.episodes

  function addEpisodes() {
    setIsSubmit(true)
    let newSelectedEpisode = selectedRowKeys.filter((row) => {
      for (let index = 0; index < episodes.length; index++) {
        if (episodes[index].id === row) {
          return false
        }
      }
      return true
    })
    let currentOrder = episodeNextOrder
    let filterSelectedEpisodes = newSelectedEpisode.map((row) => {
      let episode = selectedRows.find((episode) => episode.id === row)
      if (!episode) {
        episode = listEpisodes?.episodes.find((episode) => episode.id === row)
      }
      episode.carouselEpisodeOrder = currentOrder
      currentOrder = currentOrder + 1
      return episode
    })
    setCurrent(1)
    setEpisodeNextOrder(currentOrder)
    setEpisodes([...episodes, ...filterSelectedEpisodes])
    setFieldsValue({ [`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}.${episodeModuleData.id}.episodes`]: [...episodes, ...filterSelectedEpisodes] })
    setShowModal(false)
    setIsSubmit(false)
    setSelectedRows([])
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}.${episodeModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteEpisodeCarousel(episodeCarousel) {
    confirm({
      title: `Are you sure you want to remove episode carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === episodeCarousel.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: episodeCarousel.id, type: MODULE_TYPES_CONST.EPISODE_CAROUSEL },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== episodeCarousel.id)
        setModuleList(newModules)
      },
    })
  }

  function deleteCarouselEpisode(id, episode) {
    confirm({
      title: `Are you sure you want to remove episode`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        if (episode.carouselEpisodeId) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_EPISODE,
              variables: { id: id },
            })
            const deleteCarouselEpisode = get(response, 'data.deleteCarouselEpisode')
            if (deleteCarouselEpisode && deleteCarouselEpisode.message) {
              openNotification('success', deleteCarouselEpisode.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        const newEpisodes = episodes.filter(item => item.id !== episode.id)
        setEpisodes(newEpisodes)
        setFieldsValue({ [`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}.${episodeModuleData.id}.episodes`]: newEpisodes })
        setSelectedRowKeys(selectedRowKeys.filter(rowKeys => rowKeys !== episode.id))
      },
    })
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrent(1)
      setLastPage(false)
    }
  }

  useEffect(() => {
    async function handlePageChange() {
      if (!dataLoading) {
        try {
          getEpisodes({
            variables: {
              skip: (current - 1) * 5,
              first: 5
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { episodes } = fetchMoreResult
                if (episodes?.length < 5) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                return episodes?.length ? { episodes: [...episodes] } : prevResult
              }
            }
          })
        } catch (error) {
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  useEffect(() => {
    if (!dataLoading && listEpisodes?.episodes) {
      if (listEpisodes?.episodes?.length < 5) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [dataLoading])

  return (
    <Card key={episodeModuleData.id} title='Episode Carousel' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={
        [
          <div className="carousel-card-footer-wrapper">
            {
              getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}.${episodeModuleData.id}.isPublished`, {
                initialValue: episodeModuleData.isPublished || false
              })(
                <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}-${indexId}-isPublished`}>
                  Published
            </Checkbox>)}
            <Button id={`btn-delete-episode-carousel-${episodeModuleData.id}`}
              className="ml-2"
              icon='delete'
              onClick={() => handleDeleteEpisodeCarousel(episodeModuleData)}
              type='danger'>
              Delete
            </Button>
          </div>
        ]}
    >
      <Form.Item label='Title'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}.${episodeModuleData.id}.title`, {
          initialValue: episodeModuleData.title ? episodeModuleData.title : '',
          rules: [{ required: true, message: 'Please input your title!' }]
        })(
          <Input placeholder='Title' className={`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}-${indexId}-title`} />,
        )}
      </Form.Item>
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}.${episodeModuleData.id}.order`, {
          initialValue: episodeModuleData.order || 1
        })(
          <Input placeholder='Order' className={`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}-${episodeModuleData.id}-order`} />,
        )}
      </Form.Item>
      <Form.Item label='Episodes' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_CAROUSEL}.${episodeModuleData.id}.episodes`, {
          initialValue: episodeModuleData.episodes || [],
          rules: [{ type: 'array', required: true, min: 1, message: 'Please add atleast one episode!' }]
        })(
          <Row gutter={24} type='flex'>
            {episodes.length > 0 && episodes.map((episode, index) => (
              <EpisodeListCard
                key={episode.id}
                id={episode.carouselEpisodeId ? episode.carouselEpisodeId : episode.id}
                name={episode.title}
                image={episode.image}
                index={index}
                indexId={indexId}
                episode={episode}
                deleteCarouselEpisode={deleteCarouselEpisode}
              />
            ))}
          </Row>)}
      </Form.Item>
      <Row>
        <Button icon='plus' id={`btn-add-episode-${indexId}`} onClick={openModal}>
          Add Episode
          </Button>
        {episodes.length > 1 && (<Button className="ml-2" icon='edit' id={`btn-edit-episode-${indexId}`}
          onClick={openOrderChangeModal}>
          Change Order
        </Button>)
        }
        <Modal
          title='Add Episodes'
          visible={showModal}
          maskClosable={false}
          onOk={addEpisodes}
          onCancel={handleCancel}
          footer={[
            <Button id={`btn-episode-carousel-cancel-${indexId}`} key='back' onClick={handleCancel}>
              Cancel
              </Button>,
            <Button
              id={`btn-episode-carousel-save-${indexId}`}
              key='submit'
              type='primary'
              loading={isSubmit}
              onClick={addEpisodes}
            >
              Add
              </Button>,
          ]}
        >
          <Row>
            <Col>
              <div className='d-flex justify-content-between'>
                <div>
                  <p>Search</p>
                  <Input.Search placeholder='Search Episode' onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-episode-carousel-${indexId}`} />
                </div>
                <div>
                  <p>Show</p>
                  <Select
                    style={{ width: '250px' }}
                    loading={showsLoading}
                    defaultValue={'ALL'}
                    placeholder='Select Shows'
                    onChange={(e) => onSeasonSelect(e)}
                  >
                    <Option key={'ALL'} value={'ALL'}>
                      All Shows
                      </Option>
                    {listShowForSeason &&
                      listShowForSeason.map(({ name, id }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Table
              loading={dataLoading}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              dataSource={filteredEpisodesData}
              showHeader={false}
              pagination={false}
              onChange={handleChange}
            />
            <div className="page-change-btn-wrapper">
              <Button icon="left" onClick={() => setCurrent(current - 1)} disabled={current === 1} />
              <Button icon="right" onClick={() => setCurrent(current + 1)} disabled={lastPage} />
            </div>
          </Row>
        </Modal>
      </Row>
      <Modal
        title='Change Order'
        visible={orderChangeModal}
        maskClosable={false}
        onCancel={handleOrderChangeModalCancel}
        footer={null}
      >
        <Row>
          <Table
            columns={columnsOrderChange}
            rowKey={(record) => record.id}
            dataSource={episodes}
            showHeader={false}
            pagination={{
              onChange(currentPage) { setCurrent(currentPage) },
              defaultPageSize
            }}
          />
        </Row>
      </Modal>
    </Card >
  )
}

export default EpisodeCarousel
