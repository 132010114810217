import React, { useState } from 'react'
import { Row, Col, Divider, Tabs } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_EPISODE } from '../grapql/Queries'
import AddEpisodeCard from './AddEpisodeCard'
import EpisodeCard from './EpisodeCard'
import Spinner from '../../../components/loaders/Spinner'
import { GET_SEASONS } from '../../shows/graphql/Queries'
import SeasonCard from './SeasonCard'

const { TabPane } = Tabs

export default function (props) {
  const { podcastId, episodesCount, history, podcastData } = props
  const [stopLoading, setStopLoading] = useState(false)
  const [episodeLoading, setEpisodeLoading] = useState(false)
  const [seasonLoading, setSeasonLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState('EPISODE')

  const {
    data: allEpisodesData,
    error: episodeError,
    fetchMore,
    networkStatus,
  } = useQuery(GET_ALL_EPISODE, {
    variables: { id: podcastId },
    fetchPolicy: 'network-only',
  })

  const {
    data: seasonData,
    error: seasonError,
    fetchMore: fetchSeasonMore,
    networkStatus: seasonsNetworkStatus,
  } = useQuery(GET_SEASONS, {
    variables: { first: 20, where: { podcast: { id: podcastId } } },
    fetchPolicy: 'network-only',
  })

  const onTabsChanged = (e) => {
    setSelectedTab(e)
  }

  let episodesData =
    allEpisodesData &&
      allEpisodesData.listPodcastEpisodeDetails &&
      allEpisodesData.listPodcastEpisodeDetails.length > 0
      ? allEpisodesData.listPodcastEpisodeDetails
      : []
  let seasonsData =
    seasonData && seasonData.seasons && seasonData.seasons.length > 0
      ? seasonData.seasons
      : []

  async function handleScroll() {
    const scroller = document.getElementById('episode-list-wrapper')
    var scrollTop = scroller.scrollTop
    var scrollHeight = scroller.scrollHeight
    var clientHeight = scroller.clientHeight
    var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight

    if (selectedTab === 'SEASONS' && scrolledToBottom) {
      setSeasonLoading(true)
      await fetchSeasonMore({
        variables: {
          first: 20,
          where: { show: { id: podcastId } },
          after: seasonsData[seasonsData.length - 1].id,
        },
        notifyOnNetworkStatusChange: true,
        updateQuery: async (prevResult, { fetchMoreResult }) => {
          const { seasons } = fetchMoreResult
          if (seasons && seasons.length > 0) {
            await seasons.forEach(async (element) => {
              if (
                seasonsData.findIndex((clip) => clip.id === element.id) === -1
              ) {
                await seasonsData.push(element)
              }
            })
          }
          setSeasonLoading(false)
        },
      })
    }
    if (selectedTab === 'EPISODE') {
      if (episodesData.length > 0) {
        setStopLoading(false)
      }
      if (
        scrolledToBottom &&
        episodesData.length > 0 &&
        !episodeLoading &&
        !stopLoading
      ) {
        setEpisodeLoading(true)
        await fetchMore({
          variables: {
            id: podcastId,
            after: episodesData[episodesData.length - 1].id,
          },
          notifyOnNetworkStatusChange: true,
          updateQuery: async (prevResult, { fetchMoreResult }) => {
            const { listPodcastEpisodeDetails: episodes } = fetchMoreResult
            if (episodes && episodes.length > 0) {
              await episodes.forEach(async (element) => {
                if (
                  episodesData.findIndex(
                    (episode) => episode.id === element.id,
                  ) === -1
                ) {
                  await episodesData.push(element)
                }
              })
            }
            if (episodesData) {
              setStopLoading(true)
              setEpisodeLoading(false)
            } else {
              setEpisodeLoading(false)
            }
          },
        })
      }
    }
  }
  return (
    <div>
      <div className="podcast-description-wrapper">
        <Row gutter={24} type="flex">
          <Col
            sm={24}
            md={16}
            lg={16}
            xl={18}
            className="episode-list-wrapper"
            id="episode-list-wrapper"
            onScroll={() => handleScroll()}
          >
            <div className="box">
              <div className="show-author">
                Author:
                <span>
                  {podcastData.getPodcastDetails.author.firstName}{' '}
                  {podcastData.getPodcastDetails.author.lastName}
                </span>
              </div>
              <div className="podcast-description">
                {podcastData.getPodcastDetails.description}
              </div>
            </div>
            <Row gutter={24} type="flex" className="divider-wrapper">
              <Divider />
            </Row>
            <Tabs
              defaultActiveKey={selectedTab}
              className="podcast-tabs"
              onChange={onTabsChanged}
            >
              <TabPane tab="Episodes" key="EPISODE">
                <Row
                  gutter={24}
                  type="flex"
                  className="episode-list"
                  id="episode-list"
                >
                  {episodeError ? (
                    <>{episodeError.message}</>
                  ) : (
                    <>
                      <AddEpisodeCard podcastId={podcastId} history={history} />
                      {(networkStatus === 1 || episodeLoading) && (
                        <div className="episode-loader-wrapper">
                          <Spinner />
                        </div>
                      )}
                      {episodesData &&
                        episodesData.length > 0 &&
                        episodesData.map((episode, key) => (
                          <EpisodeCard
                            episode={episode}
                            podcastId={podcastId}
                            key={key}
                          />
                        ))}
                    </>
                  )}
                </Row>
              </TabPane>
              <TabPane tab="Seasons" key="SEASONS">
                <Row
                  gutter={24}
                  type="flex"
                  className="episode-list podcast-detail-page"
                  id="episode-list"
                >
                  {seasonError ? (
                    <>{seasonError.message}</>
                  ) : (
                    <>
                      {(seasonsNetworkStatus === 1 || seasonLoading) && (
                        <div className="episode-loader-wrapper">
                          <Spinner />
                        </div>
                      )}
                      {seasonsData && seasonsData.length > 0 ? (
                        seasonsData.map((season, key) => (
                          <SeasonCard {...season} key={key} />
                        ))
                      ) : (
                        <div className="no-seasons-found-wrapper">
                          <h2>No seasons found</h2>
                        </div>
                      )}
                    </>
                  )}
                </Row>
              </TabPane>
            </Tabs>
          </Col>
          <Col sm={24} md={8} lg={8} xl={6}>
            <div className="box show-all-images">
              {podcastData && podcastData.getPodcastDetails && <div className="podcast-card images-wrapper text-center">
                {podcastData.getPodcastDetails.coverImage && (
                  <div>
                    <div className="show-author">Cover Image</div>
                    <img
                      className="podcast-cover-image"
                      src={`${podcastData.getPodcastDetails.coverImage}?${performance.now()}`}
                      alt={podcastData.getPodcastDetails.name}
                      title={podcastData.getPodcastDetails.name}
                    />
                  </div>
                )}
                {podcastData.getPodcastDetails.backgroundImage && (
                  <div>
                    <div className="show-author">Background Image</div>
                    <img
                      className="podcast-background-image"
                      src={`${podcastData.getPodcastDetails.backgroundImage
                        }?${performance.now()}`}
                      alt={podcastData.getPodcastDetails.name}
                      title={podcastData.getPodcastDetails.name}
                    />
                  </div>
                )}
                {podcastData.getPodcastDetails.logoImage && (
                  <div>
                    <div className="show-author">Logo</div>
                    <img
                      className="podcast-logo-image"
                      src={`${podcastData.getPodcastDetails.logoImage}?${performance.now()}`}
                      alt={podcastData.getPodcastDetails.name}
                      title={podcastData.getPodcastDetails.name}
                    />
                  </div>
                )}
              </div>}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
