import React, { useState } from 'react'
import { Col, Table, Tag, PageHeader, Button, Icon, notification, Modal, Tooltip, Spin, Upload, message, Progress } from 'antd'
import moment from 'moment-timezone'
import * as Sentry from '@sentry/browser'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Player, ControlBar } from 'video-react'
import { Parser } from 'json2csv'
import HLSSource from '../../../HLSSource'
import client, { chatClient } from '../../../apollo'
import { GET_SEGMENT_LIVE_VIDEO_URL, GET_SEGMENT_SIMULATE_LIVE_VIDEO_PUT_URL, GET_SIGNED_URL } from '../graphql/Queries'
import { SUBSCRIBE_EPISODE_SEGMENTS } from '../../episode/graphql/Subscriptions'
import { UPDATE_EPISODE, UPDATE_EPISODE_LIVE_VIDEO_URL } from '../graphql/Mutations'
import { GET_EPISODE } from '../../episode/graphql/Queries'
import Comments from '../../../components/comments/Comments'
import { GET_DISCUSSION_THREAD } from '../../../components/comments/graphql/Queries'
import { UPDATE_DISCUSSION_STATUS } from '../../discussions/graphql/Mutations'
import Spinner from '../../../components/loaders/Spinner'
import { SUBSCRIBE_DISCUSSION } from '../../../components/comments/graphql/Subscriptions';
import { fileUpload } from '../../../common/fileUpload'
import { get } from 'lodash'
import { useEffect } from 'react'

const { confirm } = Modal

const columns = [
  {
    title: 'Live Details',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '',
    dataIndex: 'value',
    key: 'value',
  }
]

function beforeVideoUpload(file) {
  const isMP4 = file.type === 'video/mp4'
  if (!isMP4) {
    message.error('You can only upload MP4 file!')
  }
  return isMP4
}

export default function SegmentLive(props) {
  const [isLiveStart, setisLiveStart] = useState(false)
  const [isLiveLoaderStart, setisLiveLoaderStart] = useState(false)
  const [videoLoading, setVideoLoading] = useState(false)
  const [videoProgress, setVideoProgress] = useState(undefined)
  const [showPlayerModal, setShowPlayerModal] = useState(false)
  const [simulateLiveVideoUrl, setSimulateLiveVideoUrl] = useState('')
  const [isDownload, setIsDownload] = useState(false)

  const { episodeId, episodeTitle, episodeStatus, episodeImage, segmentId, segmentUpdatedAt, scheduleAt, videoState, discussionId, simulateLive, liveVideoURL } = props
  const { data, loading: isLiveSegmentLoading, error: liveSegmentError } = useQuery(GET_SEGMENT_LIVE_VIDEO_URL, { variables: { segmentId } })
  const { data: discussionsData, loading: isDiscussionsLoading, error: discussionsError, fetchMore, subscribeToMore } = useQuery(GET_DISCUSSION_THREAD, { client: chatClient, variables: { id: discussionId }, fetchPolicy: "network-only" })
  const { data: subscriptionData } = useSubscription(SUBSCRIBE_DISCUSSION, { variables: { id: discussionId }, client: chatClient })

  const discussionStatus = discussionsData && discussionsData.discussionThread ?
    subscriptionData && subscriptionData.discussion && subscriptionData.discussion.node && subscriptionData.discussion.node.status ?
      subscriptionData.discussion.node.status.toUpperCase()
      : discussionsData.discussionThread.status.toUpperCase()
    : ''

  useSubscription(SUBSCRIBE_EPISODE_SEGMENTS, { variables: { nodeArray: { node: { id: segmentId } } } })

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function manualLive(status) {
    const checkStatus = status === 'LIVE'
    const title = checkStatus ? "Are you sure you want to start streaming manually?" : "Are you sure you want to finish the live streaming?"
    // const content = checkStatus ? "" : ""
    const okText = checkStatus ? "Manual Go Live" : "Finish Streaming"
    const isLive = checkStatus
    const disStatus = checkStatus ? 'OPEN' : 'ENDED'
    confirm({
      title,
      okText,
      async onOk() {
        try {
          if (discussionId) {
            await chatClient.mutate({
              mutation: UPDATE_DISCUSSION_STATUS,
              variables: { isLive, status: disStatus, id: discussionId },
              refetchQueries: [{
                query: GET_DISCUSSION_THREAD,
                variables: { id: discussionId }
              }]
            })
          }
          await client.mutate({
            mutation: UPDATE_EPISODE,
            variables: { status, isLive, episodeId }
          })
          openNotification("success", "Episode Status Updated")
        }
        catch (error) {
          Sentry.captureException(error)
          openNotification("error", "Something went wrong")
        }
      },
    })
  }

  function handleStartStop(value) {
    // let content
    let title = value ? "Are you sure you want to START this live chat?" : "Are you sure you want to END this live chat?"
    let okText = value ? "START NOW" : "END NOW"
    let okType = value ? "primary" : "danger"
    let message = value ? "Live Chat has been started" : "Live Chat has been ended"
    let status = value ? "OPEN" : "ENDED"
    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          await chatClient.mutate({
            mutation: UPDATE_DISCUSSION_STATUS,
            variables: { id: discussionId, isLive: value, status },
            refetchQueries: [{
              query: GET_DISCUSSION_THREAD,
              variables: { id: discussionId }
            }]
          })
          openNotification("success", message)
        }
        catch (error) {
          Sentry.captureException(error)
          openNotification("error", "Something Went Wrong")
        }
      },
    })
  }

  function exportDetails() {
    const fields = ['videoUrl', 'RTMPURL', 'StreamKey']
    const liveDetails = [
      {
        "videoUrl": data.getSegmentLiveVideoUrl.video,
        "RTMPURL": "rtmp://live.mux.com/app",
        "StreamKey": data.getSegmentLiveVideoUrl.streamKey
      }
    ]
    const json2csvParser = new Parser({ fields })
    let csv = json2csvParser.parse(liveDetails)
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv
    }
    csv = encodeURI(csv);
    let link = document.createElement('a')
    document.body.appendChild(link)
    link.setAttribute('href', csv)
    link.setAttribute('download', `${episodeTitle}.csv`)
    link.click()
  }

  const uploadVideoButton = (
    <div id="btn-upload-segment-video">
      <Icon type={videoLoading ? 'loading' : 'play-circle'} />
      <div className="ant-upload-text">Upload Video</div>
    </div>
  )

  function handleOk(e) {
    setShowPlayerModal(false)
  }

  async function handleModal() {
    setShowPlayerModal(true)
  }

  function handleRequestFail(e) {
    setVideoLoading(false)
    const message = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  useEffect(() => {
    if (liveVideoURL) {
      client.query({
        query: GET_SIGNED_URL,
        variables: { url: liveVideoURL },
      }).then(res => {
        setSimulateLiveVideoUrl(res.data.getSignedUrl.signedUrl)
      }).catch((e) => {
        Sentry.captureException(e)
        handleRequestFail(e)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveVideoURL])

  async function handleDownload(muxAssetId) {
    try {
      const getSignedUrlRes = await client.query({
        query: GET_SIGNED_URL,
        fetchPolicy: 'network-only',
        variables: { url: liveVideoURL },
      })
      const signedUrl = getSignedUrlRes.data.getSignedUrl.signedUrl
      if (signedUrl) {
        setIsDownload(false)
        window.open(signedUrl)
      }
    } catch (err) {
      Sentry.captureException(err)
      handleRequestFail(err)
      setIsDownload(false)
    }
  }

  async function simulateLiveVideoRemoveConfirm(segmentId, episodeId) {
    confirm({
      title: 'Are you sure you want to DELETE this video?',
      content: 'Upon clicking DELETE, the video will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          await client.mutate({
            mutation: UPDATE_EPISODE_LIVE_VIDEO_URL,
            variables: { segmentId, episodeId, liveVideoURL: null },
            refetchQueries: [
              {
                query: GET_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', "Video Removed Successfully")
        } catch (e) {
          Sentry.captureException(e)
          handleRequestFail(e)
        }
      },
    })
  }

  async function handleVideoFileChange(info) {
    if (info.fileList.length > 1) {
      info.fileList.shift()
    }
    if (info.file && info.file.type === 'video/mp4') {
      try {
        setVideoLoading(true)
        setVideoProgress(0)
        const tempVideoArray = info.file.name.split('.')
        const videoFileExt = tempVideoArray[tempVideoArray.length - 1]
        const videoFileName = `simulate-live-video-${new Date().getTime()}.${videoFileExt}`

        const getSignedPutUrlResult = await client.query({
          query: GET_SEGMENT_SIMULATE_LIVE_VIDEO_PUT_URL,
          variables: { fileName: videoFileName },
        })

        const signedUrl = get(getSignedPutUrlResult, 'data.getSegmentSimulateLiveVideoPutUrl.signedUrl')
        const getUrl = get(getSignedPutUrlResult, 'data.getSegmentSimulateLiveVideoPutUrl.getUrl')
        const gets3Url = get(getSignedPutUrlResult, 'data.getSegmentSimulateLiveVideoPutUrl.gets3Url')

        if (signedUrl && getUrl && gets3Url) {
          await fileUpload(
            getSignedPutUrlResult.data.getSegmentSimulateLiveVideoPutUrl.signedUrl,
            info.file.originFileObj,
            setVideoProgress
          )
          await client.mutate({
            mutation: UPDATE_EPISODE_LIVE_VIDEO_URL,
            variables: { segmentId, episodeId, liveVideoURL: gets3Url },
            refetchQueries: [
              {
                query: GET_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
          openNotification('success', 'Video Uploaded Successfully')
          setVideoLoading(false)
          setTimeout(() => {
            setVideoProgress(undefined)
          }, 500)
        }
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail(e)
      }
    }
  }

  let tableData = []
  if (data && data.getSegmentLiveVideoUrl) {
    tableData = [
      ...(simulateLive ?
        [{
          key: '1',
          title: 'Video Upload',
          value:
            liveVideoURL ? (<div className="play-button download-play-wrapper" id="upload-live-video-aws">
              {episodeStatus === 'DRAFT' && <span
                className="remove"
                onClick={() =>
                  simulateLiveVideoRemoveConfirm(
                    segmentId,
                    episodeId,
                  )
                }
              >
                <Icon type="close-circle" />
              </span>}
              <div>
                <Button
                  id="btn-segment-play"
                  className="play-button-margin"
                  icon="play-circle"
                  shape="circle"
                  size="large"
                  onClick={(e) => handleModal(e)}
                />
                <Button
                  id="btn-segment-download"
                  shape="circle"
                  icon="download"
                  size="large"
                  loading={isDownload}
                  onClick={(e) => handleDownload()}
                />
              </div>
            </div>) :
              <Upload
                name="video"
                listType="picture-card"
                className="video-uploader"
                multiple={false}
                disabled={videoLoading}
                customRequest={() => {
                  return false
                }}
                onRemove={(e) => props.handleRemove(e)}
                showUploadList={false}
                beforeUpload={beforeVideoUpload}
                onChange={(e) =>
                  handleVideoFileChange(e)
                }
              >
                {uploadVideoButton}
                {videoProgress >= 0 && (
                  <Progress
                    percent={videoProgress}
                    size="small"
                    status="active"
                  />
                )}
              </Upload>
        }] : []),
      ...(!simulateLive ?
        [{
          key: '1',
          title: 'Video URL',
          value: <>
            {data.getSegmentLiveVideoUrl.video}
            <CopyToClipboard text={data.getSegmentLiveVideoUrl.video}>
              <Tooltip title="Click to copy!" placement="bottom">
                <span>
                  <Tooltip title="Copied!" trigger={"click"}>
                    <Icon type="copy" title="Click to copy!" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </>
        }] : []),
      ...(!simulateLive ?
        [{
          key: '2',
          title: 'RTMP URL',
          value: <>
            rtmp://live.mux.com/app
            <CopyToClipboard text="rtmp://live.mux.com/app">
              <Tooltip title="Click to copy!" placement="bottom">
                <span>
                  <Tooltip title="Copied!" trigger={"click"}>
                    <Icon type="copy" title="Click to copy!" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </>,
        }] : []),
      ...(!simulateLive ?
        [{
          key: '3',
          title: 'Stream Key',
          value: <>
            {data.getSegmentLiveVideoUrl.streamKey}
            <CopyToClipboard text={data.getSegmentLiveVideoUrl.streamKey}>
              <Tooltip title="Click to copy!" placement="bottom">
                <span>
                  <Tooltip title="Copied!" trigger={"click"}>
                    <Icon type="copy" title="Click to copy!" />
                  </Tooltip>
                </span>
              </Tooltip>
            </CopyToClipboard>
          </>,
        }] : []),
      {
        key: '4',
        title: 'Live Status',
        value: <>
          <Tag className={videoState}>{videoState}</Tag>
          {
            videoState !== 'ERROR' &&
            <>
              {
                // episodeStatus === 'SCHEDULED' && videoState === 'IDLE' &&
                episodeStatus === 'SCHEDULED' &&
                <Button id="btn-manual-go-live" type="primary" size="small" onClick={() => manualLive('LIVE')}>Manual Go Live</Button>
              }
              {
                episodeStatus === 'LIVE' &&
                <Button id="btn-manual-finish-live" type="primary" size="small" onClick={() => manualLive('LIVE_FINISHED')}>Manual Finish Live</Button>
              }
            </>
          }
        </>,
      },
      {
        key: '5',
        title: 'Scheduled Time',
        value: moment(scheduleAt).tz('America/Los_Angeles').format('Do MMMM YYYY, hh:mm:ss a'),
      },
      {
        key: '6',
        title: 'Export Details',
        value: <Button id="btn-live-chat-export" type="primary" onClick={() => exportDetails()}>Export</Button>,
      },
    ]
  }
  if (videoState === 'RECORDING') {
    if (!isLiveLoaderStart) {
      setisLiveLoaderStart(true)
    }
    const setTime = Math.round((new Date().getTime() - new Date(segmentUpdatedAt).getTime()) / 1000) > 30 ? 1000 : 15000
    if (!isLiveStart && isLiveLoaderStart) {
      setTimeout(() => {
        setisLiveStart(true)
        setisLiveLoaderStart(false)
      }, setTime)
    }
  }
  return (
    isLiveSegmentLoading ? <div className="callback-wrapper"><Spinner /></div> :
      liveSegmentError ? <>{liveSegmentError.message}</> :
        <>
          {showPlayerModal && (
            <Modal
              title={episodeTitle}
              width="60%"
              height="60vh"
              visible={showPlayerModal}
              footer={null}
              onOk={() => handleOk()}
              onCancel={() => handleOk()}
            >
              <div className="w-100 text-center">
                {liveVideoURL && simulateLiveVideoUrl !== '' && <Player autoPlay>
                  <source src={simulateLiveVideoUrl} type={'video/mp4'} />
                </Player>}
              </div>
            </Modal>
          )}
          <Col lg={12} xl={12} className="live-details-section-wrapper">
            <div className="box">
              <Table columns={columns} showHeader={false} title={() => 'Live Details'} dataSource={tableData} pagination={false} className="segment-live-table" />
            </div>
          </Col>
          <Col lg={12} xl={12} className="player-section-wrapper">
            <div className="box">
              {(videoState === 'RECORDING' || videoState === 'DISCONNECTED') && isLiveStart ?
                <Player
                  playsInline
                  poster={episodeImage}
                >
                  <ControlBar className="live-player" />
                  <HLSSource
                    isVideoChild
                    src={data.getSegmentLiveVideoUrl.video}
                  />
                </Player>
                :
                episodeImage &&
                <div className="segment-live-image-wrapper">
                  {
                    isLiveLoaderStart ?
                      <Spin tip="Please Wait...">
                        <img src={episodeImage} alt={segmentId} width={500} />
                      </Spin>
                      :
                      <img src={episodeImage} alt={segmentId} width={500} />
                  }
                </div>
              }
            </div>
          </Col>
          <Col lg={24} xl={24}>
            <PageHeader
              title="Live Chat"
              tags={<Tag className={discussionStatus}>{discussionStatus}</Tag>}
              className="d-flex box align-center"
              extra={[
                discussionStatus === 'OPEN' ?
                  <Button id="btn-end-live-chat" key="3" type="danger" size="small" onClick={() => handleStartStop(false)}>END CHAT</Button>
                  :
                  discussionStatus !== 'ENDED' &&
                  <Button id="btn-start-live-chat" key="3" type="primary" size="small" onClick={() => handleStartStop(true)}>START CHAT</Button>
              ]}
            />
          </Col>
          <Col lg={24} xl={24}>
            {
              discussionsError ? <>{discussionsError.message}</> :
                discussionsData && discussionsData.discussionThread &&
                <Comments discussionId={discussionId} discussionThreadMessages={discussionsData.discussionThread.messages} subscribeToMore={subscribeToMore} fetchMore={fetchMore} isDiscussionsLoading={isDiscussionsLoading} isLive={discussionStatus === 'OPEN'} />
            }
          </Col>
        </>
  )
}
