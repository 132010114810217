import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddVideosForm from './AddVideoForm'

const CollectionCreateForm = Form.create({ name: 'create_episode' })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        isSubmit,
        title,
        videoAccess,
        liveChatAccess,
        description,
        videoNumber,
        scheduleAt,
        allowedContinents,
        rating,
        isLive,
        isFeatured,
        featureBannerWeight,
        featuredBannerImage,
        showIsLIve,
        isEdit,
        handleLiveChange,
        showCreatedAt,
        allowedCountries
      } = this.props
      const { getFieldDecorator, setFieldsValue } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${title ? 'Edit Video Details' : 'Add New Video'}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id='btn-video-cancel' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id='btn-video-save'
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              {title ? 'Save' : 'Add Video'}
            </Button>,
          ]}
        >
          <AddVideosForm
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
            title={title}
            description={description}
            videoNumber={videoNumber}
            scheduleAt={scheduleAt}
            allowedContinents={allowedContinents}
            rating={rating}
            isLive={isLive}
            isFeatured={isFeatured}
            featuredBannerImage={featuredBannerImage}
            featureBannerWeight={featureBannerWeight}
            videoAccess={videoAccess}
            liveChatAccess={liveChatAccess}
            showIsLIve={showIsLIve}
            isEdit={isEdit}
            handleLiveChange={handleLiveChange}
            showCreatedAt={showCreatedAt}
            setFieldsValue={setFieldsValue}
            allowedCountries={allowedCountries}
          />
        </Modal>
      )
    }
  },
)

export default class AddVideoModal extends Component {
  render() {
    const {
      showModal,
      isSubmit,
      handleCancel,
      handleCreate,
      videoAccess,
      liveChatAccess,
      saveFormRef,
      title,
      description,
      videoNumber,
      scheduleAt,
      allowedContinents,
      rating,
      handleIsFeaturedChanged,
      isEdit,
      isLive,
      isFeatured,
      featuredBannerImage,
      featureBannerWeight,
      showIsLIve,
      handleLiveChange,
      showCreatedAt,
      allowedCountries
    } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        title={title}
        description={description}
        videoNumber={videoNumber}
        scheduleAt={scheduleAt}
        allowedContinents={allowedContinents}
        rating={rating}
        videoAccess={videoAccess}
        liveChatAccess={liveChatAccess}
        isEdit={isEdit}
        isLive={isLive}
        isFeatured={isFeatured}
        featuredBannerImage={featuredBannerImage}
        featureBannerWeight={featureBannerWeight}
        showIsLIve={showIsLIve}
        handleLiveChange={handleLiveChange}
        handleIsFeaturedChanged={handleIsFeaturedChanged}
        showCreatedAt={showCreatedAt}
        allowedCountries={allowedCountries}
      />
    )
  }
}
