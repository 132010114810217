import React, { Component } from 'react'
import {
  Form,
  Input,
  Upload,
  Icon,
  Button,
  Checkbox,
  DatePicker,
  message,
  Tooltip,
  Radio,
  Select,
} from 'antd'
import moment from 'moment-timezone'
import client from '../../../apollo'
import { GET_SHOWS, GET_VIDEOS } from '../graphql/Queries'
import { debounce, trimStart } from 'lodash'
import { ACCESS_LEVELS } from '../../../common/constants'

const { Option } = Select
const defaultVideoAccessValues = ['INSIDER', 'INSIDER_PLUS', 'ALL_ACCESS']

function beforeImageUpload(file) {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg'
  ) {
    return true
  }
  message.error('You can only upload image file!')
  return false
}

export default class AddClipForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      removeFile: false,
      tooltipIsVisible: false,
      scheduleTitle: '',
      shows: [],
      showsLoading: false,
      // clipType: 'SHOW',
      clipType: 'VIDEO',
      videos: [],
      videosLoading: false,
    }
  }

  onClipTypeChange = (e) => {
    this.setState({ clipType: e.target.value })
  }

  normFile = (e) => {
    if (this.state.removeFile) {
      this.setState({ removeFile: false })
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  handleRemove = (e) => {
    this.setState({ removeFile: true })
  }

  disabledStartDate = (startValue) => {
    return startValue.valueOf() < moment().valueOf()
  }

  alltDates = (startValue) => {
    return ''
  }

  handleScheduleChange = (currentTime) => {
    if (currentTime) {
      const laTime = moment(currentTime.valueOf()).tz('America/Los_Angeles')
      this.setState({
        scheduleTitle: `Schedule at: ${laTime.format(
          'lll',
        )} ${laTime.zoneAbbr()}`,
        tooltipIsVisible: true,
      })
    } else {
      this.setState({
        tooltipIsVisible: false,
        scheduleTitle: '',
      })
    }
  }

  componentDidMount() {
    const { showId } = this.props
    // this.setState({ clipType: showId ? 'SHOW' : 'VIDEO' })
    this.setState({ showsLoading: true })
    this.setState({ videosLoading: true })
    client
      .query({
        query: GET_SHOWS,
      })
      .then((data) => {
        this.setState({ shows: data.data.shows })
      })
      .catch((e) => {
        console.log({ e })
      })
      .finally(() => {
        this.setState({ showsLoading: false })
      })
    client
      .query({
        query: GET_VIDEOS,
        fetchPolicy: 'network-only'
      })
      .then((data) => {
        this.setState({ videos: data.data.videos })
      })
      .catch((e) => {
        console.log({ e })
      })
      .finally(() => {
        this.setState({ videosLoading: false })
      })
  }

  onShowSearch = (e) => {
    if (this.showDebounceJob) {
      this.showDebounceJob.cancel()
    }
    this.setState({ showsLoading: true })
    this.showDebounceJob = debounce(() => {
      client
        .query({
          query: GET_SHOWS,
          fetchPolicy: 'network-only',
          variables: {
            where: {
              OR: [
                { name_starts_with: e },
                { name_contains: e },
                { name_ends_with: e },
              ],
            },
          },
        })
        .then((data) => {
          this.setState({ shows: data.data.shows })
        })
        .catch((e) => {
          console.log({ e })
        })
        .finally(() => {
          this.setState({ showsLoading: false })
        })
    }, 500)

    this.showDebounceJob()
  }

  onVideoSearch = (e) => {
    if (this.videoDebounceJob) {
      this.videoDebounceJob.cancel()
    }
    this.videoDebounceJob = debounce(() => {
      this.setState({ videosLoading: true })
      client
        .query({
          query: GET_VIDEOS,
          fetchPolicy: 'network-only',
          variables: {
            where: {
              OR: [
                { name_starts_with: e },
                { name_contains: e },
                { name_ends_with: e },
              ],
            },
          },
        })
        .then((data) => {
          this.setState({ videos: data.data.videos })
        })
        .catch((e) => {
          console.log({ e })
        })
        .finally(() => {
          this.setState({ videosLoading: false })
        })
    }, 500)
    this.videoDebounceJob()
  }
  normalize = (value) => trimStart(value)
  render() {
    const {
      getFieldDecorator,
      title,
      description,
      clipNumber,
      clipAccess,
      showId,
      videoId,
    } = this.props

    const { shows, showsLoading, videos, videosLoading, clipType } = this.state
    const uploadProps = {
      customRequest() {
        return false
      },
    }
    return (
      <Form layout="vertical" className="add-episode-form">
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                message: 'Please write the title!',
              },
            ],
            initialValue: title,
            normalize: this.normalize,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [
              { required: true, message: 'Please write the description!' },
            ],
            initialValue: description,
            normalize: this.normalize,
          })(<Input.TextArea rows={5} />)}
        </Form.Item>
        <Form.Item label="Clip Number">
          {getFieldDecorator('clipNumber', {
            initialValue: clipNumber,
            normalize: this.normalize,
          })(<Input />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('clipType', {
            initialValue: clipType,
          })(
            <Radio.Group
              style={{ marginBottom: '8px' }}
              onChange={this.onClipTypeChange}
              value={this.state.clipType}
            >
              {/* <Radio value={'SHOW'}>Show</Radio> */}
              <Radio value={'VIDEO'}>Video</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        {this.state.clipType === 'SHOW' && (
          <Form.Item label="Select Show">
            {getFieldDecorator('showId', {
              rules: [{ required: true, message: 'Please select show' }],
              initialValue: showId,
            })(
              <Select
                className='clip-show'
                showSearch
                loading={showsLoading}
                placeholder="Select Show"
                onSearch={this.onShowSearch}
                optionFilterProp="children"
              >
                {shows &&
                  shows.map(({ id, name }) => (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        )}
        {/* {this.state.clipType === 'VIDEO' && ( */}
        <Form.Item label="Select Videos">
          {getFieldDecorator('videoId', {
            rules: [{ required: true, message: 'Please select video' }],
            initialValue: videoId,
          })(
            <Select
              className='clip-video'
              showSearch
              loading={videosLoading}
              placeholder="Select Video"
              onSearch={this.onVideoSearch}
              optionFilterProp="children"
            >
              {videos &&
                videos.map(({ id, name }) => (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                ))}
            </Select>,
          )}
        </Form.Item>
        {/* )} */}
        <Form.Item label="Thumbnail Image">
          {getFieldDecorator('image', {
            valuePropName: 'fileList',
            getValueFromEvent: this.normFile,
          })(
            <Upload
              name="logo"
              {...uploadProps}
              listType="picture"
              multiple={false}
              onRemove={(e) => this.handleRemove(e)}
            >
              <Button id='btn-clip-thumbnail-img'>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>,
          )}
        </Form.Item>
        <Form.Item label="Select Plans For Video?">
          {getFieldDecorator('clipAccess', {
            initialValue: clipAccess || defaultVideoAccessValues,
          })(
            <Checkbox.Group>              
              {ACCESS_LEVELS.map((level) => {
                return <Checkbox value={level.key}>{level.value}</Checkbox>
              })}
            </Checkbox.Group>,
          )}
        </Form.Item>
      </Form>
    )
  }
}
