import gql from 'graphql-tag'

export const GET_SEASONS = gql`
  query seasons($first: Int!, $after: String, $showId: ID) {
    seasons(
      first: $first
      after: $after
      orderBy: weight_DESC
      where: { show: { id: $showId } }
    ) {
      id
      name
      slug
      description
      show {
        id
        name
      }
      image
      weight
      orderBy
    }
  }
`

export const GET_SEASON = gql`
  query season($seasonId: ID!) {
    season(   
      where: { id: $seasonId }
    ) {
      id
      name
      slug
      description
      orderBy
      image
      weight
      status
      type
    }
  }
`

export const GET_SHOWS = gql`
  query shows {
    shows {
      id
      name
    }
  }
`

export const GET_PODCASTS = gql`
  query listPodcastDetails($first: Int!, $after: String) {
    listPodcastDetails(first: $first, after: $after) {
      id
      name
    }
  }
`

export const GET_SEASON_WITH_EPISODES = gql`
  query listSeasonEpisodes($id: ID!, $first: Int!, $after: String) {
    listSeasonEpisodes(where: { id: $id }, first: $first, after: $after) {
      id
      name
      description
      slug
      image
      weight
      orderBy
      status
      show {
        id
        name
      }
      seasonEpisodes {
        id
        season {
          id
          name
        }
        weight
        createdAt
        episode {
          id
          title
          image
          createdAt
        }
      }
      type
      podcast {
        id
        name
      }
      podcastEpisodes {
        id
        season {
          id
          name
        }
        title
        thumbnail
        weight
        publishDate
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_SEASON_SIGNED_PUT_URL = gql`
  query getSeasonSignedPutUrl(
    $fileName: String!
    $seasonId: String!
    $contentType: String!
  ) {
    getSeasonSignedPutUrl(
      data: {
        fileName: $fileName
        seasonId: $seasonId
        contentType: $contentType
      }
    ) {
      signedUrl
      getUrl
    }
  }
`
export const LIST_EPISODE_FOR_SEASON = gql`
  query listEpisodeForSeason(
    $id: ID!
    $skip: Int
    $first: Int
    $search: String
    $listSeasonEpisode: Boolean
  ) {
    listEpisodeForSeason(
      listSeasonEpisode: $listSeasonEpisode
      where: { seasonId: $id, search: $search }
      skip: $skip
      first: $first
    ) {
      show {
        name
        id
      }
      seasonEpisodes {
        id
        weight
        episode {
          title
          id
          image
        }
      }
      episodes {
        id
        title
        description
        image
        thumbnail
        featureBannerWeight
        createdAt
      }
      count
    }
  }
`

export const LIST_SHOW_FOR_SEASON = gql`
  query listShowForSeason {
    listShowForSeason {
      id
      name
    }
  }
`
export const GET_FILTERED_SEASONS = gql`
  query seasons($first: Int!, $after: String, $where: SeasonWhereInput ) {
    seasons(
      first: $first
      after: $after
      orderBy: weight_DESC
      where: $where
    ) {
      id
      name
      slug
      description
      show {
        id
        name
      }
      podcast {
        id
        name
      }
      image
      weight
      orderBy
    }
  }
`
export const PODCASTS =gql`
  query listPodcastDetails($first: Int!, $after: String) {
    listPodcastDetails(orderBy: weight_DESC, first: $first, after: $after) {
      id
      name
    }
  }
`
export const SHOWS =gql`
  query shows($after: String, $first: Int!) {
    shows(orderBy: weight_DESC, first: $first, after: $after) {
      id
      name
    }
  }
`

export const LIST_PODCAST_EPISODE_FOR_SEASON = gql`
  query listPodcastEpisodeForSeason(
    $id: ID!
    $skip: Int
    $first: Int
    $search: String
    $listSeasonEpisode: Boolean
  ) {
    listPodcastEpisodeForSeason(
      listSeasonEpisode: $listSeasonEpisode
      where: { seasonId: $id, search: $search }
      skip: $skip
      first: $first
    ) {
      podcast {
        name
        id
      }
      podcastEpisodes {
        id
        title
        thumbnail
        weight
      }
      seasonEpisodes {
        id
        title
        thumbnail
        weight
      }
      count
    }
  }
`