import gql from 'graphql-tag'

export const CREATE_PODCAST_EPISODE = gql`
  mutation createPodcastEpisodeCustom( $data: PodcastEpisodeCreateInputCustom!) {
    createPodcastEpisodeCustom( data: $data) {
      id
      title
      description
      episodeNumber
      thumbnail
    }
  }
`

export const UPDATE_PODCAST_EPISODE = gql`
  mutation updatePodcastEpisodeCustom(
    $data: PodcastEpisodeUpdateInputCustom!
    $where: PodcastEpisodeWhereUniqueInput!
  ) {
    updatePodcastEpisodeCustom(data: $data, where: $where) {
      id
    }
  }
`

export const DELETE_PODCAST_EPISODE = gql`
  mutation deletePodcastEpisodeCustom($episodeId: ID!) {
    deletePodcastEpisodeCustom(where: { id: $episodeId })
  }
`

export const REQUEST_MASTER_ACCESS = gql`
  mutation requestMasterAccess($assetId: String!) {
    requestMasterAccess(where: { assetId: $assetId })
  }
`