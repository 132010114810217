import React, { useEffect, useContext, useState } from 'react'
import { Form, Input, Upload, Icon, Button, Select, message, Typography } from 'antd'
import { debounce } from 'lodash'
import { GET_APP_AUTHORS } from '../graphql/Queries'
import client from '../../../apollo'
import { AppContext } from '../../../AppContext'

const { Option } = Select

function beforeImageUpload(file) {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg'
  ) {
    return true
  }
  message.error('You can only upload image file!')
  return false
}
export default function (props) {
  const [removeFile, setRemoveFile] = useState(false)
  const [authorLoading, setAuthorLoading] = useState(false)
  const [authors, setAuthors] = useState([])
  const { state } = useContext(AppContext)
  let authorDebounceJob

  useEffect(() => {
    if (state.currentUser.role !== 'AUTHOR') {
      getAuthorList()
    }
  }, [state.currentUser])

  function getAuthorList(searchFilter = undefined) {
    let queryFilter = { first: 50, where: { role: 'AUTHOR', isActive: true } }
    setAuthorLoading(true)
    if (searchFilter && searchFilter.length > 0) {
      queryFilter.where.OR = [
        { firstName_starts_with: searchFilter },
        { firstName_contains: searchFilter },
        { firstName_ends_with: searchFilter },
        { lastName_starts_with: searchFilter },
        { lastName_contains: searchFilter },
        { lastName_ends_with: searchFilter },
        { email_starts_with: searchFilter },
        { email_contains: searchFilter },
        { email_ends_with: searchFilter },
      ]
    }
    client
      .query({
        query: GET_APP_AUTHORS,
        variables: queryFilter,
        fetchPolicy: 'network-only',
      })
      .then((data) => {
        setAuthors(data.data.users)
        setAuthorLoading(false)
      })
      .catch((e) => {
        console.log({ e })
        setAuthorLoading(false)
      })
  }

  function onAuthorChange(author) {
    if (!author) {
      getAuthorList()
    }
  }

  function onAuthorSearch(e) {
    if (authorDebounceJob) {
      authorDebounceJob.cancel()
    }
    authorDebounceJob = debounce(() => {
      getAuthorList(e)
    }, 500)
    authorDebounceJob()
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  const { getFieldDecorator, name, description, author, weight } = props
  const uploadProps = {
    customRequest() {
      return false
    },
  }

  return (
    <Form layout="vertical" className="add-show-form">
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          rules: [{ required: true, message: 'Please write the show title' }],
          initialValue: name,
        })(<Input className='show-name'/>)}
      </Form.Item>
      <Form.Item label="Description">
        {getFieldDecorator('description', {
          rules: [{ required: true, message: 'Please write the description' }],
          initialValue: description,
        })(<Input.TextArea className='show-description' rows={5} />)}
      </Form.Item>
      {state.currentUser.role !== 'AUTHOR' && (
        <Form.Item label="Author">
          {getFieldDecorator('author', {
            rules: [{ required: true, message: 'Please select the Author' }],
            initialValue: author,
          })(
            <Select className='show-author' placeholder="Select Author"
              loading={authorLoading}
              showSearch
              allowClear
              onChange={onAuthorChange}
              onSearch={onAuthorSearch}
              optionFilterProp="children"
            >
              {authors &&
                authors.length > 0 &&
                authors.map((author, key) => {
                  return <Option value={author.id} key={key}>
                    {author.firstName} {author.lastName} ({author.email})
                  </Option>
                })}
            </Select>,
          )}
        </Form.Item>
      )}
      <Form.Item label="Show Weight">
        {getFieldDecorator('weight', {
          initialValue: weight,
        })(<Input className='show-weight' type="number" />)}
      </Form.Item>
      <Form.Item label="Logo">
        {getFieldDecorator('logoImage', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
          // rules: [{ required: true, message: "Please upload show image!" }],
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id='btn-show-logo-image'>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
      <Form.Item label="Host Image">
        {getFieldDecorator('hostImage', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
          // rules: [{ required: true, message: "Please upload show image!" }],
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id='btn-show-host-image'>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
      <Form.Item label="Background Image">
        {getFieldDecorator('backgroundImage', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
          // rules: [{ required: true, message: "Please upload show image!" }],
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id='btn-show-background-image'>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
      <Form.Item label="Thumbnail  Image">
        {getFieldDecorator('image', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
          // rules: [{ required: true, message: "Please upload show image!" }],
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id='btn-show-thumb-image'>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
      <Form.Item label="Thumbnail Portrait Image">
        {getFieldDecorator('portraitImage', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id='btn-show-thumb-portrait-image'>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
      </Form.Item>
      <Form.Item label="OTT Show Banner">
        {getFieldDecorator('showDetailImage', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id='btn-show-ott-banner-image'>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
        <Typography.Text className="ant-form-text" type="secondary">
          Dimension ( 3840 x 1176 )
        </Typography.Text>
      </Form.Item>
      <Form.Item label="OTT Episode Background">
        {getFieldDecorator('episodeDetailImage', {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button id='btn-show-ott-background-image'>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>,
        )}
        <Typography.Text className="ant-form-text" type="secondary">
          Dimension ( 3840 x 2160 )
        </Typography.Text>
      </Form.Item>
    </Form>
  )
}
