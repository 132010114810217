import gql from "graphql-tag"

const GET_DISCUSSION_THREAD = gql`
query discussionThread($id: ID!, $after: String) {
  discussionThread(where: { id: $id } ){
    id
    title
    description
    status
    isLive
    scheduleAt
    startTime
    endTime
    createdAt
    createdBy {
      id
      firstName
      userName
      lastName
    }
    updatedAt
    messages(first: 15, after: $after) {
      id
      message
      isBlocked
      createdAt
      createdBy {
        id
        firstName
        lastName
        userName
        role
        profileImage
      }
      flags {
        id
      }
      likes {
        id
      }
      flagsCount
      likesCount
      replies {
        id
        message
        createdBy {
          id
          firstName
          lastName
          userName
          role
          profileImage
        }
        flagsCount
        likesCount
        likes {
          id
        }
        flags {
          id
        }
        isBlocked
        createdAt
      }
    }
  }
}
`
const GET_DISCUSSION = gql`
query discussion($id: ID) {
  discussion(where:{ id: $id }) {
    id
    owner{
      id
      email
    }
  }
}
`

export { GET_DISCUSSION_THREAD, GET_DISCUSSION }
