import React, { useState, useContext, useRef } from 'react'
import { Col, Button, notification } from 'antd'
import * as Sentry from '@sentry/browser'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import AddVideoModal from './AddVideoModal'
import { CREATE_VIDEO, UPDATE_VIDEO } from '../graphql/Mutations'
import { GET_VIDEO_SIGNED_PUT_URL } from '../graphql/Queries'
import { AppContext } from '../../../AppContext'
import { trim } from 'lodash'
import '../episode.css'
import { LOCATIONS } from '../../../common/constants'

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const { title, description, image, logoImage, allowedContinents, rating, schedule, isLive, videoNumber, liveChatAccess, locationRestriction,
        limitBy,
        allowedCountries } = values
      try {
        let imageFileName = ''
        let logoImageFileName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        if (logoImage && logoImage.length > 0) {
          logoImageFileName = logoImage[0].name
        }
        const { history, showId } = props
        let videoAccess = ['INSIDER', 'INSIDER_PLUS', 'ALL_ACCESS']
        if (isLive) {
          videoAccess = values.videoAccess
        }

        if (locationRestriction) {
          if (limitBy === LOCATIONS.CONTINENT) {
            values.allowedCountries = []
          }
          else {
            values.allowedContinents = []
          }
        }
        else if (!locationRestriction) {
          values.allowedContinents = []
          values.allowedCountries = []
        }

        const createVideoResult = await client.mutate({
          mutation: CREATE_VIDEO,
          variables: {
            title: trim(title),
            description: trim(description),
            image: imageFileName,
            logoImage: logoImageFileName,
            showId,
            videoAccess,
            liveChatAccess,
            // isLive,
            scheduleAt: schedule,
            createdBy: state.currentUser.id,
            videoNumber,
            allowedContinents: values.allowedContinents,
            rating,
            allowedCountries: values.allowedCountries,
          },
        })

        const videoId = createVideoResult.data.createVideo.id
        if (image && image.length > 0) {
          const tempFilenameArray = image[0].name.split('.')
          const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
          const temp = createVideoResult.data.createVideo.image.split('/')
          const tempFilename = temp[temp.length - 1].split('.')[0]
          const fileName = `${tempFilename}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_VIDEO_SIGNED_PUT_URL,
            variables: {
              fileName,
              videoId: createVideoResult.data.createVideo.id,
              contentType,
            },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getVideoSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
          imageFileName = fileName
        }
        if (logoImage && logoImage.length > 0) {
          const tempFilenameArray = logoImage[0].name.split('.')
          const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
          const temp = createVideoResult.data.createVideo.logoImage.split('/')
          const tempFilename = temp[temp.length - 1].split('.')[0]
          const fileName = `${tempFilename}.${fileExt}`
          const contentType = logoImage[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_VIDEO_SIGNED_PUT_URL,
            variables: {
              fileName,
              videoId: createVideoResult.data.createVideo.id,
              contentType,
            },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getVideoSignedPutUrl.signedUrl,
            logoImage[0].originFileObj,
          )
          logoImageFileName = fileName
        }
        if ((image && image.length > 0) || (logoImage && logoImage.length > 0)) {
          await client.mutate({
            mutation: UPDATE_VIDEO,
            variables: {
              data: {
                image: imageFileName,
                logoImage: logoImageFileName,
              },
              where: {
                id: videoId,
              },
            },
          })
        }
        form.resetFields()
        setShowModal(false)
        setSubmit(false)
        history.push(`/videos/${createVideoResult.data.createVideo.id}`)
      } catch (e) {
        Sentry.captureException(e)
        setSubmit(false)
        let errorMessage =
          (e &&
            e.graphQLErrors &&
            e.graphQLErrors[0] &&
            e.graphQLErrors[0].message &&
            e.graphQLErrors[0].message &&
            e.graphQLErrors[0].message) ||
          'Something Went Wrong'

        openNotification('error', errorMessage)
      }
    })
  }

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        className="add-show-card-wrapper"
        onClick={() => setShowModal(true)}
      >
        <div className="add-show-wrapper">
          <Button
            id='btn-video-create'
            type="dashed"
            size="large"
            className="add-show-button"
            onClick={() => setShowModal(true)}
          >
            + Add Video
          </Button>
        </div>
      </Col>
      {
        showModal && (
          <AddVideoModal
            saveFormRef={saveFormRef}
            showModal={showModal}
            isSubmit={isSubmit}
            isLive={isLive}
            showIsLIve={true}
            handleLiveChange={() => setIsLive(!isLive)}
            handleCancel={() => {
              setShowModal(false)
              saveFormRef.current.props.form.resetFields()
            }}
            handleCreate={handleCreate}
            showCreatedAt={true}
          />

        )
      }
    </>
  )
}
