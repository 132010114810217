import React, { useState } from 'react'
import { Form, Input, Select, DatePicker, Tooltip, Button } from 'antd'
import moment from 'moment-timezone'

const { Option } = Select

export default function (props) {
  const { getFieldDecorator, id, firstName, lastName, email, userName, promoPlan,
    promoPlanExpiration, recurlyId, recurlyPlan, showModal, onChangeEmailClick, onChangeUserNameClick } = props
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)
  const [scheduleTitle, setScheduleTitle] = useState('')

  function handleTimeChange(currentTime) {
    if (currentTime) {
      const laTime = moment(currentTime.valueOf()).tz('America/Los_Angeles')
      setScheduleTitle(`Expiry Date: ${laTime.format('lll')} ${laTime.zoneAbbr()}`)
      setTooltipIsVisible(true)
    } else {
      setScheduleTitle('')
      setTooltipIsVisible(false)
    }
  }

  function disabledStartDate(startValue) {
    return startValue.valueOf() < moment().valueOf();
  }

  return (
    <Form layout="vertical" className="update-person-form">
      <Form.Item label="id" style={{ display: 'none' }}>
        {getFieldDecorator('id',
          {
            initialValue: id
          })(
            <Input />
          )}
      </Form.Item>
      <Form.Item label="First Name">
        {getFieldDecorator('firstName', {
          rules: [{ required: true, message: 'Please input the First Name!' }],
          initialValue: firstName
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator('lastName', {
          rules: [{ required: true, message: 'Please input the Last Name!' }],
          initialValue: lastName
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator('email',
          {
            initialValue: email
          })(
            <Input disabled={true} />
          )}
        {id && <Button id="btn-person-change-email" type="link" className="email-link-button"
          onClick={() => onChangeEmailClick()}>
          Change Email
        </Button>}
      </Form.Item>
      {id && <Form.Item label="User Name">
        {getFieldDecorator('userName',
          {
            initialValue: userName
          })(
            <Input disabled={true} />
          )}
        <Button id="btn-person-change-user-name" type="link" className="email-link-button"
          onClick={() => onChangeUserNameClick()}>
          Change Username
        </Button>
      </Form.Item>}
      <Form.Item label="Recurly Id">
        {getFieldDecorator('recurlyId', {
          initialValue: recurlyId
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Recurly Plan">
        {getFieldDecorator('recurlyPlan',
          {
            initialValue: recurlyPlan
          })(
            <Select placeholder="Recurly Plan" disabled={true}>
              <Option value="FREE">FREE</Option>
              <Option value="INSIDER">INSIDER</Option>
              <Option value="INSIDER_PLUS">INSIDER ANNUAL</Option>
              <Option value="ALL_ACCESS">ALL ACCESS</Option>
            </Select>
          )}
      </Form.Item>
      <Form.Item label="Select Promotion Plan">
        {getFieldDecorator('promoPlan',
          {
            initialValue: promoPlan ? promoPlan : "FREE"
          })(
            <Select placeholder="Select Promotion Plan">
              <Option value="FREE">FREE</Option>
              <Option value="READER">READER</Option>
              <Option value="INSIDER">INSIDER</Option>
              <Option value="INSIDER_PLUS">INSIDER ANNUAL</Option>
              <Option value="ALL_ACCESS">ALL ACCESS</Option>
            </Select>
          )}
      </Form.Item>
      <Form.Item label="Promotion Expiration Date">
        <Tooltip title={scheduleTitle} visible={showModal && tooltipIsVisible} placement="right">
          {getFieldDecorator('promoPlanExpiration', {
            rules: [{ required: false, message: 'Please enter the date!' }],
            initialValue: promoPlanExpiration ? moment(promoPlanExpiration) : null
          })(
            <DatePicker
              style={{ minWidth: 260 }}
              placeholder="Select Date/Time"
              disabledDate={disabledStartDate}
              format="MM-DD-YYYY hh:mm A"
              showTime={{ use12Hours: true, format: "hh:mm A", defaultValue: moment('23:59:59', 'HH:mm:ss') }}
              ranges={moment()}
              onChange={handleTimeChange}
              onOk={handleTimeChange}
            />
          )}
        </Tooltip>
      </Form.Item>
    </Form>
  )
}
