import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Icon } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce, cloneDeep, isEqual, uniqBy } from 'lodash'
import { useLazyQuery } from '@apollo/react-hooks'
import client from '../../../../apollo'
import { GET_CLIPS } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE, DELETE_CAROUSEL_CLIP } from '../../graphql/Mutations'
import ClipListCard from './ClipListCard'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import MoveArrow from '../MoveArrow'
import usePrevious from '../usePrevious'

const confirm = Modal.confirm
let debounceJob
const defaultPageSize = 7

function ClipCarousel(props) {
  const { clipModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [search, setSearch] = useState('')
  const [clips, setClips] = useState(clipModuleData.clips || [])
  const [isPublished, setIsPublished] = useState(clipModuleData.isPublished || false)
  const [clipNextOrder, setClipNextOrder] = useState(clipModuleData.nextOrder || 1)
  const [orderChangeModal, setOrderChangeModal] = useState(false)
  const [current, setCurrent] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const oldClipsProp = usePrevious(clipModuleData.clips || [])

  const { getFieldDecorator, setFieldsValue } = form
  useEffect(() => {
    if (!isEqual(oldClipsProp, clipModuleData.clips)) {
      setClips(clipModuleData.clips)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipModuleData.clips])

  const id_not = clips.map(({ id }) => (id));
  const [getClips, {
    data: listClips,
    loading: dataLoading
  }] = useLazyQuery(GET_CLIPS, {
    variables: {
      first: 5,
      where: {
        OR: [{ name_starts_with: titleCase(search) },
          { name_starts_with: search.toUpperCase() },
          { name_starts_with: search.toLowerCase() },
          { name_starts_with: search },
          { name_contains: titleCase(search) },
          { name_contains: search.toUpperCase() },
          { name_contains: search.toLowerCase() },
          { name_contains: search },
          { name_ends_with: search },
          { name_ends_with: search.toUpperCase() },
          { name_ends_with: search.toLowerCase() },
        ],
        id_not_in: id_not
      }
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getClips()
  }, [])

  useEffect(() => {
    if (search.length) {
      if (debounceJob) {
        debounceJob.cancel()
      }
      debounceJob = debounce(() => {
        getClips()
      }, 500)
      debounceJob()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      sorter: false,
      width: '20%',
      render: (image) => (
        <img
          className="table-image"
          src={image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'name',
      sorter: false,
      render: (name) => (<div className="table-name">
        {name}
      </div>)
    },

  ]

  const columnsOrderChange = [
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (record) => (
        <img
          className="table-image"
          src={record.image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      sorter: false,
      render: (_, record, index) => (<div className="table-name">{record.name}</div>),
    },
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (_, record, index) => {
        let indexId = index + (current - 1) * defaultPageSize
        return <div>
          {(indexId !== (clips.length - 1)) &&
            <Icon type="down" className="p-2" onClick={() => changeOrder(indexId, clips, 1)} />}

          {(indexId !== 0) &&
            <Icon type="up" className="p-2" onClick={() => changeOrder(indexId, clips, -1)} />}
        </div>
      },
    },

  ]

  function changeOrder(indexId, clips, direction) {
    const item = cloneDeep(clips[indexId])
    const nextItem = cloneDeep(clips[indexId + direction])
    const itemOrder = item.carouselClipOrder
    item.carouselClipOrder = nextItem.carouselClipOrder
    nextItem.carouselClipOrder = itemOrder
    const newClipList = clips.filter(clip => {
      if (clip.id === item.id || clip.id === nextItem.id) {
        return false
      }
      return true
    })

    if (direction === -1) {
      newClipList.splice(indexId + direction, 0, item, nextItem)
    } else {
      newClipList.splice(indexId, 0, nextItem, item)
    }
    setClips(newClipList)
    setFieldsValue({ [`${MODULE_TYPES_CONST.CLIP_CAROUSEL}.${clipModuleData.id}.clips`]: newClipList })
  }

  function handleCancel() {
    setCurrent(1)
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    getClips()
    setShowModal(true)
  }

  function handleOrderChangeModalCancel() {
    setOrderChangeModal(false)
  }

  function openOrderChangeModal() {
    setOrderChangeModal(true)
  }

  const onSelectChange = (selectedRowKeys, pageSelectedRowsData) => {
    let newSelectedRowData = uniqBy([...selectedRows, ...pageSelectedRowsData], 'id')
    setSelectedRows(newSelectedRowData)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  let filteredClipsData = listClips?.clips

  function addClips() {
    setIsSubmit(true)
    let newSelectedClip = selectedRowKeys.filter((row) => {
      for (let index = 0; index < clips.length; index++) {
        if (clips[index].id === row) {
          return false
        }
      }
      return true
    })
    let currentOrder = clipNextOrder
    let filterSelectedClips = newSelectedClip.map((row) => {
      let clip = selectedRows.find((clip) => clip.id === row)
      if (!clip) {
        clip = listClips?.clips?.find((clip) => clip.id === row)
      }
      clip.carouselClipOrder = currentOrder
      currentOrder = currentOrder + 1
      return clip
    })
    setCurrent(1)
    setClipNextOrder(currentOrder)
    setClips([...clips, ...filterSelectedClips])
    setFieldsValue({ [`${MODULE_TYPES_CONST.CLIP_CAROUSEL}.${clipModuleData.id}.clips`]: [...clips, ...filterSelectedClips] })
    setShowModal(false)
    setIsSubmit(false)
    setSelectedRows([])
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.CLIP_CAROUSEL}.${clipModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteClipCarousel(clipCarouselPage) {
    confirm({
      title: `Are you sure you want to remove clip carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === clipCarouselPage.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: clipCarouselPage.id, type: MODULE_TYPES_CONST.CLIP_CAROUSEL },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== clipCarouselPage.id)
        setModuleList(newModules)
      },
    })
  }

  function deleteCarouselClip(id, clip) {
    confirm({
      title: `Are you sure you want to remove clip`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        if (clip.carouselClipId) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_CLIP,
              variables: { id: id },
            })
            const deleteCarouselClip = get(response, 'data.deleteCarouselClip')
            if (deleteCarouselClip && deleteCarouselClip.message) {
              openNotification('success', deleteCarouselClip.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        const newClips = clips.filter(item => item.id !== clip.id)
        setClips(newClips);
        setFieldsValue({ [`${MODULE_TYPES_CONST.CLIP_CAROUSEL}.${clipModuleData.id}.clips`]: newClips })
        setSelectedRowKeys(selectedRowKeys.filter(rowKeys => rowKeys !== clip.id))
      },
    })
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrent(1)
      setLastPage(false)
    }
  }

  useEffect(() => {
    async function handlePageChange() {
      if (!dataLoading) {
        try {
          getClips({
            variables: {
              skip: (current - 1) * 5,
              first: 5
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { clips } = fetchMoreResult
                if (clips?.length < 5) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                return clips?.length ? { clips: [...clips] } : prevResult
              }
            }
          })
        } catch (error) {
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  useEffect(() => {
    if (!dataLoading && listClips?.clips) {
      if (listClips?.clips?.length < 5) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [dataLoading])

  return (
    <Card key={clipModuleData.id} title='Clip Carousel' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {getFieldDecorator(`${MODULE_TYPES_CONST.CLIP_CAROUSEL}.${clipModuleData.id}.isPublished`, {
            initialValue: clipModuleData.isPublished || false
          })
            (<Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.CLIP_CAROUSEL}-${indexId}-isPublished`}>
              Published
          </Checkbox>)}
          <Button id={`btn-delete-clip-carousel-${indexId}`} className="ml-2"
            icon='delete'
            onClick={() => handleDeleteClipCarousel(clipModuleData)}
            type='danger'>
            Delete
          </Button>
        </div >
      ]
      }
    >
      <Form.Item label='Title'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.CLIP_CAROUSEL}.${clipModuleData.id}.title`, {
          initialValue: clipModuleData.title ? clipModuleData.title : '',
          rules: [{ required: true, message: 'Please input your title!' }]
        })(
          <Input placeholder='Title' className={`${MODULE_TYPES_CONST.CLIP_CAROUSEL}-${indexId}-title`} />,
        )}
      </Form.Item>
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.CLIP_CAROUSEL}.${clipModuleData.id}.order`, {
          initialValue: clipModuleData.order || 1
        })(
          <Input placeholder='Order' className={`${MODULE_TYPES_CONST.CLIP_CAROUSEL}-${clipModuleData.id}-order`} />,
        )}
      </Form.Item>
      <Form.Item label='Clips' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.CLIP_CAROUSEL}.${clipModuleData.id}.clips`, {
          initialValue: clipModuleData.clips || [],
          rules: [{ type: 'array', required: true, min: 1, message: 'Please add atleast one clip!' }]
        })
          (<Row gutter={24} type='flex'>
            {clips.length > 0 && clips.map((clip, index) => (
              <ClipListCard
                key={clip.id}
                id={clip.carouselClipId ? clip.carouselClipId : clip.id}
                name={clip.name}
                image={clip.image}
                index={index}
                indexId={indexId}
                clip={clip}
                deleteCarouselClip={deleteCarouselClip}
              />
            ))}
          </Row>)}
      </Form.Item>
      <Row>
        <Button icon='plus' id={`btn-add-clip-${indexId}`}
          onClick={openModal}>
          Add Clip
          </Button>
        {clips.length > 1 && (<Button className="ml-2" icon='edit' id={`btn-edit-clip-${indexId}`}
          onClick={openOrderChangeModal}>
          Change Order
        </Button>)
        }
        <Modal
          title='Add Clips'
          visible={showModal}
          maskClosable={false}
          onOk={addClips}
          onCancel={handleCancel}
          footer={[
            <Button id={`btn-clip-carousel-cancel-${indexId}`} key='back' onClick={handleCancel}>
              Cancel
              </Button>,
            <Button
              id={`btn-clip-carousel-save-${indexId}`}
              key='submit'
              type='primary'
              loading={isSubmit}
              onClick={addClips}
            >
              Add
              </Button>,
          ]}
        >
          <Row>
            <Col>
              <Input.Search placeholder='Search Clip' onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-clip-carousel-${indexId}`} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Table
              loading={dataLoading}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              dataSource={filteredClipsData}
              showHeader={false}
              pagination={false}
              onChange={handleChange}
            />
            <div className="page-change-btn-wrapper">
              <Button icon="left" onClick={() => setCurrent(current - 1)} disabled={current === 1} />
              <Button icon="right" onClick={() => setCurrent(current + 1)} disabled={lastPage} />
            </div>
          </Row>
        </Modal>
      </Row>
      <Modal
        title='Change Order'
        visible={orderChangeModal}
        maskClosable={false}
        onCancel={handleOrderChangeModalCancel}
        footer={null}
      >
        <Row>
          <Table
            columns={columnsOrderChange}
            rowKey={(record) => record.id}
            dataSource={clips}
            showHeader={false}
            pagination={{
              onChange(currentPage) { setCurrent(currentPage) },
              defaultPageSize
            }}
          />
        </Row>
      </Modal>
    </Card >
  )
}

export default ClipCarousel
