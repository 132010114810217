import gql from 'graphql-tag'

export const GET_ALL_EPISODE = gql`
  query listPodcastEpisodeDetails($id: ID!, $after: String) {
    listPodcastEpisodeDetails(
      where: { podcast: { id: $id } }
      first: 15
      after: $after
      orderBy: createdAt_DESC
    ) {
      id
      title
      thumbnail
      status
      createdAt
      publishDate
    }
  }
`

export const GET_SEASONS = gql`
  query seasons($first: Int, $after: String, $where: SeasonWhereInput) {
    seasons(first: $first, after: $after, orderBy: weight_DESC, where: $where) {
      id
      name
      slug
      description
      status
      show {
        id
        name
      }
      image
      weight
      orderBy
      createdAt
    }
  }
`

export const GET_PODCAST_EPISODE_SIGNED_PUT_URL = gql`
  query getPodcastEpisodeSignedPutUrl(
    $fileName: String!
    $podcastEpisodeId: String!
    $contentType: String!
  ) {
    getPodcastEpisodeSignedPutUrl(
      data: {
        fileName: $fileName
        podcastEpisodeId: $podcastEpisodeId
        contentType: $contentType
      }
    ) {
      signedUrl
      getUrl
    }
  }
`

export const GET_PODCAST_EPISODE = gql`
  query getPodcastEpisodeDetails($id: ID!){
    getPodcastEpisodeDetails(where: {id: $id}){
      id
      title
      description
      episodeNumber
      weight
      thumbnail
      podcast {
        id
        name
      }
      season {
        id 
        name
      }
      status
      rating
      duration
      audio
      audioState
      audioMuxAssetId
      durationWithAds
      audioWithAds
      audioWithAdsState
      audioWithAdsMuxAssetId
      audioAccess
      audioWithAdsAccess
      publishDate
      scheduleAt
      allowedContinents
      allowedCountries
    }
  }
`

export const GET_PODCAST_EPISODE_SIGNED_AUDIO_PUT_URL = gql`
  query getPodcastEpisodeSignedAudioPutUrl(
    $where: PodcastEpisodeDataInput
    $corsOrigin: String
    $withAds: Boolean!
  ) {
    getPodcastEpisodeSignedAudioPutUrl(
      where: $where
      corsOrigin: $corsOrigin
      withAds: $withAds
    ) {
      signedUrl
    }
  }
`

export const GET_MUX_DATA = gql`
  query getMuxData($assetId: String!) {
    getMuxData(where: { assetId: $assetId })
  }
`