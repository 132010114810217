import React, { Component } from "react"
import { Modal, Button, Form } from "antd"
import BadgeForm from "./BadgeForm"

const CollectionCreateForm = Form.create({ name: "create_update_badge" })(
  class extends React.Component {
    render() {
      const {
        visible,
        isSubmit,
        onCancel,
        onCreate,
        form,
        badgeEditableData,
        isUpdate,
      } = this.props
      const { getFieldDecorator, getFieldValue } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={isUpdate ? "Update Badge" : "Add New Badge"}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id="btn-badge-cancel" key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button
              id="btn-badge-save"
              key="submit"
              type="primary"
              loading={isSubmit}
              onClick={onCreate}
            >
              {isUpdate ? "Update Badge" : "Add Badge"}
            </Button>,
          ]}
        >
          <BadgeForm
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            showModal={visible}
            {...badgeEditableData}
          />
        </Modal>
      )
    }
  },
)

export default class BadgeModal extends Component {
  render() {
    const {
      showModal,
      isSubmit,
      handleCancel,
      handleOk,
      saveFormRef,
      badgeEditableData,
      isUpdate,
    } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleOk}
        isUpdate={isUpdate}
        badgeEditableData={badgeEditableData}
      />
    )
  }
}
