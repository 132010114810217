import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Table, Typography, Form, notification, Tooltip, Icon, Button } from 'antd'
import moment from 'moment-timezone'
import { useQuery } from '@apollo/react-hooks'
import { NavLink, withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { get } from 'lodash'
import { AppContext } from '../../AppContext'
import { chatClient } from '../../apollo'
import { GET_LIVECHATS } from './graphql/Queries'
import Spinner from '../../components/loaders/Spinner'
import Meta from '../../components/Meta'
import { getDownloadDiscussionData } from '../discussions/api'
import './livechat.css'

const { Title } = Typography
const EditableContext = React.createContext()

function EditableCell(props) {
  const { dataIndex, title, inputType, record, index, ...restProps } = props
  return (
    <EditableContext.Consumer>
      {(form) => <td {...restProps}> {restProps.children} </td>}
    </EditableContext.Consumer>
  )
}

function openNotification(type, message) {
  notification[type]({
    message
  })
}

function exportDetails(discussion) {
  if (discussion && discussion.id && discussion.subject) {
    getDownloadDiscussionData(discussion.id).then((res) => {
      if (res && res.data && res.hasData) {
        const csvData = new Blob([res.data], { type: 'text/csv' })
        let link = document.createElement('a')
        let csvUrl = URL.createObjectURL(csvData)
        link.href = csvUrl
        link.setAttribute('download', `${discussion.subject}.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else if (res && !res.hasData && res.message) {
        openNotification("error", res.message)
      } else {
        openNotification("error", "No discussion messages are available.")
      }
    }).catch((error) => {
      if (error && error.key && error.key === 'INVALID_TOKEN' && error.message) {
        openNotification("error", error.message)
      } else {
        openNotification("error", "Something Went Wrong")
      }
      Sentry.captureException(new Error(JSON.stringify(error)))
    })
  } else {
    openNotification("error", "Something Went Wrong. Discussion id not avaialble.")
  }
}

function EditableTable(props) {
  const [tableLoading, setTableLoading] = useState(false)
  const { state } = useContext(AppContext)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const { data: liveChatData, loading: isLiveChatLoading, error: liveChatError, fetchMore, networkStatus } = useQuery(GET_LIVECHATS, { variables: { first: 10 }, client: chatClient, fetchPolicy: "network-only" })
  const isAdmin = get(state, 'currentUser.role') === 'ADMIN'
  
  useEffect(() => {
    async function handlePageChange() {
      if (!isLiveChatLoading) {
        try {
          setTableLoading(true)
          fetchMore({
            variables: {
              skip: (currentPageNumber - 1) * 10,
              first: 10
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { discussions } = fetchMoreResult
                if (discussions?.length < 10) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                setTableLoading(false)
                return discussions?.length ? { discussions: [...discussions] } : prevResult
              }
            }
          })
        } catch (error) {
          setTableLoading(false)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber])

  useEffect(() => {
    if (!isLiveChatLoading && liveChatData?.discussions) {
      if (liveChatData?.discussions.length < 10) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [isLiveChatLoading])

  let column = [
    {
      title: 'Episode Name',
      dataIndex: 'subject',
      width: '35%',
      render: (text, record, index) => <NavLink to={`/live-chat/${record.id}`} className="discussions-subject">{text}</NavLink>
    },
    {
      title: 'Host Name',
      dataIndex: 'host',
      editable: state.currentUser.role !== 'AUTHOR' ? true : false,
      width: '25%',
    },
    {
      title: 'Status',
      dataIndex: 'isLive',
      width: '10%'
    },
    {
      title: 'Time',
      dataIndex: 'scheduleAt',
      width: '15%',
      render: (text, record, index) => moment(text).tz('America/Los_Angeles').format('Do MMMM YYYY, hh:mm:ss a')
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      editable: false,
      width: '15%',
      render: text => `${text ? `${text} Comments` : ''}`,
    },
  ]
  if (isAdmin) {
    column.push({
      title: 'Actions',
      dataIndex: 'operation',
      width: '25%',
      render: (text, record) => {
        const { isLive } = record
        return isLive === 'ENDED' ? <Tooltip title="Export Messages">
          <Button id={`btn-export-${record.id}-live-chat`} type='link' className='link-button' onClick={() => exportDetails(record)}>
            <Icon type="download" className='link-button-icon' />
          </Button>
        </Tooltip> : null
      }
    })
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  }

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'host' ? 'select' : col.dataIndex === 'scheduleAt' ? 'date' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        onClick: () => col.dataIndex === 'subject' ? props.history.push(`/live-chat/${record.id}`) : ''
      }),
    }
  })
  function handleChange(sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      setLastPage(false)
    }
  }
  let liveChatTableData = []
  if (liveChatData && liveChatData.discussions) {
    liveChatTableData = liveChatData.discussions.map((discussion, key) => (
      {
        id: discussion.id,
        key: key.toString(),
        subject: discussion.title,
        isLive: discussion.status.toUpperCase(),
        host: `${discussion.owner.firstName} ${discussion.owner.lastName}`,
        comments: discussion.messagesCount,
        scheduleAt: discussion.scheduleAt
      }
    ))
  }

  return (
    networkStatus === 1 ? <div className="callback-wrapper"><Spinner /></div> :
      liveChatError ? <>{liveChatError.message}</> :
        <EditableContext.Provider value={props.form}>
          <Table
            bordered
            loading={isLiveChatLoading || tableLoading}
            rowClassName="editable-row"
            components={components}
            dataSource={liveChatTableData}
            columns={columns}
            pagination={false}
            onChange={handleChange}
          />
          <div className="page-change-btn-wrapper">
            <Button icon="left" onClick={() => setCurrentPageNumber(currentPageNumber - 1)} disabled={currentPageNumber === 1} />
            <Button icon="right" onClick={() => setCurrentPageNumber(currentPageNumber + 1)} disabled={lastPage} />
          </div>
        </EditableContext.Provider>
  )
}

const EditableFormTable = withRouter(Form.create()(EditableTable))

export default function LiveChat(props) {
  return (
    <Row gutter={24} type="flex" className="live-chat-wrapper">
      <Meta title="Episode Live Chat" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Episode Live Chat</Title>
        </div>
        <EditableFormTable />
      </Col>
    </Row>
  )
}
