import React, { useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { LIST_EPISODE_FOR_SEASON, GET_SEASON, LIST_PODCAST_EPISODE_FOR_SEASON } from '../graphql/Queries'
import { UPDATE_SEASONS_EPISODES, UPDATE_SEASONS_PODCAST_EPISODES } from '../graphql/Mutations'
import client from '../../../apollo'
import {
  Table,
  Pagination,
  Input,
  Row,
  Col,
  Typography,
  Icon,
  Button,
  PageHeader,
} from 'antd'
import { debounce, uniqBy, find, findIndex, get } from 'lodash'
import { useState } from 'react'
import './EpisodeListPage.css'
import { AppContext } from '../../../AppContext'
import Meta from '../../../components/Meta'
const { Search } = Input
const { Title } = Typography
const EpisodeUpdateListPage = (props) => {
  const [currentSeason, setCurrentSeason] = useState({})
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [selectionEnabled, setSelectionEnabled] = useState(false)
  const [showName, setShowName] = useState()
  const [episodes, setEpisodes] = useState([])
  const [visitedEpisodes, setVisitedEpisodes] = useState([])
  const [episodeLoading, setAddEpisodeLoading] = useState(false)
  const [episodeCount, setEpisodeCount] = useState()
  const { state } = useContext(AppContext)
  const [podcastName, setPodcastName] = useState()
  const [podcastEpisodes, setPodcastEpisodes] = useState([])
  const [visitedPodcastEpisodes, setVisitedPodcastEpisodes] = useState([])
  const [addPodcastEpisodeLoading, setAddPodcastEpisodeLoading] = useState(false)
  const [podcastEpisodeCount, setPodcastEpisodeCount] = useState()
  const {
    match: {
      params: { seasonId },
    },
  } = props

  const { data: { listEpisodeForSeason } = {}, loading, fetchMore } = useQuery(
    LIST_EPISODE_FOR_SEASON,
    {
      variables: { id: seasonId, first: 10, listSeasonEpisode: true },
      fetchPolicy: 'network-only',
      client: client,
    },
  )

  useEffect(() => {
    getCurrentSeasonData()
  }, [])

  useEffect(() => {
    if (listEpisodeForSeason) {
      setEpisodeCount(listEpisodeForSeason?.count)
      setEpisodes(listEpisodeForSeason?.seasonEpisodes)
      setVisitedEpisodes(listEpisodeForSeason?.seasonEpisodes)
      setShowName(listEpisodeForSeason?.show?.name)
    }
  }, [listEpisodeForSeason])

  const getCurrentSeasonData = async () => {
    const getSeasonData = await client.query({
      query: GET_SEASON,
      fetchPolicy: 'network-only',
      variables: { seasonId: seasonId },
    })
    setCurrentSeason(getSeasonData)
  }

  const onSelectChange = (selectedRowKeys) => {
    setSelectionEnabled(!!selectedRowKeys.length)
    setSelectedRowKeys(selectedRowKeys)
  }

  const onChangeInput = (value, id) => {    
    const matchGlobalEpisode = find(visitedEpisodes, (episode) => episode.id === id)
    if (matchGlobalEpisode) {
      const foundIndex = findIndex(visitedEpisodes, (episode) => episode.id === id)
      visitedEpisodes[foundIndex].weight = value
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'episode',
      sorter: false,
      key: 'image',
      render: (episode) => (
        <img
          className="season-episode-table-img"
          src={episode.image}
          alt={episode.title}
        />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'episode',
      sorter: false,
      key: 'title',
      render: (episode) => episode.title,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      sorter: false,
      render: (value, render) => (
        <Input
          className="seasonepisode-update-weight-input"
          defaultValue={value}
          onChange={(e) => onChangeInput(+e.target.value, render.id)}
          type="number"
        />
      ),
    },
  ]

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  function handlePagination(pageNumber) {
    if (episodeCount > episodes.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { listEpisodeForSeason } = fetchMoreResult
          if (listEpisodeForSeason && listEpisodeForSeason.count && listEpisodeForSeason.seasonEpisodes) {
            setEpisodeCount(listEpisodeForSeason.count)
            setEpisodes(listEpisodeForSeason.seasonEpisodes)
            setVisitedEpisodes(uniqBy([...visitedEpisodes, ...listEpisodeForSeason.seasonEpisodes], 'id'))
          }
          setTableLoading(false)
        },
      })
    }
  }

  const addEpisodes = () => {
    const { data: { season } } = currentSeason
    setAddEpisodeLoading(true)
    const updateMany = selectedRowKeys.map((row) => {
      const episode = visitedEpisodes.find((episode) => episode.id === row) || {}
      return {
        where: { id: episode.id },
        data: {
          weight: episode.weight,
        },
      }
    })

    if (updateMany && Array.isArray(updateMany) && updateMany.length) {
      const foundWeightNull = find(updateMany, (data) => data && data.data && data.data.weight === null || data.data.weight === undefined)
      if (foundWeightNull && season && season.orderBy === 'Manual') {
        setAddEpisodeLoading(false)
        return alert('Please add weight for selected episodes.')
      }
    }

    client
      .mutate({
        mutation: UPDATE_SEASONS_EPISODES,
        variables: {
          seasonEpisodes: { updateMany },
          id: seasonId,
        },
        refetchQueries: [
          {
            query: LIST_EPISODE_FOR_SEASON,
            variables: { id: seasonId, first: 10, listSeasonEpisode: true },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setSelectedRowKeys([])
        setSelectionEnabled(false)
        setEpisodes([])
        setVisitedEpisodes([])
        props.history.push(`/seasons/${seasonId}`)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setSelectedRowKeys([])
        setSelectionEnabled(false)
        setAddEpisodeLoading(false)
      })
  }

  const onSearch = (value) => {
    debounce(() => {
      client
        .query({
          query: LIST_EPISODE_FOR_SEASON,
          fetchPolicy: 'network-only',
          variables: {
            id: seasonId,
            first: 10,
            search: value,
            listSeasonEpisode: true,
          },
        })
        .then(({ data: { listEpisodeForSeason } = {} }) => {
          setEpisodeCount(listEpisodeForSeason.count)
          setEpisodes(listEpisodeForSeason.seasonEpisodes)
        })
        .catch((e) => {
          console.log(e)
        })
    }, 500)()
  }

  const { data: { listPodcastEpisodeForSeason } = {}, loading: podcastEpisodesLoading, fetchMore: fetchMorePodcasts } = useQuery(
    LIST_PODCAST_EPISODE_FOR_SEASON,
    {
      variables: { id: seasonId, first: 10, listSeasonEpisode: true },
      fetchPolicy: 'network-only',
      client: client,
    },
  )

  useEffect(() => {
    if (listPodcastEpisodeForSeason) {
      setPodcastEpisodeCount(listPodcastEpisodeForSeason?.count)
      setPodcastEpisodes(listPodcastEpisodeForSeason?.seasonEpisodes)
      setVisitedPodcastEpisodes(listPodcastEpisodeForSeason?.seasonEpisodes)
      setPodcastName(listPodcastEpisodeForSeason?.podcast?.name)
    }
  }, [listPodcastEpisodeForSeason])

  const onPodcastChangeInput = (value, id) => {
    const matchGlobalEpisode = find(visitedPodcastEpisodes, (episode) => episode.id === id)
    if (matchGlobalEpisode) {
      const foundIndex = findIndex(visitedPodcastEpisodes, (episode) => episode.id === id)
      visitedPodcastEpisodes[foundIndex].weight = value
    }
  }

  const columnsForPodcasts = [
    {
      title: '',
      dataIndex: 'thumbnail',
      render: (thumbnail, record) => (
        <div>
          <img
            className="season-podcastepisode-table-img"
            src={thumbnail}
            alt={record.title}
          />
        </div>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render: (title) => <div>{title}</div>,
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      render: (value, render) => (
        <div>
          <Input
            className="seasonepisode-update-weight-input"
            defaultValue={value}
            onChange={(e) => onPodcastChangeInput(+e.target.value, render.id)}
            type="number"
          />
        </div>
      ),
    },
  ]

  function handlePodcastPagination(pageNumber) {
    if (podcastEpisodeCount > podcastEpisodes.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMorePodcasts({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { listPodcastEpisodeForSeason } = fetchMoreResult
          if (listPodcastEpisodeForSeason && listPodcastEpisodeForSeason.count && listPodcastEpisodeForSeason.seasonEpisodes) {
            setPodcastEpisodeCount(listPodcastEpisodeForSeason.count)
            setPodcastEpisodes(listPodcastEpisodeForSeason.seasonEpisodes)
            setVisitedPodcastEpisodes(uniqBy([...visitedPodcastEpisodes, ...listPodcastEpisodeForSeason.seasonEpisodes], 'id'))
          }
          setTableLoading(false)
        },
      })
    }
  }

  const addPodcastEpisodes = () => {
    const { data: { season } } = currentSeason
    setAddPodcastEpisodeLoading(true)
    const updateMany = selectedRowKeys.map((row) => {
      const episode = visitedPodcastEpisodes.find((episode) => episode.id === row) || {}
      return {
        where: { id: episode.id },
        data: {
          weight: episode.weight,
        },
      }
    })

    if (updateMany && Array.isArray(updateMany) && updateMany.length) {
      const foundWeightNull = find(updateMany, (data) => data && data.data && (data.data.weight === null || data.data.weight === undefined))
      if (foundWeightNull && season && season.orderBy === 'Manual') {
        setAddEpisodeLoading(false)
        return alert('Please add weight for selected episodes.')
      }
    }

    client
      .mutate({
        mutation: UPDATE_SEASONS_PODCAST_EPISODES,
        variables: {
          podcastEpisodes: { updateMany },
          id: seasonId,
        },
        refetchQueries: [
          {
            query: LIST_PODCAST_EPISODE_FOR_SEASON,
            variables: { id: seasonId, first: 10, listSeasonEpisode: true },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        setSelectedRowKeys([])
        setSelectionEnabled(false)
        setPodcastEpisodes([])
        setVisitedPodcastEpisodes([])
        props.history.push(`/seasons/${seasonId}`)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setSelectedRowKeys([])
        setSelectionEnabled(false)
        setAddPodcastEpisodeLoading(false)
      })
  }

  if (get(currentSeason, 'data.season.type') === 'PODCAST' && listPodcastEpisodeForSeason) {
    return (
      <>
        <Row gutter={24} type="flex" className="shows-title-wrapper">
          <Meta title={podcastName || ''} description="Episode list" />
          <Col span={24}>
            <PageHeader
              onBack={() => props.history.goBack()}
              title={`Update Episode from ${podcastName || ''}`}
              className="box page-header"
            />
          </Col>
        </Row>
        <Row gutter={24} type="flex" className="person-management-wrapper">
          <Col span={24}>
            <div className="seasonepisode-action-buttons-wrapper">
              <div className="episode-header d-flex seasonepisode-action-header">
                {selectionEnabled && (
                  <div className="action-btn">
                    <Button
                      className="cancel-btn"
                      onClick={() => {
                        setSelectionEnabled(false)
                        setSelectedRowKeys([])
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      loading={addPodcastEpisodeLoading}
                      onClick={() => addPodcastEpisodes()}
                    >
                      Update Selected
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <Table
              loading={podcastEpisodesLoading || tableLoading}
              dataSource={podcastEpisodes}
              columns={columnsForPodcasts}
              rowSelection={rowSelection}
              rowKey={record => record.id}
              pagination={false}
            />
            <Pagination
              onChange={handlePodcastPagination}
              current={currentPageNumber}
              total={podcastEpisodeCount}
            />
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Row gutter={24} type="flex" className="shows-title-wrapper">
        <Meta title={showName || ''} description="Episode list" />
        <Col span={24}>
          <PageHeader
            onBack={() => props.history.goBack()}
            title={`Update Episode from ${showName || ''}`}
            className="box page-header"
          />
        </Col>
      </Row>
      <Row gutter={24} type="flex" className="person-management-wrapper">
        <Col span={24}>
          <div className="seasonepisode-action-buttons-wrapper">
            <div className="episode-header d-flex seasonepisode-action-header">
              {selectionEnabled && (
                <div className="action-btn">
                  <Button
                    className="cancel-btn"
                    onClick={() => {
                      setSelectionEnabled(false)
                      setSelectedRowKeys([])
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    loading={episodeLoading}
                    onClick={() => addEpisodes()}
                  >
                    Update Selected
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Table
            loading={loading || tableLoading}
            columns={columns}
            rowSelection={rowSelection}
            rowKey={(record) => record.id}
            dataSource={episodes}
            pagination={false}
          />
          <Pagination
            onChange={handlePagination}
            current={currentPageNumber}
            total={episodeCount}
          />
        </Col>
      </Row>
    </>
  )
}

export default EpisodeUpdateListPage
