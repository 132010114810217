import gql from 'graphql-tag'

const GET_MODULAR_PAGES = gql`
  query modulePages($first: Int!, $skip: Int, $after: String, $where: ModulePageWhereInput) {   
    modulePages(orderBy: createdAt_DESC, first: $first, skip: $skip,after: $after, where:$where){
      id
      title
      slug
      isPublished
      modules {
        id
        moduleReferenceId
        moduleType
        order
        isPublished
        modulePage {
          id
          title
        }
        createdAt
        updatedAt
      }     
      createdAt
      updatedAt
    }
  }
`

const GET_MODULAR_PAGE = gql`
  query getModularPage($where: ModulePageWhereUniqueInput!){
    getModularPage(where: $where){
      id
      title
      slug
      nextOrder
      isPublished
      createdBy{
        id
      }
      modules {
        __typename
        ... on ClipCarousel {
          id
          type
          title
          order
          nextOrder
          isPublished
          clips {
            id
            name
            image
            carouselClipOrder
            carouselClipId            
          }

        }
        ... on VideoCarousel {
          id
          type
          title
          order
          nextOrder
          isPublished
          videos {
            id
            name
            image
            carouselVideoOrder
            carouselVideoId            
          }
        }
        ... on EpisodeCarousel {
          id
          type
          title
          order
          nextOrder
          isPublished
          episodes {
            id
            title
            image 
            carouselEpisodeOrder
            carouselEpisodeId            
          }
        }
        ... on ShowCarousel {
          id
          type
          title
          order
          nextOrder
          isPublished
          orientation
          shows {
            id
            name
            image
            portraitImage
            carouselShowOrder
            carouselShowId            
          }
        }
        ... on ContinueWatching {
          id
          type
          title
          order
          isPublished
        }
        ... on VideoPremiere {
          id
          type
          order
          isPublished
          premiereImage
          video {
            id 
            name
            description
            image
            carouselVideoOrder
            carouselVideoId
          }
        }
        ... on EpisodePremiere {
          id
          type
          order
          isPublished
          premiereImage
          episode {
            id 
            title
            description
            image
            carouselEpisodeOrder
            carouselEpisodeId
          }
        }
        ... on ShowPremiere {
          id
          type
          order
          isPublished
          premiereImage
          show {
            id 
            name
            description
            image
            carouselShowOrder
            carouselShowId
          }
        }
        ... on Headliner {
          id
          type
          order
          isPublished
          title
          description
          CTAText
          routing
          link
          referenceId
          referenceType
          referenceSlug
        }
        ... on Highlight {
          id
          type
          order
          isPublished
          title
          description
          image
          CTAText
          routing
          link
          referenceId
          referenceType
          referenceSlug
        }
        ... on PodcastCarousel {
          id
          type
          title
          order
          nextOrder
          isPublished
          orientation
          podcasts {
            id
            name
            coverImage
            backgroundImage
            carouselPodcastOrder
            carouselPodcastId            
          }
        }
        ... on PodcastPremiere {
          id
          type
          order
          isPublished
          premiereImage
          podcast {
            id 
            name
            description
            coverImage
            carouselPodcastOrder
            carouselPodcastId
          }
        }
        ... on PodcastEpisodeCarousel {
          id
          type
          title
          order
          nextOrder
          isPublished
          podcastEpisodes {
            id
            title
            thumbnail
            carouselPodcastEpisodeOrder
            carouselPodcastEpisodeId            
          }
        }
        ... on PodcastEpisodePremiere {
          id
          type
          order
          isPublished
          premiereImage
          podcastEpisode {
            id 
            title
            description
            thumbnail
            carouselPodcastEpisodeOrder
            carouselPodcastEpisodeId
          }
        }
        ... on ContinueListening {
          id
          type
          title
          order
          isPublished
        }
      }
      createdAt
      updatedAt
    }
  }
`

const GET_CLIPS = gql`
  query clips($first: Int, $after: String, $skip: Int, $where: ClipWhereInput) {
    clips(first: $first, after: $after, skip: $skip, where: $where) {
      id
      name
      description
      clipNumber
      image
      status
      videoState
      show {
        id
        name
      }
      video {
        id
        name
      }
      muxAssetId
      uploadId
      muxPlaybackId
      thumbnail
      videoURL
      duration
      clipAccess
      createdAt
      updatedAt
      message
    }
  }
`
const GET_VIDEOS = gql`
  query videos($first: Int, $after: String, $skip: Int, $where: VideoWhereInput) {
    videos(first: $first, after: $after, skip: $skip, where: $where) {
      id
      name
      description
      videoNumber
      image
      logoImage
      allowedContinents
      rating
      status
      isLive
      scheduleAt
      discussionId
      videoState
      audioState
      muxAssetId
      streamKey
      uploadId
      thumbnail
      muxPlaybackId
      videoURL
      duration
      videoAccess
      liveChatAccess
      createdAt
      updatedAt
    }
  }
`

const GET_EPISODES = gql`
  query episodes($where: EpisodeWhereInput,$first: Int, $after: String, $skip: Int) {
    episodes(
      where: $where,
      first: $first,
      after: $after,
      skip: $skip,
      orderBy: createdAt_DESC,
    ) {
      id
      title
      image
      status
      description
      featuredBannerImage
      createdAt
    }
  }
`
const LIST_SHOW_FOR_SEASON = gql`
  query listShowForSeason {
    listShowForSeason {
      id
      name
    }
  }
`
const GET_SHOWS = gql`
  query shows($first: Int, $after: String,$where: ShowWhereInput) {
    shows(orderBy: weight_DESC, first: $first, after: $after, where: $where) {
      id
      name
      weight
      image
      description
      author {
        id
      }
      thumbnail
      logoImage
      portraitImage
      createdAt
      updatedAt
    }
  }
`

const GET_SEASONS = gql`
  query seasons($first: Int, $after: String, $where: SeasonWhereInput) {
    seasons(
      first: $first
      after: $after
      orderBy: weight_DESC
      where: $where
    ) {
      id
      name
      slug
      description
      show {
        id
        name
      }
      image
      weight
      orderBy
    }
  }
`
const GET_VIDEO = gql`
  query video($where: VideoWhereUniqueInput!) {
    video(where: $where) {
      id
      name
    }
  }
`
const GET_SEASON = gql`
  query season($where: SeasonWhereUniqueInput!) {
    season(   
      where: $where
    ) {
      id
      name
    }
  }
`
const GET_SHOW = gql`
  query show($where: ShowWhereUniqueInput!) {
    show(where: $where) {
      id
      name
    }
  }
`
const GET_CLIP = gql`
  query clip($where: ClipWhereUniqueInput!) {
    clip(where: $where) {
      id
      name
    }
  }
`
const GET_EPISODE = gql`
  query episode($where: EpisodeWhereUniqueInput!) {
    episode(where: $where) {
      id
      title
    }
  }
`

const GET_PODCASTS = gql`
  query listPodcastDetails($first: Int, $after: String, $skip: Int, $where: PodcastWhereInput) {
    listPodcastDetails(orderBy: weight_DESC, first: $first, after: $after, skip: $skip, where: $where) {
      id
      name
      description
      coverImage
    }
  }
`

const GET_PODCAST_EPISODES = gql`
  query listPodcastEpisodeDetails($first: Int, $after: String, $skip: Int, $where: PodcastEpisodeWhereInput) {
    listPodcastEpisodeDetails(
      where: $where,
      first: $first,
      after: $after,
      skip: $skip,
      orderBy: createdAt_DESC
    ) {
      id
      title
      thumbnail
      description
      status
    }
  }
`

export {
  GET_MODULAR_PAGES,
  GET_MODULAR_PAGE,
  GET_CLIPS,
  GET_VIDEOS,
  GET_EPISODES,
  LIST_SHOW_FOR_SEASON,
  GET_SHOWS,
  GET_SEASONS,
  GET_VIDEO,
  GET_SEASON,
  GET_SHOW,
  GET_CLIP,
  GET_EPISODE,
  GET_PODCASTS,
  GET_PODCAST_EPISODES
}