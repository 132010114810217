import React, { useState } from 'react'
import { Card, Form, Input, Button, Modal, Checkbox } from 'antd'
import * as Sentry from '@sentry/browser'
import { get } from 'lodash'
import client from '../../../../apollo'
import { DELETE_CAROUSEL_MODULE } from '../../graphql/Mutations'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification } from '../../../../common/utility'
import MoveArrow from '../MoveArrow'

const confirm = Modal.confirm

function Programmatic(props) {
  const { programModuleData, form, setModuleList, modulesList, indexId } = props
  const [isPublished, setIsPublished] = useState(programModuleData.isPublished || false)

  const { getFieldDecorator, setFieldsValue } = form

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.CONTINUE_WATCHING}.${programModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteProgramCarousel(programCarousel) {
    confirm({
      title: `Are you sure you want to remove programmatic continue watching carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === programCarousel.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: programCarousel.id, type: MODULE_TYPES_CONST.CONTINUE_WATCHING },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        setModuleList(modulesList.filter((module) => module.id !== programCarousel.id))
      },
    })
  }

  return (
    <Card key={programModuleData.id} title='Programmatic: Continue Watching' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {
            getFieldDecorator(`${MODULE_TYPES_CONST.CONTINUE_WATCHING}.${programModuleData.id}.isPublished`, {
              initialValue: programModuleData.isPublished || false
            })(
              <Checkbox checked={isPublished} onChange={handlePublishChange}>
                Published
          </Checkbox>)}
          <Button id={`btn-delete-program-carousel-page-${programModuleData.id}`}
            className="ml-2"
            icon='delete'
            onClick={() => handleDeleteProgramCarousel(programModuleData)}
            type='danger'>
            Delete
        </Button>
        </div>
      ]}
    >
      <Form.Item label='Title'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.CONTINUE_WATCHING}.${programModuleData.id}.title`, {
          initialValue: programModuleData.title ? programModuleData.title : '',
          rules: [{ required: true, message: 'Please input your title!' }]
        })(
          <Input placeholder='Title' />,
        )}
      </Form.Item>
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.CONTINUE_WATCHING}.${programModuleData.id}.order`, {
          initialValue: programModuleData.order || 1
        })(<Input placeholder='Order' />,)}
      </Form.Item>
    </Card >
  )
}

export default Programmatic
