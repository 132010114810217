import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Table, Typography, Button, Popconfirm, Input, Icon, notification, Tooltip, Pagination } from 'antd'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import moment from 'moment-timezone'
import { get, clone, startCase, upperCase } from 'lodash'
import { chatClient } from '../../apollo'
import { GET_PERSONS, SYNC_RECURLY_PLAN } from './graphql/Queries'
import { RESET_PASSWORD, UPDATE_PERSON } from './graphql/Mutations'
import { GET_PERSONS_SUBSCRIPTION } from '../user-management/graphql/Subscription'
import { UPDATE_USER_EMAIL, UPDATE_USER_NAME } from '../user-management/graphql/Mutations'
import Meta from '../../components/Meta'
import PersonModal from './components/PersonModal'
import EditEmailModal from '../user-management/components/EditEmailModal'
import EditUserNameModal from '../user-management/components/EditUserNameModal'
import './person-management.css'

const { Title } = Typography

export default function PersonManagement() {
  const [tableLoading, setTableLoading] = useState(false)
  const [syncId, setSyncId] = useState(undefined)
  const [showModal, setShowModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showUserNameModal, setShowUserNameModal] = useState(false)
  const [personEditableData, setPersonEditableData] = useState("")
  const [isSubmit, setSubmit] = useState(false)
  const [filters, setFilters] = useState({ role: 'USER' })
  const [orderByFilter, setOrderByFilter] = useState("email_ASC")
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  
  const { data: personData, loading, fetchMore, networkStatus } = useQuery(GET_PERSONS, { variables: { first: 10, filters, orderByFilter }, fetchPolicy: "network-only", client: chatClient })
  const { data: personSubscriptionData } = useSubscription(GET_PERSONS_SUBSCRIPTION, { client: chatClient })
  

  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleCancelEmail() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowEmailModal(false)
    setPersonEditableData('')
  }

  function handleSuccess(operation) {
    openNotification('success', `User ${operation} successfully`)
    setShowEmailModal(false)
    setShowModal(false)
    setSubmit(false)
    setTableLoading(false)
    setPersonEditableData('')
    const isFormObjFields = get(saveFormRef, 'current.props.form.resetFields')
    if (isFormObjFields) {
      saveFormRef.current.props.form.resetFields()
    }
  }

  function handleUpdateEmail() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      setSubmit(true)
      const { email } = values
      if (email && personEditableData && personEditableData.email) {
        chatClient.mutate({
          mutation: UPDATE_USER_EMAIL,
          variables: {
            currentEmail: personEditableData.email,
            newEmail: email
          },
        })
          .then(() => {
            handleSuccess('updated')
          }).catch((error) => {
            console.log('updateUserEmailResult: ', error)
            handleRequestFail(error)
          })
      }
    })
  }

  function handleUpdateUserName() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      setSubmit(true)
      const { userName } = values
      if (userName && personEditableData && personEditableData.id) {
        chatClient.mutate({
          mutation: UPDATE_USER_NAME,
          variables: {
            personID: personEditableData.id,
            username: userName
          }          
        })
          .then(() => {
            setShowUserNameModal(false)
            handleSuccess('updated')
          }).catch((error) => {
            console.log('updateUserNameResult: ', error)
            handleRequestFail(error)
          })
      }
    })
  }

  function handleCancelUserName() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowUserNameModal(false)
    setPersonEditableData('')
  }

  function onChangeUserNameClick() {
    setShowModal(false)
    setShowUserNameModal(true)
  }

  function onChangeEmailClick() {
    setShowModal(false)
    setShowEmailModal(true)
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowModal(false)
    setPersonEditableData("")
  }

  function handleRequestFail(e) {
    setTableLoading(false)
    if (e && e.message) {
      const message =
        (e && e.graphQLErrors && Array.isArray(e.graphQLErrors) && e.graphQLErrors[0] && e.graphQLErrors[0].message) || e.message
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (error, values) => {
      if (error) {
        return
      }
      setSubmit(true)
      const { id: personId, firstName, lastName, recurlyId, recurlyPlan, promoPlan, promoPlanExpiration } = values
      await chatClient.mutate({
        mutation: UPDATE_PERSON,
        variables: { personId, firstName, lastName, recurlyId, recurlyPlan, promoPlan, promoPlanExpiration }
      })
        .then(createPersonResult => {
          setTimeout(() => {
            openNotification("success", "User updated successfully")
            setSubmit(false)
            setShowModal(false)
            form.resetFields()
          }, 500)
        })
        .catch((e) => {
          handleRequestFail(e)
        })
    })
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            id={`btn-${dataIndex}-search`}
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button id={`btn-${dataIndex}-reset`} onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon id={`btn-icon-${dataIndex}-search`} type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      sorter: (a, b) => a.age - b.age
    }
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      setLastPage(false)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm()
    let tempFilters = {}
    if (dataIndex === 'email') {
      tempFilters["email_starts_with"] = selectedKeys[0] && selectedKeys[0].toLowerCase()
    } else if (dataIndex === "lastName") {
      tempFilters["lastName_starts_with"] = selectedKeys[0]
    } else if (dataIndex === "firstName") {
      tempFilters["firstName_starts_with"] = selectedKeys[0]
    } else {
      tempFilters["userName_starts_with"] = selectedKeys[0]
    }
    setCurrentPageNumber(1)
    setLastPage(false)
    setFilters({ ...filters, ...tempFilters })
  }

  function handleReset(clearFilters, dataIndex) {
    clearFilters()
    let tempFilters = clone(filters)
    if (dataIndex === 'email') {
      delete tempFilters['email_starts_with']
    } else if (dataIndex === 'lastName') {
      delete tempFilters['lastName_starts_with']
    } else if (dataIndex === "firstName") {
      delete tempFilters['firstName_starts_with']
    } else {
      delete tempFilters['userName_starts_with']
    }
    setCurrentPageNumber(1)
    setLastPage(false)
    setFilters(tempFilters)
  }

  async function handleActive(personId, isActive) {
    setTableLoading(true)
    await chatClient.mutate({
      mutation: UPDATE_PERSON,
      variables: { personId, isActive }
    })
      .then(async updateUserResult => {
        setTimeout(() => {
          openNotification("success", "User updated successfully")
          setTableLoading(false)
        }, 500)
      })
      .catch((e) => {
        handleRequestFail(e)
      })
  }


  async function handleResetPassword(authZeroID) {
    setTableLoading(true)
    chatClient
      .mutate({
        mutation: RESET_PASSWORD,
        variables: { authId: authZeroID },
      })
      .then(({ data: { resetPassword: { message } = {} } = {} }) => {
        setTimeout(() => {
          openNotification('success', message)
          setTableLoading(false)
        }, 500)
      })
      .catch((e) => {
        handleRequestFail(e)
      })
  }

  async function handleSync(recurlyId) {
    setSyncId(recurlyId)
    chatClient.query({
      query: SYNC_RECURLY_PLAN,
      fetchPolicy: "network-only",
      variables: { recurlyId }
    }).then((data) => {
      setSyncId(undefined)
      openNotification("success", "Sync Completed")
    }).catch((error) => {
      handleRequestFail(error)
      setSyncId(undefined)
    })
  }

  function handleEditButton(data) {
    setPersonEditableData(data)
    setShowModal(true)
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      width: '10%',
      sorter: false,
      className: 'break-word'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      width: '10%',
      sorter: false,
      className: 'break-word'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
      sorter: false
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      width: '10%',
      ...getColumnSearchProps('userName'),
      sorter: false,
      className: 'break-word'
    },
    {
      title: 'Recurly Plan',
      dataIndex: 'recurlyPlan',
      width: '10%',
      render: (text) => {
        if (text && text === 'INSIDER_PLUS') {
          return 'INSIDER ANNUAL'
        }
        return `${startCase(upperCase(text))}`
      }
    },
    {
      title: 'Promo Plan',
      dataIndex: 'promoPlan',
      width: '10%',
      render: (text) => {
        if (text && text === 'INSIDER_PLUS') {
          return 'INSIDER ANNUAL'
        }
        return `${startCase(upperCase(text))}`
      }
    },
    {
      title: 'Promo Expiration',
      dataIndex: 'promoPlanExpiration',
      width: '20%',
      render: (text, record) => text ? moment(text).tz('America/Los_Angeles').format('Do MMMM YYYY, hh:mm A') : ''
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      width: '20%',
      render: (text, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Icon id={`btn-icon-${record.id}-edit`} type="edit" theme="twoTone" onClick={() => handleEditButton(record)} />
            </Tooltip>
            <Tooltip title="Sync Plan">
              <Icon id={`btn-icon-${record.id}-sync-plan`} type="sync" spin={syncId === record.recurlyId} onClick={() => handleSync(record.recurlyId)} />
            </Tooltip>
            <Popconfirm
              title={`Are you sure you want to initiate a password reset for this user?`}
              onConfirm={() => handleResetPassword(record.authZeroID)}
            >
              <Tooltip
                title={`Reset password`}
              >
                <Icon id={`btn-icon-${record.id}-reset`} type="key" />
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title={`Are you sure you want to ${record.isActive ? 'deactivate' : 'activate'} this user?`}
              onConfirm={() => handleActive(record.id, !record.isActive)}
            >
              <Tooltip title={`${record.isActive ? 'Deactivate' : 'Activate'}`}>
                {record.isActive
                  ? <Icon id={`btn-icon-${record.id}-active`} type="stop" />
                  : <Icon id={`btn-icon-${record.id}-deactive`} type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                }
              </Tooltip>
            </Popconfirm>
          </div>
        )
      }
    }
  ]

  let personTableData = []
  if (personData && personData.persons) {
    personTableData = personData.persons.map((person, key) => (
      { key: key.toString(), ...person }
    ))
  }

  if (personSubscriptionData && personSubscriptionData.person && personSubscriptionData.person.mutation === "UPDATED") {
    const checkInPersons = personTableData.findIndex((person) => person.id === personSubscriptionData.person.node.id)
    if (checkInPersons >= 0) {
      personTableData[checkInPersons] = { key: personTableData[checkInPersons].key, ...personSubscriptionData.person.node }
    }
  }

  useEffect(() => {  
    async function handlePageChange() {
      if(!loading) {
        try {
          setTableLoading(true)
          fetchMore({
            variables: {
              skip: (currentPageNumber - 1) * 10,
              first: 10
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { persons } = fetchMoreResult
                if (persons?.length < 10) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                setTableLoading(false)
                return persons?.length ? { persons: [...persons] } : prevResult
              }
            }
          })
        } catch (error) {
          setTableLoading(false)
          handleRequestFail(error) 
        } 
      }
    }

    handlePageChange()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber])

  useEffect(() => {
    if(!loading && personData?.persons) {
      if(personData?.persons?.length < 10){
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [loading])

  return (
    <Row gutter={24} type="flex" className="person-management-wrapper">
      <Meta title="Subscriber Management" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Subscribers</Title>
        </div>
        <Table
          rowClassName={({ isActive }) => {
            return isActive ? 'is-active' : 'is-inactive'
          }}
          locale={{ emptyText: 'This module is currently suspended' }}
          loading={networkStatus === 1 || tableLoading || loading} columns={columns} dataSource={personTableData} pagination={false} onChange={handleChange} />
        <div className="page-change-btn-wrapper">
          <Button icon="left" onClick={()=> setCurrentPageNumber(currentPageNumber - 1)} disabled={ currentPageNumber === 1 }/>
          <Button icon="right" onClick={()=> setCurrentPageNumber(currentPageNumber + 1)} disabled={lastPage}/>
        </div>
        {showModal && <PersonModal personEditableData={personEditableData} saveFormRef={saveFormRef}
          showModal={showModal} isSubmit={isSubmit} handleOk={handleCreate} handleCancel={handleCancel}
          onChangeEmailClick={onChangeEmailClick}
          onChangeUserNameClick={onChangeUserNameClick} />}
        {showEmailModal && <EditEmailModal
          saveFormRef={saveFormRef}
          showModal={showEmailModal}
          isSubmit={isSubmit}
          handleOkEmail={handleUpdateEmail}
          handleCancelEmail={handleCancelEmail}
          {...personEditableData} />}
        {showUserNameModal && <EditUserNameModal
          saveFormRef={saveFormRef}
          showModal={showUserNameModal}
          isSubmit={isSubmit}
          handleOkUserName={handleUpdateUserName}
          handleCancelUserName={handleCancelUserName}
          {...personEditableData}
        />}
      </Col>
    </Row>
  )
}
