import React from 'react'
import { Col, Button } from 'antd'
import { truncate } from 'lodash'
import { truncateName } from '../../../../common/utility'

function PortraitView(props) {
  const { show, id, image, name, deleteCarouselShow, index, indexId } = props
  return <Col className='show-carousel-portrait-card-col-wrapper'>
    <div className='show-carousel-portrait-card-wrapper'>
      <div className='show-carousel-card-delete'>
        <Button
          id={`btn-show-${indexId}-${index}-delete`}
          key='2'
          size='default'
          icon='delete'
          type='danger'
          onClick={() => {
            deleteCarouselShow(id, show)
          }}
        />
      </div>
      <div className='show-carousel-card'>
        <div className='text-center d-flex'>
          <img src={`${image}?w=127&h=227`} alt={name} title={name} />
        </div>
        <div className='show-carousel-detail line-height-1'>
          <p className='show-carousel-title'>{truncateName(name, { 'length': 40 })}</p>  {/* added due to Potrait View  */}
        </div>
      </div>
    </div>
  </Col>
}

function LandscapeView(props) {
  const { show, id, image, name, deleteCarouselShow, index, indexId } = props
  return <Col
    xs={24}
    sm={24}
    md={8}
    lg={6}
    xl={6}
    className='tile-card-show-col-wrapper'
  >
    <div className='tile-card-show-wrapper'>
      <div className='tile-card-delete'>
        <Button
          id={`btn-show-${indexId}-${index}-delete`}
          key='2'
          size='default'
          icon='delete'
          type='danger'
          onClick={() => {
            deleteCarouselShow(id, show)
          }}
        />
      </div>
      <div className='tile-card-show'>
        <div className='text-center d-flex'>
          <img src={`${image}?w=300&h=250`} alt={name} title={name} />
        </div>
        <div className="tile-details-show line-height-1">
          <p className='tile-title-show'>{truncateName(name)}</p>
        </div>
      </div>
    </div>
  </Col>
}

function ShowListCard(props) {
  const { orientation } = props
  const isLandscapeView = orientation === 'LANDSCAPE'
  return (
    isLandscapeView ? <LandscapeView {...props} /> : <PortraitView {...props} />
  )
}

export default ShowListCard
