import gql from 'graphql-tag'

const GET_FEATURED_EPISODES = gql`
  query episodes($after: String) {
    episodes(
      where: { isFeatured: true }
      after: $after
      orderBy: featureBannerWeight_DESC
    ) {
      id
      title
      show {
        id
      }
      image
      status
      createdAt
    }
  }
`

export { GET_FEATURED_EPISODES }
