import React, { Component } from 'react'
import { Modal, Button, Form } from 'antd'
import PersonForm from './PersonForm'

const CollectionCreateForm = Form.create({ name: 'update_person' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, isSubmit, onCancel, onCreate, form, personEditableData, onChangeEmailClick, onChangeUserNameClick } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title="Update User Details"
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button id="btn-person-cancel" key="back" onClick={onCancel}>Cancel</Button>,
            <Button id="btn-person-save" key="submit" type="primary" loading={isSubmit} onClick={onCreate}>Update</Button>
          ]}
        >
          <PersonForm getFieldDecorator={getFieldDecorator} 
            showModal={visible} {...personEditableData} 
            onChangeEmailClick={onChangeEmailClick}
            onChangeUserNameClick={onChangeUserNameClick}/>
        </Modal>
      )
    }
  }
)

export default class PersonModal extends Component {
  render() {
    const { showModal, isSubmit, handleCancel, handleOk, saveFormRef, 
      personEditableData, onChangeEmailClick, onChangeUserNameClick } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={showModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleOk}
        personEditableData={personEditableData}
        onChangeEmailClick={onChangeEmailClick}
        onChangeUserNameClick={onChangeUserNameClick}
      />
    );
  }
}
