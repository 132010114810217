import React, { useState, useContext, useRef } from 'react'
import { Col, Button, notification } from 'antd'
import * as Sentry from '@sentry/browser'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import AddClipsModal from './AddClipModal'
import { CREATE_CLIP, UPDATE_CLIP } from '../graphql/Mutations'
import { GET_CLIP_SIGNED_PUT_URL } from '../graphql/Queries'
import { AppContext } from '../../../AppContext'
import { trim } from 'lodash'
import '../episode.css'

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function name(params) {}

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const {
        title,
        description,
        clipNumber,
        image,
        clipAccess,
        showId,
        clipType,
        videoId,
      } = values
      try {
        let imageFileName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        const { history } = props
        var data = {
          name: trim(title),
          description: trim(description),
          image: imageFileName,
          clipAccess: { set: clipAccess },
          createdBy: { connect: { id: state.currentUser.id } },
          clipNumber,
        }

        if (clipType === 'VIDEO') {
          data.video = { connect: { id: videoId } }
        } else {
          data.show = { connect: { id: showId } }
        }

        const createClipResult = await client.mutate({
          mutation: CREATE_CLIP,
          variables: { data },
        })
        const clipId = createClipResult.data.createClip.id
        if (image && image.length > 0) {
          const tempFilenameArray = image[0].name.split('.')
          const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
          const temp = createClipResult.data.createClip.image.split('/')
          const tempFilename = temp[temp.length - 1].split('.')[0]
          const fileName = `${tempFilename}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_CLIP_SIGNED_PUT_URL,
            variables: {
              fileName,
              clipId: createClipResult.data.createClip.id,
              contentType,
            },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getClipSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_CLIP,
            variables: {
              data: {
                image: fileName,
              },
              where: {
                id: clipId,
              },
            },
          })
        }
        form.resetFields()
        setShowModal(false)
        setSubmit(false)
        history.push(`/clips/${createClipResult.data.createClip.id}`)
      } catch (e) {
        Sentry.captureException(e)
        setSubmit(false)
        let errorMessage =
          (e &&
            e.graphQLErrors &&
            e.graphQLErrors[0] &&
            e.graphQLErrors[0].message) ||
          'Something Went Wrong'

        openNotification('error', errorMessage)
      }
    })
  }

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        className="add-show-card-wrapper"
        onClick={() => setShowModal(true)}
      >
        <div className="add-show-wrapper">
          <Button
            id='btn-clip-create'
            type="dashed"
            size="large"
            className="add-show-button"
            onClick={() => setShowModal(true)}
          >
            + Add Clip
          </Button>
        </div>
      </Col>
      <AddClipsModal
        saveFormRef={saveFormRef}
        showModal={showModal}
        isSubmit={isSubmit}
        isLive={isLive}
        showIsLIve={true}
        handleLiveChange={() => setIsLive(!isLive)}
        handleCancel={() => {
          setShowModal(false)
          setShowModal(false)
          saveFormRef.current.props.form.resetFields()
        }}
        handleCreate={handleCreate}
        showCreatedAt={true}
      />
    </>
  )
}
