import gql from "graphql-tag"


const UPDATE_DISCUSSION_STATUS = gql`
mutation updateDiscussion($isLive: Boolean, $status: String, $id: ID!){
  updateDiscussion(data: { isLive: $isLive, status: $status }, where: { id: $id }) {
    id
  }
}
`

export { UPDATE_DISCUSSION_STATUS }
