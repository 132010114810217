import React, { useState, useContext, useRef } from 'react'
import { Col, Button, notification } from 'antd'
import * as Sentry from '@sentry/browser'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import { GET_PODCAST_SIGNED_PUT_URL } from '../graphql/Queries'
import { CREATE_PODCAST, UPDATE_PODCAST_IMAGE } from '../graphql/Mutations'
import AddPodcastModal from './AddPodcastModal'
import { AppContext } from '../../../AppContext'

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleShowModal(b) {
    setShowModal(b)
  }

  function handleRequestFail(errorMessage) {
    setSubmit(false)
    openNotification('error', errorMessage || 'Something Went Wrong')
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const queryVariables = values
      const {
        coverImage,
        backgroundImage,
        logoImage,
      } = values
      try {
        let coverImageName = ''
        let backgroundImageName = ''
        let logoImageName = ''
        if (coverImage && coverImage.length > 0) {
          coverImageName = coverImage[0].name
        }
        if (backgroundImage && backgroundImage.length > 0) {
          backgroundImageName = backgroundImage[0].name
        }
        if (logoImage && logoImage.length > 0) {
          logoImageName = logoImage[0].name
        }
        if (queryVariables.coverImage) {
          delete queryVariables.coverImage
        }
        if (queryVariables.backgroundImage) {
          delete queryVariables.backgroundImage
        }
        if (queryVariables.logoImage) {
          delete queryVariables.logoImage
        }
        if (!queryVariables.author) {
          queryVariables.author = state.currentUser.id
        }
        if (queryVariables.weight) {
          queryVariables.weight = Number(queryVariables.weight)
        } else {
          queryVariables.weight = 100
        }
        const createPodcastResult = await client.mutate({
          mutation: CREATE_PODCAST,
          variables: queryVariables,
        })

        if (coverImage && coverImage.length > 0) {
          const tempFilenameArray = coverImage[0].name.split('.')
          const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
          const fileName = `${createPodcastResult.data.createPodcastCustom.id
            }-coverImage-${new Date().getTime()}.${fileExt}`
          const contentType = coverImage[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_PODCAST_SIGNED_PUT_URL,
            variables: { fileName, contentType },
          })
          coverImageName = fileName
          await fileUpload(
            getSignedPutUrlResult.data.getPodcastSignedPutUrl.signedUrl,
            coverImage[0].originFileObj,
          )
        }
        if (backgroundImage && backgroundImage.length > 0) {
          const tempBgImgFilenameArray = backgroundImage[0].name.split('.')
          const bgFileExt =
            tempBgImgFilenameArray[tempBgImgFilenameArray.length - 1]
          const bgFileName = `${createPodcastResult.data.createPodcastCustom.id
            }-backgroundImage-${new Date().getTime()}.${bgFileExt}`
          const bgContentType = backgroundImage[0].type
          const getBISignedPutUrlResult = await client.query({
            query: GET_PODCAST_SIGNED_PUT_URL,
            variables: { fileName: bgFileName, contentType: bgContentType },
          })
          backgroundImageName = bgFileName
          await fileUpload(
            getBISignedPutUrlResult.data.getPodcastSignedPutUrl.signedUrl,
            backgroundImage[0].originFileObj,
          )
        }
        if (logoImage && logoImage.length > 0) {
          const tempLogoFilenameArray = logoImage[0].name.split('.')
          const logoFileExt =
            tempLogoFilenameArray[tempLogoFilenameArray.length - 1]
          const logoImageFileName = `${createPodcastResult.data.createPodcastCustom.id
            }-logoImage-${new Date().getTime()}.${logoFileExt}`
          const logoContentType = logoImage[0].type
          const getLogoSignedPutUrlResult = await client.query({
            query: GET_PODCAST_SIGNED_PUT_URL,
            variables: { fileName: logoImageFileName, contentType: logoContentType },
          })
          logoImageName = logoImageFileName
          await fileUpload(
            getLogoSignedPutUrlResult.data.getPodcastSignedPutUrl.signedUrl,
            logoImage[0].originFileObj,
          )
        }
        if (
          (logoImage && logoImage.length > 0) ||
          (backgroundImage && backgroundImage.length > 0) ||
          (coverImage && coverImage.length > 0)
        ) {
          await client.mutate({
            mutation: UPDATE_PODCAST_IMAGE,
            variables: {
              logoImage: logoImageName,
              backgroundImage: backgroundImageName,
              coverImage: coverImageName,
              podcastId: createPodcastResult.data.createPodcastCustom.id,
            },
          })
        }
        setShowModal(false)
        setSubmit(false)
        form.resetFields()
        props.history.push(`/podcasts/${createPodcastResult.data.createPodcastCustom.id}`)
      } catch (e) {
        Sentry.captureException(e)
        let errorMessage =
          e &&
            e.graphQLErrors &&
            e.graphQLErrors[0] &&
            e.graphQLErrors[0].message &&
            e.graphQLErrors[0].message === 'Same name already exist'
            ? e.graphQLErrors[0].message
            : 'Something Went Wrong'
        handleRequestFail(errorMessage)
      }
    })
  }
  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={4}
        xl={4}
        className="add-show-card-wrapper"
        onClick={() => handleShowModal(true)}
      >
        <div className="add-podcast-wrapper">
          <Button
            id='btn-podcast-create'
            type="dashed"
            size="large"
            className="add-podcast-button"
            onClick={() => handleShowModal(true)}
          >
            + Add Podcast
          </Button>
        </div>
      </Col>
      <AddPodcastModal
        saveFormRef={saveFormRef}
        showModal={showModal}
        isSubmit={isSubmit}
        handleCancel={() => {
          saveFormRef.current.props.form.resetFields()
          handleShowModal(false)
        }}
        handleCreate={handleCreate}
      />
    </>
  )
}
