import React, { useState, useContext, useRef } from 'react'
import { Col, Button, notification } from 'antd'
import { isEqual } from 'lodash'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import AddEpisodeModal from './AddEpisodeModal'
import { CREATE_PODCAST_EPISODE, UPDATE_PODCAST_EPISODE } from '../grapql/Mutations'
import { AppContext } from '../../../AppContext'
import { GET_PODCAST_EPISODE_SIGNED_PUT_URL } from '../grapql/Queries'
import { LOCATIONS } from '../../../common/constants'

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)

      const {
        title,
        schedule,
        description,
        episodeNumber,
        seasonId,
        publishDate,
        audioWithAdsAccess,
        audioAccess,
        allowedContinents,
        rating,
        thumbnail,
        locationRestriction,
        limitBy,
        allowedCountries
      } = values
      try {

        const { history, podcastId } = props

        if (locationRestriction) {
          if (limitBy === LOCATIONS.CONTINENT) {
            values.allowedCountries = []
          }
          else {
            values.allowedContinents = []
          }
        }
        else if (!locationRestriction) {
          values.allowedContinents = []
          values.allowedCountries = []
        }


        let data = {
          title,
          description,
          episodeNumber,
          seasonId: seasonId,
          podcastId: podcastId,
          scheduleAt: schedule,
          publishDate,
          audioWithAdsAccess: { set: audioWithAdsAccess },
          audioAccess: { set: audioAccess },
          rating,
          allowedContinents: { set: values.allowedContinents },
          allowedCountries: values.allowedCountries,
        }

        const createEpisodeResult = await client.mutate({
          mutation: CREATE_PODCAST_EPISODE,
          variables: { data },
        })

        const podcastEpisodeId = createEpisodeResult.data.createPodcastEpisodeCustom.id
        if (thumbnail && thumbnail.length > 0) {
          const tempFilenameArray = thumbnail[0].name.split('.')
          const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
          const fileName = `${podcastEpisodeId}-thumbnail-${new Date().getTime()}.${fileExt}`
          const contentType = thumbnail[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_PODCAST_EPISODE_SIGNED_PUT_URL,
            variables: {
              fileName,
              podcastEpisodeId: podcastEpisodeId,
              contentType,
            },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getPodcastEpisodeSignedPutUrl.signedUrl,
            thumbnail[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_PODCAST_EPISODE,
            variables: {
              data: {
                thumbnail: fileName,
              },
              where: { id: podcastEpisodeId },
            },
          })
        }
        form.resetFields()
        setShowModal(false)
        setSubmit(false)
        history.push(
          `/podcasts/${podcastId}/${podcastEpisodeId}`,
        )
      } catch (e) {
        Sentry.captureException(e)
        setSubmit(false)
        let errorMessage =
          e &&
            e.graphQLErrors &&
            e.graphQLErrors[0] &&
            e.graphQLErrors[0].message &&
            e.graphQLErrors[0].message === 'Same title already exist'
            ? e.graphQLErrors[0].message
            : 'Something Went Wrong'
        openNotification('error', errorMessage)
      }
    })
  }

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        xxl={6}
        className="add-episode-card-wrapper"
        onClick={() => setShowModal(true)}
      >
        <div className="add-podcastepisode-wrapper">
          <Button
            id='btn-episode-create'
            type="dashed"
            size="large"
            className="add-podcast-button"
            onClick={() => setShowModal(true)}
          >
            + Add Episode
          </Button>
        </div>
      </Col>
      {showModal && <AddEpisodeModal
        saveFormRef={saveFormRef}
        showModal={showModal}
        isSubmit={isSubmit}
        handleCancel={() => {
          setShowModal(false)
          saveFormRef.current.props.form.resetFields()
        }}
        handleCreate={handleCreate}
        podcastId={props.podcastId}
      />}
    </>
  )
}
