import React, { useEffect, useState, useRef, useContext, Fragment } from 'react'
import { Row, Col, notification, PageHeader, Button, Tooltip, Modal, Tag, Menu, Dropdown, Icon } from 'antd'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import screenfull from 'screenfull'
import { chatClient } from '../../apollo'
import { AppContext } from '../../AppContext'
import { GET_DISCUSSION_THREAD, GET_DISCUSSION } from '../../components/comments/graphql/Queries'
import { UPDATE_DISCUSSION, UPDATE_DISCUSSION_STATUS, DELETE_DISCUSSION } from './graphql/Mutations'
import { SUBSCRIBE_DISCUSSION } from '../../components/comments/graphql/Subscriptions'
import AddDiscussionModal from './components/AddDiscussionModal'
import Spinner from '../../components/loaders/Spinner'
import Page404 from '../../components/Page404'
import Meta from '../../components/Meta'
import Comments from '../../components/comments/Comments'
import './discussions.css'

const { confirm } = Modal

export default function DiscussionDetailPage({ history, match: { params: { discussionId } } }) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [fullScreenModeSupported, setFullScreenModeSupported] = useState(screenfull.isEnabled)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isAdminMessages, setIsAdminMessages] = useState(false)
  const { state } = useContext(AppContext)

  useEffect(() => {
    if (!screenfull || !screenfull.isEnabled) {
      setFullScreenModeSupported(false)
    } else {
      setFullScreenModeSupported(true)
    }

    if (screenfull.isEnabled) {
      screenfull.on('change', onScreenChange)
    }

    return () => {
      screenfull.exit()
      // screenfull.off('change', onScreenChange)
    }
  }, [])

  function onScreenChange() {
    const bodyElement = document.body
    if (screenfull.isFullscreen && bodyElement) {
      bodyElement.classList.add("fullscreen-mode")
    } else if (!screenfull.isFullscreen && bodyElement) {
      bodyElement.classList.remove("fullscreen-mode")
    }
    setIsFullScreen(screenfull.isFullscreen)
  }

  function handleScreenControllerClick(value) {
    if (!fullScreenModeSupported) {
      console.warn('Full Screen feature is not available')
      return
    }
    const bodyElement = document.body
    if (value && bodyElement) {
      bodyElement.classList.add("fullscreen-mode")
    } else if (!value && bodyElement) {
      bodyElement.classList.remove("fullscreen-mode")
    }
    screenfull.toggle()
    const addNewComment = document.getElementById('add-new-comment')
    if (addNewComment) {
      addNewComment.focus()
    }
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setIsAdminMessages(false)}>All Comments</Menu.Item>
      <Menu.Item onClick={() => setIsAdminMessages(true)}>Team Comments</Menu.Item>
    </Menu>
  )

  const saveFormRef = useRef()

  const { data, loading: isDiscussionsLoading, error: discussionsError, fetchMore, networkStatus, subscribeToMore } = useQuery(GET_DISCUSSION_THREAD, { client: chatClient, variables: { id: discussionId }, fetchPolicy: "network-only" })
  const { data: discussionData, loading: isDiscussionLoading } = useQuery(GET_DISCUSSION, { client: chatClient, variables: { id: discussionId }, fetchPolicy: "network-only" })
  const { data: subscriptionData } = useSubscription(SUBSCRIBE_DISCUSSION, { variables: { id: discussionId }, client: chatClient })

  const discussionStatus = data && data.discussionThread ?
    subscriptionData && subscriptionData.discussion && subscriptionData.discussion.node && subscriptionData.discussion.node.status ?
      subscriptionData.discussion.node.status.toUpperCase()
      : data.discussionThread.status.toUpperCase()
    : ''

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleRequestFail() {
    setIsSubmit(false)
    openNotification("error", "Something Went Wrong")
  }

  function handleShowModal() {
    setShowModal(true)
  }

  function handleUpdate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setIsSubmit(true)
        const { subject, host, description, schedule } = values
        let scheduleAtTime = null
        if (schedule) {
          scheduleAtTime = schedule.toISOString()
        }
        await chatClient.mutate({
          mutation: UPDATE_DISCUSSION,
          variables: { title: subject, email: host || state.currentUser.email, description, scheduleAt: scheduleAtTime, id: discussionId },
          refetchQueries: [{
            query: GET_DISCUSSION_THREAD,
            variables: { id: discussionId }
          },
          {
            query: GET_DISCUSSION,
            variables: { id: discussionId }
          }]
        })
        openNotification("success", "Discussion Updated Successfully")
        form.resetFields()
        setIsSubmit(false)
        setShowModal(false)
      } catch (error) {
        Sentry.captureException(error);
        handleRequestFail()
      }
    })
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    setShowModal(false)
  }

  function handleStartStop(value) {
    // let content
    let title = value ? "Are you sure you want to START this discussion?" : "Are you sure you want to END this discussion?"
    let okText = value ? "START NOW" : "END NOW"
    let okType = value ? "primary" : "danger"
    let message = value ? "Discussion has been started" : "Discussion has been ended"
    let status = value ? "OPEN" : "ENDED"
    confirm({
      title,
      okText,
      okType,
      async onOk() {
        try {
          await chatClient.mutate({
            mutation: UPDATE_DISCUSSION_STATUS,
            variables: { id: discussionId, isLive: value, status },
            refetchQueries: [{
              query: GET_DISCUSSION_THREAD,
              variables: { id: discussionId }
            }]
          })
          openNotification("success", message)
        }
        catch (error) {
          Sentry.captureException(error)
          handleRequestFail()
        }
      },
    })
  }

  function deleteDiscussion(discussionId) {
    confirm({
      title: 'Are you sure you want to DELETE this discussion?',
      content: 'Upon clicking DELETE, the discussion will be permanently erased.',
      okText: 'Delete',
      async onOk() {
        try {
          await chatClient.mutate({
            mutation: DELETE_DISCUSSION,
            variables: { id: discussionId }
          })
          openNotification("success", "Discussion Deleted Successfully")
          setTimeout(() => {
            history.push('/discussions')
          }, 500)
        }
        catch (error) {
          Sentry.captureException(error)
          handleRequestFail()
        }
      },
    })
  }
  function checkStatus(status) {
    return <Tag className={status}>{status}</Tag>
  }
  return (
    <div className={`fullscreen ${isFullScreen ? "fullscreen-enabled" : ""}`}>
      <div className="discussions-detailpage-wrapper">
        {
          networkStatus === 1 || isDiscussionLoading ? <Spinner /> :
            discussionsError ? <>{discussionsError.message}</> :
              (data && data.discussionThread) && (discussionData && discussionData.discussion) ?
                <>
                  <Meta title={data.discussionThread.title || ''} description="Discussion" />
                  <AddDiscussionModal
                    saveFormRef={saveFormRef}
                    showModal={showModal}
                    isSubmit={isSubmit}
                    handleOk={handleUpdate}
                    handleCancel={handleCancel}
                    subject={data.discussionThread.title}
                    scheduleAt={data.discussionThread.scheduleAt}
                    description={data.discussionThread.description}
                    isEdit={(discussionStatus === 'OPEN' || discussionStatus === 'ENDED')}
                    host={discussionData.discussion.owner.email}
                  />
                  <Row gutter={24} type="flex" className="discussions-wrapper">
                    <Col span={24}>
                      <PageHeader
                        onBack={() => history.push('/discussions')}
                        title={data.discussionThread.title}
                        tags={checkStatus(discussionStatus)}
                        className="box page-header"
                        extra={[
                          <Dropdown key="0" overlay={menu} placement="bottomCenter" className="comments-filter-dropdown">
                            <Button>{isAdminMessages ? "Team Comments" : "All Comments"}<Icon type="down" /></Button>
                          </Dropdown>,
                          <Button key="1" shape="circle" size="small" icon={isFullScreen ? "fullscreen-exit" : "fullscreen"} onClick={() => handleScreenControllerClick(!isFullScreen)} />,
                          <Fragment key={2}>
                            <Button key="3" shape="circle" size="small" icon="edit" onClick={() => handleShowModal()} />
                            {discussionStatus === 'OPEN' ?
                              <Tooltip key="4" title="Unable to delete! Discussion is live!" trigger="click">
                                <Button shape="circle" size="small" icon="delete" type="danger" ghost={true} />
                              </Tooltip>
                              :
                              <Button key="4" shape="circle" icon="delete" size="small" type="danger" ghost={true} onClick={() => deleteDiscussion(discussionId)} />
                            }
                            {discussionStatus === 'OPEN' ?
                              <Button key="5" type="danger" size="small" onClick={() => handleStartStop(false)}>END DISCUSSION</Button>
                              :
                              discussionStatus !== 'ENDED' &&
                              <Button key="5" type="primary" size="small" onClick={() => handleStartStop(true)}>START DISCUSSION</Button>
                            }
                          </Fragment>
                        ]}
                      />
                    </Col>
                    {
                      data.discussionThread.description && discussionStatus !== 'OPEN' && <Col span={24}>
                        <div className="box discussions-description">
                          <div className="fw-600">Description</div>
                          <div className="description-content">
                            {data.discussionThread.description}
                          </div>
                        </div>
                      </Col>
                    }
                  </Row>
                  <Comments isFullscreen={screenfull.isFullscreen} isDiscussion={true} isAdminMessages={isAdminMessages} subscribeToMore={subscribeToMore} discussionId={discussionId} discussionThreadMessages={data.discussionThread.messages} fetchMore={fetchMore} isDiscussionsLoading={isDiscussionsLoading} isLive={discussionStatus === 'OPEN'} description={data.discussionThread.description ? true : false} />
                </>
                :
                <Page404 />
        }
      </div>
    </div>
  )
}
