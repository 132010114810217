import { notification } from 'antd'
import { truncate } from 'lodash'

export function openNotification(type, message) {
  notification[type]({
    message,
  })
}

export function titleCase(str) {
  let string = str.toLowerCase().split(' ');
  for (var i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
  }
  return string.join(' ');
}

export function truncateName(name, option) {
  return truncate(name, option || { 'length': 90 })

}



export const UniqueArray = (array) => {
  let uniqueArray = [...new Set(array)];
  return uniqueArray
}