import React, { useState, useContext, useRef } from 'react'
import { Col, Button, notification } from 'antd'
import { isEqual } from 'lodash'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import client from '../../../apollo'
import { fileUpload } from '../../../common/fileUpload'
import AddEpisodeModal from './AddEpisodeModal'
import { CREATE_EPISODE, UPDATE_EPISODE } from '../graphql/Mutations'
import { GET_EPISODE_SIGNED_PUT_URL, GET_EPISODE } from '../graphql/Queries'
import { AppContext } from '../../../AppContext'
import { LOCATIONS } from '../../../common/constants'

export default function (props) {
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const [simulateLive, setSimulateLive] = useState(false)
  const [isAllowScheduleAccessLevel, setIsAllowScheduleAccessLevel] = useState(false)
  const { state } = useContext(AppContext)
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
    })
  }

  function isValidAccessLevels(values) {
    const { videoAccess, scheduleVideoAccess } = values
    if (videoAccess && scheduleVideoAccess) {
      let isValid = false
      if (!isEqual(videoAccess.sort(), scheduleVideoAccess.sort())) {
        isValid = true
      }
      return isValid
    }
    return true
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      if (isAllowScheduleAccessLevel && !isValidAccessLevels(values)) {
        openNotification('error', 'Please select different schedule access levels from previous one.')
        return
      }
      setSubmit(true)
      const {
        title,
        description,
        episodeNumber,
        image,
        schedule,
        isLive,
        simulateLive,
        isFeatured,
        featuredBannerImage,
        featureBannerWeight,
        createdAt,
        accessLevelScheduleAt,
        scheduleVideoAccess,
        seasonId,
        allowedContinents,
        rating,
        locationRestriction,
        limitBy,
        allowedCountries
      } = values
      try {
        let imageFileName = ''
        let featuredBannerImageName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        if (featuredBannerImage && featuredBannerImage.length > 0) {
          featuredBannerImageName = featuredBannerImage[0].name
        }
        const { history, showId } = props
        let videoAccess = ['INSIDER', 'INSIDER_PLUS', 'ALL_ACCESS']
        let audioAccess = ['INSIDER', 'INSIDER_PLUS', 'ALL_ACCESS']
        let liveChatAccess = ['INSIDER', 'INSIDER_PLUS', 'ALL_ACCESS']
        if (isLive) {
          videoAccess = values.videoAccess
          audioAccess = values.videoAccess
          liveChatAccess = values.liveChatAccess
        }
        if(locationRestriction){
          if(limitBy === LOCATIONS.CONTINENT){
            values.allowedCountries = []
          }
          else{
            values.allowedContinents = []
          }
        }
        else if(!locationRestriction){
          values.allowedContinents = []
          values.allowedCountries = []
        }

        let scheduleAudioAccess = scheduleVideoAccess || []
        const createEpisodeResult = await client.mutate({
          mutation: CREATE_EPISODE,
          variables: {
            title,
            description,
            episodeNumber,
            image: imageFileName,
            showId,
            videoAccess,
            audioAccess,
            liveChatAccess,
            isLive,
            simulateLive,
            isFeatured,
            featuredBannerImage: featuredBannerImageName,
            featureBannerWeight: parseInt(featureBannerWeight),
            scheduleAt: schedule,
            createdBy: state.currentUser.id,
            createdAt,
            accessLevelScheduleAt,
            scheduleVideoAccess,
            scheduleAudioAccess,
            seasonId,
            allowedContinents : values.allowedContinents,
            rating,
            allowedCountries : values.allowedCountries,
            publishDate: createdAt || moment()
          },
        })

        const episodeId = createEpisodeResult.data.createEpisode.id
        if (image && image.length > 0) {
          const tempFilenameArray = image[0].name.split('.')
          const fileExt = tempFilenameArray[tempFilenameArray.length - 1]
          const temp = createEpisodeResult.data.createEpisode.image.split('/')
          const tempFilename = temp[temp.length - 1].split('.')[0]
          const fileName = `${tempFilename}.${fileExt}`
          const contentType = image[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_EPISODE_SIGNED_PUT_URL,
            variables: {
              fileName,
              episodeId: createEpisodeResult.data.createEpisode.id,
              contentType,
            },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getEpisodeSignedPutUrl.signedUrl,
            image[0].originFileObj,
          )
        }
        if (featuredBannerImage && featuredBannerImage.length > 0) {
          const tempFilename = featuredBannerImage[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${episodeId}-feature-${new Date().getTime()}.${fileExt}`
          const contentType = featuredBannerImage[0].type
          const getSignedPutUrlResult = await client.query({
            query: GET_EPISODE_SIGNED_PUT_URL,
            variables: { fileName, episodeId, contentType },
          })
          await fileUpload(
            getSignedPutUrlResult.data.getEpisodeSignedPutUrl.signedUrl,
            featuredBannerImage[0].originFileObj,
          )
          await client.mutate({
            mutation: UPDATE_EPISODE,
            variables: {
              data: {
                featuredBannerImage: fileName,
              },
              where: { id: episodeId },
            },
            refetchQueries: [
              {
                query: GET_EPISODE,
                variables: { id: episodeId },
              },
            ],
          })
        }
        form.resetFields()
        setShowModal(false)
        setSubmit(false)
        props.setIsModalOpen(false)
        history.push(
          `/shows/${showId}/${createEpisodeResult.data.createEpisode.id}`,
        )
      } catch (e) {
        Sentry.captureException(e)
        setSubmit(false)
        let errorMessage =
          e &&
            e.graphQLErrors &&
            e.graphQLErrors[0] &&
            e.graphQLErrors[0].message &&
            e.graphQLErrors[0].message === 'Same title already exist'
            ? e.graphQLErrors[0].message
            : 'Something Went Wrong'
        openNotification('error', errorMessage)
      }
    })
  }

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={8}
        xl={8}
        xxl={6}
        className="add-episode-card-wrapper"
        onClick={() => {
          setShowModal(true)
          props.setIsModalOpen(true)
        }}
      >
        <div className="add-episode-wrapper">
          <Button
            id='btn-episode-create'
            type="dashed"
            size="large"
            className="add-show-button"
            onClick={() => {
              setShowModal(true)
              props.setIsModalOpen(true)
            }
            }
          >
            + Add Episode
          </Button>
        </div>
      </Col>
      {showModal && <AddEpisodeModal
        saveFormRef={saveFormRef}
        showModal={showModal}
        isSubmit={isSubmit}
        isLive={isLive}
        simulateLive={simulateLive}
        showIsLIve={true}
        handleLiveChange={() => {
          if (isLive) {
            setSimulateLive(false)
          }
          setIsLive(!isLive)
        }}
        handlesimulateLiveChange={() => setSimulateLive(!simulateLive)}
        handleCancel={() => {
          setShowModal(false)
          setIsLive(false)
          props.setIsModalOpen(false)
          setIsAllowScheduleAccessLevel(false)
          saveFormRef.current.props.form.resetFields()
        }}
        handleCreate={handleCreate}
        isAllowScheduleAccessLevel={isAllowScheduleAccessLevel}
        handleAccessLevelChange={(e) => setIsAllowScheduleAccessLevel(e.target.checked)}
        showId={props.showId}
      />}
    </>
  )
}
