import React, { Component } from 'react'
import { Col, Tag } from 'antd'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

export default class EpisodeCard extends Component {
  render() {
    const {
      showId,
      episode: { id, title, image, status, createdAt },
    } = this.props
    return (
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={8}
        xl={8}
        xxl={6}
        className="episode-card-col-wrapper"
      >
        <div className="episode-card-wrapper">
          <Tag className={status}>{status}</Tag>
          <NavLink to={`/shows/${showId}/${id}`} className="tile-card-episode">
            <div className="text-center d-flex">
              <img src={`${image}?w=300&h=250`} alt={title} title={title} />
            </div>
            <div className="episode-details">
              <p className="episode-title">{title}</p>
              <p className="episode-date">
                {moment(createdAt).format('MMMM Do, YYYY')}
              </p>
            </div>
          </NavLink>
        </div>
      </Col>
    )
  }
}
