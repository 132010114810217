import React, { Component } from 'react'
import ClipCard from './ClipCard'
export default class ClipWrapper extends Component {
  constructor(props) {
    super(props)
    this.container = document.querySelector('.content-wrapper')
  }
  componentDidMount() {
    this.container.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.handleOnScroll)
  }

  handleOnScroll = () => {
    if (
      this.container.scrollTop + this.container.offsetHeight ===
      this.container.scrollHeight
    ) {
      this.props.onLoadMore()
    }
  }

  render() {
    const { clips, updateEpisodeList } = this.props
    return (
      clips &&
      clips.map((clip, id) => (
        <ClipCard updateEpisodeList={updateEpisodeList} {...clip} key={id} />
      ))
    )
  }
}
