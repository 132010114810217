import React, { useEffect, useState } from 'react'
import { Card, Form, Input, Button, Modal, Checkbox, Upload, Icon, message, Radio, Select, Row } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce, trimStart } from 'lodash'
import client from '../../../../apollo'
import { DELETE_CAROUSEL_MODULE } from '../../graphql/Mutations'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import { GET_CLIP, GET_CLIPS, GET_EPISODE, GET_EPISODES, GET_MODULAR_PAGE, GET_MODULAR_PAGES, GET_SHOW, GET_SHOWS, GET_VIDEO, GET_VIDEOS } from '../../graphql/Queries'
import MoveArrow from '../MoveArrow'
import ShowImage from './ShowImage'

const confirm = Modal.confirm
const { Option } = Select

function beforeImageUpload(file) {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg'
  ) {
    return true
  }
  message.error('You can only upload image file!')
  return false
}

const contentTypes = [
  { 'label': 'Clip', 'value': 'CLIP' },
  { 'label': 'Video', 'value': 'VIDEO' },
  { 'label': 'Episode', 'value': 'EPISODE' },
  { 'label': 'Show', 'value': 'SHOW' },
  { 'label': 'Module Page', 'value': 'MODULE_PAGE' },
]

const queryList = {
  CLIP: GET_CLIPS,
  EPISODE: GET_EPISODES,
  VIDEO: GET_VIDEOS,
  SHOW: GET_SHOWS,
  MODULE_PAGE: GET_MODULAR_PAGES,
}

const selectLabel = {
  CLIP: 'Clips',
  EPISODE: 'Episodes',
  VIDEO: 'Videos',
  SHOW: 'Shows',
  MODULE_PAGE: 'Module Page',
}

const queryListForItem = {
  CLIP: GET_CLIP,
  EPISODE: GET_EPISODE,
  VIDEO: GET_VIDEO,
  SHOW: GET_SHOW,
  MODULE_PAGE: GET_MODULAR_PAGE,
}

function FreeFormHighlight(props) {
  const { freeFormModuleData, form, setModuleList, modulesList, indexId, modularPageId } = props
  const [isPublished, setIsPublished] = useState(freeFormModuleData.isPublished || false)
  const [removeFile, setRemoveFile] = useState(false)
  const [routing, setRouting] = useState(freeFormModuleData.routing ? freeFormModuleData.routing : '')
  const [listLoading, setListLoading] = useState(false)
  const [list, setList] = useState([])
  const [contentType, setContentType] = useState(freeFormModuleData.referenceType ? freeFormModuleData.referenceType : '')
  const [selectedItem, setSelectedItem] = useState({})

  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  let searchDebounceJob
  const removeImageField = getFieldValue(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.removeImage`)

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteFreeFormCarousel(freeformCarousel) {
    confirm({
      title: `Are you sure you want to remove freeform highlight carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === freeformCarousel.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: freeformCarousel.id, type: MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== freeformCarousel.id)
        setModuleList(newModules)
      },
    })
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  function handleRemoveImage() {
    confirm({
      title: `Are you sure you want to remove image override`,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        setFieldsValue({ [`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.removeImage`]: true })
      },
    })
  }

  const uploadProps = {
    customRequest() {
      return false
    },
  }

  function onRoutingChange(e) {
    setRouting(e.target.value)
    if (e.target.value === 'REFERENCE') {
      if (freeFormModuleData.referenceType) {
        setContentType(freeFormModuleData.referenceType)
      }
    }
  }

  function getList(searchFilter = undefined) {
    if (contentType === '') {
      return
    }
    setListLoading(true)
    let variables = { first: 20, where: {} }

    if (contentType === 'MODULE_PAGE') {
      variables.where.isPublished = true
    } else if (contentType !== 'SHOW') {
      variables.where.status = 'PUBLISHED'
    }

    if (searchFilter && searchFilter.length > 0) {
      if (contentType === 'EPISODE' || contentType === 'MODULE_PAGE') {
        variables.where.OR = [
          { title_starts_with: titleCase(searchFilter) },
          { title_starts_with: searchFilter.toUpperCase() },
          { title_starts_with: searchFilter.toLowerCase() },
          { title_starts_with: searchFilter },
          { title_contains: titleCase(searchFilter) },
          { title_contains: searchFilter.toUpperCase() },
          { title_contains: searchFilter.toLowerCase() },
          { title_contains: searchFilter },
          { title_ends_with: searchFilter },
          { title_ends_with: searchFilter.toUpperCase() },
          { title_ends_with: searchFilter.toLowerCase() },
        ]
      } else {
        variables.where.OR = [
          { name_starts_with: titleCase(searchFilter) },
          { name_starts_with: searchFilter.toUpperCase() },
          { name_starts_with: searchFilter.toLowerCase() },
          { name_starts_with: searchFilter },
          { name_contains: titleCase(searchFilter) },
          { name_contains: searchFilter.toUpperCase() },
          { name_contains: searchFilter.toLowerCase() },
          { name_contains: searchFilter },
          { name_ends_with: searchFilter },
          { name_ends_with: searchFilter.toUpperCase() },
          { name_ends_with: searchFilter.toLowerCase() },
        ]
      }
    }

    let query = queryList[contentType]
    client
      .query({
        query: query,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          if (data.hasOwnProperty('episodes')) {
            setList(data.episodes)
          }
          if (data.hasOwnProperty('clips')) {
            setList(data.clips)
          }
          if (data.hasOwnProperty('videos')) {
            setList(data.videos)
          }
          if (data.hasOwnProperty('shows')) {
            setList(data.shows)
          }
          if (data.hasOwnProperty('modulePages')) {
            setList(data.modulePages)
          }
        }
        setListLoading(false)
      })
      .catch((e) => {
        console.log({ e })
        setList([])
        setListLoading(false)
      })
  }

  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType])

  function onSearch(e) {
    if (searchDebounceJob) {
      searchDebounceJob.cancel()
    }
    searchDebounceJob = debounce(() => {
      getList(e)
    }, 500)
    searchDebounceJob()
  }

  function onContentChange(type) {
    setContentType(type)
    setFieldsValue({ [`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.referenceId`]: undefined })
  }

  function onSelect(value) {
    getList()
  }


  useEffect(() => {
    if (!freeFormModuleData.referenceId) {
      return
    }

    setListLoading(true)
    let query = queryListForItem[contentType]
    let variables = { where: { id: freeFormModuleData.referenceId } }
    client
      .query({
        query: query,
        variables: variables,
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        if (data) {
          if (data.hasOwnProperty('episode')) {
            setSelectedItem(data.episode)
          }
          if (data.hasOwnProperty('clip')) {
            setSelectedItem(data.clip)
          }
          if (data.hasOwnProperty('video')) {
            setSelectedItem(data.video)
          }
          if (data.hasOwnProperty('show')) {
            setSelectedItem(data.show)
          }
          if (data.hasOwnProperty('getModularPage')) {
            setSelectedItem(data.getModularPage)
          }
        }
        setListLoading(false)
      })
      .catch((e) => {
        setSelectedItem({})
        setListLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeFormModuleData.referenceId])

  function contentTypesItem(itemList) {
    let filterItemList = itemList.filter(item => {
      if (item.id === modularPageId || item.id === selectedItem.id) {
        return false
      }
      return true
    })

    return filterItemList.map((item, key) => {
      return <Option value={item.id} key={key}>
        {item.title ? item.title : item.name}
      </Option>
    })
  }

  const normalize = (value) => trimStart(value)

  return (
    <Card key={freeFormModuleData.id} title='Freeform: Highlight' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.isPublished`, {
            initialValue: freeFormModuleData.isPublished || false
          })(
            <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${indexId}-isPublished`}>
              Published
          </Checkbox>)}
          <Button id={`btn-delete-freefom-highlight-carousel-${indexId}`}
            className="ml-2"
            icon='delete'
            onClick={() => handleDeleteFreeFormCarousel(freeFormModuleData)}
            type='danger'>
            Delete
          </Button>
        </div>
      ]}
    >
      <Form.Item label='Title'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.title`, {
          initialValue: freeFormModuleData.title ? freeFormModuleData.title : '',
          rules: [{ required: true, message: 'Please input your title!' }]
        })(
          <Input placeholder='Title' className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${indexId}-title`} />,
        )}
      </Form.Item>
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.order`, {
          initialValue: freeFormModuleData.order || 1
        })(<Input placeholder='Order' className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${indexId}-order`} />,)}
      </Form.Item>
      <Form.Item label='Description'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.description`, {
          initialValue: freeFormModuleData.description ? freeFormModuleData.description : '',
          rules: [{ required: true, message: 'Please input your description!' }],
          normalize: normalize,
        })(
          <Input.TextArea rows={5} placeholder='Description' className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${freeFormModuleData.id}-description`} />,
        )}
      </Form.Item>
      <Form.Item label="Image (override) (3360 x 1728)" className="mb-0">
        {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.removeImage`, {
          initialValue: false
        })(
          <Row>
            {freeFormModuleData.image && !removeImageField &&
              <ShowImage id={freeFormModuleData.id} image={freeFormModuleData.image} handleRemoveImage={handleRemoveImage} />}
          </Row>
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.image`, {
          valuePropName: 'fileList',
          getValueFromEvent: normFile,
        })(
          <Upload
            name="logo"
            {...uploadProps}
            listType="picture"
            multiple={false}
            onRemove={(e) => handleRemove(e)}
          >
            <Button className="mt-5" id={`btn-freeform-highlight-image-${indexId}`}>
              <Icon type="upload" /> {freeFormModuleData.image ? 'Edit Image' : 'Add Image'}
            </Button>
          </Upload>,
        )}
      </Form.Item>
      <Form.Item label='CTA Text'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.CTAText`, {
          initialValue: freeFormModuleData.CTAText ? freeFormModuleData.CTAText : '',
        })(
          <Input placeholder='CTA Text' className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${indexId}-CTAText`} />,
        )}
      </Form.Item>
      <Form.Item label="Routing">
        {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.routing`, {
          initialValue: freeFormModuleData.routing ? freeFormModuleData.routing : '',
        })(
          <Radio.Group onChange={onRoutingChange} className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${indexId}-routing`}>
            <Radio value="INTERNAL">Internal</Radio>
            <Radio value="REFERENCE">Reference</Radio>
            <Radio value="EXTERNAL">External</Radio>
          </Radio.Group>,
        )}
      </Form.Item>
      {
        (routing === 'INTERNAL' || routing === 'EXTERNAL') && (
          <Form.Item label='Link'>
            {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.link`, {
              initialValue: freeFormModuleData.link ? freeFormModuleData.link : '',
              validateTrigger: 'onBlur',
              rules: [
                (routing === 'INTERNAL' ? {
                  message: 'Please input valid url!', validator: (rule, value, cb) => {
                    if (value !== '') {
                      let pattern = /(^\/{1}(?![/]))(?!.*[.\\])(?!http)(?!https)(?!.*\/$)(?!.*[.]com$)(?!.*&$)/
                      if (pattern.test(value)) {
                        return true
                      }
                      return false
                    }
                    return true
                  }
                } : {
                  message: 'Please input valid url!',
                  type: 'url',
                  required: false
                })
              ]
            })(
              <Input placeholder='Link' className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${indexId}-link`} />,
            )}
          </Form.Item>
        )
      }
      {
        routing === 'REFERENCE' && (
          <>
            <Form.Item label="Select Content Type">
              {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.referenceType`, {
                initialValue: freeFormModuleData.referenceType ? freeFormModuleData.referenceType : undefined,
              })(
                <Select placeholder="Select Content Type" onChange={onContentChange} className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${indexId}-referenceType`}>
                  {contentTypes.map(content =>
                    <Option key={`${freeFormModuleData.id} - ${content.label}`} value={content.value}>{content.label}</Option>
                  )}
                </Select>,
              )}
            </Form.Item>
            {contentType && getFieldValue(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.referenceType`) && <Form.Item label={`Select ${selectLabel[contentType]}`}>
              {getFieldDecorator(`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}.${freeFormModuleData.id}.referenceId`, {
                initialValue: (freeFormModuleData.referenceId && contentType === freeFormModuleData.referenceType) ? freeFormModuleData.referenceId : undefined,
                rules: [{ required: contentType ? true : false, message: `Please Select ${selectLabel[contentType]}!` }]
              })(
                <Select placeholder={`Select ${selectLabel[contentType]}`}
                  loading={listLoading}
                  className={`${MODULE_TYPES_CONST.FREEFORM_HIGHLIGHT}-${indexId}-referenceId`}
                  showSearch
                  allowClear
                  onSearch={onSearch}
                  optionFilterProp="children"
                  onSelect={onSelect}
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {Object.keys(selectedItem).length > 0 && contentType === freeFormModuleData.referenceType && <Option value={selectedItem.id} key={selectedItem.id}>
                    {selectedItem.title ? selectedItem.title : selectedItem.name}
                  </Option>}
                  {contentTypesItem(list)}
                </Select>,
              )}
            </Form.Item>}
          </>
        )
      }

    </Card >
  )
}

export default FreeFormHighlight
