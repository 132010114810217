import React, { Component } from 'react'
import { Col, Tag } from 'antd'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

export default class FeaturedEpisodeCard extends Component {
  render() {
    const {
      id,
      title,
      image,
      status,
      createdAt,
      show: { id: showId },
    } = this.props
    return (
      <Col
        xs={24}
        sm={24}
        md={8}
        lg={6}
        xl={6}
        className="text-center show-card"
      >
        <div className="episode-card-wrapper">
          <Tag className={status}>{status}</Tag>
          <NavLink
            to={`/featured-episodes/${showId}/${id}/true`}
            className="episode-card"
          >
            <div className="text-center d-flex">
              <img src={`${image}?w=300&h=250`} alt={title} title={title} />
            </div>
            <div className="episode-details">
              <p className="episode-title">{title}</p>
              <p className="episode-date">
                {moment(createdAt).format('MMMM Do, YYYY')}
              </p>
            </div>
          </NavLink>
        </div>
      </Col>
    )
  }
}
