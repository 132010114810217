import React, { Component } from 'react'
import PodcastCard from './podcastCard'

export default class PodcastWrapper extends Component {
  constructor(props) {
    super(props)
    this.container = document.querySelector('.content-wrapper')
  }
  componentDidMount() {
    this.container.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.handleOnScroll)
  }

  handleOnScroll = () => {
    if (
      this.container.scrollTop + this.container.offsetHeight >=
      this.container.scrollHeight - 5
    ) {
      this.props.onLoadMore()
    }
  }

  render() {
    const { podcasts } = this.props
    return podcasts && podcasts.map((podcast, id) => <PodcastCard {...podcast} key={id} />)
  }
}
