import gql from 'graphql-tag'

export const CREATE_CLIP = gql`
  mutation createClip($data: ClipCreateInput!) {
    createClip(data: $data) {
      id
      name
      description
      clipNumber
      image
      status
      show {
        id
        name
      }
      video {
        id
        name
      }
      muxAssetId
      uploadId
      muxPlaybackId
      thumbnail
      videoURL
      duration
      clipAccess
      createdAt
      updatedAt
      message
    }
  }
`

export const UPDATE_CLIP = gql`
  mutation updateClip($data: ClipUpdateInput!, $where: ClipWhereUniqueInput!) {
    updateClip(data: $data, where: $where) {
      id
      name
      description
      clipNumber
      image
      status
      show {
        id
        name
      }
      video {
        id
        name
      }
      muxAssetId
      uploadId
      muxPlaybackId
      thumbnail
      videoURL
      duration
      clipAccess
      createdAt
      updatedAt
      message
    }
  }
`

export const DELETE_CLIP = gql`
  mutation deleteClip($clipId: ID!) {
    deleteClip(where: { id: $clipId }) {
      id
    }
  }
`

export const REQUEST_MASTER_ACCESS = gql`
  mutation requestMasterAccess($assetId: String!) {
    requestMasterAccess(where: { assetId: $assetId })
  }
`
export const CREATE_CAPTION_CUSTOM = gql`
  mutation createCaptionCustom($data: [CreateCaptionInput!]!) {
    createCaptionCustom(data: $data){
      message
    }
  }
`
export const DELETE_CAPTION_CUSTOM = gql`
  mutation deleteCaptionCustom($where: DeleteCaptionCustomInput) {
    deleteCaptionCustom(where: $where){
      message
    }
  }
`