import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { Player, ControlBar, ClosedCaptionButton } from 'video-react'
import HLSSource from '../../../HLSSource'
import ReactAudioPlayer from 'react-audio-player'
import client from '../../../apollo'
import { GET_SEGMENT } from '../graphql/Queries'
import * as Sentry from '@sentry/browser'
import { openNotification } from '../../../common/utility'
import { get } from 'lodash'

export default function PlayerModal(props) {
  const { segment, isAudioPlay, showPlayerModal, handleOk, captions } = props
  const [playerVideoUrl, setPlayerVideoUrl] = useState()
  const tracks = captions && captions.length > 0 && captions.map((caption, i) =>
    <track
      key={i}
      kind="captions"
      src={caption.url}
      srcLang={caption.language}
      label={caption.name}
      default={i === 0 ? true : false}
    />
  )


  useEffect(() => {
    getVideoData()            // this api call due to subscription is not giving url with token 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment.video])

  function getVideoData() {
    client.query({
      query: GET_SEGMENT,
      variables: { id: segment.id },
      fetchPolicy: 'network-only'
    }).then(data => {
      if (data && data.data) {
        const updatedVideoUrl = get(data, 'episodeSegment.video')
        setPlayerVideoUrl(updatedVideoUrl)
      }
    }).catch(e => {
      Sentry.captureException(e)
      const message = e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
      if (message) {
        openNotification('error', message)
      } else {
        openNotification('error', 'Something Went Wrong')
      }
    })
  }


  return (
    showPlayerModal &&
    <Modal
      title={segment.title}
      width={`${!isAudioPlay ? '60%' : '40%'}`}
      height={`${!isAudioPlay ? '60vh' : '40vh'}`}
      visible={showPlayerModal}
      footer={null}
      onOk={() => handleOk()}
      onCancel={() => handleOk()}
    >
      <div className="w-100 text-center">
        {
          !isAudioPlay ?
            <Player children={tracks ? tracks : ''}>
              <HLSSource
                isVideoChild
                src={playerVideoUrl || segment.video}
              />
              <ControlBar autoHide={true}>
                {captions.length > 0 && <ClosedCaptionButton order={7} />}
              </ControlBar>
              {/* <ReactPlayer url={segment.video} playing controls config={{ file: { hlsOptions: { forceHls: true } } }} className='react-player' /> */}
            </Player>
            :
            <ReactAudioPlayer
              src={segment.audio}
              autoPlay
              controls
            />
        }
      </div>
    </Modal>
  )
}
