import React, { useState, useRef } from 'react'
import { notification, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { get, isEqual } from 'lodash'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import client from '../../../apollo'
import SegmentList from '../../segment/components/SegmentList'
import SegmentDetails from '../../segment/components/SegmentDetails'
import { GET_EPISODE, GET_SEGMENT_SIGNED_PUT_URL } from '../graphql/Queries'
import { SUBSCRIBE_EPISODE_SEGMENTS } from '../graphql/Subscriptions'
import AddSegmentModal from '../../segment/components/AddSegmentModal'
import { CREATE_EPISODE_SEGMENT, UPDATE_SEGMENT, DELETE_SEGMENT } from '../graphql/Mutations'
import { fileUpload } from '../../../common/fileUpload'

const { confirm } = Modal

function handleMoment(duration) {
  if (!duration) {
    return ''
  }
  let tempDuration = moment.duration(duration, 'seconds')._data
  tempDuration = tempDuration.hours !== 0 ? `${tempDuration.hours}:${tempDuration.minutes}:${tempDuration.seconds} Hours`
    : (tempDuration.minutes !== 0 ? `${tempDuration.minutes}:${tempDuration.seconds} Minutes` : `${tempDuration.seconds} Seconds`)
  return tempDuration
}

export default withRouter(function (props) {
  const { episodeId } = props
  let segmentsParts = []
  const saveSegmentFormRef = useRef()
  const { match } = props
  const { data: episodeData } = useQuery(GET_EPISODE, { fetchPolicy: 'network-only', variables: { id: episodeId } })
  const [segmentModalOpen, setSegmentModalOpen] = useState(false)
  const [isSegmentsSubmit, setIsSegmentSubmit] = useState(false)
  const [isNewSegment, setIsNewSegment] = useState(false)
  const [segment, setSegment] = useState(undefined)
  const [currentEpisode, setCurrentEpisode] = useState(undefined)
  const [showLiveChatAccssField, setShowLiveChatAccssField] = useState(false)
  const [videoLoading, setVideoLoading] = useState(false)
  const [audioLoading, setAudioLoading] = useState(false)
  const [isAllowScheduleAccessLevel, setIsAllowScheduleAccessLevel] = useState(false)

  function showSegmentModalWithEdit(segment, segmentPosition) {
    setSegmentModalOpen(true)
    if (segmentPosition === 0) {
      setShowLiveChatAccssField(true)
    } else {
      setShowLiveChatAccssField(false)
    }
    setSegment(segment)
    if (segment && segment.accessLevelScheduleAt) {
      setIsAllowScheduleAccessLevel(true)
    } else {
      setIsAllowScheduleAccessLevel(false)
    }
  }

  if (!segment && episodeData && episodeData.episode && episodeData.episode.segments) {
    setSegment(episodeData.episode.segments[0])
    setCurrentEpisode(episodeData.episode)
  }
  let SegmentNodes = []

  function transformSegmentData(segmentData) {
    return {
      'id': segmentData.id,
      'title': segmentData.title,
      'description': segmentData.description,
      'image': segmentData.image,
      'duration': handleMoment(segmentData.duration),
      'video': segmentData.video,
      'audio': segmentData.audio,
      'audioAccess': segmentData.audioAccess,
      'videoAccess': segmentData.videoAccess,
      'liveChatAccess': segmentData.liveChatAccess,
      'accessLevelScheduleAt': segmentData.accessLevelScheduleAt,
      'scheduleAudioAccess': segmentData.scheduleAudioAccess,
      'scheduleVideoAccess': segmentData.scheduleVideoAccess,
    }
  }

  if (episodeData && episodeData.episode && episodeData.episode.segments && episodeData.episode.segments.length > 0) {
    SegmentNodes = episodeData.episode.segments.map((segmentsPart) => {
      segmentsParts.push(transformSegmentData(segmentsPart))
      return { node: { id: segmentsPart.id } }
    })
  }

  useSubscription(SUBSCRIBE_EPISODE_SEGMENTS, { variables: { nodeArray: SegmentNodes } })

  function isValidAccessLevels(values) {
    const { audioAccess, videoAccess, scheduleAudioAccess, scheduleVideoAccess } = values
    if (audioAccess && scheduleAudioAccess && videoAccess && scheduleVideoAccess) {
      let isValid = false
      if (!isEqual(videoAccess.sort(), scheduleVideoAccess.sort())) {
        isValid = true
      } else if (!isEqual(audioAccess.sort(), scheduleAudioAccess.sort())) {
        isValid = true
      }
      return isValid
    }
    return true
  }

  function handleSegmentCreate() {
    const form = saveSegmentFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      const { title, description, image, audioAccess, videoAccess, order,
        accessLevelScheduleAt, scheduleAudioAccess, scheduleVideoAccess } = values
      if (isAllowScheduleAccessLevel && !isValidAccessLevels(values)) {
        openNotification('error', 'Please select different access levels from previous one.')
        return
      }
      setIsSegmentSubmit(true)
      try {
        let imageFileName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        const { params: { episodeId } } = match
        const createEpisodeResult = await client.mutate({
          mutation: CREATE_EPISODE_SEGMENT,
          variables: {
            title, description, imageFileName, order, audioAccess, videoAccess, episodeId,
            accessLevelScheduleAt, scheduleAudioAccess, scheduleVideoAccess
          },
          refetchQueries: [{
            query: GET_EPISODE,
            variables: { id: episodeId }
          }]
        })
        if (image && image.length > 0) {
          const tempFilename = image[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${createEpisodeResult.data.createEpisodeSegment.id}-${(new Date()).getTime()}.${fileExt}`
          const contentType = image[0].type
          const getSegmentSignedPutUrlResult = await client.query({
            query: GET_SEGMENT_SIGNED_PUT_URL,
            variables: { fileName, episodeId, segmentId: createEpisodeResult.data.createEpisodeSegment.id, contentType }
          })
          await fileUpload(getSegmentSignedPutUrlResult.data.getSegmentSignedPutUrl.signedUrl, image[0].originFileObj)
          await client.mutate({
            mutation: UPDATE_SEGMENT,
            variables: { image: fileName, segmentId: createEpisodeResult.data.createEpisodeSegment.id },
          })
        }
        setTimeout(() => {
          openNotification('success', 'Segment Added Successfully')
          form.resetFields()
          setSegmentModalOpen(false)
          setIsSegmentSubmit(isSegmentsSubmit)
          setIsNewSegment(false)
          setSegment(undefined)
        }, 500)
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail()
      }
    })
  }

  function handleRequestFail() {
    setIsSegmentSubmit(false)
    setSegmentModalOpen(false)
    setIsNewSegment(false)
    openNotification('error', 'Something Went Wrong')
  }

  function showSegmentConfirm(segmentId) {
    confirm({
      title: 'Are you sure you want to DELETE this segment?',
      content: 'Upon clicking DELETE, the segment will be permanently erased.',
      okText: 'Delete',
      okType: 'danger',
      async onOk() {
        try {
          await client.mutate({
            mutation: DELETE_SEGMENT,
            variables: { segmentId },
            refetchQueries: [{
              query: GET_EPISODE,
              variables: { id: episodeId }
            }]
          })
          openNotification('success', 'Segment Deleted Successfully')
          setSegment('')
        }
        catch (e) {
          Sentry.captureException(e)
          handleRequestFail()
        }
      },
    })
  }

  function openNotification(type, message) {
    notification[type]({
      message
    })
  }

  function handleSegmentUpdate() {
    const form = saveSegmentFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        console.log(err)
        return
      }
      if (isAllowScheduleAccessLevel && !isValidAccessLevels(values)) {
        openNotification('error', 'Please select different access levels from previous one.')
        return
      }
      setIsSegmentSubmit(true)
      const { id: segmentId } = segment
      try {
        if (!segmentId) {
          return
        }
        const { title, description, image, audioAccess, videoAccess, liveChatAccess } = values
        let accessLevelScheduleAt = values.accessLevelScheduleAt
        let scheduleAudioAccess = values.scheduleAudioAccess
        let scheduleVideoAccess = values.scheduleVideoAccess
        if (!isAllowScheduleAccessLevel) {
          accessLevelScheduleAt = null
          scheduleAudioAccess = []
          scheduleVideoAccess = []
        }
        let imageFileName = ''
        if (image && image.length > 0) {
          imageFileName = image[0].name
        }
        const { params: { episodeId } } = match
        await client.mutate({
          mutation: UPDATE_SEGMENT,
          variables: {
            title, description, imageFileName, audioAccess, videoAccess, liveChatAccess, segmentId,
            accessLevelScheduleAt, scheduleAudioAccess, scheduleVideoAccess
          },
          refetchQueries: [{
            query: GET_EPISODE,
            variables: { id: episodeId }
          }]
        })
        if (image && image.length > 0) {
          const tempFilename = image[0].name.split('.')
          const fileExt = tempFilename[tempFilename.length - 1]
          const fileName = `${segmentId}-${(new Date()).getTime()}.${fileExt}`
          const contentType = image[0].type
          const getSegmentSignedPutUrlResult = await client.query({
            query: GET_SEGMENT_SIGNED_PUT_URL,
            variables: { fileName, episodeId, segmentId, contentType }
          })
          await fileUpload(getSegmentSignedPutUrlResult.data.getSegmentSignedPutUrl.signedUrl, image[0].originFileObj)
          await client.mutate({
            mutation: UPDATE_SEGMENT,
            variables: { image: fileName, segmentId },
          })
        }
        openNotification('success', 'Segment Updated Successfully')
        form.resetFields()
        setSegmentModalOpen(false)
        setIsSegmentSubmit(false)
        setSegment(undefined)
      } catch (e) {
        Sentry.captureException(e)
        handleRequestFail()
      }
    })
  }

  const episode = get(episodeData, 'episode')
  const episodeStatus = get(episodeData, 'episode.status')
  const segments = get(episodeData, 'episode.segments') || []

  return (
    episode ? <>
      <SegmentList
        segmentsParts={segmentsParts}
        selectedSegment={segment}
        setSegment={setSegment}
        setSegmentModal={setSegmentModalOpen}
        showSegmentModalWithEdit={showSegmentModalWithEdit}
        showSegmentConfirm={showSegmentConfirm}
        videoLoading={videoLoading}
        audioLoading={audioLoading}
        setIsNewSegment={setIsNewSegment}
        episodeStatus={episodeStatus}
      />
      <SegmentDetails
        segment={segment}
        episodeStatus={episodeStatus}
        episodeId={episodeId}
        setVideoLoading={setVideoLoading}
        setAudioLoading={setAudioLoading}
        videoLoading={videoLoading}
        audioLoading={audioLoading}
        openNotification={openNotification}
      />
      <AddSegmentModal
        saveFormRef={saveSegmentFormRef}
        showModal={segmentModalOpen}
        isSubmit={isSegmentsSubmit}
        handleCancel={() => {
          setShowLiveChatAccssField(false)
          setSegmentModalOpen(false)
          saveSegmentFormRef.current.props.form.resetFields()
          setIsNewSegment(false)
          setIsAllowScheduleAccessLevel(false)
          setSegment(undefined)
        }}
        handleCreate={!isNewSegment ? handleSegmentUpdate : handleSegmentCreate}
        segment={segment}
        showLiveChatAccssField={showLiveChatAccssField}
        currentEpisode={currentEpisode}
        isNewSegment={isNewSegment}
        order={segments.length === 0 ? 0 : (segments[segments.length - 1].order + 1)}
        handleAccessLevelChange={(e) => setIsAllowScheduleAccessLevel(e.target.checked)}
        isAllowScheduleAccessLevel={isAllowScheduleAccessLevel}
      />
    </> : null
  )
})
