import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Select, Upload, Icon, message } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce } from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import client from '../../../../apollo'
import { GET_EPISODES, LIST_SHOW_FOR_SEASON } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE } from '../../graphql/Mutations'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import EpisodeCard from './EpisodeCard'
import MoveArrow from '../MoveArrow'
import ShowImage from './ShowImage'

const confirm = Modal.confirm
const Option = Select.Option
let debounceJob

function EpisodePremiereCarousel(props) {
  const { episodePremiereModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState('')
  const [episode, setEpisode] = useState(episodePremiereModuleData.episode || '')
  const [isPublished, setIsPublished] = useState(episodePremiereModuleData.isPublished || false)
  const [show, setShow] = useState('')
  const [removeFile, setRemoveFile] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const removeImageField = getFieldValue(`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.removeImage`)

  const {
    data: showsData,
    loading: showsLoading,
  } = useQuery(LIST_SHOW_FOR_SEASON, {
    fetchPolicy: 'network-only',
  })

  const [getEpisodes, {
    data: listEpisodes,
    loading: dataLoading
  }] = useLazyQuery(GET_EPISODES, {
    variables: {
      first: 5,
      where: {
        ...(show !== '' && { show: { id: show } }),
        ...(search !== '' && {
          OR: [{ title_starts_with: titleCase(search) },
          { title_starts_with: search.toUpperCase() },
          { title_starts_with: search.toLowerCase() },
          { title_starts_with: search },
          { title_contains: titleCase(search) },
          { title_contains: search.toUpperCase() },
          { title_contains: search.toLowerCase() },
          { title_contains: search },
          { title_ends_with: search },
          { title_ends_with: search.toUpperCase() },
          { title_ends_with: search.toLowerCase() },
          ]
        }),
      }
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (debounceJob) {
      debounceJob.cancel()
    }
    debounceJob = debounce(() => {
      getEpisodes()
    }, 500)
    debounceJob()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, show])

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      sorter: false,
      width: '20%',
      render: (image) => (
        <img
          className="table-image"
          src={image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'title',
      sorter: false,
      render: (title) => (<div className="table-name">
        {title}
      </div>)
    },

  ]

  function handleCancel() {
    setSearch('')
    setCurrentPageNumber(1)
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    setShow('')
    getEpisodes()
    setShowModal(true)
  }

  function onRowSelect(record, rowIndex) {
    return {
      onClick: event => {
        setEpisode(record)
        setFieldsValue({ [`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.episode`]: record })
        setSearch('')
        setCurrentPageNumber(1)
        setShowModal(false)
      }, // click row
    };
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteEpisodePremiereCarousel(episodePremiere) {
    confirm({
      title: `Are you sure you want to remove episode premiere carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === episodePremiere.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: episodePremiere.id, type: MODULE_TYPES_CONST.EPISODE_PREMIERE },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== episodePremiere.id)
        setModuleList(newModules)
      },
    })
  }

  const onSeasonSelect = (e) => {
    if (e === 'ALL') {
      setShow('')
    } else {
      setShow(e)
    }
    setSearch('')
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? (e.fileList = [e.file]) : false
    }
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  function handleRemoveImage() {
    confirm({
      title: `Are you sure you want to remove image override`,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        setFieldsValue({ [`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.removeImage`]: true })
      },
    })
  }

  const uploadProps = {
    customRequest() {
      return false
    },
  }

  function beforeImageUpload(file) {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    ) {
      return true
    }
    message.error('You can only upload image file!')
    return false
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      setLastPage(false)
    }
  }

  useEffect(() => {
    async function handlePageChange() {
      if (!dataLoading) {
        try {
          getEpisodes({
            variables: {
              skip: (currentPageNumber - 1) * 5,
              first: 5
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { episodes } = fetchMoreResult
                if (episodes?.length < 5) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                return episodes?.length ? { episodes: [...episodes] } : prevResult
              }
            }
          })
        } catch (error) {
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber])

  useEffect(() => {
    if (!dataLoading && listEpisodes?.episodes) {
      if (listEpisodes?.episodes?.length < 5) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [dataLoading])

  return (
    <Card key={episodePremiereModuleData.id} title='Episode Premiere' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.isPublished`, {
            initialValue: episodePremiereModuleData.isPublished || false
          })(
            <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.EPISODE_PREMIERE}-${indexId}-isPublished`}>
              Published
          </Checkbox>)}
          <Button id={`btn-delete-episode-premiere-${indexId}`}
            className="ml-2"
            icon='delete'
            onClick={() => handleDeleteEpisodePremiereCarousel(episodePremiereModuleData)}
            type='danger'>
            Delete
          </Button>
        </div>
      ]
      }
    >
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.order`, {
          initialValue: episodePremiereModuleData.order || 1
        })(<Input placeholder='Order' className={`${MODULE_TYPES_CONST.EPISODE_PREMIERE}-${indexId}-order`} />)}
      </Form.Item>
      <Form.Item label='Episode' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.episode`, {
          initialValue: episodePremiereModuleData.episode || '',
          rules: [{ type: 'object', required: true, min: 1, message: 'Please Add Episode!' }]
        })(
          <Row gutter={24} type="flex">
            {
              Object.keys(episode).length > 0 && <EpisodeCard
                name={episode.title}
                image={episode.image}
                description={episode.description} />
            }
          </Row>)
        }
      </Form.Item>
      <Row>
        <Col>
          <Button icon={episodePremiereModuleData.episode ? 'edit' : 'add'}
            id={`btn-add-episode-premiere-${indexId}`}
            onClick={openModal} >
            {episodePremiereModuleData.episode ? 'Edit Episode' : 'Add Episode'}
          </Button>
          <Modal
            title={episodePremiereModuleData.episode ? 'Edit Episode' : 'Add Episode'}
            visible={showModal}
            maskClosable={false}
            onCancel={handleCancel}
            footer={null}
          >
            <Row>
              <Col>
                <div className="d-flex justify-content-between">
                  <div>
                    <p>Search</p>
                    <Input.Search placeholder="Search Episode" onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-episode-premiere-${indexId}`} />
                  </div>
                  <div>
                    <p>Show</p>
                    <Select
                      style={{ width: '250px' }}
                      loading={showsLoading}
                      defaultValue={'ALL'}
                      placeholder="Select Shows"
                      onChange={(e) => onSeasonSelect(e)}
                    >
                      <Option key={'ALL'} value={'ALL'}>
                        All Shows
                      </Option>
                      {
                        showsData?.listShowForSeason?.map(({ name, id }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Table
                loading={dataLoading}
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={listEpisodes?.episodes}
                showHeader={false}
                onRow={onRowSelect}
                pagination={false}
                onChange={handleChange}
              />
              <div className="page-change-btn-wrapper">
                <Button icon="left" onClick={() => setCurrentPageNumber(currentPageNumber - 1)} disabled={currentPageNumber === 1} />
                <Button icon="right" onClick={() => setCurrentPageNumber(currentPageNumber + 1)} disabled={lastPage} />
              </div>
            </Row>
          </Modal>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Form.Item label="Image (override) (3360 x 1728)" className="mb-0">
            {getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.removeImage`, {
              initialValue: false
            })(
              < Row >
                {episodePremiereModuleData.premiereImage && !removeImageField &&
                  <ShowImage id={episodePremiereModuleData.id} image={episodePremiereModuleData.premiereImage} handleRemoveImage={handleRemoveImage} indexId={indexId} />}
              </Row>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(`${MODULE_TYPES_CONST.EPISODE_PREMIERE}.${episodePremiereModuleData.id}.image`, {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
            })(
              <Upload
                name="logo"
                {...uploadProps}
                listType="picture"
                multiple={false}
                onRemove={(e) => handleRemove(e)}
              >
                <Button className="image-override-margin-btn" id={`btn-episode-premiere-image-${indexId}`}>
                  <Icon type="upload" /> {episodePremiereModuleData.premiereImage ? 'Edit Image' : 'Add Image'}
                </Button>
              </Upload>,
            )}
          </Form.Item>
        </Col>
      </Row>
    </Card >
  )
}

export default EpisodePremiereCarousel
