import React, { Fragment } from 'react'
import { Row, Typography } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { GET_FEATURED_EPISODES } from './graphql/Queries'
import CardLoader from '../../components/loaders/CardLoader'
import FeaturedEpisodeWrapper from './components/FeaturedEpisodeWrapper'
import Meta from '../../components/Meta'
import './featured.css'
const { Title } = Typography
export default function (props) {
  const {
    data,
    loading: isShowsLoading,
    error: showsError,
    fetchMore,
  } = useQuery(GET_FEATURED_EPISODES, {
    variables: { first: 50 },
    fetchPolicy: 'network-only',
  })
  const { history } = props
  return !showsError ? (
    <Fragment>
      <div className="shows-title-wrapper">
        <Title level={2}>Featured Episodes</Title>
      </div>
      <Row gutter={24} type="flex" className="shows-wrapper shows-list">
        <Meta title="Shows" description="" />
        {isShowsLoading ? (
          <CardLoader />
        ) : (
          <FeaturedEpisodeWrapper
            episodes={data.episodes}
            onLoadMore={() =>
              fetchMore({
                variables: {
                  first: 10,
                  after: data.episodes[data.episodes.length - 1].id,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  const { episodes } = fetchMoreResult
                  return episodes.length
                    ? {
                        episodes: [...prevResult.episodes, ...episodes],
                      }
                    : prevResult
                },
              })
            }
          />
        )}
      </Row>
    </Fragment>
  ) : (
    <>{showsError.message}</>
  )
}
