import gql from 'graphql-tag'

export const GET_CLIPS = gql`
  query clips($first: Int!, $after: String, $where: ClipWhereInput) {
    clips(first: $first, after: $after, where: $where) {
      id
      name
      description
      clipNumber
      image
      status
      videoState
      show {
        id
        name
      }
      video {
        id
        name
      }
      muxAssetId
      uploadId
      muxPlaybackId
      thumbnail
      videoURL
      duration
      clipAccess
      createdAt
      updatedAt
      message
    }
  }
`
export const GET_SHOWS = gql`
  query shows {
    shows {
      id
      name
    }
  }
`
export const GET_VIDEOS = gql`
  query videos($where: VideoWhereInput) {
    videos(where: $where, orderBy: createdAt_DESC) {
      id
      name
    }
  }
`

export const GET_CLIP = gql`
  query clip($id: ID!) {
    clip(where: { id: $id }) {
      id
      name
      description
      clipNumber
      image
      status
      videoState
      show {
        id
        name
      }
      video {
        id
        name
      }
      captions {
        id
        name
        url
        language
        status
        muxTrackId
      }
      muxAssetId
      uploadId
      muxPlaybackId
      thumbnail
      videoURL
      duration
      clipAccess
      createdAt
      updatedAt
      message
    }
  }
`

export const GET_CLIP_LIVE_STREAM_URL = gql`
  query getVideoLiveStreamUrl($id: ID!) {
    getVideoLiveStreamUrl(where: { id: $id }) {
      videoURL
      streamKey
    }
  }
`

export const GET_CLIP_SIGNED_PUT_URL = gql`
  query getClipSignedPutUrl(
    $fileName: String!
    $clipId: String!
    $contentType: String!
  ) {
    getClipSignedPutUrl(
      data: { fileName: $fileName, clipId: $clipId, contentType: $contentType }
    ) {
      signedUrl
      getUrl
    }
  }
`

export const GET_CLIP_SIGNED_CLIP_PUT_URL = gql`
  query getClipSignedVideoPutUrl($clipId: ID, $corsOrigin: String!) {
    getClipSignedVideoPutUrl(corsOrigin: $corsOrigin, where: { id: $clipId }) {
      signedUrl
    }
  }
`

export const GET_MUX_DATA = gql`
  query getMuxData($assetId: String!) {
    getMuxData(where: { assetId: $assetId })
  }
`

export const LIST_SHOW_FOR_CLIP = gql`
  query listShowForClip {
    listShowForClip {
      id
      name
    }
  }
`
export const LIST_VIDEO_FOR_CLIP = gql`
  query listVideoForClip {
    listVideoForClip {
      id
      name
    }
  }
`
export const CAPTION_SIGNED_PUT_URL = gql`
  query captionSingedPutUrl($fileName: String!) {
    captionSingedPutUrl(fileName: $fileName){
      fileName
      signedUrl
      getUrl
    }
  }
`