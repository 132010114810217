import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Row, Button, Modal, Table, Col, notification, Checkbox, Icon } from 'antd'
import * as Sentry from '@sentry/browser'
import { get, debounce, cloneDeep, isEqual, uniqBy } from 'lodash'
import { useLazyQuery } from '@apollo/react-hooks'
import client from '../../../../apollo'
import { GET_VIDEOS } from '../../graphql/Queries'
import { DELETE_CAROUSEL_MODULE, DELETE_CAROUSEL_VIDEO } from '../../graphql/Mutations'
import VideoListCard from './VideoListCard'
import { MODULE_TYPES_CONST } from '../../../../common/constants'
import { openNotification, titleCase } from '../../../../common/utility'
import MoveArrow from '../MoveArrow'
import usePrevious from '../usePrevious'

const confirm = Modal.confirm
let debounceJob
const defaultPageSize = 7

function VideoCarousel(props) {
  const { videoModuleData, form, setModuleList, modulesList, indexId } = props
  const [showModal, setShowModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [search, setSearch] = useState('')
  const [videos, setVideos] = useState(videoModuleData.videos || [])
  const [isPublished, setIsPublished] = useState(videoModuleData.isPublished || false)
  const [videoNextOrder, setVideoNextOrder] = useState(videoModuleData.nextOrder || 1)
  const [orderChangeModal, setOrderChangeModal] = useState(false)
  const [current, setCurrent] = useState(1)
  const [lastPage, setLastPage] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const oldVideosProp = usePrevious(videoModuleData.videos || [])

  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {
    if (!isEqual(oldVideosProp, videoModuleData.videos)) {
      setVideos(videoModuleData.videos)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoModuleData.videos])

  const id_not = videos.map(({ id }) => (id));

  const [getVideos, {
    data: listVideos,
    loading: dataLoading
  }] = useLazyQuery(GET_VIDEOS, {
    variables: {
      first: 5,
      where: {
        OR:
          [{ name_starts_with: titleCase(search) },
              { name_starts_with: search.toUpperCase() },
              { name_starts_with: search.toLowerCase() },
              { name_starts_with: search },
              { name_contains: titleCase(search) },
              { name_contains: search.toUpperCase() },
              { name_contains: search.toLowerCase() },
              { name_contains: search },
              { name_ends_with: search },
              { name_ends_with: search.toUpperCase() },
            { name_ends_with: search.toLowerCase() },
          ],
        id_not_in: id_not
      }
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getVideos()
  }, [])

  useEffect(() => {
    if (search.length) {
      if (debounceJob) {
        debounceJob.cancel()
      }
      debounceJob = debounce(() => {
        getVideos()
      }, 500)
      debounceJob()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      sorter: false,
      width: '20%',
      render: (image) => (
        <img
          className="table-image"
          src={image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'name',
      sorter: false,
      render: (name) => (<div className="table-name">
        {name}
      </div>)
    },

  ]

  const columnsOrderChange = [
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (record) => (
        <img
          className="table-image"
          src={record.image}
          alt={''}
        />
      ),
    },
    {
      title: '',
      sorter: false,
      render: (_, record, index) => (<div className="table-name">{record.name}</div>),
    },
    {
      title: '',
      sorter: false,
      width: '20%',
      render: (_, record, index) => {
        let indexId = index + (current - 1) * defaultPageSize
        return <div>
          {(indexId !== (videos.length - 1)) &&
            <Icon type="down" className="p-2" onClick={() => changeOrder(indexId, videos, 1)} />}

          {(indexId !== 0) &&
            <Icon type="up" className="p-2" onClick={() => changeOrder(indexId, videos, -1)} />}
        </div>
      },
    },

  ]

  function changeOrder(indexId, videos, direction) {
    const item = cloneDeep(videos[indexId])
    const nextItem = cloneDeep(videos[indexId + direction])
    const itemOrder = item.carouselVideoOrder
    item.carouselVideoOrder = nextItem.carouselVideoOrder
    nextItem.carouselVideoOrder = itemOrder
    const newVideoList = videos.filter(clip => {
      if (clip.id === item.id || clip.id === nextItem.id) {
        return false
      }
      return true
    })

    if (direction === -1) {
      newVideoList.splice(indexId + direction, 0, item, nextItem)
    } else {
      newVideoList.splice(indexId, 0, nextItem, item)
    }
    setVideos(newVideoList)
    setFieldsValue({ [`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}.${videoModuleData.id}.videos`]: newVideoList })
  }

  function handleCancel() {
    setCurrent(1)
    setShowModal(false)
  }

  function openModal() {
    setSearch('')
    getVideos()
    setShowModal(true)
  }

  function handleOrderChangeModalCancel() {
    setOrderChangeModal(false)
  }

  function openOrderChangeModal() {
    setOrderChangeModal(true)
  }

  const onSelectChange = (selectedRowKeys, pageSelectedRowsData) => {
    let newSelectedRowData = uniqBy([...selectedRows, ...pageSelectedRowsData], 'id')
    setSelectedRows(newSelectedRowData)
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  let filteredVideosData = listVideos?.videos

  function addVideos() {
    setIsSubmit(true)
    let newSelectedVideos = selectedRowKeys.filter((row) => {
      for (let index = 0; index < videos.length; index++) {
        if (videos[index].id === row) {
          return false
        }
      }
      return true
    })
    let currentOrder = videoNextOrder
    let filterSelectedVideos = newSelectedVideos.map((row) => {
      let video = selectedRows.find((video) => video.id === row)
      if (!video) {
        video = listVideos?.videos?.find((video) => video.id === row)
      }
      video.carouselVideoOrder = currentOrder
      currentOrder = currentOrder + 1
      return video
    })
    setCurrent(1)
    setVideoNextOrder(currentOrder)
    setVideos([...videos, ...filterSelectedVideos])
    setFieldsValue({ [`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}.${videoModuleData.id}.videos`]: [...videos, ...filterSelectedVideos] })
    setShowModal(false)
    setIsSubmit(false)
    setSelectedRows([])
  }

  function handlePublishChange(e) {
    setIsPublished(e.target.checked)
    setFieldsValue({ [`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}.${videoModuleData.id}.isPublished`]: e.target.checked })
  }

  function handleRequestFail(e) {
    const message =
      e && e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
    if (message) {
      openNotification('error', message)
    } else {
      openNotification('error', 'Something Went Wrong')
    }
  }

  function handleDeleteVideoCarousel(videoCarousel) {
    confirm({
      title: `Are you sure you want to remove video carousel`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        const matchModuleId = props.modules.findIndex((module) => module.id === videoCarousel.id)
        if (matchModuleId !== -1) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_MODULE,
              variables: { id: videoCarousel.id, type: MODULE_TYPES_CONST.VIDEO_CAROUSEL },
            })
            const deleteModuleCustom = get(response, 'data.deleteModuleCustom')
            if (deleteModuleCustom && deleteModuleCustom.message) {
              openNotification('success', deleteModuleCustom.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleRequestFail(error)
            return
          }
        }
        let newModules = modulesList.filter((module) => module.id !== videoCarousel.id)
        setModuleList(newModules)
      },
    })
  }

  function deleteCarouselVideo(id, video) {
    confirm({
      title: `Are you sure you want to remove video`,
      okText: 'Delete',
      okType: 'danger',
      onOk: async () => {
        if (video.carouselVideoId) {
          try {
            const response = await client.mutate({
              mutation: DELETE_CAROUSEL_VIDEO,
              variables: { id: id },
            })
            const deleteCarouselVideo = get(response, 'data.deleteCarouselVideo')
            if (deleteCarouselVideo && deleteCarouselVideo.message) {
              openNotification('success', deleteCarouselVideo.message)
            }
          } catch (error) {
            Sentry.captureException(error)
            this.handleRequestFail(error)
            return
          }
        }
        const newVideos = videos.filter(item => item.id !== video.id)
        setVideos(newVideos);
        setFieldsValue({ [`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}.${videoModuleData.id}.videos`]: newVideos })
        setSelectedRowKeys(selectedRowKeys.filter(rowKeys => rowKeys !== video.id))
      },
    })
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrent(1)
      setLastPage(false)
    }
  }

  useEffect(() => {
    async function handlePageChange() {
      if (!dataLoading) {
        try {
          getVideos({
            variables: {
              skip: (current - 1) * 5,
              first: 5
            },
            updateQuery: (prevResult, { fetchMoreResult }) => {
              if (fetchMoreResult) {
                const { videos } = fetchMoreResult
                if (videos?.length < 5) {
                  setLastPage(true)
                } else {
                  setLastPage(false)
                }
                return videos?.length ? { videos: [...videos] } : prevResult
              }
            }
          })
        } catch (error) {
          handleRequestFail(error)
        }
      }
    }

    handlePageChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  useEffect(() => {
    if (!dataLoading && listVideos?.videos) {
      if (listVideos?.videos?.length < 5) {
        setLastPage(true)
      } else {
        setLastPage(false)
      }
    }
  }, [dataLoading])

  return (
    <Card key={videoModuleData.id} title='Video Carousel' className='mt-2 carousel-card'
      headStyle={{ backgroundColor: '#dddddd', borderRadius: '5px 5px 0px 0px' }}
      extra={<MoveArrow indexId={indexId} setModuleList={setModuleList} modulesList={modulesList} />}
      actions={[
        <div className="carousel-card-footer-wrapper">
          {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}.${videoModuleData.id}.isPublished`, {
            initialValue: videoModuleData.isPublished || false
          })(
            <Checkbox checked={isPublished} onChange={handlePublishChange} className={`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}-${indexId}-isPublished`}>
              Published
          </Checkbox>)}
          <Button id={`btn-delete-video-carousel-${indexId}`}
            className="ml-2"
            icon='delete'
            onClick={() => handleDeleteVideoCarousel(videoModuleData)}
            type='danger'>
            Delete
          </Button>
        </div>
      ]}
    >
      <Form.Item label='Title'>
        {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}.${videoModuleData.id}.title`, {
          initialValue: videoModuleData.title ? videoModuleData.title : '',
          rules: [{ required: true, message: 'Please input your title!' }]
        })(
          <Input placeholder='Title' className={`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}-${indexId}-title`} />,
        )}
      </Form.Item>
      <Form.Item label='Order' className="d-none">
        {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}.${videoModuleData.id}.order`, {
          initialValue: videoModuleData.order || 1
        })(<Input placeholder='Order' className={`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}-${videoModuleData.id}.order`} />,)}
      </Form.Item>
      <Form.Item label='Videos' required>
        {getFieldDecorator(`${MODULE_TYPES_CONST.VIDEO_CAROUSEL}.${videoModuleData.id}.videos`, {
          initialValue: videoModuleData.videos || [],
          rules: [{ type: 'array', required: true, min: 1, message: 'Please Add At least One Video!' }]
        })(
          <Row gutter={24} type='flex'>
            {videos.length > 0 && videos.map((video, index) => (
              <VideoListCard
                key={video.id}
                id={video.carouselVideoId ? video.carouselVideoId : video.id}
                name={video.name}
                image={video.image}
                index={index}
                indexId={indexId}
                video={video}
                deleteCarouselVideo={deleteCarouselVideo}
              />
            ))}
          </Row>)}
      </Form.Item>
      <Row>
        <Button icon='plus' id={`btn-add-video-${indexId}`}
          onClick={openModal}>
          Add Video
          </Button>
        {videos.length > 1 && (<Button className="ml-2" icon='edit' id={`btn-edit-video-${indexId}`}
          onClick={openOrderChangeModal}>
          Change Order
        </Button>)
        }
        <Modal
          title='Add Videos'
          visible={showModal}
          maskClosable={false}
          onOk={addVideos}
          onCancel={handleCancel}
          footer={[
            <Button id={`btn-video-carousel-cancel-${indexId}`} key='back' onClick={handleCancel}>
              Cancel
              </Button>,
            <Button
              id={`btn-video-carousel-save-${indexId}`}
              key='submit'
              type='primary'
              loading={isSubmit}
              onClick={addVideos}
            >
              Add
              </Button>,
          ]}
        >
          <Row>
            <Col>
              <Input.Search placeholder='Search Video' onChange={e => setSearch(e.target.value)} value={search} allowClear id={`seach-video-carousel-${indexId}`} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Table
              loading={dataLoading}
              columns={columns}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              dataSource={filteredVideosData}
              showHeader={false}
              pagination={false}
              onChange={handleChange}
            />
            <div className="page-change-btn-wrapper">
              <Button icon="left" onClick={() => setCurrent(current - 1)} disabled={current === 1} />
              <Button icon="right" onClick={() => setCurrent(current + 1)} disabled={lastPage} />
            </div>
          </Row>
        </Modal>
      </Row>
      <Modal
        title='Change Order'
        visible={orderChangeModal}
        maskClosable={false}
        onCancel={handleOrderChangeModalCancel}
        footer={null}
      >
        <Row>
          <Table
            columns={columnsOrderChange}
            rowKey={(record) => record.id}
            dataSource={videos}
            showHeader={false}
            pagination={{
              onChange(currentPage) { setCurrent(currentPage) },
              defaultPageSize
            }}
          />
        </Row>
      </Modal>
    </Card >
  )
}

export default VideoCarousel
