import gql from 'graphql-tag'

export const CREATE_MODULAR_PAGE = gql`
  mutation createModularPageCustom(
    $title: String!
    $slug: String     
  ) {
    createModularPageCustom(
      data: {
        title: $title
        slug: $slug        
      }
    ) {
      id
      message
    }
  }
`
export const UPDATE_MODULAR_PAGE = gql`
  mutation updateModularPageCustom($id: ID!, $data: updateModularPageInput!) {
    updateModularPageCustom(
      data: $data
      where: { id: $id }
    ) {
      id
      message
    }
  }
`

export const DELETE_CUSTOM_MODULAR_PAGE = gql`
  mutation deleteModularPageCustom($id: ID!) {
    deleteModularPageCustom(where: { id: $id }) {
      message
    }
  }
`

export const DELETE_CAROUSEL_CLIP = gql`
  mutation deleteCarouselClip($id: ID!) {
    deleteCarouselClip(where: { id: $id }) {
      message
    }
  }
`
export const DELETE_CAROUSEL_MODULE = gql`
  mutation deleteModuleCustom($id: ID!,$type:ModuleType!) {
    deleteModuleCustom(where: { id: $id,type: $type }) {
      message
    }
  }
`
export const DELETE_CAROUSEL_VIDEO = gql`
  mutation deleteCarouselVideo($id: ID!) {
    deleteCarouselVideo(where: { id: $id }) {
      message
    }
  }
`
export const DELETE_CAROUSEL_EPISODE = gql`
  mutation deleteCarouselEpisode($id: ID!) {
    deleteCarouselEpisode(where: { id: $id }) {
      message
    }
  }
`
export const DELETE_CAROUSEL_SHOW = gql`
  mutation deleteCarouselShow($id: ID!) {
    deleteCarouselShow(where: { id: $id }) {
      message
    }
  }
`

export const GET_PREMIER_IMG_PUT_URL = gql`
  query getPremiereImageSignedPutUrl($fileName: String!) {
    getPremiereImageSignedPutUrl(
      data: { fileName: $fileName }
    ) {
      signedUrl
      getUrl
    }
  }
`
export const DELETE_CAROUSEL_PODCAST = gql`
  mutation deleteCarouselPodcast($id: ID!) {
    deleteCarouselPodcast(where: { id: $id }) {
      message
    }
  }
`
export const DELETE_CAROUSEL_PODCAST_EPISODE = gql`
  mutation deleteCarouselPodcastEpisode($id: ID!) {
    deleteCarouselPodcastEpisode(where: { id: $id }) {
      message
    }
  }
`